import { store } from "../redux/store";

export const getMessage = (id: string, values?: Record<string, unknown>) => {
    const { intl } = store.getState();
    const { messages } = intl;

    let message = messages[id];

    // Emulate the message formatting from react-inl to be used in redux
    if (message && values) {
        Object.keys(values).forEach((key) => {
            if (values[key]) {
                message = message.replace(`{${key}}`, values[key]);
            }
        });
    }

    return message;
};
