import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "{name}은 목록이어야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "{name}을 입력해야합니다 : Min ~ : Max 문자.",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "{name}은 Boule 값이어야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "{name}은 올바른 날짜 형식을 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "{name}과 : 다른 것이 동일하지 않아야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "{name}은 올바른 이메일 주소를 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "{name}은 Max.아래에 입력해야합니다. ",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "{name}은 : Max 문자를 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "{name}은 최소 이상으로 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "{name} : 최소 문자 이상으로 입력하십시오.",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "selected {name}은 유효하지 않습니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "숫자 값으로 {name}을 입력하십시오.",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: '{name} value ": 값은 패턴과 일치해야합니다. ',
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "{name}이 필요합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: ": 기타 : 값이 될 때 {name}이 필요합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: "값이 필드에 설정되면 {name} 항목이 필요합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "{name}과 : 동일해야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "{name}은 문자열이어야합니다.",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "{name}에 대한 올바른 URL을 입력하십시오.",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name}이 필요합니다",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name}은 올바른 이메일 주소를 입력해야합니다",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name}은 올바른 전화 번호를 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name}은 올바른 카드 번호를 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_ZIP]: "{name}은 올바른 우편 번호를 입력해야합니다.",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name}은 정확하지 않습니다",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "만료 된 카드",
    [VALIDATION_LABELS.VALIDATION_CVV_LENGTH]: "보안 코드는 3자리로 입력하세요.",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "이 항목은 필수입니다",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name}을 사용할 수없는 문자가 포함되어 있습니다",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name} 형식은 사기입니다",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name}은 적어도 하나의 공백이 필요합니다.",
};

export default LABELS;
