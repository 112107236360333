import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "오류",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "원래 응용 프로그램에서 체크 아웃에 연결할 수 없습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "매개 변수는 사기성이므로 체크 아웃을 초기화 할 수 없습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "커뮤니케이션이 실패했습니다. 커뮤니케이션 상태를 확인하고 다시 시작하십시오. ",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "내부 처리로 인해 타임 아웃이 발생했습니다. 지원에 문의하십시오.",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "타임아웃이 발생했습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE]: "지불 방법은 사기성이므로 Check -Aut를 초기화 할 수 없습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE]: "이 지불 방법은 지원되지 않습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE]: "이 지불 방법은 지원되지 않습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "오류가 발생했습니다. 당신을 괴롭히서 미안하지만 잠시 후 다시 시도하십시오. ",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "계정에는 연속 청구 토큰을 사용할 권한이 없습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "연속성 청구 토큰은 사용 제한이 필요합니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "이 카드 브랜드는 지원되지 않습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "이 도메인에서 체크 아웃을 실행할 수 없습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "토큰을 만들지 못했습니다.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "CVV 인증 중 타임 아웃",
    [ERRORS_LABELS.ERRORS_ALERTS_THREE_DS_FAILED]: "3D 보안 인증을 완료하지 못했습니다",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT]: "설치 결제는 지원되지 않습니다.",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "서비스를 사용할 수 없습니다",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "현재 서비스는 일시적으로 제공되지 않습니다. 당신을 괴롭히서 미안하지만 잠시 후 다시 시도하십시오. ",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "직불 및 선불 카드는 실제 판매에만 지원되며 임시 판매를 지원하지 않습니다. ",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "지불 처리가 실패했습니다.",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "정규 청구 프로세스가 실패했습니다.",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "매개 변수가 올바르지 않습니다.",
    [ERRORS_LABELS.PROCESSING_ERROR]: "처리 오류",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "승인은 Paty보다 사라지지 않았습니다. 자세한 내용은 PaiLY에 문의하십시오. ",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "많은 결제 실패로 인해 일정 기간 동안 서비스를 이용할 수 없다.",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "결제 처리에 실패했습니다.",
    [ERRORS_LABELS.NO_GATEWAY_AVAILABLE_TO_PROCESS_REQUEST]: "이 요청은 연결 대상 시스템에서 지원되지 않습니다. ",

    /* request */
    [RequestErrorCode.RequestError]: "무단 요청",

    /* generic */
    [ResponseErrorCode.BadRequest]: "무단 요청 오류",
    [ResponseErrorCode.Forbidden]: "금지 된 운영",
    [ResponseErrorCode.NotFound]: "나는 자원을 찾을 수 없다",
    [ResponseErrorCode.NotAllowed]: "무단 작업",
    [ResponseErrorCode.Conflicted]: "우리는 다른 자원과 경쟁하고 있습니다",
    [ResponseErrorCode.TooManyRequests]: "API에 대한 요청은 집중되어 있습니다",
    [ResponseErrorCode.InternalServerError]: "내부 오류가 발생했습니다. (지원 센터에 문의하십시오. 0570-035-672)",
    [ResponseErrorCode.ServiceUnavailable]: "서비스를 사용할 수 없습니다",
    [ResponseErrorCode.NotAuthorized]: "무단 작업",

    /* global */
    [ResponseErrorCode.UnknownError]: "예상치 못한 오류. 지원에 연락하십시오.",
    [ResponseErrorCode.Timeout]: "요청에 대한 응답은 t- 타임 t입니다. (지원 센터에 문의하십시오. 0570-035-672)",
    [ResponseErrorCode.DBError]: "내부 데이터베이스 오류. 지원에 연락하십시오.",
    [ResponseErrorCode.InvalidRequest]: "무단 요청",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "키에 해당하는 캐시가 발견되었으므로 요청 된 컨텐츠는 처리되지 않았지만 이전 처리 결과를 얻지 못했습니다. ",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "서비스는 일시적으로 사용할 수 없습니다. 시간을 남기고 다시 시도하십시오. ",
    [ResponseErrorCode.RateLimitExceeded]: "API 요청 제한이 초과되었습니다.",

    /* auth */
    [ResponseErrorCode.InvalidIpAddress]: "무단 IP 주소",
    [ResponseErrorCode.InvalidDomain]: "요청 된 원점 헤더 도메인은 지정된 응용 프로그램 토큰 도메인에 등록되지 않습니다. ",
    [ResponseErrorCode.InvalidCredentials]: "인증 정보가 잘못되었습니다.",
    [ResponseErrorCode.AuthHeaderMissing]: "인증 헤더는 지정되지 않았습니다.",
    [ResponseErrorCode.InvalidPermissions]: "응용 프로그램 토큰 유형이 올바르지 않거나 계정 권한이 충분하지 않습니다. ",
    [ResponseErrorCode.ImproperAuth]: "권한이 잘못되었습니다. 지원에 연락하십시오.",
    [ResponseErrorCode.CouldNotRefreshAuth]: "로그인 토큰을 업데이트하지 못했습니다. 지원에 연락하십시오.",
    [ResponseErrorCode.UserBanned]: "사용하는 사용자는 금지되어 있습니다.",
    [ResponseErrorCode.InvalidLoginToken]: "로그인 토큰이 유효하지 않습니다.",
    [ResponseErrorCode.InvalidAppToken]: "응용 프로그램 토큰의 사양이 잘못되었습니다.",
    [ResponseErrorCode.ExpiredLoginToken]: "로그인 토큰의 만료일이 만료되었습니다.",
    [ResponseErrorCode.OutdatedAppToken]: "응용 프로그램 토큰 버전은 오래되었습니다. 새 응용 프로그램 토큰을 만듭니다. ",
    [ResponseErrorCode.ChargeTooQuick]: "우리는 제한된 퇴직 토큰으로 지정된 기간 내에 여러 청구서를 만들려고 노력했습니다. ",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "파일 업로드 오류",
    [ResponseErrorCode.FileMaxSizeExceeded]: "업로드 된 파일의 크기가 너무 큽니다.",
    [ResponseErrorCode.FileInvalidType]: "업로드 된 파일의 마임 유형이 올바르지 않습니다.",
    [ResponseErrorCode.FileNotFound]: "파일 찾기 없음",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "토큰이 생성 될 때 예외가 발생했습니다",
    [ResponseErrorCode.TokenForWrongStore]: "거래 토큰 스토어는 일반 청구 저장소와 다릅니다.",
    [ResponseErrorCode.NonSubscriptionPayment]: "청구를 만들기 위해 1 타임 토큰 또는 퇴직 토큰을 지정하십시오. ",
    [ResponseErrorCode.BankAccountExists]: "이미 존재하는 은행 계좌",
    [ResponseErrorCode.EmailExists]: "이미 존재하는 이메일 주소입니다.",
    [ResponseErrorCode.StoreExists]: "이미 존재하는 상점",
    [ResponseErrorCode.GatewayCredentialsExists]: "이미 존재하는 게이트웨이 배송입니다",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "가맹점에 대한 규칙은 독특하지 않습니다",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "상인을위한 규칙은 독특하지 않습니다",
    [ResponseErrorCode.NonUniqueRule]: "규칙은 독특하지 않습니다",
    [ResponseErrorCode.RulePriorityMustBePositive]: "규칙의 우선 순위는 긍정적 가치가 있어야합니다",
    [ResponseErrorCode.NonExistingRule]: "존재하지 않는 규칙입니다.",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "요소는 독특해야합니다",
    [ResponseErrorCode.WebhookURLExists]: "지정된 URL이 이미 등록되었습니다.",
    [ResponseErrorCode.GroupExists]: "이미 존재하는 그룹입니다",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "기본 은행 계좌를 찾을 수 없습니다",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "기본 은행 계좌가 필요합니다",
    [ResponseErrorCode.VerificationDataExists]: "검사를위한 데이터가 있습니다",
    [ResponseErrorCode.TemplateExists]: "템플릿이 있습니다",
    [ResponseErrorCode.StratusCredentialsExists]: "CAFIS 자격 증명이 있습니다",
    [ResponseErrorCode.StratusRuleStillInUse]: "CAFIS 규칙이 사용되고 있습니다",
    [ResponseErrorCode.ResourceLimitReached]: "자원 제한의 상한.",
    [ResponseErrorCode.GatewayConfigurationExists]: "게이트웨이 설정이 있습니다",
    [ResponseErrorCode.RequiresValidMerchantGatewayConfig]: "게이트웨이 설정을 찾을 수 없습니다",
    [ResponseErrorCode.MergedConfigurationNotFound]: "게이트웨이 설정을 찾을 수 없습니다",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "트랜잭션 토큰의 유형이 잘못되었습니다.",
    [ResponseErrorCode.InvalidToken]: "무단 토큰",
    [ResponseErrorCode.InvalidCard]: "무단 카드",
    [ResponseErrorCode.TransactionTokenIsNotRecurring]: "토큰은 연속 청구 토큰이 아닙니다",
    [ResponseErrorCode.ForbiddenIP]: "요청 소스 IP 주소에서 결정된 국가는 정해진 국가에 포함되어 있지 않습니다. ",
    [ResponseErrorCode.InvalidUserData]: "사용자 데이터 또는 요청 데이터는 유효하지 않습니다.",
    [ResponseErrorCode.NonUniqueActiveToken]: "이미 활성 거래 토큰이 있습니다.",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "중고 트랜잭션 토큰을 지정할 수 없습니다.",
    [ResponseErrorCode.TransactionTokenExpired]: "거래 토큰이 만료되었습니다.",
    [ResponseErrorCode.NoTestCardInLiveMode]: "생산 모드 (LIVE)에서는 테스트 카드를 사용할 수 없습니다.",
    [ResponseErrorCode.ProcessingModeMismatch]: "처리 모드 불일치",
    [ResponseErrorCode.PaymentTypeNotAllowed]: "지불 방법은 허용되지 않습니다",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "연속성 청구 토큰은 허용되지 않습니다",
    [ResponseErrorCode.RecurringTokenDisabled]: "트랜잭션 토큰이 유효하지 않거나 계정은 검색 토큰을 사용할 권한이 없습니다. ",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "usage_limit 매개 변수가 필요합니다.",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "CVV를 제공해야합니다.",
    [ResponseErrorCode.CvvAuthorizationNotCompleted]: "CVV를 확인할 수 없습니다",
    [ResponseErrorCode.UsageLimitNotApplicable]: "usage_limit을 지정할 수 없습니다.",
    [ResponseErrorCode.CardProcessingDisabled]: "카드는 결제 방법에 의해 비활성화됩니다.",
    [ResponseErrorCode.QRProcessingDisabled]: "QR 코드는 지불 방법에 따라 비활성화됩니다.",
    [ResponseErrorCode.PaidyProcessingDisabled]: "Paily Processing은 비활성화되었습니다",
    [ResponseErrorCode.MerchantQRProcessingDisabled]: "MerchantQR 처리는 비활성화되었습니다",
    [ResponseErrorCode.OnlineProcessingDisabled]: "온라인 처리는 유효하지 않습니다",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "편의점 결제는 지불 방법에 따라 비활성화됩니다.",
    [ResponseErrorCode.NotOneTimeToken]: "하나의 텐트 토큰을 제외하고는 지원되지 않습니다.",
    [ResponseErrorCode.NotSubscriptionToken]: "정기적 인 청구 토큰 또는 퇴각 토큰을 지정하십시오.",
    [ResponseErrorCode.NotRecurringToken]: "연속 청구 토큰이 아닙니다.",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "환불 당시의 통화는 청구 당시 통화와 동일해야합니다.",
    [ResponseErrorCode.NoDirectCurrencyGateway]: "통화 변환없이 사용할 수있는 게이트웨이는 없습니다.",
    [ResponseErrorCode.TokenMustBeConfirmed]: "그것은 확인되지 않은 토큰입니다",
    [ResponseErrorCode.ConfirmationRequiresEmail]: "확인을 위해서는 이메일이 필요합니다",
    [ResponseErrorCode.WrongConfirmationCode]: "무단 확인 코드",
    [ResponseErrorCode.RefundNotWithinBounds]: "환불 금액은 유효한 범위가 아닙니다. 0보다 크며 청구 금액보다 낮아야합니다.",
    [ResponseErrorCode.PartialRefundNotSupported]: "그것은 부분 환불을지지하지 않습니다.",
    [ResponseErrorCode.InvalidTransfer]: "무단 이체",
    [ResponseErrorCode.TransferAlreadyExists]: "이미 이체가 있습니다.",
    [ResponseErrorCode.NoLedgers]: "원장이 없습니다.",
    [ResponseErrorCode.FailedToAssociateLedgers]: "원장은 관련이 없었다",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "생산 모드 (LIVE)를 사용하려면 계정 심사를 완료해야합니다.",
    [ResponseErrorCode.SelfTransferNotPermitted]: "자신에게 옮기는 것은 허용되지 않습니다.",
    [ResponseErrorCode.CardLocked]: "일정 기간 내의 실패 수가 스릴 값을 초과하기 때문에이 카드는 일시적으로 잠겨 있습니다.",
    [ResponseErrorCode.SubscriptionProcessing]: "우리는 정기적 인 청구를 처리하고 있습니다",
    [ResponseErrorCode.AlreadyCaptured]: "대상 청구가 이미 캡처되었거나 당국이 완료되지 않았습니까?",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "거래 토큰을 업데이트 할 수 없습니다",
    [ResponseErrorCode.CaptureAmountTooLarge]: "캡처 금액은 당국보다 큽니다.",
    [ResponseErrorCode.PartialCaptureNotSupported]: "부분 캡처는 지원되지 않습니다.",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "직불 카드 당국은 유효하지 않습니다",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "선불 카드의 권한은 무효입니다",

    [ResponseErrorCode.NoGatewaysAvailable]: "사용 가능한 게이트웨이를 찾을 수 없습니다.",
    [ResponseErrorCode.NoGatewayCredentialsAvailable]: "유효한 게이트웨이 자격 증명 없음",
    [ResponseErrorCode.NoGatewayTransactionIdAvailable]: "유효한 트랜잭션 ID가 없습니다",
    [ResponseErrorCode.PaymentTypeNotSupportedForCheck]: "지불 방법은 지원되지 않습니다",
    [ResponseErrorCode.NoGatewayCredentialsForSelectedPaymentType]: "선택한 지불 방법의 관문에 대한 자격 증명이 없으며",
    [ResponseErrorCode.DisabledPaymentType]: "지불 방법이 비활성화되었습니다",
    [ResponseErrorCode.CardBrandNotSupported]: "지정된 카드 브랜드에 해당하는 게이트웨이는 없습니다.",
    [ResponseErrorCode.CardCountryNotSupported]: "지정된 카드 발급자에 해당하는 게이트웨이는 없습니다.",
    [ResponseErrorCode.CVVRequired]: "CVV를 제공해야합니다.",
    [ResponseErrorCode.LastNameRequired]: "카드 이름으로 공간으로 분리 된 성이 필요합니다.",
    [ResponseErrorCode.AuthNotSupported]: "Authori에 해당하는 게이트웨이는 없습니다.",
    [ResponseErrorCode.GatewayConfigurationRequired]: "게이트웨이 설정이 필요합니다",
    [ResponseErrorCode.GatewayConfigurationNotRequired]: "게이트웨이 설정이 필요하지 않습니다",

    [ResponseErrorCode.InvalidBinRange]: "빈 번호는 잘못된 범위에 있습니다",
    [ResponseErrorCode.OverlappingStratusTerminalIdRange]: "Stratus 터미널 ID가 복제되었습니다",
    [ResponseErrorCode.InvalidStratusTerminalIdRange]: "불법 지층의 터미널 ID",
    [ResponseErrorCode.InvalidCardCompany]: "무단 카드 회사",
    [ResponseErrorCode.VerificationRequired]: "스크리닝 ID가 필요합니다",

    /* validation responses */
    [ResponseErrorCode.ChangeProhibited]: "이 분야의 변화는 금지됩니다.",
    [ResponseErrorCode.ForbiddenParameter]: "이 매개 변수를 사용할 권한이 충분하지 않거나 요구 사항을 충족하지 않습니다.",
    [ResponseErrorCode.ValidationError]: "요청 내용의 매개 변수에는 유효성 검사 오류가 있습니다. 자세한 내용은 오류를 참조하십시오. ",
    [ResponseErrorCode.RequiredValue]: "필요한 매개 변수입니다.",
    [ResponseErrorCode.MustBeFutureTime]: "미래 날짜와 시간이 필요합니다.",
    [ResponseErrorCode.InvalidFormat]: "불법 형식입니다.",
    [ResponseErrorCode.InvalidPercentFee]: "불법 수수료 요금입니다",
    [ResponseErrorCode.InvalidCardNumber]: "카드 번호의 형태가 올바르지 않습니다.",
    [ResponseErrorCode.InvalidCardExpiration]: "카드 만료 날짜가 통과되었습니다.",
    [ResponseErrorCode.InvalidCVV]: "보안 코드는 3자리 또는 4자리로 입력하세요.",
    [ResponseErrorCode.InvalidFormatLength]: "길이 형식은 사기입니다",
    [ResponseErrorCode.InvalidFormatUUID]: "uuid 형식은 사기입니다",
    [ResponseErrorCode.InvalidFormatBase64]: "Base64 형식은 사기입니다",
    [ResponseErrorCode.InvalidFormatEmail]: "이메일 주소 형식은 유효하지 않습니다.",
    [ResponseErrorCode.InvalidCardDescriptor]: "제자의 형식은 틀 렸습니다.",
    [ResponseErrorCode.InvalidFormatCurrency]: "인식 할 수없는 통화 코드입니다.",
    [ResponseErrorCode.InvalidCurrency]: "그것은 인식 할 수없는 통화입니다.",
    [ResponseErrorCode.InvalidAmount]: "금액은 0보다 큰 정수 여야합니다.",
    [ResponseErrorCode.InvalidEventForStore]: "그 상점의 불법 행사입니다",
    [ResponseErrorCode.InvalidEventForPlatform]: "플랫폼의 잘못된 이벤트",
    [ResponseErrorCode.InvalidEventForMerchant]: "상인을위한 잘못된 이벤트",
    [ResponseErrorCode.InvalidFormatDomain]: "도메인 이름의 형식은 사기입니다.",
    [ResponseErrorCode.InvalidFormatUrl]: "URL은 사기입니다",
    [ResponseErrorCode.InvalidFormatObject]: "대상의 형식은 사기입니다",
    [ResponseErrorCode.InvalidFormatCountry]: "인식 할 수없는 국가 코드입니다.",
    [ResponseErrorCode.InvalidPhoneNumber]: "전화 번호 형식은 사기입니다.",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "Slifcodes는 사기입니다",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "라우팅 번호는 사기입니다",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "라우팅 코드는 사기입니다",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "IFCS 코드는 사기입니다.",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "은행 계좌 번호의 형식이 잘못되었습니다",
    [ResponseErrorCode.InvalidPasswords]: "비밀번호는 사기입니다",
    [ResponseErrorCode.InvalidTimePeriod]: "이것은 인식 할 수없는 기간의 형식입니다.",
    [ResponseErrorCode.InvalidDayOfWeek]: "불법적 인 요일",
    [ResponseErrorCode.InvalidWeekOfMonth]: "몇 주 안에 사기가 있습니다",
    [ResponseErrorCode.InvalidDayOfMonth]: "이달의 날은 사기입니다",
    [ResponseErrorCode.InvalidColorsSize]: "색상 크기는 사기입니다",
    [ResponseErrorCode.NestedJsonNotAllowed]: "중첩 된 JSON 형식을 사용할 수 없습니다.",
    [ResponseErrorCode.InvalidFormatDate]: "날짜 형식은 사기입니다",
    [ResponseErrorCode.InvalidChargeStatus]: "이것은 불법 청구 상태입니다.",
    [ResponseErrorCode.InvalidQRScanGateway]: "QR 코드 지불 게이트웨이는 설정되지 않았거나 유효하지 않습니다.",
    [ResponseErrorCode.NotQRCharge]: "QR 청구가 아닙니다",
    [ResponseErrorCode.NotOnlineCharge]: "온라인 청구가 아닙니다",
    [ResponseErrorCode.IssuerTokenEmpty]: "Isshuer 토큰이 비어 있습니다",
    [ResponseErrorCode.CardLimitExceededForStore]: "일정 기간 내에 카드의 총 지불액이 한도를 초과했습니다.",
    [ResponseErrorCode.InvalidLanguage]: "인식 할 수없는 언어 코드입니다.",
    [ResponseErrorCode.SubscriptionNotAllowed]: "정기 청구 사용은 허용되지 않습니다",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "한 번만 허용됩니다",
    [ResponseErrorCode.AuthExpired]: "당국이 만료되었습니다",
    [ResponseErrorCode.InvalidTemplateKey]: "템플릿 키는 사기입니다",
    [ResponseErrorCode.NonPublicTemplate]: "공개 템플릿이 아닙니다",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "일본 전화 번호 만 지원됩니다.",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "만료 날짜는 허가가 없습니다.",
    [ResponseErrorCode.UnsupportedLanguage]: "지원되지 않는 언어",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "기본 언어가 설정되지 않았다",
    [ResponseErrorCode.OnlyForCardPayment]: "지불 방법은 카드와 함께만 사용할 수 있습니다.",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "캡처는 카드 지불의 경우에만 가능합니다.",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "카드 유형이 결정되지 않기 때문에 권한이 없습니다",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "캡처 날짜와 시간은 현재에서 1 시간 이하로 7 일 미만이어야합니다. ",
    [ResponseErrorCode.IncoherentDateRange]: "날짜 범위가 잘못되었습니다",
    [ResponseErrorCode.InvalidMerchantStatus]: "가맹점의 상태가 잘못되었습니다",
    [ResponseErrorCode.MustHaveOneElement]: "적어도 하나의 요소가 필요합니다",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "적어도 하나의 요소가 필요합니다",
    [ResponseErrorCode.ThresholdMustBeNull]: "임계 값은 무효가되어야합니다",
    [ResponseErrorCode.IllegalNumberOfItems]: "품목 수는 사기입니다",
    [ResponseErrorCode.UnableToReadCredentials]: "나는 크레딧을 읽을 수 없다",
    [ResponseErrorCode.GatewayError]: "게이트웨이 오류",
    [ResponseErrorCode.GatewayNoLongerSupported]: "게이트웨이는 지원되지 않습니다",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "청구 금액의 한도가 올바르지 않습니다",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "플랫폼 통화에 CVV의 양을 지정하십시오",
    [ResponseErrorCode.ChargeAmountTooLow]: "청구 금액은 최소 청구서보다 적습니다.",
    [ResponseErrorCode.ChargeAmountTooHigh]: "청구 금액이 최대 금액을 초과합니다.",
    [ResponseErrorCode.MustContainPlatformCurrency]: "플랫폼 통화로 지정하십시오",
    [ResponseErrorCode.CurrencyMustBeInAmountsList]: "통화로 지정하십시오",
    [ResponseErrorCode.InvalidJapanesePostalCode]: "유효하지 않은 일본 우편 번호",

    [ResponseErrorCode.BrandNotDefined]: "비상장 브랜드",

    /* Used when creating a new merchant */
    [ResponseErrorCode.OnlyASCII]: "ASCII 캐릭터가 지원됩니다.",
    [ResponseErrorCode.UniqueCharacters]: "독특한 캐릭터",
    [ResponseErrorCode.AtLeastOneDigit]: "최소 숫자",
    [ResponseErrorCode.AtLeastOneLetter]: "적어도 하나의 캐릭터",
    [ResponseErrorCode.EmptyRoles]: "당신은 빈 역할을 할 수 없습니다.",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "나는 내 역할을 편집 할 수 없다",
    [ResponseErrorCode.EditOwnStatusNotAllowed]: "나는 내 상태를 편집 할 수 없다",
    [ResponseErrorCode.InvalidPathValue]: "무단 경로",
    [ResponseErrorCode.InvalidCardBrand]: "인식 할 수없는 카드 브랜드입니다.",
    [ResponseErrorCode.UnsupportedCountry]: "국가는 지원되지 않습니다.",
    [ResponseErrorCode.UnsupportedCurrency]: "통화는 지원되지 않습니다.",
    [ResponseErrorCode.CannotBanSelf]: "당신은 자신을 금지 된 사용자로 만들 수 없습니다",
    [ResponseErrorCode.CannotSelfTerminate]: "자기 종료 할 수 없다",

    [ResponseErrorCode.NoDuplicateCurrencies]: "중복으로 동일한 통과를 등록 할 수 없습니다",
    [ResponseErrorCode.PlatformNotFound]: "플랫폼을 찾을 수 없습니다",
    [ResponseErrorCode.InvalidPlatform]: "플랫폼의 사양이 올바르지 않습니다.",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "불법 송장 비용 설정",
    [ResponseErrorCode.InvalidPlatformRole]: "플랫폼의 롤은 사기입니다",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "데이터는 제출되지 않습니다.",
    [ResponseErrorCode.NoBankAccount]: "은행 계좌는 없습니다.",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "수수료는 제출되지 않습니다.",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "시스템 관리자 정보는 불충분합니다",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "크레딧은 이미 존재합니다.",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "유료 자격 증명 만 사용할 수 있습니다",
    [ResponseErrorCode.OnlyOneDBaraiCredentialsAllowed]: "D- 지불 가능한 크레딧 하나만 제공됩니다",
    [ResponseErrorCode.OnlyMerchantOrStoreCredentialsAllowed]: "상인과 상점의 크레딧이 필요합니다",
    [ResponseErrorCode.RefundExceedsChargeAmount]: "환불 금액이 청구를 초과했습니다.",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "성공 이외의 주에서의 지불은 환불 될 수 없습니다.",
    [ResponseErrorCode.RefundNotAllowed]: "환불 또는 환불을 지원하지 않는 지불 방법은 허용되지 않습니다.",
    [ResponseErrorCode.RefundPercentageExceeded]: "환불 한도 너머",
    [ResponseErrorCode.CancelNotAllowed]: "이 지불 방법은 취소를 지원하지 않습니다. 또는 대상 청구 상태를 취소 할 수 없습니다. ",

    [ResponseErrorCode.PaidyShippingAddressNeedsOneOptionalField]: "유료의 배송 대상에는 하나의 선택 필드가 필요합니다",

    [ResponseErrorCode.RevertNotAllowed]: "반환은 허용되지 않습니다",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay는 유효하지 않습니다",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay는 이미 유효합니다",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Apple Pay 인증서가 업데이트되었습니다",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "이것은 Apple Pay가 지원하지 않는 알고리즘입니다.",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Apple Pay 인증서를 찾을 수 없습니다",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple은 인증서 요청을 생성 할 수 없습니다",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay 유효하지 않은 구성",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay 유효하지 않은 인증서",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay Invalid 알고리즘",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay Invalid 인증서 형식",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay Invalid Signature",
    [ResponseErrorCode.ApplePayError]: "Apple Pay Error",
    [ResponseErrorCode.ApplePayCertificateAlreadySet]: "Apple Pay 인증서는 이미 설정되었습니다",
    [ResponseErrorCode.ApplePayCertificateStillInUse]: "Apple Pay 인증서는 여전히 사용됩니다",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "카드 번호 오류",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "카드의 유효한 달은 유효하지 않습니다",
    [ResponseErrorCode.CardExpirationYearInvalid]: "카드의 유효한 해가 유효하지 않습니다",
    [ResponseErrorCode.CardExpired]: "카드의 만료일이 만료되었습니다",
    [ResponseErrorCode.CardCVVInvalid]: "보안 코드 오류",
    [ResponseErrorCode.CardRejected]: "인증 시험 오류 (카드 발급자에게 문의하십시오)",
    [ResponseErrorCode.CardInvalid]: "카드가 유효하지 않습니다",
    [ResponseErrorCode.ChargeInvalidData]: "잘못된 요청 데이터.",
    [ResponseErrorCode.TooManyChargeRequests]: "짧은 시간 안에 같은 카드에 대한 청구 요청이 너무 많습니다. 잠시 후 다시 시도하고 다시 시도하십시오",
    [ResponseErrorCode.CancelUnavailable]: "이 청구서는 취소 할 수 없습니다",
    [ResponseErrorCode.ChargeExpired]: "청구 만료",
    [ResponseErrorCode.SeizeCard]: "인증 시험 오류 (카드 손실, 도난, 유효하지 않은, 제한 등)",
    [ResponseErrorCode.ContactBank]: "인증 시험 오류 (카드 발급자에게 문의하십시오)",
    [ResponseErrorCode.FraudSuspected]: "인증 시험 오류 (불법 사용 / 보안 제한이 의심됨)",
    [ResponseErrorCode.BankSystemFailure]: "카드 회사 거래 오류",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "역동적 인 제자들은지지되지 않습니다",
    [ResponseErrorCode.PaymentCodeInvalid]: "바코드/QR 코드는 유효하지 않습니다",
    [ResponseErrorCode.PaymentCodeExpired]: "바코드/QR 코드의 만료일이 만료되었습니다",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "바코드/QR 코드가 이미 사용되었습니다",
    [ResponseErrorCode.PaymentCodeStillInUse]: "바코드/QR 코드는 현재 사용 중입니다",
    [ResponseErrorCode.RejectedHighRisk]: "높은 위험 가치를 초과했기 때문에 거부당했습니다.",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "확인 기간이 끝났습니다",
    [ResponseErrorCode.RevertFailed]: "돌아 오지 못했습니다. 수동 작동이 필요합니다",
    [ResponseErrorCode.RefundFailed]: "환불 실패",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "우리는 고객 펀드가 부족합니다",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "메타 데이터 필드 값은 유효하지 않거나 누락되었습니다",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "국경 간의 거래는 허용되지 않습니다. 신분증이 없습니다",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "국경 간의 거래는 허용되지 않습니다. 전화 번호가없고",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "국경 간의 거래는 허용되지 않습니다. 허용 할 수없는 지불 방법",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "국경을 통한 거래는 허용되지 않습니다. 이름이 없습니다",
    [ResponseErrorCode.LimitExceededForPaymentType]: "지불 방법의 거래 제한을 넘어서",
    [ResponseErrorCode.LimitExceededForMerchant]: "계정 제한을 초과합니다",
    [ResponseErrorCode.TransactionNotFound]: "나는 거래를 찾을 수 없었다",
    [ResponseErrorCode.DuplicateTransaction]: "중복 거래",
    [ResponseErrorCode.PaymentWalletRejected]: "결제 지갑은 연결 시스템에 의해 거부되었습니다",
    [ResponseErrorCode.InsufficientMerchantInformation]: "계정은 계정이 충분하지 않습니다",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "국경 간의 거래는 허용되지 않습니다. 용납 할 수없는 통화",
    [ResponseErrorCode.GatewayServerError]: "연결 대상 시스템 오류 (지원 센터에 문의하십시오. 0570-035-672)",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "연결 대상 시스템에 따라 선택한 지불을 일시적으로 사용할 수 없습니다",
    [ResponseErrorCode.PaymentCanceled]: "지불이 취소되었습니다",
    [ResponseErrorCode.ExceededPendingThreshold]: "지불 처리를 시작하는 데 시간이 걸렸습니다",
    [ResponseErrorCode.InternalError]: "내부 시스템 오류",

    /* online */
    [ResponseErrorCode.AlipayOnlineInvalidSignatureType]: "무단 AlipayOnline 표시 유형",
    [ResponseErrorCode.PayPayOnlineInvalidPayMethod]: "무단 PayPayOnline 결제 방법",
    [ResponseErrorCode.PayPayOnlineInvalidMerchantId]: "무단 PayPayOnline Merchantid",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "인증서를 요청할 수 없습니다",
    [ResponseErrorCode.InvalidMerchantSettings]: "가맹점의 구성이 잘못되었습니다",
    [ResponseErrorCode.UnsupportedAlgorithm]: "지원되는 알고리즘이 아닙니다",
    [ResponseErrorCode.InvalidPaymentToken]: "지불 토큰은 사기입니다",
    [ResponseErrorCode.ExpiredPaymentToken]: "지불 토큰이 만료되었습니다",
    [ResponseErrorCode.InvalidCertificate]: "불법 인증서입니다",
    [ResponseErrorCode.InvalidLeafCertificate]: "불법 암초 증명서입니다",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "불법 임시 증명서",
    [ResponseErrorCode.InvalidChainOfTrust]: "불법 신탁 체인입니다",
    [ResponseErrorCode.InvalidSignature]: "불법적 인 서명입니다",

    /** platform */
    [ResponseErrorCode.PlatformKeyExists]: "플랫폼 키가 있습니다",
    [ResponseErrorCode.MerchantConsoleURIExists]: "상인 콘솔의 URI가 있습니다",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "보장 된 요청 시점에서 지정된 冪 키는 다른 API 및 메소드에 사용됩니다. ",

    /* bannedCards */
    [ResponseErrorCode.CardBanned]: "금지 된 카드",
    [ResponseErrorCode.CardAlreadyBanned]: "이미 금지 된 카드",
    [ResponseErrorCode.CardNotBanned]: "카드가 금지되지 않음",
    [ResponseErrorCode.TestCardCannotBeBanned]: "테스트 카드는 금지 될 수 없습니다.",
    [ResponseErrorCode.InvalidMetric]: "무단 유닛",
    [ResponseErrorCode.InvalidResource]: "무단 자원",

    /* installments */
    [ResponseErrorCode.UseStartOn]: "정기 청구 시작 날짜 사용",
    [ResponseErrorCode.PaymentInTimePeriod]: "마감일 내의 지불",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "두 번째 법안이 구현되고 있습니다",
    [ResponseErrorCode.NotSupportedByProcessor]: "게이트웨이에 의해 지원되지 않습니다.",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "정기 청구가 취소되었습니다",
    [ResponseErrorCode.SubscriptionNotFound]: "정기적 인 청구를 찾을 수 없습니다",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "총 금액보다 작아야합니다.",
    [ResponseErrorCode.InstallmentPlanNotFound]: "할부 지불금이 발견되지 않았다",
    [ResponseErrorCode.InstallmentAlreadySet]: "정기적 인 청구가 이미 시작 되었기 때문에 변경할 수 없습니다.",
    [ResponseErrorCode.InstallmentInvalidPlan]: "이것은 지원되지 않는 할부 지불 계획입니다.",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "인식 할 수없는 할부 지불 계획입니다.",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "이것은 할부 지불을위한 지불 방법으로 받아 들일 수없는 지불 방법입니다.",
    [ResponseErrorCode.CardProcessorDisabledForInstallmentPlan]: "이것은 할부 지불을 지원하지 않는 카드 회사입니다.",
    [ResponseErrorCode.CardProcessorInstallmentFutureDated]: "이것은 할부 지불 시작일을 설정할 수없는 카드 회사입니다.",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "사기 할부 지불의 초기 금액",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "할부 지불 기간이 최대 지불 기간을 초과했습니다. ",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "각 할부 금액이 너무 작습니다",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "회전 지불을 통해 첫 번째 지불 금액을 설정할 수 없습니다.",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "회전 지불을 통해 정규 청구의 시작일을 설정할 수 없습니다.",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "이 게이트웨이는 초기 금액 사양을 지원하지 않습니다. ",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "게이트웨이는 할부 기간을 지원하지 않으며",
    [ResponseErrorCode.CannotChangeToken]: "이 상태의 정기 청구를위한 거래 토큰은 변경 될 수 없습니다.",
    [ResponseErrorCode.SubscriptionNotEnabled]: "정기적 인 청구는 유효하지 않습니다",
    [ResponseErrorCode.SubscriptionAlreadyEnded]: "정기적 인 청구가 이미 끝났습니다.",
    [ResponseErrorCode.SubscriptionUnsupportedForInstallment]: "정기 청구는 할부를 지원하지 않습니다",
    [ResponseErrorCode.InstallmentsNotEnabled]: "설치 결제가 유효하지 않습니다",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "분할 금액을 변경할 수 없습니다",
    [ResponseErrorCode.CannotChangeToInstallment]: "할부 지불로 변경할 수 없습니다",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "충전 빈도는 매월이어야합니다.",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "나는 2 번 이내야한다",
    [ResponseErrorCode.Deprecated]: "권장하지 않는 매개 변수입니다.",
    [ResponseErrorCode.CannotBeZero]: "0을 설정할 수 없습니다",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "나는 적어도 두 번 필요하다",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "사용할 수없는 분할의 수.",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "초기 금액은 0으로 설정할 수 없습니다",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "정기 청구가 시작될 때까지 설정할 수 없습니다.",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "정기적 인 청구 후에는 설정할 수 없습니다",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "정기 청구서를 취소 할 수 없습니다.",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "CAFIS 회원 상점은 이미 사용되었습니다",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "Cafis Store는 이미 사용되었습니다",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "CAFIS 상점은 지정된 번호의 상한에 도달했습니다",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "유효하지 않은 탄성 지수",
    [ResponseErrorCode.InvalidDynamoTable]: "무효 인 Dynamo 테이블",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "잘못된 날짜 히스토그램 간격",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "잘못된 SQS 엔드 포인트",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "빈 번호를 찾을 수 없습니다",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhn Check는 실패했습니다.",
    [ResponseErrorCode.InvalidCardNumberLength]: "카드 번호의 숫자 수는 부적절합니다.",
    [ResponseErrorCode.CardPaymentDisabled]: "카드를 사용할 수 없습니다.",
    [ResponseErrorCode.DebitDisabled]: "직불 카드를 사용할 수 없습니다.",
    [ResponseErrorCode.PrepaidDisabled]: "선불 카드를 사용할 수 없습니다.",
    [ResponseErrorCode.CountryNotSupported]: "지원되지 않는 국가의 카드입니다.",
    [ResponseErrorCode.Unspecified]: "무단 카드",
};

export default LABELS;
