import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "Назад",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "Закрыть",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "Завершить",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "Далее",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "Предидущее",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "Отправить",
    [COMMON_LABELS.COMMON_TOTAL]: "ИТОГО: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "Способ оплаты {method}",

    [COMMON_LABELS.COMMON_OPTIONAL]: "Не обязательно",
    [COMMON_LABELS.COMMON_REQUIRED]: "Обязательно к заполнению",

    [COMMON_LABELS.PRIVACY_LINK]: "Об обращении с личной информацией",

    [COMMON_LABELS.COMMON_COMMA]: ",",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+ комиссия за обработку",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "Выбрать...",
};

export default LABELS;
