import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "請將您的付款匯到以下銀行賬號",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "銀行名稱",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "銀行代碼",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "分行名稱",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "分行代碼",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "賬號",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "賬戶名",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "金額",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "截止日期",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "首次付款",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "首次付款截止日期",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "下次付款",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "下次付款截止日期",
};

export default LABELS;
