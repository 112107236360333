import { createModel } from "@rematch/core";
import { isDevelop } from "checkout/ts/components/checkout/utils";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import {
    OnlineTestBrand,
    onlineTestBrands,
    PatchedOnlineBrand,
    SUPPORTED_ONLINE_GATEWAYS,
    SupportedOnlineBrand,
} from "checkout/ts/redux/utils/online-constants";
import { CheckoutInfoBrandPayload, ExternalFees, PaymentMethodBrand } from "checkout/ts/resources/CheckoutInfoBrand";
import { SupportedMethodBrand } from "checkout/ts/types";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import {
    getSupportedCardBrands,
    getSupportedKonbiniBrands,
    isCardBrand,
    isKonbiniBrand,
    isOnlineBrand,
    isPaymentType,
} from "checkout/ts/utils/paymentType";
import Color from "color";
import { ParametersError } from "common/errors/ParametersError";
import { CheckoutParams, CheckoutType, PatchedCardBrand } from "common/types";
import { getOnlineCallMethodParams, isMobile, isWeChatBrowser } from "common/utils/browser";
import { validateDomain } from "common/utils/domain";
import { extractRootDomain } from "common/utils/url";
import { groupBy, isNil, uniq } from "lodash";
import {
    CardBrand,
    CardConfigurationItem,
    CheckoutInfoItem,
    ConvenienceStore,
    JWTPayload,
    OnlineBrand,
    PaymentType,
    ProcessingMode,
    RecurringTokenPrivilege,
    SupportedBrand,
    TransactionTokenType,
} from "univapay-node";
import { v4 as uuid } from "uuid";

import { sdk } from "../../SDK";
import { rateLimit } from "../rate-limiter";
import { Dispatch, StateShape } from "../store";

const computePaymentMethodsWithFees = (
    fees: Partial<ExternalFees>,
    paymentMethods: PaymentMethod[],
    filteredCardBrands: PaymentMethod[],
    filteredKonbiniBrands: PaymentMethod[]
) => {
    const hasFees = Object.keys(fees).length >= 1;

    const groupMethodsByFees = (brands: PaymentMethod[], paymentType: PaymentType) => {
        const brandsWithFees = brands
            .filter((brand) => (hasFees ? fees[brand] !== null && fees[brand] !== undefined : true))
            .map((brand) => ({ brand, fees: fees[brand] || 0 }));
        const brandsByFees = groupBy(brandsWithFees, "fees");
        const methodsKeys = Object.keys(brandsByFees);

        return methodsKeys.map((brandFees: string) => {
            const brands = (brandsByFees[brandFees] || []).map(({ brand }) => brand);

            return {
                key: uuid(),
                fees: Number(brandFees || 0),
                brands,
                method: paymentType,
                type: paymentType,
                hasAllBrands: methodsKeys.length === 1,
            };
        });
    };

    const cardMethodGroups = groupMethodsByFees(filteredCardBrands, PaymentType.CARD);
    const konbiniMethodGroups = groupMethodsByFees(filteredKonbiniBrands, PaymentType.KONBINI);

    return paymentMethods
        .filter((method) => !([PaymentType.CARD, PaymentType.KONBINI] as PaymentMethod[]).includes(method))
        .filter((method) => !hasFees || !isNil(fees[method]))
        .map((method) => ({
            key: uuid(),
            fees: Number(fees[method] || 0),
            method,
            type: isOnlineBrand(method) ? PaymentType.ONLINE : (method as PaymentType),
            hasAllBrands: true,
        }))
        .concat(cardMethodGroups, konbiniMethodGroups)
        .sort(({ method: method1, fees: fees1 }, { method: method2, fees: fees2 }) => {
            if (method1 === method2) {
                return fees1 - fees2;
            }

            return PAYMENT_METHOD_ORDER.indexOf(method1) - PAYMENT_METHOD_ORDER.indexOf(method2);
        });
};

export type PaymentMethod = PaymentType | SupportedOnlineBrand | PatchedCardBrand | ConvenienceStore;

type OnlineBrandLogo = {
    logoName: string;
    logoUrl: string;
    logoPattern: string;
    logoWidth: string;
    logoHeight: string;
};

type OnlineBrandInfo = {
    name: string;
    logos: OnlineBrandLogo[];
    promotions: Record<string, string>[];
    loading: boolean;
};
export type PatchedSupportedBrand = SupportedBrand & {
    paymentType: PaymentType;
    brand: SupportedMethodBrand;
    installmentCapable?: boolean;
};

export type PatchedCheckoutInfo = Omit<CheckoutInfoItem, "supportedBrands"> & {
    cardConfiguration: CardConfigurationItem & {
        threeDsAddressRequired?: boolean;
        threeDsRequired?: boolean;
    };
    bankTransferConfiguration: { expirationTimeShift?: { enabled?: boolean; value?: string } };
    convenienceConfiguration: { expiration?: string; expirationTimeShift?: { enabled?: boolean; value?: string } };

    // TODO (fees): Replace property when API is ready
    hasExternalFees?: boolean;

    supportedBrands: (SupportedBrand & {
        paymentType: PaymentType;
        brand: OnlineBrand | PatchedOnlineBrand | CardBrand | ConvenienceStore | "paidy";
        appId?: string;
        installmentCapable?: boolean;
    })[];
};

export type PaymentMethodWithFees = {
    key: string;
    fees: number;
    method: PaymentMethod;
    brands?: PaymentMethodBrand[];
    type: PaymentType;
    hasAllBrands: boolean;
};

type ModelStateShape = {
    // TODO: Use SDK after webpack 5 update
    data: PatchedCheckoutInfo;
    error?: any;
    paymentMethods?: PaymentMethodWithFees[];
    supportedPaymentMethods?: (PaymentType | SupportedOnlineBrand)[];
    darkTheme: boolean;
    brandsToInfo: Partial<Record<OnlineBrand | PatchedOnlineBrand, OnlineBrandInfo>>;
};

const initialState: ModelStateShape = {
    data: null,
    error: null,
    paymentMethods: [],
    supportedPaymentMethods: [],
    darkTheme: false,
    brandsToInfo: {},
};

const validateResponse = (response: PatchedCheckoutInfo, applicationParams: Partial<CheckoutParams>) => {
    const { tokenType, usageLimit, univapayCustomerId } = applicationParams;

    if (tokenType === TransactionTokenType.RECURRING) {
        if (response.recurringTokenPrivilege === RecurringTokenPrivilege.NONE) {
            return new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_PRIVILEGES);
        } else if (response.recurringTokenPrivilege === RecurringTokenPrivilege.BOUNDED && !usageLimit) {
            return new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_USAGE);
        }
    }

    if (univapayCustomerId && response.recurringTokenPrivilege !== RecurringTokenPrivilege.INFINITE) {
        return new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_PRIVILEGES);
    }

    return null;
};

type SetItemPayload = {
    response: PatchedCheckoutInfo;
    checkoutType: CheckoutType;
    isSubscription: boolean;
    forcedPaymentMethods: PaymentMethod[];
    fees: Partial<ExternalFees>;
    currency?: string;
};

const PAYMENT_METHOD_ORDER: PaymentMethod[] = [
    PaymentType.APPLE_PAY,
    PaymentType.CARD,
    OnlineBrand.PAY_PAY_ONLINE,
    OnlineTestBrand.TEST_PAY_PAY_ONLINE,
    PaymentType.PAIDY,
    PaymentType.KONBINI,
    PaymentType.BANK_TRANSFER,
    OnlineBrand.ALIPAY_PLUS_ONLINE,
    OnlineTestBrand.TEST_ALIPAY_PLUS_ONLINE,
    OnlineBrand.ALIPAY_ONLINE,
    OnlineTestBrand.TEST_ALIPAY_ONLINE,
    OnlineBrand.WE_CHAT_ONLINE,
    OnlineTestBrand.TEST_WE_CHAT_ONLINE,
    PatchedOnlineBrand.D_BARAI_ONLINE,
    OnlineTestBrand.TEST_D_BARAI_ONLINE,
];

const CARD_BRAND_ORDER: PaymentMethod[] = [
    CardBrand.VISA,
    CardBrand.MASTERCARD,
    CardBrand.JCB,
    CardBrand.AMEX,
    CardBrand.DINERS,
    CardBrand.UNIONPAY,
    CardBrand.DISCOVER,
    CardBrand.MAESTRO,
];

const model = {
    state: initialState,

    reducers: {
        setItem: (
            state: ModelStateShape,
            { response, isSubscription, checkoutType, forcedPaymentMethods, fees, currency }: SetItemPayload
        ) => {
            const { onlineConfigs = [], bankTransferConfigs = [], convenienceConfigs = [], cardConfigs = [] } = groupBy(
                response.supportedBrands,
                (config) => {
                    switch ((config as SupportedBrand & { paymentType: PaymentType }).paymentType) {
                        case PaymentType.ONLINE:
                            return "onlineConfigs";
                        case PaymentType.BANK_TRANSFER:
                            return "bankTransferConfigs";
                        case PaymentType.CARD:
                            return "cardConfigs";
                        case PaymentType.QR_MERCHANT:
                            return "qrMerchantConfigs";
                        case PaymentType.PAIDY:
                            return "paidyConfigs";
                        case PaymentType.KONBINI:
                            return "convenienceConfigs";
                        default:
                            return "unsorted";
                    }
                }
            );

            // Get all available online brands or the online test brand for test token when no other are available
            const onlineBrands = (() => {
                // Online only works for one time payments
                const isOnlineEnabled =
                    response.onlineConfiguration?.enabled && !isSubscription && checkoutType === CheckoutType.PAYMENT;

                if (!isOnlineEnabled) {
                    return [];
                }

                const onlineBrands = onlineConfigs
                    .filter((config) => SUPPORTED_ONLINE_GATEWAYS.includes(config.onlineBrand))
                    .filter(
                        (config) =>
                            isMobile() ||
                            isWeChatBrowser() ||
                            (response.mode === ProcessingMode.TEST && isDevelop()) ||
                            config.onlineBrand !== OnlineBrand.WE_CHAT_ONLINE // WeChat H5 and OfficialAccount are mobile only
                    )
                    .map((config) => config.onlineBrand);

                // Get all available online brands
                const onlineBrandsWithTest =
                    !onlineBrands.length && response.mode === ProcessingMode.TEST ? onlineTestBrands : onlineBrands;

                if (!onlineBrands.length) {
                    console.warn("Online method is enabled but no credentials has been found.");
                }

                // Use set to ensure unicity as the API can have several configurations with the same brand
                return Array.from(new Set(onlineBrandsWithTest));
            })();

            // Filter supported payment types and add available online configurations
            const supportedPaymentMethods = [
                response.cardConfiguration?.enabled && cardConfigs.length && PaymentType.CARD,
                response.convenienceConfiguration?.enabled && convenienceConfigs.length && PaymentType.KONBINI,
                response.bankTransferConfiguration?.enabled &&
                    !isSubscription &&
                    (bankTransferConfigs.length || response.mode === ProcessingMode.TEST) &&
                    PaymentType.BANK_TRANSFER,
                response.paidyConfiguration?.enabled && !!response.paidyPublicKey && PaymentType.PAIDY,
                ...onlineBrands,
            ].filter(Boolean);

            const supportedBrands = response.supportedBrands as PatchedSupportedBrand[];
            const supportedKonbiniBrands: ConvenienceStore[] = getSupportedKonbiniBrands(supportedBrands);
            const supportedCardBrands: CardBrand[] = getSupportedCardBrands(
                supportedBrands,
                response.cardConfiguration?.onlyDirectCurrency,
                currency
            );

            const filterBrands = (
                supportedBrands: PaymentMethod[],
                paymentType: PaymentType,
                isBrand: (brand: PaymentMethod) => boolean,
                isMethodDisabled = false
            ) => {
                if (isMethodDisabled) {
                    return [];
                }

                if (!forcedPaymentMethods?.length || forcedPaymentMethods?.includes(paymentType)) {
                    return supportedBrands;
                }

                return uniq(
                    forcedPaymentMethods?.filter((method) => isBrand(method) && supportedBrands.includes(method))
                );
            };

            const filteredKonbiniBrands = filterBrands(
                supportedKonbiniBrands,
                PaymentType.KONBINI,
                isKonbiniBrand,
                !supportedPaymentMethods.includes(PaymentType.KONBINI)
            );
            const filteredCardBrands = filterBrands(
                supportedCardBrands,
                PaymentType.CARD,
                isCardBrand,
                !supportedPaymentMethods.includes(PaymentType.CARD)
            ).sort((brand1, brand2) => CARD_BRAND_ORDER.indexOf(brand1) - CARD_BRAND_ORDER.indexOf(brand2));

            const filteredOnlineBrands = filterBrands(onlineBrands, PaymentType.ONLINE, isOnlineBrand);

            // Filter the supported methods with the forced methods
            const paymentMethods = (() => {
                if (!forcedPaymentMethods?.length) {
                    return supportedPaymentMethods;
                }

                const filteredForcedTypeSet = new Set([
                    ...(filteredKonbiniBrands.length ? [PaymentType.KONBINI] : []),
                    ...(filteredCardBrands.length ? [PaymentType.CARD] : []),
                    ...filteredOnlineBrands,
                    ...forcedPaymentMethods.filter((method) => isPaymentType(method)),
                ]);

                return supportedPaymentMethods.filter((type) => filteredForcedTypeSet.has(type));
            })();

            const paymentMethodsWithFees = computePaymentMethodsWithFees(
                fees,
                paymentMethods,
                filteredCardBrands,
                filteredKonbiniBrands
            );

            return {
                ...state,
                data: response,
                paymentMethods: paymentMethodsWithFees,
                supportedPaymentMethods,
                darkTheme: Color(response.theme.colors.mainBackground).isDark(),
            };
        },

        clearItem: (state: ModelStateShape) => ({ ...state, data: null }),

        setOnlineBrandInfo: (
            state: ModelStateShape,
            payload: {
                brand: OnlineBrand | PatchedOnlineBrand;
                promotions: string[];
                name: string;
                logos: OnlineBrandLogo[];
            }
        ): ModelStateShape => ({
            ...state,
            brandsToInfo: {
                ...state.brandsToInfo,
                [payload.brand]: {
                    promotions: payload.promotions?.map((promo) => JSON.parse(promo)),
                    name: payload.name,
                    logos: payload.logos,
                    loading: false,
                },
            },
        }),

        setOnlineBrandLoading: (
            state: ModelStateShape,
            payload: { brand: OnlineBrand | PatchedOnlineBrand }
        ): ModelStateShape => ({
            ...state,
            brandsToInfo: {
                ...state.brandsToInfo,
                [payload.brand]: {
                    ...state.brandsToInfo[payload.brand],
                    loading: true,
                },
            },
        }),
    },

    effects: (dispatch: Dispatch) => ({
        get: async (_?, state?: StateShape): Promise<PatchedCheckoutInfo> => {
            const { application, configuration: self, paidy, online } = dispatch;
            const { checkoutType, isSubscription, amount, currency } = getCheckoutParams(
                state.application.params,
                state.product.products,
                state.configuration.data
            );

            application.clearError();
            self.clearItem();

            const connectionResponse = await self.setSDKEndpoint();
            if (!connectionResponse?.connected) {
                return null;
            }

            try {
                const response = (await rateLimit(() =>
                    sdk.checkoutInfo.get(sdk.api.appId ? { origin: state.application.connector.originDomain } : null)
                )) as PatchedCheckoutInfo;

                const error = validateResponse(response, state.application.params.params);
                if (error) {
                    throw error;
                }

                if (response.paidyPublicKey) {
                    await paidy.setApiKey({ publicKey: response.paidyPublicKey });
                }

                await self.setCvvDisplay(response);

                const fees = response.hasExternalFees ? await self.getExternalFees() : {};

                if (
                    isWeChatBrowser() &&
                    response.supportedBrands.some(({ brand }) => brand === OnlineBrand.WE_CHAT_ONLINE)
                ) {
                    const { appId } = ((await self.getGatewayInfo({
                        brand: OnlineBrand.WE_CHAT_ONLINE,
                        amount,
                        currency,
                    })) as unknown) as { appId?: string };

                    online.setWeChatAppId({ appId });
                }

                self.setItem({
                    response,
                    isSubscription,
                    checkoutType,
                    forcedPaymentMethods: state.application.params?.params?.paymentTypes,
                    fees,
                    currency: state.application.params?.params?.currency,
                });

                return response;
            } catch (error) {
                application.setError({ error });
                return null;
            }
        },

        setSDKEndpoint: async (_?, state?): Promise<{ connected: boolean }> => {
            const { application } = dispatch;
            const { appId } = state.application.params;
            const { connector } = state.application;

            const baseEndpoint = sdk.api.endpoint;
            try {
                const parsed = extractRootDomain(window.location.host);

                if (parsed !== null && parsed !== "") {
                    sdk.api.endpoint = `${window.location.protocol}//api.${parsed}`;
                    await rateLimit(() => sdk.api.ping());
                }
            } catch {
                sdk.api.endpoint = baseEndpoint;
            }

            if (!appId) {
                application.setError({ error: new ParametersError(LOCALE_LABELS.ERRORS_NO_APP_ID) });
                return { connected: false };
            }

            sdk.api.jwtRaw = appId;

            if (connector.originDomain) {
                const supportedDomains = (sdk.api.jwt as JWTPayload<{ domains: string[] }>).domains;
                const apiDomain = extractRootDomain(sdk.api.endpoint);

                if (!validateDomain(connector.originDomain, apiDomain, supportedDomains)) {
                    console.error(
                        `Domain ${connector.originDomain} is not valid for the token. Valid domains: `,
                        supportedDomains.concat(apiDomain).join(",")
                    );

                    application.setError({ error: new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_DOMAIN) });
                    return { connected: false };
                }
            } else {
                console.error("Could not retrieve hostname from connector.");
                application.setError({ error: new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_DOMAIN) });
                return { connected: false };
            }

            try {
                await rateLimit(() => sdk.api.ping());

                return { connected: true };
            } catch (error) {
                application.setError({ error });
                return { connected: false };
            }
        },

        setCvvDisplay: async (payload: PatchedCheckoutInfo, state: StateShape): Promise<void> => {
            const { tokens } = dispatch;
            const { enabled: recurringEnabled, threshold: recurringThreshold = [] } =
                payload.recurringCardChargeCvvConfirmation || {};

            const { univapayCustomerId, currency, amount } = state.application.params?.params || {};

            if (!univapayCustomerId || !recurringEnabled || !currency) {
                await tokens.setCvvRequired({ cvvRequired: false });
                return;
            }

            const matchingCurrency = recurringThreshold.find(
                (value) => value.currency.toLowerCase() === currency.toLowerCase()
            );

            // When the currency being used is present in the recurring CVV threshold data, no exchange rates necessary
            if (matchingCurrency) {
                await tokens.setCvvRequired({ cvvRequired: matchingCurrency.amount <= amount });
                return;
            }

            // When the currency being used is not present, we have to use the exchange rate API to convert this currency
            // into the platform's default currency, which is guaranteed to be in the list.
            const data = { amount, currency, to: "platform" };
            const exchangeRate = await rateLimit(() => sdk.exchangeRates.calculate(data));
            const primaryCurrency = recurringThreshold.find((value) => value.currency === exchangeRate.currency);
            if (primaryCurrency) {
                await tokens.setCvvRequired({ cvvRequired: primaryCurrency.amount <= exchangeRate.amount });
            }
        },

        getGatewayInfo: async (payload: {
            brand: OnlineBrand | PatchedOnlineBrand;
            amount: number;
            currency: string;
        }) => {
            const { configuration: self } = dispatch;
            const { brand, amount, currency } = payload;

            const { callMethod, osType } = getOnlineCallMethodParams(brand);
            const data = { amount, currency, callMethod, osType } as CheckoutInfoBrandPayload;

            self.setOnlineBrandLoading({ brand });

            try {
                const gatewayInfo = await sdk.checkoutInfoBrand.get(brand, data);

                self.setOnlineBrandInfo({
                    brand: brand as OnlineBrand,
                    promotions: gatewayInfo?.brands?.[0]?.extras?.promoNames,
                    name: gatewayInfo?.brands?.[0]?.brandDisplayName,
                    logos: gatewayInfo?.brands?.[0]?.extras?.logos,
                });

                return gatewayInfo;
            } catch (e) {
                console.warn(`Unable to fetch information on gateway ${brand}`);
                self.setOnlineBrandInfo({ brand, promotions: [], name: null, logos: [] });
            }
        },

        getExternalFees: async (): Promise<ExternalFees> => {
            const { application } = dispatch;

            try {
                return rateLimit(() => sdk.checkoutInfoBrand.getExternalFees());
            } catch (error) {
                application.setError({ error });
                return null;
            }
        },
    }),
};

export const configuration = createModel()(model);
