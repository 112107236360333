import { IntlState } from "react-intl-redux";
import { OnlineTestBrand, PatchedOnlineBrand, SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { OnlineBrand, PaymentType } from "univapay-node";

import { defaultLocale, getLocale } from "../../../common/locale";
import { SupportedLocale } from "../../../common/locale/labels";
import { SupportedMethodBrand } from "../types";

import en_US from "./en_US";
import ja_JP from "./ja_JP";
import ko_KR from "./ko_KR";
import { getOnlineBrandLabel, LOCALE_LABELS } from "./labels";
import ru_RU from "./ru_RU";
import { getCardLabel, getConvenienceStoreLabel } from "./selectors";
import th_TH from "./th_TH";
import zh_CN from "./zh_CN";
import zh_TW from "./zh_TW";

export const locales = {
    [SupportedLocale.EN_US]: { locale: SupportedLocale.EN_US, messages: en_US },
    [SupportedLocale.JA_JP]: { locale: SupportedLocale.JA_JP, messages: ja_JP },
    [SupportedLocale.ZH_CN]: { locale: SupportedLocale.ZH_CN, messages: zh_CN },
    [SupportedLocale.ZH_TW]: { locale: SupportedLocale.ZH_TW, messages: zh_TW },
    [SupportedLocale.RU_RU]: { locale: SupportedLocale.RU_RU, messages: ru_RU },
    [SupportedLocale.TH_TH]: { locale: SupportedLocale.TH_TH, messages: th_TH },
    [SupportedLocale.KO_KR]: { locale: SupportedLocale.KO_KR, messages: ko_KR },
};

export const getInitialLocale = (): IntlState => getLocaleData(getLocale("auto"));
export const getLocaleData = (locale: string): IntlState => locales[locale] || locales[defaultLocale];

export const getPaymentTypeLabel = (paymentType: PaymentType) => {
    switch (paymentType) {
        case PaymentType.CARD:
            return LOCALE_LABELS.FORM_PAYMENT_TYPE_CARD;

        case PaymentType.KONBINI:
            return LOCALE_LABELS.FORM_PAYMENT_TYPE_KONBINI;

        case PaymentType.PAIDY:
            return LOCALE_LABELS.FORM_PAYMENT_TYPE_PAIDY;

        case PaymentType.BANK_TRANSFER:
            return LOCALE_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER;
    }
};

export const getPaymentMethodLabel = (
    paymentType: PaymentType | SupportedOnlineBrand,
    brand?: SupportedOnlineBrand
) => {
    // Support for legacy usage with brand
    if (paymentType === PaymentType.ONLINE) {
        return getOnlineBrandLabel(brand);
    }

    return [
        ...Object.values(OnlineBrand),
        ...Object.values(PatchedOnlineBrand),
        ...Object.values(OnlineTestBrand),
    ].includes(paymentType as SupportedOnlineBrand)
        ? getOnlineBrandLabel(paymentType as SupportedOnlineBrand)
        : getPaymentTypeLabel(paymentType as PaymentType);
};

export const getMethodBrandLabelId = (type: PaymentType, method: SupportedMethodBrand) => {
    switch (type) {
        case PaymentType.APPLE_PAY:
        case PaymentType.CARD:
            return getCardLabel(method);

        case PaymentType.KONBINI:
            return getConvenienceStoreLabel(method);

        default:
            return getPaymentMethodLabel(type);
    }
};
