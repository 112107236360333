import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "Paidyのトークン発行処理にリダイレクトされています。しばらくお待ち下さい。",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "Paidyによって支払いが承認されました。完了するには以下のボタンをクリックして下さい。",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "Paidy",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "生年月日　例）1970-01-30",
};

export default LABELS;
