import { createModel } from "@rematch/core";
import { Metadata, TransactionTokenItem, TransactionTokenType, TransactionTokenUpdateParams } from "univapay-node";

import { sdk } from "../../SDK";
import { Dispatch, StateShape } from "../store";

export type TokensMap = {
    [uuid: string]: TransactionTokenItem;
};

export type PatchedTransactionTokenUpdateParams = Omit<TransactionTokenUpdateParams, "data"> & {
    data?: {
        cvv?: string;
        line1?: string;
        line2?: string;
        state?: string;
        city?: string;
        country?: string;
        zip?: string;
        phoneNumber?: {
            countryCode?: string;
            localNumber?: string;
        };
    };

    /**
     * Metadata or stringified JSON object
     */
    metadata?: string | Metadata;
};

export const getDefaultSelectedTokenId = (tokens: TransactionTokenItem[]): string =>
    tokens?.length > 0
        ? tokens.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()).reverse()[0].id
        : "";

type ModelStateShape = {
    tokens?: TokensMap;
    error: any;
    cvvRequired: boolean;
};

const initialState: ModelStateShape = {
    tokens: null,
    error: null,
    cvvRequired: false,
};

const model = {
    state: initialState,

    reducers: {
        setTokens: (state: ModelStateShape, { tokenMap }: { tokenMap: TokensMap }) => ({ ...state, tokens: tokenMap }),
        updateToken: (state: ModelStateShape, { token }: { token: TransactionTokenItem }) => ({
            ...state,
            tokens: { ...state.tokens, [token.id]: token },
        }),
        setError: (state: ModelStateShape, { error }: { error: Error }) => ({ ...state, error }),
        setCvvRequired: (state: ModelStateShape, { cvvRequired }: { cvvRequired: boolean }) => ({
            ...state,
            cvvRequired,
        }),

        clearTokens: (state: ModelStateShape) => ({ ...state, tokens: null }),
        clearError: (state: ModelStateShape) => ({ ...state, error: null }),
    },

    effects: (dispatch: Dispatch) => ({
        list: async (_?, state?: StateShape): Promise<TokensMap> => {
            const { tokens: self } = dispatch;
            const { univapayCustomerId } = state.application.params.params;

            self.clearTokens();
            self.clearError();

            try {
                if (univapayCustomerId) {
                    const tokens = await sdk.transactionTokens.list({
                        customerId: univapayCustomerId,
                        type: TransactionTokenType.RECURRING,
                        mode: state.configuration.data.mode,
                        limit: 100,
                    });

                    // Get full tokens
                    const fullTokens: TransactionTokenItem[] = [];
                    const listItems = tokens?.items || [];
                    for (const token of listItems) {
                        fullTokens.push(await sdk.transactionTokens.get(token.storeId, token.id));
                    }

                    const tokenMap = fullTokens.reduce((acc, token) => {
                        acc[token.id] = token;
                        return acc;
                    }, {});

                    self.setTokens({ tokenMap });

                    return tokenMap;
                } else {
                    self.setTokens({ tokenMap: {} });

                    return {};
                }
            } catch (error) {
                self.setError({ error });
            }
        },

        getRecurring: async ({ storeId, id }: { storeId: string; id: string }): Promise<TransactionTokenItem> => {
            const { tokens: self, application } = dispatch;

            try {
                const token = await sdk.transactionTokens.get(storeId, id);

                if (token?.type !== TransactionTokenType.RECURRING) {
                    throw new Error("Invalid token type");
                }

                self.updateToken({ token });

                return token;
            } catch (error) {
                application.setError({ error });
            }
        },
    }),
};

export const tokens = createModel()(model);
