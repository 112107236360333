import * as ENUM_LABELS from "../labels/enum";

export const LABELS: Record<string, string> = {
    [ENUM_LABELS.CONVENIENCE_STORE_SEVEN_ELEVEN]: "7-Eleven",
    [ENUM_LABELS.CONVENIENCE_STORE_FAMILY_MART]: "全家",
    [ENUM_LABELS.CONVENIENCE_STORE_LAWSON]: "羅森",
    [ENUM_LABELS.CONVENIENCE_STORE_MINI_STOP]: "MINISTOP",
    [ENUM_LABELS.CONVENIENCE_STORE_SEICO_MART]: "Seicomart",
    [ENUM_LABELS.CONVENIENCE_STORE_DAILY_YAMAZAKI]: "Daily YAMAZAKI",
    [ENUM_LABELS.CONVENIENCE_STORE_PAY_EASY]: "PayEasy",
    [ENUM_LABELS.CONVENIENCE_STORE_SUNKUS]: "Sunkus",
    [ENUM_LABELS.CONVENIENCE_STORE_CIRCLE_K]: "CircleK",
    [ENUM_LABELS.CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE]: "Yamazaki Daily",

    [ENUM_LABELS.CARD_VISA]: "Visa",
    [ENUM_LABELS.CARD_AMEX]: "American Express",
    [ENUM_LABELS.CARD_MASTERCARD]: "Mastercard",
    [ENUM_LABELS.CARD_MAESTRO]: "Maestro",
    [ENUM_LABELS.CARD_DISCOVER]: "Discover",
    [ENUM_LABELS.CARD_JCB]: "JCB",
    [ENUM_LABELS.CARD_DINERS]: "Diners Club",
    [ENUM_LABELS.CARD_UNIONPAY]: "UnionPay",
};

export default LABELS;
