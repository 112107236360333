import { IntlState } from "react-intl-redux";
import { parsePhoneNumber } from "awesome-phonenumber";
import { PhoneNumber } from "univapay-node/dist/cjs";

export const getIntlMessage = (id: string, intl: IntlState) => intl.messages[id];

export const concatPhoneNumber = (phoneNumber: PhoneNumber): string =>
    `${phoneNumber?.countryCode ? `+${phoneNumber.countryCode} ` : ""}${phoneNumber?.localNumber || ""}`;

export const parsePhone = (phoneNumber: string): PhoneNumber => {
    if (!phoneNumber) {
        return null;
    }

    const parsed = parsePhoneNumber(phoneNumber, { regionCode: "JP" });

    return parsed
        ? { countryCode: `${parsed.countryCode}`, localNumber: parsed.number.input }
        : { countryCode: "", localNumber: "" };
};
