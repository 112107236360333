import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "{name}必須為List。",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "{name}必須在:min和:max之間",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "{name}必須為布爾值。",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "{name}請輸入正確的日期格式。",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "{name}和:different不能相同。",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "{name}請輸入正確的郵件地址。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "{name}必須小於:max。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "{name}字數必須小於:max。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "{name}必須大於:min。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "{name}字數必須大於:min。",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "已選的{name}無效。",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "{name}請輸入數字。",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: "{name}格式有誤。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "{name}為必填項。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: ":other的值為:value時，{name}為必填項。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: ":field的值被設定時，{name}為必填項。",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "{name}和:same不能相同。",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "{name}必須為字符串。",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "{name}請輸入正確的URL鏈接。",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name}為必填項。",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name}請輸入有效的郵件地址",
    [VALIDATION_LABELS.VALIDATION_BANNED_EMAIL]: "Email banned",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name}請輸入有效的電話號碼",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name}卡號無效",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name}日期格式無效",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "信用卡已過期",
    [VALIDATION_LABELS.VALIDATION_CVV_LENGTH]: "請輸入三位數安全代碼",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "此欄位是必需的。",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name}包含無效字符",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name}長度無效",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name}必須至少包含一個空格",
};

export default LABELS;
