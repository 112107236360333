/**
 *  @module Resources/Products
 */

import { AuthParams, HTTPMethod, ProductItem, Products as NodeProducts, SendData } from "univapay-node";

export class Products extends NodeProducts {
    getByCode(code: string, data?: SendData<void>, auth?: AuthParams): Promise<ProductItem> {
        return this.defineRoute(HTTPMethod.GET, "/checkout/products/code/:code")(data, undefined, auth, { code });
    }
}
