import * as ENUM_LABELS from "../labels/enum";

export const LABELS: Record<string, string> = {
    [ENUM_LABELS.CONVENIENCE_STORE_SEVEN_ELEVEN]: "Seven-Eleven",
    [ENUM_LABELS.CONVENIENCE_STORE_FAMILY_MART]: "Family Mart",
    [ENUM_LABELS.CONVENIENCE_STORE_LAWSON]: "ลอว์สัน",
    [ENUM_LABELS.CONVENIENCE_STORE_MINI_STOP]: "Ministop",
    [ENUM_LABELS.CONVENIENCE_STORE_SEICO_MART]: "Seiko Mart",
    [ENUM_LABELS.CONVENIENCE_STORE_DAILY_YAMAZAKI]: "ทุกวันยามาซากิ",
    [ENUM_LABELS.CONVENIENCE_STORE_PAY_EASY]: "หน้าหนังสือ",
    [ENUM_LABELS.CONVENIENCE_STORE_SUNKUS]: "ขอบคุณ",
    [ENUM_LABELS.CONVENIENCE_STORE_CIRCLE_K]: "Circle K",
    [ENUM_LABELS.CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE]: "ยามาซากิทุกวัน",

    [ENUM_LABELS.CARD_VISA]: "วีซ่า",
    [ENUM_LABELS.CARD_AMEX]: "American Express",
    [ENUM_LABELS.CARD_MASTERCARD]: "มาสเตอร์การ์ด",
    [ENUM_LABELS.CARD_MAESTRO]: "มาสโทร",
    [ENUM_LABELS.CARD_DISCOVER]: "ค้นพบ",
    [ENUM_LABELS.CARD_JCB]: "JCB",
    [ENUM_LABELS.CARD_DINERS]: "Diners Club",
    [ENUM_LABELS.CARD_UNIONPAY]: "UnionPay",
};

export default LABELS;
