export const ERROR_CHECKOUT_CHARGE = "ERROR_CHECKOUT_CHARGE";

export class ChargeError extends Error {
    code: string = ERROR_CHECKOUT_CHARGE;
    error: any = null;

    constructor(error?: any, message?: string) {
        super(message);
        this.error = error;
    }
}
