import * as SUBSCRIPTIONS_LABELS from "../labels/subscriptions";

export const LABELS: Record<string, string> = {
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_BUTTONS_PAY]: "Pay {money} per {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD]: "Pay {money} per {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INITIAL]: "Pay {money} now ",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY]: "then pay {money} per {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD]: "then pay {money} per {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_INSTALLMENT]: "then {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_START]: "Starting on {date}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_CVV_AUTH]: "Register your card today",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_DAILY]: "day",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_WEEKLY]: "week",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIWEEKLY]: "2 weeks",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_MONTHLY]: "month",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIMONTHLY]: "2 months",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_QUARTERLY]: "3 months",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_SEMIANNUALLY]: "6 months",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_ANNUALLY]: "year",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY]: "Billed daily",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY]: "Billed weekly",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY]: "Billed every 2 weeks",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY]: "Billed monthly",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY]: "Billed every 3 months",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY]: "Billed every 6 months",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY]: "Billed annually",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT]: "Initial Amount of {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE]: "Repayment Plan",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT]: "{money} now",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES]: "Pay {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING]: "Pay {money} (revolving)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL]: "Pay {money} per {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL]: "then {money} per {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL]: "then pay {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES]: "then {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL]: "Pay {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS]: "{cycles} payments",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_CYCLES]: "Next Payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_START]: "First payment",
};

export default LABELS;
