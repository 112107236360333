import {
    ConvenienceStore,
    ECFormCustomField,
    InstallmentPlan,
    OnlineBrand,
    PaymentType,
    TemporaryTokenAliasQrLogoType,
    TransactionTokenType,
    UsageLimit,
} from "univapay-node";
import { CardBrand } from "univapay-node";

import {
    MessageChargeCreated,
    MessageError,
    MessageSubscriptionCreated,
    MessageSuccess,
    MessageThreeDsAuthorization,
    MessageThreeDsAuthorizationFailure,
    MessageThreeDsAuthorizationSuccess,
    MessageTokenCreated,
} from "./Messages";

/** Patched SDK types */
export enum NewCardBrand {
    PRIVATE_LABEL = "private_label",
}

export type PatchedCardBrand = CardBrand | NewCardBrand;
/** End patched SDK types */

export enum CheckoutType {
    PAYMENT = "payment",
    TOKEN = "token",
    QR = "qr",
}

export enum IFrameType {
    DIALOG = "dialog",
    INLINE = "inline",
}

/**
 * TODO: Use correct type when testcafe updates its own typescript (version >= 2.0.0)
 */
export type PeriodDuration = string; // "D" | "M" | "Y"
export type Period = string; // `P${number}${PeriodDuration}`

export enum ThreeDsMode {
    NORMAL = "normal",
    SKIP = "skip",
    FORCE = "force",
    IF_AVAILABLE = "if_available",
    REQUIRE = "require",
    PROVIDED = "provided",
}

export interface CommonParams {
    /**
     * Boolean when the checkout should be displayed as an inline form instead of a dialog
     */
    inline?: boolean;
    inlineStyles?: Record<InlineStyleKeys, Record<string, string>>;

    address: boolean;
    amount: number;
    appId: string;
    autoClose: boolean;
    autoCloseOnError: boolean;
    autoSubmit: boolean;
    autoSubmitOnError: boolean;
    capture: boolean;
    captureAt: string;
    captureIn: Period;
    captureDayOfMonth?: number;
    confirmationRequired: boolean;
    checkout: CheckoutType;
    merchantCardRegistration?: boolean;
    currency: string;
    description: string;
    email: string;
    phoneNumber?: string;
    phoneNumberCountryCode?: string;
    customerName?: string;
    customerNameKana?: string;
    locale: string;
    tokenType: TransactionTokenType;
    usageLimit: UsageLimit;
    subscriptionPeriod: string;
    subscriptionId: string;
    subscriptionInitialAmount: number;
    requireEmail?: boolean;
    requireBillingAddress?: boolean;
    requirePhoneNumber?: boolean;
    requireName?: boolean;
    requireNameKana?: boolean;
    subscriptionStart: string;
    subscriptionStartIn: Period;
    installmentPlan?: InstallmentPlan;
    subscriptionPlan?: InstallmentPlan;
    installmentQty?: number;
    subscriptionQty?: number;
    title: string;
    header: string;
    dark: boolean;
    showLogo: boolean;
    timeout?: number;
    hideRecurringCheckbox?: boolean;
    hidePrivacyLink?: boolean;
    showCvv: boolean;
    removeCheckoutButtonAfterCharge?: boolean;
    univapayCustomerId: string;
    subscriptionTimezone: string;
    subscriptionPreserveEndOfMonth?: boolean;
    subscriptionRetryInterval?: string;
    threeDsMode: ThreeDsMode;
    threeDsRedirect?: string;

    // User installments
    allowCardInstallments?: boolean;
    cardInstallmentOptions?: string[];

    subscriptionStartDayOfMonth: number;
    products?: string[];
    productCodes?: string[];
    productQuantities?: number[];
    productCodeQuantities?: number[];
    paymentType?: PaymentType | OnlineBrand;
    paymentTypes?: (PaymentType | OnlineBrand | PatchedCardBrand | ConvenienceStore)[];
    paymentMethods?: (PaymentType | OnlineBrand | PatchedCardBrand | ConvenienceStore)[];
    descriptor: string;
    ignoreDescriptorOnError: boolean;
    onlyDirectCurrency: boolean;
    buyerName: string;
    buyerNameTransliteration: string;
    buyerDateOfBirth: string;
    submitButtonText?: string;
    univapayReferenceId?: string;

    bankTransferExpirationPeriod?: string;
    bankTransferExpirationTimeShift?: string;
    convenienceStoreExpirationPeriod?: string;
    convenienceStoreExpirationTimeShift?: string;

    // Legacy expiration params
    expirationPeriod?: string;
    expirationTimeShift?: string;

    shippingAddressLine1: string;
    shippingAddressLine2: string;
    shippingAddressCity: string;
    shippingAddressState: string;
    shippingAddressZip: string;
    shippingAddressCountryCode: string;

    customFields?: ECFormCustomField[];

    onSuccess?: (message: MessageSuccess) => void;
    onPending?: (message: MessageSuccess) => void;
    callback?: (message: MessageSuccess) => void; // legacy parameter for onSuccess
    onError?: (message: MessageError) => void;
    beforeClosing?: () => boolean;
    onTokenCreated?: (message: MessageTokenCreated) => boolean;
    onChargeCreated?: (message: MessageChargeCreated) => boolean;
    onSubscriptionCreated?: (message: MessageSubscriptionCreated) => boolean;
    onThreeDsAuthorization?: (message: MessageThreeDsAuthorization) => boolean;
    onThreeDsAuthorizationSuccess?: (message: MessageThreeDsAuthorizationSuccess) => boolean;
    onThreeDsAuthorizationFailure?: (message: MessageThreeDsAuthorizationFailure) => boolean;
    onThreeDsAuthorizationModalOpened?: () => boolean;
    onValidationError?: (fields: string[]) => boolean;
    hasBeforeClosingCallback?: boolean;

    /**
     * For recurring token, save the CVV with the card data for future usage
     */
    cvvAuthorize: boolean;

    qrColor: string;
    qrLogoType: TemporaryTokenAliasQrLogoType;

    /**
     * UUID. When set forces an instant charge with the given token id.
     */
    instantChargeTokenId?: string;

    cardholder?: string;
    exp?: string;
}

type InlineParams = {
    inlineBaseFontSize?: string;
    horizontalInlineLayout?: boolean;
};

export interface MetadataParams {
    [key: string]: string | boolean | number | BigInt;
}

export interface MetaParams {
    metadata: MetadataParams;
    metadataCharge: MetadataParams;
    metadataSubscription: MetadataParams;
    metadataToken: MetadataParams;
}

export interface CustomFieldParams {
    customFieldKeys?: string;
    customFieldLabels?: string;
    customFieldTypes?: string;
    customFieldRequired?: string;
    customFieldOptions?: string;
}

export type CheckoutStyles = {
    inlineBaseFontSize?: string;
    inlineItemStyle?: string;
    inlineItemLabelStyle?: string;
    inlineItemErrorStyle?: string;
    inlineFieldStyle?: string;
    inlineFieldInvalidStyle?: string;
    inlineFieldFocusStyle?: string;

    inlineTextFieldStyle?: string;
    inlineTextFieldInvalidStyle?: string;
    inlineTextFieldFocusStyle?: string;
    inlineTextItemStyle?: string;
    inlineTextItemLabelStyle?: string;
    inlineTextItemErrorStyle?: string;

    inlineToggleFieldStyle?: string;
    inlineToggleFieldInvalidStyle?: string;
    inlineToggleFieldFocusStyle?: string;
    inlineToggleItemStyle?: string;
    inlineToggleItemLabelStyle?: string;
    inlineToggleItemErrorStyle?: string;

    inlineSelectFieldStyle?: string;
    inlineSelectFieldInvalidStyle?: string;
    inlineSelectFieldFocusStyle?: string;
    inlineSelectItemStyle?: string;
    inlineSelectItemLabelStyle?: string;
    inlineSelectItemErrorStyle?: string;

    inlineRadioItemStyle?: string;
    inlineRadioItemLabelStyle?: string;
    inlineRadioFieldFocusStyle?: string;

    horizontalInlineLayout?: boolean;
};

export enum InlineStyleKeys {
    ITEM = "item",
    ITEM_LABEL = "item-label",
    ITEM_ERROR = "item-error",
    FIELD = "field",
    FIELD_INVALID = "field-invalid",
    FIELD_FOCUS = "field-focus",

    TEXT_FIELD = "text-field",
    TEXT_FIELD_INVALID = "text-field-invalid",
    TEXT_FIELD_FOCUS = "text-field-focus",
    TEXT_ITEM = "text-item",
    TEXT_ITEM_LABEL = "text-item-label",
    TEXT_ITEM_ERROR = "text-item-error",

    TOGGLE_FIELD = "toggle-field",
    TOGGLE_FIELD_INVALID = "toggle-field-invalid",
    TOGGLE_FIELD_FOCUS = "toggle-field-focus",
    TOGGLE_ITEM = "toggle-item",
    TOGGLE_ITEM_LABEL = "toggle-item-label",
    TOGGLE_ITEM_ERROR = "toggle-item-error",

    SELECT_FIELD = "select-field",
    SELECT_FIELD_INVALID = "select-field-invalid",
    SELECT_FIELD_FOCUS = "select-field-focus",
    SELECT_ITEM = "select-item",
    SELECT_ITEM_LABEL = "select-item-label",
    SELECT_ITEM_ERROR = "select-item-error",

    RADIO_ITEM = "radio-item",
    RADIO_ITEM_LABEL = "radio-item-label",
    RADIO_FIELD_FOCUS = "radio-field-focus",
}

export type CheckoutParams = CommonParams & MetaParams & InlineParams & CustomFieldParams;
