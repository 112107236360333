import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "Please send your payment to the bank account below",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION]: "Please check your emails to confirm the bank account details, or contact the store directly.",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "Bank name",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "Bank code",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "Branch name",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "Branch code",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "Account number",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "Account name",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "Amount",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "Deadline",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "First payment",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "First deadline",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "Next payments",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "Next deadline",
};

export default LABELS;
