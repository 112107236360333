import * as FORM_LABELS from "../labels/online";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.ONLINE_PROCESSING_INFO]: "{brand} 토큰 발행 프로세스가 방향을 바꿨습니다. 잠시 기다려주세요.",
    [FORM_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE]: "고객 정보",

    [FORM_LABELS.ONLINE_INSTANT_DISCOUNT]: "특별 할인",
};

export default LABELS;
