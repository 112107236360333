import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "โปรดโอนไปยังบัญชีธนาคารต่อไปนี้",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION]: "โปรดตรวจสอบข้อมูลปลายทางการชำระเงินจากอีเมลขั้นตอนที่เสร็จสมบูรณ์หรือติดต่อร้านค้าของคุณ",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "ชื่อสถาบันการเงิน",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "รหัสธนาคาร",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "ชื่อสาขา",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "รหัสสาขา",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "หมายเลขบัญชี",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "ผู้ถือบัญชี",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "โอนเงินจำนวนเงิน",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "กำหนดเส้นตาย",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "จำนวนการโอนเริ่มต้น",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "กำหนดเส้นตายการโอนครั้งแรก",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "จำนวนการโอนถัดไป",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "กำหนดเวลาการโอนครั้งต่อไป",
};

export default LABELS;
