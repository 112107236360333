import { Models } from "@rematch/core";

import { application } from "./application";
import { bankTransfer } from "./bank-transfer";
import { checkout } from "./checkout";
import { configuration } from "./configuration";
import { expiry } from "./expiry";
import { online } from "./online";
import { paidy } from "./paidy";
import { product } from "./product";
import { subscription } from "./subscription";
import { tokens } from "./tokens";
import { userData } from "./user-data";

export interface UnivapayModels {
    application: typeof application;
    bankTransfer: typeof bankTransfer;
    checkout: typeof checkout;
    configuration: typeof configuration;
    expiry: typeof expiry;
    online: typeof online;
    paidy: typeof paidy;
    product: typeof product;
    subscription: typeof subscription;
    tokens: typeof tokens;
    userData: typeof userData;
}

// Prevent circular reference
export type RootModels = Models & UnivapayModels;

const rootModels: RootModels = {
    application,
    bankTransfer,
    checkout,
    configuration,
    expiry,
    online,
    paidy,
    product,
    subscription,
    tokens,
    userData,
};

export default rootModels;
