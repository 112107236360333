import { createModel } from "@rematch/core";
import { merge } from "lodash";
import { PhoneNumber } from "univapay-node";

export type UserDataStateShape = {
    email?: string;
    phoneNumber?: PhoneNumber;
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;

    // Paidy data
    name: string;
    nameKana: string;
    birthday: string;

    customFields: Record<string, string>;
};

export type UserData = {
    email: string;
    name?: string;
    nameKana?: string;
    birthday: string;
    phoneNumber: PhoneNumber;
    data: {
        line1: string;
        line2?: string;
        zip: string;
        city: string;
        state: string;
        country: string;
    };
    customFields: Record<string, string>;
    cardholder?: string;
    exp?: string;
};

const initialState: UserDataStateShape = {
    email: "",
    phoneNumber: null,
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: "",
    country: "",

    name: "",
    nameKana: "",
    birthday: "",

    customFields: {},
};

const model = {
    state: initialState,

    reducers: {
        setUserData: (state: UserDataStateShape, userData: Partial<UserData>) =>
            merge(
                { ...state },
                {
                    name: userData.name ?? state.name,
                    nameKana: userData.nameKana ?? state.nameKana,
                    email: userData.email,
                    phoneNumber: userData.phoneNumber,
                    birthday: userData.birthday,
                    line1: userData.data?.line1,
                    line2: userData.data?.line2,
                    city: userData.data?.city,
                    state: userData.data?.state,
                    zip: userData.data?.zip,
                    country: userData.data?.country,
                    customFields: userData.customFields || {},
                }
            ),
    },
};

export const userData = createModel()(model);
