import { ApplicationTimeoutError } from "common/errors/ApplicationTimeoutError";
import { SubmittingError } from "common/errors/SubmittingError";
import { generate } from "common/random";
import { isMobile } from "common/utils/browser";
import {
    cleanup as cleanupThreeDsModal,
    createIFrame as createThreeDsModal,
    redirect as showThreeDsModal,
    setRedirectLocale,
} from "common/utils/redirect";
import ee from "event-emitter";
import { Emitter } from "event-emitter";

import { IFRAME_NAME, IFRAME_ZINDEX, LOAD_TIMEOUT, SUBMIT_TIMEOUT } from "../../common/constants";
import { ConnectorError } from "../../common/errors/ConnectorError";
import { TimeoutError } from "../../common/errors/TimeoutError";
import {
    BaseMessageType,
    ConnectorMessage,
    EnhancedMessageEvent,
    Message,
    MessageBeforeClosingResponse,
    MessageExecute,
    MessageExecutePayment,
    MessageHandshake,
    MessageType,
    ResourceType,
} from "../../common/Messages";
import { timeout } from "../../common/timeout";
import { development } from "../../common/utils/development";
import { JSONParse, JSONStringify } from "../../common/utils/json";

import { getInputName, renderInput } from "./templates/ButtonTemplate";
import { FULL_CHECKOUT_URL } from "./utils";

const dialogIframeStyles = {
    background: "transparent",
    border: "0 none transparent",
    bottom: "0",
    display: "none",
    height: "100%",
    left: "0",
    overflowX: "hidden",
    overflowY: "auto",
    position: "fixed",
    right: "0",
    top: "0",
    visibility: "visible",
    width: "100%",
    zIndex: IFRAME_ZINDEX,
};

const inlineIFrameStyles = {
    border: "0 none transparent",
    display: "none",
    width: "100%",
    visibility: "visible",
};
export class Connector {
    private static instances = [];

    public emitter: Emitter;
    public iframe: HTMLIFrameElement;
    public iframeId: string;

    private connectorId: string;
    private remote: Window;
    private connected = false;
    private initCallbacks: ((...params: unknown[]) => void)[] = [];
    private submitCallbacks: ((...params: unknown[]) => void)[] = [];
    private execute: Message<MessageExecutePayment>;

    private threeDsModal: { form: HTMLElement; iFrame: HTMLElement; iFrameContainer: HTMLElement };

    private originalOverflow: string = null;

    static addInstance(connector: Connector) {
        Connector.instances.push(connector);
    }

    static removeClosedInstance() {
        Connector.instances = Connector.instances.filter((instance) => !!instance.remote);
    }

    static getConnectorByIFrame(iframe: HTMLIFrameElement) {
        if (!Connector.instances?.length) {
            throw new Error("No opened connector found.");
        }

        return Connector.instances.length === 1
            ? Connector.instances[0]
            : Connector.instances.find(
                  (instance) => instance.iframe === iframe || (instance.iframeId && instance.iframeId === iframe?.id)
              );
    }

    constructor() {
        this.connectorId = generate();
        this.emitter = ee({});
        this.emitter.on("checkout:execute", this.executeCheckout);
        window.addEventListener("message", this.handleMessage);
    }

    open = async (message: Message<MessageExecutePayment>, parentNode?: HTMLElement) => {
        const promise = Promise.race([
            new Promise((resolve, reject) => {
                this.initCallbacks = [resolve, reject];
            }),
            timeout(LOAD_TIMEOUT, new ApplicationTimeoutError(LOAD_TIMEOUT), () => {
                this.initCallbacks = [];
            }),
        ]);

        development(() => console.info("1- Opening checkout..."));
        const checkout = this.createIframe(parentNode);

        this.remote = (checkout as HTMLIFrameElement).contentWindow;
        this.execute = message;

        if (this.iframe) {
            this.iframe.style.display = "block";
        }

        await promise;
        this.finishConnect();

        Connector.addInstance(this);

        if (!parentNode) {
            // overflow is hidden for dialog, not for inline style with a parent
            this.originalOverflow = document.body.style.overflow;
            document.body.style.overflow = "hidden";
        }

        return checkout as HTMLIFrameElement;
    };

    async submit() {
        if (this.submitCallbacks.length) {
            console.warn("Form already submitting. Please wait.");
            return Promise.reject(new SubmittingError("FORM_ALREADY_SUBMITTING"));
        }

        this.sendMessage({ type: MessageType.SUBMIT_CARD_DATA, data: { connectorId: this.connectorId } });

        return Promise.race([
            new Promise((resolve, reject) => {
                this.submitCallbacks = [resolve, reject];
            }),
            timeout(SUBMIT_TIMEOUT, new TimeoutError(SUBMIT_TIMEOUT), () => {
                this.submitCallbacks = [];
            }),
        ]);
    }

    close(): void {
        this.connected = false;

        // Do not close for inline form
        if (!this.remote) {
            return;
        }

        document.body.style.overflow = this.originalOverflow;
        this.remote.close();
        this.remote = null;

        if (this.iframe) {
            window.document.body.removeChild(this.iframe);
        } else {
            Connector.removeClosedInstance();
        }
    }

    private createIframe(parent: HTMLElement): HTMLIFrameElement {
        const iframe: HTMLIFrameElement = window.document.createElement("iframe");

        iframe.frameBorder = "0";
        iframe.name = IFRAME_NAME;
        iframe.className = IFRAME_NAME;
        iframe.src = FULL_CHECKOUT_URL;
        iframe.setAttribute("allowtransparency", "true");

        const iframeId = `${IFRAME_NAME}-${generate(8)}`;
        iframe.id = iframeId;
        this.iframeId = iframeId;

        const styles = parent ? inlineIFrameStyles : dialogIframeStyles;
        Object.keys(styles).forEach((key: string) => (iframe.style[key] = styles[key]));

        this.iframe = iframe;
        (parent || window.document.body).appendChild(iframe);

        return iframe;
    }

    private finishConnect = (connectorId?: string): void => {
        this.initCallbacks = [];

        if (connectorId === this.connectorId && this.execute) {
            this.sendMessage(this.execute);
        }
    };

    private handleMessage = (e: EnhancedMessageEvent): void => {
        if (!e || FULL_CHECKOUT_URL.indexOf(e.origin) === -1) {
            return;
        }

        if (typeof e.data === "object" && e.data.type === "webpackOk") {
            return;
        }

        try {
            const message = JSONParse<ConnectorMessage>(e.data);
            if (!message) {
                return;
            }

            switch (message.type) {
                case MessageType.HANDSHAKE: {
                    const data = message.data;

                    if (!data.paired || (data.paired && this.connectorId === data.connectorId)) {
                        this.handleHandshake(message);
                    }
                    break;
                }

                case MessageType.OPENED:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:opened");
                    }
                    break;

                case MessageType.BEFORE_CLOSING:
                    if (message.data.connectorId === this.connectorId) {
                        // Prevent closing for false only. Other falsy value should close the widget
                        const shouldClose = this.execute.data.params?.beforeClosing?.() !== false;

                        this.sendMessage({
                            type: MessageType.BEFORE_CLOSING_RESPONSE,
                            data: { connectorId: this.connectorId, shouldClose } as MessageBeforeClosingResponse,
                        });
                    }
                    break;

                case MessageType.CLOSED:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:closed", {
                            failedSubscriptionId: message.data.failedSubscriptionId,
                        });
                    }
                    break;

                case MessageType.SUCCESS:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:success", message.data);
                    }
                    break;

                case MessageType.ERROR:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:error", message.data);
                    }
                    break;

                case MessageType.TOKEN_CREATED:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:token-created", message.data);
                    }
                    break;

                case MessageType.CHARGE_CREATED:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:charge-created", message.data);
                    }
                    break;

                case MessageType.SUBSCRIPTION_CREATED:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:subscription-created", message.data);
                    }
                    break;

                case MessageType.THREE_DS_AUTHORIZATION:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:three-ds-authorization", message.data);
                    }
                    break;

                case MessageType.THREE_DS_AUTHORIZATION_SUCCESS:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:three-ds-authorization-success", message.data);

                        if (this.threeDsModal) {
                            cleanupThreeDsModal(
                                this.threeDsModal.iFrame,
                                this.threeDsModal.iFrameContainer,
                                this.threeDsModal.form
                            );
                        }
                    }
                    break;

                case MessageType.THREE_DS_AUTHORIZATION_FAILURE:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:sthree-ds-authorization-failure", message.data);

                        if (this.threeDsModal) {
                            cleanupThreeDsModal(
                                this.threeDsModal.iFrame,
                                this.threeDsModal.iFrameContainer,
                                this.threeDsModal.form
                            );
                        }
                    }
                    break;

                case MessageType.VALIDATION_ERROR:
                    if (message.data.connectorId === this.connectorId) {
                        this.emitter.emit("checkout:validation-error", message.data);
                    }
                    break;

                case MessageType.RESIZE:
                    if (message.data.connectorId === this.connectorId) {
                        this.iframe.style.height = message.data.height;
                        this.iframe.height = message.data.height;
                    }
                    break;

                case MessageType.THREE_DS_AUTHORIZATION_MODAL:
                    if (message.data.connectorId === this.connectorId) {
                        const { iFrame, iFrameContainer } = createThreeDsModal({
                            iFrameSize: !isMobile()
                                ? { width: "600px", height: "400px", top: "130px", left: "calc(50% - 300px)" }
                                : "100%",
                            parent: document.body,
                            isOnClientPage: true,
                        });

                        const form = showThreeDsModal(iFrame, {
                            url: message.data.issuerToken.issuerToken,
                            callMethod: message.data.issuerToken.callMethod,
                            search: message.data.issuerToken.payload,
                            parent: document.body,
                        });

                        setRedirectLocale(iFrame, message.data.locale);

                        this.threeDsModal = { iFrame, iFrameContainer, form };
                    }
                    break;

                case MessageType.SUBMITTED: {
                    if (message.data.connectorId === this.connectorId) {
                        const [resolve, reject] = this.submitCallbacks;

                        if (message.errors) {
                            reject?.(message.errors);
                        } else {
                            const hiddenTokenInput = renderInput(
                                getInputName(ResourceType.TRANSACTION_TOKEN),
                                message.data.token
                            );
                            this.iframe.parentElement.appendChild(hiddenTokenInput);

                            if (ResourceType.CHARGE in message.data) {
                                const hiddenChargeInput = renderInput(
                                    getInputName(ResourceType.CHARGE),
                                    message.data[ResourceType.CHARGE] as string
                                );
                                this.iframe.parentElement.appendChild(hiddenChargeInput);
                            }

                            if (ResourceType.SUBSCRIPTION in message.data) {
                                const hiddenSubscriptionInput = renderInput(
                                    getInputName(ResourceType.SUBSCRIPTION),
                                    message.data[ResourceType.SUBSCRIPTION] as string
                                );
                                this.iframe.parentElement.appendChild(hiddenSubscriptionInput);
                            }

                            resolve?.(message.data);
                        }

                        this.submitCallbacks = [];
                    }
                    break;
                }

                default:
                    return;
            }
        } catch (e) {
            this.connected = false;
            this.connectorId = null;
            this.remote = null;

            console.error(e);
            throw new ConnectorError();
        }
    };

    private sendMessage(message: Message<BaseMessageType>): void {
        this.remote.postMessage(
            JSONStringify({ ...message, data: { ...message.data, connectorId: this.connectorId } }),
            FULL_CHECKOUT_URL
        );
    }

    private executeCheckout = (message: Message<MessageExecute>): void => {
        this.sendMessage(message);
    };

    private handleHandshake(message: Message<MessageHandshake>): void {
        if (this.connected) {
            return;
        }

        if (message.data.paired) {
            development(() => console.info(`5- Paired (id: ${message.data.connectorId})`, message));
            const [resolve] = this.initCallbacks;
            this.connected = true;

            if (typeof resolve === "function") {
                resolve(message.data.connectorId);
            } else {
                this.finishConnect(message.data.connectorId);
            }
        } else if (this.remote) {
            development(() => console.info(`3- Receiving handshake (id: ${message.data.connectorId})`));
            this.sendMessage(message);
        }
    }
}
