export const VALIDATION_RULE_ARRAY = "VALIDATION_RULE_ARRAY";
export const VALIDATION_RULE_BETWEEN = "VALIDATION_RULE_BETWEEN";
export const VALIDATION_RULE_EMAIL = "VALIDATION_RULE_EMAIL";
export const VALIDATION_RULE_DIFFERENT = "VALIDATION_RULE_DIFFERENT";
export const VALIDATION_RULE_NUMERIC = "VALIDATION_RULE_NUMERIC";
export const VALIDATION_RULE_REQUIRED = "VALIDATION_RULE_REQUIRED";
export const VALIDATION_RULE_REQUIRED_IF = "VALIDATION_RULE_REQUIRED_IF";
export const VALIDATION_RULE_SAME = "VALIDATION_RULE_SAME";
export const VALIDATION_RULE_REGEX = "VALIDATION_RULE_REGEX";
export const VALIDATION_RULE_URL = "VALIDATION_RULE_URL";
export const VALIDATION_RULE_NOT_IN = "VALIDATION_RULE_NOT_IN";
export const VALIDATION_RULE_BOOLEAN = "VALIDATION_RULE_BOOLEAN";
export const VALIDATION_RULE_STRING = "VALIDATION_RULE_STRING";
export const VALIDATION_RULE_DATE = "VALIDATION_RULE_DATE";
export const VALIDATION_RULE_REQUIRED_WITH = "VALIDATION_RULE_REQUIRED_WITH";
export const VALIDATION_RULE_MIN_NUMERIC = "VALIDATION_RULE_MIN_NUMERIC";
export const VALIDATION_RULE_MIN_STRING = "VALIDATION_RULE_MIN_STRING";
export const VALIDATION_RULE_MAX_NUMERIC = "VALIDATION_RULE_MAX_NUMERIC";
export const VALIDATION_RULE_MAX_STRING = "VALIDATION_RULE_MAX_STRING";

export const VALIDATION_REQUIRED = "VALIDATION_REQUIRED";
export const VALIDATION_EMAIL = "VALIDATION_EMAIL";
export const VALIDATION_BANNED_EMAIL = "VALIDATION_BANNED_EMAIL";
export const VALIDATION_PHONE_NUMBER = "VALIDATION_PHONE_NUMBER";
export const VALIDATION_CARD_NUMBER = "VALIDATION_CARD_NUMBER";
export const VALIDATION_ZIP = "VALIDATION_ZIP";
export const VALIDATION_DATE = "VALIDATION_DATE";
export const VALIDATION_CARD_DATE_EXPIRED = "VALIDATION_CARD_DATE_EXPIRED";
export const VALIDATION_CVV_LENGTH = "VALIDATION_CVV_LENGTH";
export const VALIDATION_GENERIC_FIELD_REQUIRED = "VALIDATION_GENERIC_FIELD_REQUIRED";
export const VALIDATION_INVALID_CHARACTERS = "VALIDATION_INVALID_CHARACTERS";
export const VALIDATION_INVALID_LENGTH = "VALIDATION_INVALID_LENGTH";
export const VALIDATION_MISSING_SPACE = "VALIDATION_MISSING_SPACE";
