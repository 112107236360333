import { createModel } from "@rematch/core";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { replace as replaceUrl } from "connected-react-router";
import { CheckoutType, PaymentType, TransactionTokenPaidyBilling } from "univapay-node";

import { Dispatch, StateShape } from "../store";

import { CheckoutResponse } from "./checkout";

const SCRIPT_ID = "paidy-script";
const SCRIPT_URL = "https://apps.paidy.com/";

export type PaidyStateShape = {
    token: string;
    publicKey: string;
    widget: {
        readyToOpen: boolean;
        open: boolean;
        error?: Error;
    };
};

export type CallbackDataProps = {
    id: string;
    created_at: string;
    status: string;
};

const initialState: PaidyStateShape = {
    token: "",
    publicKey: "",
    widget: {
        readyToOpen: false,
        open: false,
        error: null,
    },
};

const getUserDataFromState = (state: StateShape) => {
    const { userData } = state;
    const { params: baseParams } = state.application.params;
    const { name, nameKana, birthday, email, phoneNumber, line1, line2, city, zip, state: region } = userData;

    const phoneLocalNumber = phoneNumber?.localNumber || baseParams.phoneNumber;

    return {
        name1: name,
        name2: nameKana,
        dob: birthday,

        email: email || baseParams.email,
        phoneNumber: phoneLocalNumber ? { countryCode: 81, localNumber: phoneLocalNumber } : undefined, // Paidy forces japan
        address: {
            shippingAddressLine1: line1 || baseParams.shippingAddressLine1,
            shippingAddressLine2: line2 || baseParams.shippingAddressLine2,
            shippingAddressCity: city || baseParams.shippingAddressCity,
            shippingAddressState: region || baseParams.shippingAddressState,
            shippingAddressZip: zip || baseParams.shippingAddressZip,
            shippingAddressCountryCode: "JP",
        },
    };
};

const model = {
    state: initialState,

    reducers: {
        setToken: (state: PaidyStateShape, { token }: { token: string }) => ({ ...state, token }),
        setApiKey: (state: PaidyStateShape, { publicKey }: { publicKey: string }) => ({ ...state, publicKey }),

        setScriptLoaded: (state: PaidyStateShape) => ({
            ...state,
            widget: { readyToOpen: true, open: false },
        }),

        setScriptLoadingError: (state: PaidyStateShape) => ({
            ...state,
            widget: { readyToOpen: false, open: false },
        }),

        setCheckoutOpen: (state: PaidyStateShape) => ({
            ...state,
            widget: { ...state.widget, open: true },
        }),

        setCheckoutOpeningError: (state: PaidyStateShape, error: Error) => ({
            ...state,
            widget: { ...state.widget, open: false, error },
        }),
    },

    effects: (dispatch: Dispatch) => ({
        loadCheckout: async () => {
            const { paidy: self } = dispatch;

            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = SCRIPT_URL;
            script.id = SCRIPT_ID;
            document.head.appendChild(script);

            script.onload = () => {
                self.setScriptLoaded();
                self.openCheckout();
            };

            script.addEventListener("error", () => {
                self.setScriptLoadingError();
            });
        },

        openCheckout: async (_, state: StateShape) => {
            const { paidy: self } = dispatch;

            const {
                paidy: { publicKey },
                configuration: {
                    data: { logoImage, name },
                },
            } = state;
            const userData = getUserDataFromState(state);

            const config = {
                "api_key": publicKey,
                "logo_url": logoImage,
                "closed": (data: CallbackDataProps) => self.closeCheckout(data),
                "token": { "wallet_id": "default", "type": "recurring" },
            };

            try {
                const paidyHandler = Paidy.configure(config);
                await paidyHandler.launch({ "store_name": name, "buyer": userData });

                self.setCheckoutOpen();
            } catch (error) {
                self.setCheckoutOpeningError(error);
            }
        },

        closeCheckout: async (payload: CallbackDataProps, state: StateShape) => {
            const { id: token } = payload;
            const { paidy: self } = dispatch;

            const {
                application: {
                    params: {
                        params: { checkout: checkoutType },
                    },
                },
                checkout: { paymentMethodKey },
            } = state;

            self.setToken({ token });

            if (checkoutType !== CheckoutType.TOKEN) {
                await dispatch(replaceUrl(getStepUrl(PaymentType.PAIDY, paymentMethodKey, StepName.PREVIEW)));
            } else {
                await self.createPaidyTransaction();
            }
        },

        createPaidyTransaction: (_, state: StateShape): Promise<CheckoutResponse> => {
            const { checkout } = dispatch;

            const userData = getUserDataFromState(state);

            return checkout.process({
                values: {
                    paymentType: PaymentType.PAIDY,
                    email: userData.email,
                    data: {
                        paidyToken: state.paidy.token,
                        ...userData,
                        phoneNumber: { ...userData.phoneNumber, countryCode: `${userData.phoneNumber?.countryCode}` },
                        shippingAddress: {
                            city: userData.address.shippingAddressCity,
                            zip: userData.address.shippingAddressZip,
                            line1: userData.address.shippingAddressLine1,
                            line2: userData.address.shippingAddressLine2,
                            state: userData.address.shippingAddressState,
                            country: userData.address.shippingAddressCountryCode,
                        } as TransactionTokenPaidyBilling,
                        failsOnValidationError: true,
                    },
                },
            });
        },
    }),
};

export const paidy = createModel()(model);
