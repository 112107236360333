import * as DURATION_LABELS from "../labels/duration";

export const LABELS: Record<string, string> = {
    [DURATION_LABELS.DURATION_SPACER]: " ",
    [DURATION_LABELS.DURATION_YEARS]: "{value}年",
    [DURATION_LABELS.DURATION_MONTHS]: "{value}ヶ月",
    [DURATION_LABELS.DURATION_WEEKS]: "{value}週間",
    [DURATION_LABELS.DURATION_DAYS]: "{value}日",
};

export default LABELS;
