import { createModel } from "@rematch/core";
import { ProductItem } from "univapay-node";

import { sdk } from "../../SDK";
import { Dispatch } from "../store";

export type ProductStateShape = {
    products?: PatchProductItem[];
};

const initialState: ProductStateShape = {
    products: null,
};

export type PatchProductItem = Omit<ProductItem, "platformId" | "active"> & {
    /**
     * When provided on a subscription, the subscription becomes an installment with limited number
     */
    subscriptionCycles?: number;

    /**
     * Fix start date (e.g. 1998/11/23).
     * Does not apply when there are no CVV authorization or when an initial amount is provided
     */
    subscriptionStartOn?: string;

    /**
     * Dynamic start interval (e.g. P7D).
     * Does not apply when there are no CVV authorization or when an initial amount is provided
     */
    subscriptionStartIn?: string;

    /**
     * Dynamic start month interval. 0 is the current month and 1 is the next month
     * Does not apply when there are no CVV authorization, when an initial amount is provided and when subscriptionStartIn is provided
     */
    subscriptionStartInMonths?: number;

    /**
     * Fixed day of the month the transaction should start on
     * Does not apply when subscriptionStartInMonths is not applied or not provided
     */
    subscriptionStartDayOfMonth?: number;
    subscriptionInitialAmount?: number;
    subscriptionPeriod?: string;
    subscriptionCyclicalPeriod?: string;
    subscriptionPreserveEndOfMonth?: boolean;

    subscriptionRetryInterval?: string;

    metadata: Record<string, string>;
};

const model = {
    state: initialState,

    reducers: {
        setItems: (state: ProductStateShape, { products }: { products: PatchProductItem[] }): ProductStateShape => ({
            ...state,
            products,
        }),

        updateItems: (state: ProductStateShape, { products }: { products: PatchProductItem[] }): ProductStateShape => ({
            ...state,
            products: (state.products || []).concat(products),
        }),
    },

    effects: (dispatch: Dispatch) => ({
        get: async (payload: { ids: string[] }): Promise<PatchProductItem[]> => {
            const { product: self, checkout, application } = dispatch;
            const { ids } = payload;

            try {
                const products: PatchProductItem[] = await (async () => {
                    const tmpProducts = [];
                    for (const id of ids) {
                        tmpProducts.push(await sdk.patchedProducts.get(id));
                    }

                    return tmpProducts;
                })();

                self.setItems({ products });

                return products;
            } catch (error) {
                application.setError({ error });
                checkout.setError({ error });
                checkout.resetProcessed();
            }
        },

        getByCodes: async (payload: { codes: string[] }): Promise<PatchProductItem[]> => {
            const { product: self, checkout, application } = dispatch;
            const { codes } = payload;

            try {
                const products: PatchProductItem[] = await (async () => {
                    const tmpProducts = [];
                    for (const code of codes) {
                        tmpProducts.push(await sdk.patchedProducts.getByCode(code));
                    }

                    return tmpProducts;
                })();

                self.updateItems({ products });

                return products;
            } catch (error) {
                application.setError({ error });
                checkout.setError({ error });
                checkout.resetProcessed();
            }
        },
    }),
};

export const product = createModel()(model);
