import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "戻る",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "閉じる",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "完了",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "次へ",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "戻る",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "送信",
    [COMMON_LABELS.COMMON_TOTAL]: "合計: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "{method}で支払う",

    [COMMON_LABELS.COMMON_OPTIONAL]: "任意",
    [COMMON_LABELS.COMMON_REQUIRED]: "必須",

    [COMMON_LABELS.PRIVACY_LINK]: "個人情報の取扱いについて",

    [COMMON_LABELS.COMMON_COMMA]: "、",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+取扱手数料",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "選択...",
};

export default LABELS;
