import { OnlineTestBrand, PatchedOnlineBrand, SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { OnlineBrand } from "univapay-node";

import { LOCALE_LABELS as CHECKOUT_COMMON_LABELS } from "../../../../common/locale/labels";

import * as BANK_TRANSFER_LABELS from "./bank-transfer";
import * as COMMON_LABELS from "./common";
import * as DURATION_LABELS from "./duration";
import * as ENUM_LABELS from "./enum";
import * as ERRORS_LABELS from "./errors";
import * as FORM_LABELS from "./form";
import * as ONLINE_LABELS from "./online";
import * as PAIDY_LABELS from "./paidy";
import * as PAYMENT_LABELS from "./payment";
import * as SUBSCRIPTIONS_LABELS from "./subscriptions";
import * as VALIDATION_LABELS from "./validation";

export type BankTransferLabels = typeof BANK_TRANSFER_LABELS;
export type CommonLabels = typeof COMMON_LABELS;
export type DurationLabels = typeof DURATION_LABELS;
export type EnumLabels = typeof ENUM_LABELS;
export type ErrorsLabels = typeof ERRORS_LABELS;
export type FormLabels = typeof FORM_LABELS;
export type OnlineLabels = typeof ONLINE_LABELS;
export type PaidyLabels = typeof PAIDY_LABELS;
export type PaymentLabels = typeof PAYMENT_LABELS;
export type SubscriptionsLabels = typeof SUBSCRIPTIONS_LABELS;
export type ValidationLabels = typeof VALIDATION_LABELS;

export type LocaleLabels = typeof CHECKOUT_COMMON_LABELS &
    BankTransferLabels &
    CommonLabels &
    DurationLabels &
    EnumLabels &
    ErrorsLabels &
    FormLabels &
    OnlineLabels &
    PaidyLabels &
    PaymentLabels &
    SubscriptionsLabels &
    ValidationLabels;

export const LOCALE_LABELS: LocaleLabels = {
    ...CHECKOUT_COMMON_LABELS,
    ...BANK_TRANSFER_LABELS,
    ...COMMON_LABELS,
    ...DURATION_LABELS,
    ...ENUM_LABELS,
    ...ERRORS_LABELS,
    ...FORM_LABELS,
    ...ONLINE_LABELS,
    ...PAIDY_LABELS,
    ...PAYMENT_LABELS,
    ...SUBSCRIPTIONS_LABELS,
    ...VALIDATION_LABELS,
};

const handleUnknownLabel = (resource: string) => {
    console.warn(`No label found for resource ${resource}`);

    return LOCALE_LABELS.GLOBAL_UNKNOWN;
};

export const getOnlineBrandLabel = (gateway: SupportedOnlineBrand) => {
    switch (gateway) {
        case OnlineBrand.ALIPAY_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_ALIPAY;

        case OnlineBrand.ALIPAY_PLUS_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS;

        case PatchedOnlineBrand.D_BARAI_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_D_BARAI;

        case OnlineBrand.PAY_PAY_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY;

        case OnlineBrand.WE_CHAT_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT;

        case OnlineTestBrand.TEST_ALIPAY_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE;

        case OnlineTestBrand.TEST_ALIPAY_PLUS_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE;

        case OnlineTestBrand.TEST_D_BARAI_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE;

        case OnlineTestBrand.TEST_PAY_PAY_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE;

        case OnlineTestBrand.TEST_WE_CHAT_ONLINE:
            return LOCALE_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE;

        default:
            return handleUnknownLabel(gateway);
    }
};
