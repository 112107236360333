import * as FORM_LABELS from "../labels/online";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.ONLINE_PROCESSING_INFO]: "{brand}のトークン発行処理にリダイレクトされています。しばらくお待ち下さい。",
    [FORM_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE]: "お客様情報",

    [FORM_LABELS.ONLINE_INSTANT_DISCOUNT]: "特別割引",
};

export default LABELS;
