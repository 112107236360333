import { SupportedLocale } from "common/locale/labels";

export const createIFrame = ({
    iFrameSize = "100%",
    parent = document.body,
    isOnClientPage = false,
}: {
    iFrameSize?: string | { width?: string; height?: string; top?: string; left?: string };
    parent?: HTMLElement;
    isOnClientPage?: boolean;
}) => {
    const defaultSizes = { top: "0px", left: "0px", width: "100%", height: "100%" };
    const size = !iFrameSize
        ? defaultSizes
        : typeof iFrameSize === "string"
        ? { ...defaultSizes, width: iFrameSize, height: iFrameSize }
        : { ...defaultSizes, ...iFrameSize };

    const { height: parentHeight, width: parentWidth } = parent.getBoundingClientRect();
    const isIFrameBiggerThanScreen =
        (size.width.includes("px") && parentWidth < Number(size.width.replace("px", ""))) ||
        (size.height.includes("px") && parentHeight < Number(size.height.replace("px", "")));

    // Add iFrame to load redirect
    const iFrame = document.createElement("iframe");
    iFrame.name = "three-ds-i-frame";
    iFrame.style.width = isIFrameBiggerThanScreen ? "100%" : size.width;
    iFrame.style.height = isIFrameBiggerThanScreen ? "100vh" : size.height;
    iFrame.style.top = isIFrameBiggerThanScreen ? "0%" : size.top;
    iFrame.style.left = isIFrameBiggerThanScreen ? "0%" : size.left;
    iFrame.style.position = "absolute";
    iFrame.style.background = "white";
    iFrame.style.zIndex = "9999";

    let iFrameContainer;
    if (isOnClientPage) {
        iFrameContainer = document.createElement("div");
        iFrameContainer.style.position = "fixed";
        iFrameContainer.style.top = "0px";
        iFrameContainer.style.left = "0px";
        iFrameContainer.style.width = "100%";
        iFrameContainer.style.height = "100%";
        iFrameContainer.style.background = "rgba(0, 0, 0, 0.5)";
        parent.appendChild(iFrameContainer);
        iFrameContainer.appendChild(iFrame);
    } else {
        parent.appendChild(iFrame);
    }

    return { iFrame, iFrameContainer };
};

export const redirect = (
    iFrame: HTMLIFrameElement,
    {
        search,
        url,
        callMethod = "http_get",
        parent = document.body,
    }: {
        search: Record<string, string>;
        url?: string;
        callMethod?: "http_get" | "http_post";
        parent?: HTMLElement;
    }
) => {
    // Compute url with search params
    const urlParameters = Object.keys(search)
        .filter((key) => !!key && !!search[key])
        .map((key) => `${key}=${search[key]}`)
        .join("&");

    const iFrameUrl = `${url || `${window.location.origin}/redirect/index.html`}${
        urlParameters && callMethod === "http_get" ? `?${urlParameters}` : ""
    }`;

    // Redirect to the iFrame
    let form;
    if (callMethod === "http_get") {
        iFrame.setAttribute("src", iFrameUrl);
    } else if (callMethod === "http_post") {
        form = document.createElement("form");
        form.target = "three-ds-i-frame";
        form.action = iFrameUrl;
        form.method = "post";

        Object.keys(search).forEach((key) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = search[key];
            form.appendChild(input);
        });

        parent.appendChild(form);
        form.submit();
    }

    return form;
};

export const setRedirectLocale = (iFrame: HTMLIFrameElement, locale: SupportedLocale) =>
    window.addEventListener("message", (event) => {
        if (event.data.type === "REDIRECT_OPENED") {
            iFrame.contentWindow.postMessage({ type: "LOCALE_UPDATE", locale }, "*");
        }
    });

export const cleanup = (iFrame: HTMLElement, iFrameContainer: HTMLElement, form: HTMLElement) => {
    // Close iFrame and cleanup
    if (form) {
        form.remove();
    }

    iFrame.remove();

    if (iFrameContainer) {
        iFrameContainer.remove();
    }
};

export const redirectInIFrame = async <T>({
    search,
    promise,
    url,
    callMethod = "http_get",
    iFrameSize = "100%",
    parent = document.body,
    isOnClientPage = false,
    locale,
}: {
    search: Record<string, string>;
    promise: Promise<T>;
    locale: SupportedLocale;
    url?: string;
    callMethod?: "http_get" | "http_post";
    iFrameSize?: string | { width?: string; height?: string; top?: string; left?: string };
    parent?: HTMLElement;
    isOnClientPage?: boolean;
}): Promise<T> => {
    const { iFrame, iFrameContainer } = createIFrame({ iFrameSize, parent, isOnClientPage });

    const form = redirect(iFrame, { search, url, callMethod, parent });

    setRedirectLocale(iFrame, locale);

    // Wait for promise for redirect page return
    const response = await promise;

    cleanup(iFrame, iFrameContainer, form);

    return response;
};
