import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "支払い方法の選択",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "支払い方法",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "お支払い方法：{method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "請求先住所",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "確認コードの入力",

    [FORM_LABELS.FORM_FIELDS_NAME]: "山田 太郎",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "example@email.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "電話番号",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "メールアドレス",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "セキュリティコード",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "有効期限（月/年）",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "有効期限",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "カード名義",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "カード番号",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "カード",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "コンビニ決済",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "Paidy",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "Online",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "銀行振込",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_D_BARAI]: "d払い",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "PayPay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "PayPay（テストのみ）",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE]: "d払い（テストのみ）",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay（テストのみ）",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+（テストのみ）",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat（テストのみ）",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "市区町村",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "国",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "番地",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "マンション名・部屋番号",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "名前",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "名前（カナ）",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "都道府県",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "郵便番号",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "4444 3333 2222 1111",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "TARO YAMADA",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "123",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "署名欄上部等に記載の数字3（または4）桁",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "月",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "年",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "鈴木 太郎",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "コンビニエンスストア",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "コンビニを選ぶ",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "個人情報取得への同意",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "カードを選ぶ",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "新しいカード",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "カード情報を削除する",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "コンビニを選ぶ",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "別なコンビニ",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "コンビニ情報を削除する",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "Select previously used data set:",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "New",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "Delete data set",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "メールまたはSMSをご確認のうえ、確認コードを入力してください。",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "支払い回数",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "一括",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20回",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24回",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "リボ払い",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "{brand}では分割払いはご利用いただけません",
};

export default LABELS;
