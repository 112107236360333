import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "付款處理失敗。",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "付款處理過程中，請勿關閉該窗口。",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "付款成功。",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "卡信息註冊成功。",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "便利店支付方式注册成功，请在附近的便利店付款。",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "定期付款成功。",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "支付{money}",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "付款",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "付款",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "卡信息",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "包括本地卡",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "便利店支付信息",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "便利店或Pay-easy",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "Processing payment timed out. Please contact support.",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "Successfully updated subscription information.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "Updating subscription information... Please do not close this checkout.",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "由於系統連接錯誤，無法確認交易。給您帶來不便，我們深表歉意，請聯繫支付客服中心已以確認您的交易是否成功",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "Univa Paycast 客服中心",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "日本國內通話: 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "國際通話, IP 電話 和 PHS 電話: 06-6538-2060",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "電子郵件: {email}",

    // Review
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} 以 {lastFour}結尾",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "有效期限 {month}/{year}",
};

export default LABELS;
