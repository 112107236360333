import * as FORM_LABELS from "../labels/online";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.ONLINE_PROCESSING_INFO]: "กระบวนการออกโทเค็น {brand} ถูกเปลี่ยนเส้นทางโปรดรอสักครู่",
    [FORM_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE]: "ข้อมูลลูกค้า",

    [FORM_LABELS.ONLINE_INSTANT_DISCOUNT]: "ลดราคาพิเศษ",
};

export default LABELS;
