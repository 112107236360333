import { ResponseError, ResponseErrorCode } from "univapay-node";

const COOLDOWN_ADJUST = 1.2;
const COOLDOWN_WAIT = 500; // Cooldown wait period after first fail (0.5s)
const FAIL_TRESHOLD = 10; // Fail processing after X concurrent fails. If 0, then will try forever.

export function rateLimit<R>(exec: (...args: any[]) => Promise<R>): Promise<R> {
    let cooldown = 0;
    let tries = 0;

    return new Promise((resolve, reject) => {
        async function executor() {
            try {
                tries++;
                const result = await exec();
                cooldown = 0;
                tries = 0;
                resolve(result);
            } catch (error) {
                if (
                    error instanceof ResponseError &&
                    (error.errorResponse.code === ResponseErrorCode.TooManyRequests ||
                        error.errorResponse.httpCode === 429)
                ) {
                    if (FAIL_TRESHOLD > 0 && tries > FAIL_TRESHOLD) {
                        reject(error);
                    } else {
                        cooldown = cooldown === 0 ? COOLDOWN_WAIT : cooldown * COOLDOWN_ADJUST;
                        setTimeout(executor, cooldown);
                    }
                } else {
                    reject(error);
                }
            }
        }

        return executor();
    });
}
