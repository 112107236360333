import * as SUBSCRIPTIONS_LABELS from "../labels/subscriptions";

export const LABELS: Record<string, string> = {
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_BUTTONS_PAY]: "{period} {money}의 지불",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD]: "각 {period}에 대한{money} 지불",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INITIAL]: "첫 번째 {money}의 지불,",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY]: "{period} {money}의 지불, 그런 다음",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD]: "그런 다음 각 {period}에 대해 {money}를 지불합니다.",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_INSTALLMENT]: "나중에 {money}의 지불",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_START]: "다음 청구 날짜 : {date}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_CVV_AUTH]: "카드 등록은 처음입니다",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_DAILY]: "매일",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_WEEKLY]: "매주",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIWEEKLY]: "큰 주간",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_MONTHLY]: "월간 간행물",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIMONTHLY]: "2 개월마다",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_QUARTERLY]: "4분의 1",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_SEMIANNUALLY]: "하프 기간",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_ANNUALLY]: "매년",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY]: "정기 지불 (Sun)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY]: "정기 지불 (주)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY]: "정기적 인 지불 (매주)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY]: "정기 지불 (월)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY]: "정기 지불 (분기)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY]: "정기 지불 (반기)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY]: "정기 지불 (연도)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT]: "초기 금액 : {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE]: "지불 계획",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT]: "첫 번째 {money}의 지불,",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES]: "{cycles} 시간에 총 {money}를 지불하십시오.",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING]: "{money} (회전)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL]: "{period} {money}의 지불",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL]: "{period} {money}의 지불, 그런 다음",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL]: "{cycles} 시간에 총 {money}를 지불하십시오.",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES]: "그런 다음 {cycles} 시간에 {money}를 지불하십시오.",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL]: "그런 다음 {cycles} 시간에 {money}를 지불했습니다.",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS]: "자동 청구 시간 : {cycles} times",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_CYCLES]: "고장",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_START]: "첫 번째 지불",
};

export default LABELS;
