import SDK, { RestAPIOptions, TemporaryTokenAlias } from "univapay-node";

import { Charges } from "./resources/Charges";
import { CheckoutInfoBrand } from "./resources/CheckoutInfoBrand";
import { Products } from "./resources/Products";
import { Subscriptions } from "./resources/Subscription";
import { TransactionTokens } from "./resources/TransactionTokens";

export class CustomSDK extends SDK {
    tokenAliases: TemporaryTokenAlias;
    checkoutInfoBrand: CheckoutInfoBrand;
    patchedProducts: Products;
    patchedCharges: Charges;
    patchedSubscriptions: Subscriptions;
    patchedTransactionTokens: TransactionTokens;

    constructor(options?: RestAPIOptions) {
        super(options);

        this.tokenAliases = new TemporaryTokenAlias(this.api);
        this.checkoutInfoBrand = new CheckoutInfoBrand(this.api);
        this.patchedProducts = new Products(this.api);
        this.patchedCharges = new Charges(this.api);
        this.patchedTransactionTokens = new TransactionTokens(this.api);
        this.patchedSubscriptions = new Subscriptions(this.api);
    }
}

export const sdk: CustomSDK = new CustomSDK({ endpoint: API_BASE });
