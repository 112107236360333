import * as FORM_LABELS from "../labels/online";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.ONLINE_PROCESSING_INFO]: "Процесс выпуска токена {brаnd} перенаправлен. Пожалуйста, немного подождите",
    [FORM_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE]: "Информация для клиентов",

    [FORM_LABELS.ONLINE_INSTANT_DISCOUNT]: "Специальная скидка",
};

export default LABELS;
