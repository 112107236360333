import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "การประมวลผลการชำระเงินล้มเหลว",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "การประมวลผลการชำระเงินอย่าปิดวิดเจ็ตนี้",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "การประมวลผลการชำระเงินที่ประสบความสำเร็จ",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "ฉันประสบความสำเร็จในการลงทะเบียนข้อมูลการชำระเงิน",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "ฉันประสบความสำเร็จในการลงทะเบียนการชำระเงินร้านสะดวกซื้อ กรุณาเยี่ยมชมร้านค้าที่อยู่ใกล้คุณและชำระเงิน",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "การชำระเงินที่ประสบความสำเร็จนั้นประสบความสำเร็จ",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "การชำระเงินของ {money}",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "จ่าย",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "จ่าย",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "ข้อมูลบัตร",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "เข้ากันได้กับการ์ดท้องถิ่น",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "ข้อมูลการชำระเงินของร้านสะดวกซื้อ",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "การชำระเงินร้านสะดวกซื้อหรือการจ่ายเงิน",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "การประมวลผลการชำระเงินหมดเวลากรุณาติดต่อฝ่ายสนับสนุน",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "การชำระเงินปกติประสบความสำเร็จ",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "การชำระเงินปกติกำลังได้รับการอัปเดต อย่าปิดวิดเจ็ตนี้",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "มันกลายเป็นข้อผิดพลาดในการสื่อสาร กรุณาติดต่อฝ่ายสนับสนุนการสนับสนุนต่อไปนี้เพื่อขอการยืนยันการชำระเงิน",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "สอบถามเกี่ยวกับการประมวลผลการชำระเงิน",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "Univa Peacast Support Center Co. , Ltd. ",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "โทรศัพท์: 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "* โปรดใช้ 06-6538-2060 สำหรับโทรศัพท์ระหว่างประเทศโทรศัพท์ IP โทรศัพท์และลูกค้า PHS",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "อีเมล: {อีเมล}",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} หมายเลขสุดท้าย {lastFour}",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "วันหมดอายุ {month}/{year}",
};

export default LABELS;
