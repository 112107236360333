import { noop } from "lodash";

/**
 * TODO: A service from which we can log the errors needs to be implemented
 */
const sendError = async (error: Error, errorInfo: string) => noop(error, errorInfo);

export const raiseTsError = async (error: Error, errorInfo: string) => {
    console.error(error, errorInfo);
    await sendError(error, errorInfo);
};

export const raiseAppError = async (error: Error, errorInfo: string) => {
    await sendError(error, errorInfo);
};

export const raiseAPIClientError = async (error, errorInfo?: string) => {
    if (error?.code >= 400 && error?.code < 500) {
        await sendError(error, errorInfo);
    }
};

export const raisePCIError = async (scripts: Record<string, unknown>, errorInfo?: string) => {
    console.warn(scripts, errorInfo);
    await sendError(new Error("Unknown external script hosts detected"), Object.keys(scripts).join(", "));
};
