import { createModel } from "@rematch/core";
import { raiseAPIClientError } from "checkout/ts/utils/monitoring";
import { BankTransferBrand, ErrorResponse, PaymentType, ResponseError } from "univapay-node";

import { sdk } from "../../SDK";
import { Dispatch, StateShape } from "../store";

import { CheckoutResponse } from "./checkout";

type BankTransferIssuerToken = {
    accountHolderName: string;
    accountId: string;
    accountNumber: string;
    branchCode: string;
    branchName: string;
    paymentType: PaymentType;
};

export type BankTransferStateShape = {
    issuerToken?: BankTransferIssuerToken;
    error?: ResponseError | ErrorResponse | Error;
};

type IssuerTokenData = { storeId: string; chargeId: string; issuerToken: BankTransferIssuerToken };

type CreatePayload = { name: string; brand: BankTransferBrand };

const initialState: BankTransferStateShape = {
    issuerToken: null,
    error: null,
};

const model = {
    state: initialState,

    reducers: {
        setIssuerTokenData: (state: BankTransferStateShape, { storeId, chargeId, issuerToken }: IssuerTokenData) => ({
            ...state,
            storeId,
            chargeId,
            issuerToken,
        }),
        setIssuerTokenError: (state: BankTransferStateShape, { error }: { error: Error }) => ({ ...state, error }),
    },

    effects: (dispatch: Dispatch) => ({
        create: async (payload: CreatePayload): Promise<CheckoutResponse> => {
            const { bankTransfer: self, checkout } = dispatch;
            const { name, brand } = payload;

            const response = await checkout.process({
                values: { paymentType: PaymentType.BANK_TRANSFER, data: { name, brand } },
            });
            await self.getIssuerToken();

            return response;
        },

        getIssuerToken: async (_, { checkout: { charge } }: StateShape): Promise<BankTransferIssuerToken> => {
            const { bankTransfer: self } = dispatch;

            if (!charge) {
                return null;
            }

            try {
                const { storeId, id: chargeId } = charge;

                // TODO: Fix the issuer token type in SDK for bank transfer
                const issuerToken = ((await sdk.charges.getIssuerToken(
                    storeId,
                    chargeId
                )) as unknown) as BankTransferIssuerToken;

                self.setIssuerTokenData({ storeId, chargeId, issuerToken });

                return issuerToken;
            } catch (error) {
                raiseAPIClientError(error, "Issuer token request failure");
                console.error("Could not fetch issuer token for bank transfer charge", charge);
            }
        },
    }),
};

export const bankTransfer = createModel()(model);
