import { add, addHours, addMinutes, set, subHours, subMinutes } from "date-fns";
import { parse as parsePeriod } from "iso8601-duration";

export type Period = string; // `P${number}${PeriodDuration}`

export const getNextPeriodTick = (period: Period, date?: Date | string | number): Date =>
    add(date ? new Date(date) : new Date(), parsePeriod(period));

export const offsetWithTimeShift = (date: Date, timeShift?: string) => {
    if (!timeShift) {
        return date;
    }
    const [time = "", timeZoneStringValue = "00:00"] = timeShift.split(/[Z+-]/);
    const hasNegativeOffset = timeShift.includes("-");
    const [hours = 0, minutes = 0, seconds = 0] = time.split(":").map(Number);
    const shiftedTime = set(date, { hours, minutes, seconds, milliseconds: 0 });

    const currentTimeZoneOffset = date.getTimezoneOffset();
    const [timeZoneHours = 0, timeZoneMinute = 0] = timeZoneStringValue.split(":").map(Number);

    return hasNegativeOffset
        ? addMinutes(addHours(shiftedTime, timeZoneHours), timeZoneMinute + currentTimeZoneOffset)
        : subMinutes(subHours(shiftedTime, timeZoneHours), timeZoneMinute + currentTimeZoneOffset);
};
