import * as SUBSCRIPTIONS_LABELS from "../labels/subscriptions";

export const LABELS: Record<string, string> = {
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_BUTTONS_PAY]: "每隔{period}支付{money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD]: "每隔{period}支付{money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INITIAL]: "先支付{money},然后",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY]: "每隔{period}支付{money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_INSTALLMENT]: "每隔{period}支付{money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_START]: "从{date}开始",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_CVV_AUTH]: "Register your card today",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_DAILY]: "每天",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_WEEKLY]: "每周",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIWEEKLY]: "隔周",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_MONTHLY]: "毎月",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIMONTHLY]: "2个月",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_QUARTERLY]: "3个月",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_SEMIANNUALLY]: "6个月",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_ANNUALLY]: "毎年",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY]: "定期付款（日）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY]: "定期付款（周）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY]: "定期付款（隔周）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY]: "定期付款（月）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY]: "定期付款（3个月）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY]: "定期付款（6个月）",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY]: "定期付款（年）",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT]: "首次付款金额：{money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE]: "付款计划",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT]: "{money} now",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES]: "总金額{money}分{cycles}次付款",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING]: "{money} (循环付款)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL]: "Pay {money} per {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL]: "then {money} per {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL]: "then pay {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES]: "then {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL]: "Pay {money} in {cycles} payments",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS]: "{cycles} payments",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_CYCLES]: "下次付款",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_START]: "最初付款",
};

export default LABELS;
