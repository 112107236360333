import * as DURATION_LABELS from "../labels/duration";

export const LABELS: Record<string, string> = {
    [DURATION_LABELS.DURATION_SPACER]: " ",
    [DURATION_LABELS.DURATION_YEARS]: "{value, plural, one {# year} other {# years}}",
    [DURATION_LABELS.DURATION_MONTHS]: "{value, plural, one {# month} other {# months}}",
    [DURATION_LABELS.DURATION_WEEKS]: "{value, plural, one {# week} other {# weeks}}",
    [DURATION_LABELS.DURATION_DAYS]: "{value, plural, one {# day} other {# days}}",
};

export default LABELS;
