import React from "react";
import { StateShape } from "checkout/ts/redux/store";
import { PatchedOnlineBrand, SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { OnlineBrand, PaymentType } from "univapay-node";

import { AlipayOnlineIcon } from "../../icons/AlipayOnlineIcon";
import { DBaraiOnlineIcon } from "../../icons/DBaraiOnlineIcon";
import { PayPayOnlineIcon } from "../../icons/PayPayOnlineIcon";
import { WeChatOnlineIcon } from "../../icons/WeChatOnlineIcon";
import { getRequiredFields } from "../common/utils/customerInfo";

export const getOnlineRoutes = (stepName: StepName, paymentMethodKey: string) =>
    ({
        [StepName.GATEWAY]: getStepUrl(PaymentType.ONLINE, paymentMethodKey, StepName.GATEWAY),
        [StepName.PROCESSING]: getStepUrl(PaymentType.ONLINE, paymentMethodKey, StepName.PROCESSING),
        [StepName.ADDRESS]: getStepUrl(PaymentType.ONLINE, paymentMethodKey, StepName.ADDRESS),
        [StepName.DATA]: getStepUrl(PaymentType.ONLINE, paymentMethodKey, StepName.DATA),
        [StepName.CONFIRM]: getStepUrl(PaymentType.ONLINE, paymentMethodKey, StepName.CONFIRM),
    }[stepName]);

export const getOnlineBrandIcon = (paymentType: SupportedOnlineBrand) => {
    switch (paymentType) {
        case OnlineBrand.ALIPAY_ONLINE:
            return <AlipayOnlineIcon />;

        case PatchedOnlineBrand.D_BARAI_ONLINE:
            return <DBaraiOnlineIcon />;

        case OnlineBrand.PAY_PAY_ONLINE:
            return <PayPayOnlineIcon />;

        case OnlineBrand.WE_CHAT_ONLINE:
            return <WeChatOnlineIcon />;

        case OnlineBrand.ALIPAY_PLUS_ONLINE:
            return null;
    }
};

export const hasUserData = (state: StateShape) => {
    const { requireEmail, requireBillingAddress, requirePhoneNumber, requireName } = getRequiredFields(
        PaymentType.ONLINE,
        state.application.params.params
    );

    return requireEmail || requireBillingAddress || requirePhoneNumber || requireName;
};
