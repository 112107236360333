import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "已重定向到Paidy Token發行處理。請稍候。",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "Paidy付款已被批准。請點擊下面的按鈕完成付款。",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "Paidy付款",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "出生年月日(例， 1970-01-30)",
};

export default LABELS;
