import CHECKOUT_COMMON_LABELS from "../../../../common/locale/en_US";

import BANK_TRANSFER_LABELS from "./bank-transfer.locale";
import COMMON_LABELS from "./common.locale";
import DURATION_LABELS from "./duration.locale";
import ENUM_LABELS from "./enum.locale";
import ERRORS_LABELS from "./errors.locale";
import FORM_LABELS from "./form.locale";
import ONLINE_LABELS from "./online.locale";
import PAIDY_LABELS from "./paidy.locale";
import PAYMENT_LABELS from "./payment.locale";
import SUBSCRIPTIONS_LABELS from "./subscriptions.locale";
import VALIDATION_LABELS from "./validation.locale";

export const LABELS: Record<string, string> = {
    ...CHECKOUT_COMMON_LABELS,
    ...BANK_TRANSFER_LABELS,
    ...COMMON_LABELS,
    ...DURATION_LABELS,
    ...ENUM_LABELS,
    ...ERRORS_LABELS,
    ...FORM_LABELS,
    ...ONLINE_LABELS,
    ...PAIDY_LABELS,
    ...PAYMENT_LABELS,
    ...SUBSCRIPTIONS_LABELS,
    ...VALIDATION_LABELS,
};

export default LABELS;
