export const CONVENIENCE_STORE_SEVEN_ELEVEN = "CONVENIENCE_STORE_SEVEN_ELEVEN";
export const CONVENIENCE_STORE_FAMILY_MART = "CONVENIENCE_STORE_FAMILY_MART";
export const CONVENIENCE_STORE_LAWSON = "CONVENIENCE_STORE_LAWSON";
export const CONVENIENCE_STORE_MINI_STOP = "CONVENIENCE_STORE_MINI_STOP";
export const CONVENIENCE_STORE_SEICO_MART = "CONVENIENCE_STORE_SEICO_MART";
export const CONVENIENCE_STORE_DAILY_YAMAZAKI = "CONVENIENCE_STORE_DAILY_YAMAZAKI";
export const CONVENIENCE_STORE_PAY_EASY = "CONVENIENCE_STORE_PAY_EASY";
export const CONVENIENCE_STORE_CIRCLE_K = "CONVENIENCE_STORE_CIRCLE_K";
export const CONVENIENCE_STORE_SUNKUS = "CONVENIENCE_STORE_SUNKUS";
export const CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE = "CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE";

export const CARD_VISA = "CARD_VISA";
export const CARD_AMEX = "CARD_AMEX";
export const CARD_MASTERCARD = "CARD_MASTERCARD";
export const CARD_MAESTRO = "CARD_MAESTRO";
export const CARD_DISCOVER = "CARD_DISCOVER";
export const CARD_JCB = "CARD_JCB";
export const CARD_DINERS = "CARD_DINERS";
export const CARD_UNIONPAY = "CARD_UNIONPAY";
