import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "Back",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "Close",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "Finish",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "Next",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "Previous",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "Submit",
    [COMMON_LABELS.COMMON_TOTAL]: "Total: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "Pay with {method}",

    [COMMON_LABELS.COMMON_OPTIONAL]: "Optional",
    [COMMON_LABELS.COMMON_REQUIRED]: "Required",

    [COMMON_LABELS.PRIVACY_LINK]: "Privacy policy",

    [COMMON_LABELS.COMMON_COMMA]: ", ",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+ processing fees",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "Select...",
};

export default LABELS;
