import * as FORM_LABELS from "../labels/online";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.ONLINE_PROCESSING_INFO]: "You are being redirected to {brand}. Please wait...",
    [FORM_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE]: "顧客信息",

    [FORM_LABELS.ONLINE_INSTANT_DISCOUNT]: "即時折扣",
};

export default LABELS;
