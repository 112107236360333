import * as ERRORS_LABELS from "../labels/errors";

export const LABELS = {
    [ERRORS_LABELS.ERRORS_TITLE]: "チェックアウトの設定エラー",
    [ERRORS_LABELS.ERRORS_CHECKOUT]: "チェックアウトサービスに接続できません。",
    [ERRORS_LABELS.ERRORS_NO_SCRIPT_ID]: "チェックアウトスクリプトが正しく組み込まれていません。",
    [ERRORS_LABELS.ERRORS_NO_APP_ID]: "アプリケーショントークンが必要です。",
    [ERRORS_LABELS.ERRORS_INVALID_APP_ID]: "アプリケーショントークンが無効です。",
    [ERRORS_LABELS.ERRORS_NO_CHECKOUT]: "’token'または'payment'のチェックアウトタイプが必要です。",
    [ERRORS_LABELS.ERRORS_NOT_PAYMENT_CHECKOUT]: "'payment'のチェックアウトタイプが必要です。",
    [ERRORS_LABELS.ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE]: "'one_time'のトークンタイプが必要です。",
    [ERRORS_LABELS.ERRORS_INVALID_TOKEN_TYPE]: "トークンタイプには、'one_time'、'subscription'、'recurring'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_RETRY_INTERVAL]: "リトライ間隔が無効です。",
    [ERRORS_LABELS.ERRORS_INVALID_AMOUNT]: "金額は、正の整数で指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_DATE]: "無効な日付フォーマットです。",
    [ERRORS_LABELS.ERRORS_INVALID_CURRENCY]: "お取り扱いできない通貨です。",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PERIOD]: "定期課金の期間には、ISO8601のペリオッド、'daily'、'weekly'、'biweekly'、'monthly'、'bimonthly'、'quarterly'、'semiannually'、'annually'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_LOCALE]: "ロケールは、'auto'またはサポートされているコードのいずれかです。",
    [ERRORS_LABELS.ERRORS_INVALID_USAGE_LIMIT]: "使用制限には、'daily'、'weekly'、'monthly'、'yearly'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_AMOUNT]: "商品を設定する場合、金額は省略する必要があります。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_CURRENCY]: "商品を設定する場合、通貨は省略する必要があります。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_SUBSCRIPTION_ID]: "定期課金IDを指定する場合には、トークンタイプは、'subscription'を指定して下さい。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN]: "分割払いプランを指定する場合には、トークンタイプは、'subscription'を指定して下さい。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN_QTY]: "分割払いパラメータを指定する場合には、トークンタイプは、'subscription'を指定して下さい。",
    [ERRORS_LABELS.ERRORS_NO_SUBSCRIPTION_PERIOD]: "定期課金の期間を指定して下さい。",
    [ERRORS_LABELS.ERRORS_TITLE_TOO_LONG]: "｀Titleは、最大64文字です。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_PLAN]: "分割払いプランには、'revolving'、'fixed_cycles'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PLAN]: "分割払いプランには、'fixed_cycles'、'fixed_cycle_amount'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_AMOUNT]: "金額は、正の整数で指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_INITIAL_AMOUNT]: "金額は、正の整数で指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_CUSTOMER_ID]: "カスタマーIDは、UUID形式で指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_TIMEZONE]: "無効なタイムゾーンです。",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPE]: "サポートされていない支払い方法です。",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPES]: "サポートされていない支払い方法です。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENTS]: "サポートされていない分割払いサイクルです。1、3、5、6、10、12、15、18、20、24かrevolvingを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_PRODUCTS]: "商品は”,”で区切られたUUID形式のリストで指定してください。",
    [ERRORS_LABELS.ERRORS_INVALID_AUTH_CODE]: "認証コードが正しくありません。",
    [ERRORS_LABELS.ERRORS_INVALID_COLOR_CODE]: "カラーコードは#で始まる3桁または6桁の16進数で指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_LOGO_TYPE]: "ロゴタイプには'Background'、'Centered'、'None'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS]: "The shipping address is invalid",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE]: "The address's country code is invalid",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_ZIP]: "The address's zip code is invalid",
    [ERRORS_LABELS.ERRORS_EXPIRATION_TIMESHIFT]: "振込期限の時刻が正しくありません。",
    [ERRORS_LABELS.ERRORS_EXPIRATION_PERIOD]: "有効期限が無効です。",
    [ERRORS_LABELS.ERRORS_PAIDY_ZIP_CODE_REQUIRED]: "Zip code value is required for Paidy payment type",
    [ERRORS_LABELS.ERRORS_INVALID_METADATA]: "Metadata should be a record of string, number or boolean.",
    [ERRORS_LABELS.ERRORS_GENERIC]: "不正なパラメータが指定されています。",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT]: "'allowCardInstallments'パラメータを指定する場合には、'checkout'は、'token'、'payment'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_TOKEN_TYPE]: "'allowCardInstallments'パラメータを指定する場合には、トークンタイプは、'one_time'、'recurring'のいずれかを指定して下さい。",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_USAGE_LIMIT]: "'allowCardInstallments'パラメータを指定する場合には、パラメーターから使用制限を消して下さい。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_KEY]: "カスタムフィールドのキーが必要です。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_LABEL]: "カスタムフィールドのラベルが必要です。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_TYPE]: "カスタムフィールドのタイプは'select'または'string'である必要があります。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS]: "'select'タイプのカスタムフィールドには選択肢のリストが必要です。",
    [ERRORS_LABELS.ERRORS_INVALID_THREE_DS_MODE]: "3DSモードには、'normal'、'skip'、'force'のいずれかを指定して下さい。",
    [ERRORS_LABELS.PAYMENT_TYPE_NOT_SUPPORTED]: "การ์ดใบนี้ไม่รองรับหมวดหมู่การชำระเงินที่ระบุ (จำนวนการแยก ฯลฯ)",
    [ERRORS_LABELS.UNFINISHED_CHARGE_EXIST_FOR_TOKEN]: "Unfinished charge exist for token",
    [ERRORS_LABELS.PAYMENT_EXPIRATION_EXCEEDS_PERIOD]: "Expiration exceeds period",
};

export default LABELS;
