import * as DURATION_LABELS from "../labels/duration";

export const LABELS: Record<string, string> = {
    [DURATION_LABELS.DURATION_SPACER]: "",
    [DURATION_LABELS.DURATION_YEARS]: "{value} ปี",
    [DURATION_LABELS.DURATION_MONTHS]: "{value} เดือน",
    [DURATION_LABELS.DURATION_WEEKS]: "{value} รายสัปดาห์",
    [DURATION_LABELS.DURATION_DAYS]: "{value} วัน",
};

export default LABELS;
