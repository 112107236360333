import { IntlState } from "react-intl-redux";
import { intlReducer as intl } from "react-intl-redux";
import { ExtractRematchDispatchersFromEffects, init, Models, RematchDispatch, RematchRootState } from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import selectPlugin from "@rematch/select";
import { routerMiddleware, RouterState } from "connected-react-router";
import { connectRouter } from "connected-react-router";
import { createMemoryHistory } from "history";
import { Action as ReduxAction } from "redux";
import { FormReducer, FormStateMap } from "redux-form";
import { reducer as form } from "redux-form";

import { getInitialLocale } from "../locale";

import models, { RootModels } from "./models";

export const history = createMemoryHistory();

export type Action<Payload = any> = ReduxAction<string> & { payload: Payload };

interface LoadingPluginState<M extends Models> {
    loading: {
        models: { [modelKey in keyof M]: M[modelKey]["name"] };
        effects: { [modelKey in keyof M]: ExtractRematchDispatchersFromEffects<M[modelKey]["effects"]> };
        global: boolean;
    };
}

type ReduxStateShape = {
    intl: IntlState;
    router: RouterState;
    form: FormStateMap & FormReducer;
};

export const store = init({
    plugins: [selectPlugin(), createLoadingPlugin()],
    models,
    redux: {
        reducers: { router: connectRouter(history), intl, form },
        initialState: { intl: getInitialLocale() },
        devtoolOptions: { disabled: process.env.NODE_ENV === "production" },
        middlewares: [routerMiddleware(history)],
    },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModels>;
export type StateShape = ReduxStateShape & RematchRootState<RootModels> & LoadingPluginState<RootModels>;
