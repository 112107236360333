import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

enum NotSelectedReason {
    DescriptorNotSupported = "descriptor_not_supported",
    GatewayAlreadyUsed = "gateway_already_used",
    ChargeWithoutCVVNotSupported = "charge_without_cvv_not_supported",
    CardBrandNotSupported = "card_brand_not_supported",
    CountryNotSupported = "country_not_supported",
    ModeNotSupported = "mode_not_supported",
    AuthCaptureNotSupported = "auth_capture_not_supported",
    CvvAuthNotSupported = "cvv_auth_not_supported",
    CustomerIpAddressMissing = "customer_ip_address_missing",
    PaymentTypeNotSupported = "payment_type_not_supported",
    NotActive = "not_active",
    GatewayNotAvailable = "gateway_not_available",
    GatewayNotSupported = "gateway_not_supported",
    PlatformNotSupported = "platform_not_supported",
    MerchantNotSupported = "merchant_not_supported",
    StoreNotSupported = "store_not_supported",
    DoesNotMatchRequestedCurrency = "does_not_match_requested_currency",
    RateLimited = "rate_limited",
    GatewayConfigNotFound = "gateway_config_not_found",
    LastNameRequired = "last_name_required",
    NotChosenGatewayForQRCode = "not_chosen_gateway_for_qr_code",
    NotChosenGateway = "not_chosen_gateway",
    CallMethodNotSupported = "call_method_not_supported",
    OSTypeNotSpecified = "os_type_not_specified",
    SubscriptionPlanValidationFailed = "subscription_plan_validation_failed",
    InstallmentCapableNotMatching = "installment_capable_not_matching",
    CardBankIssuerNotSupported = "card_bank_issuer_not_supported",
    CardBinNotSupported = "card_bin_not_supported",
    CurrencyNotSupported = "currency_not_supported",
    AuthCaptureSupportNotMatching = "auth_capture_support_not_matching",
    NotOnWhitelist = "not_on_whitelist",
    BlacklistedTagOnMerchant = "blacklisted_tag_on_merchant",
    BlacklistedTagOnStore = "blacklisted_tag_on_store",
    ForbiddenGateway = "forbidden_gateway",
    ForbiddenCredential = "forbidden_credential",
    CannotShareMerchantCredentials = "cannot_share_merchant_credentials",
    CannotShareStoreCredentials = "cannot_share_store_credentials",
    PlatformCredentialsDisabled = "platform_credentials_disabled",
    TaggedPlatformCredentialsDisabled = "tagged_platform_credentials_disabled",
}

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "checkout设定有误",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "原始应用程序无法连接到Checkout。",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "参数有误，Checkout无法初始化。",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "通信失败，请查看通信状况，重新再试。",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "Timeout error",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "发生超时",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "发生错误。请稍后再试。",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "该账号没有使用自动付款Token的权限。",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "需要自动付款Token的使用权限。",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "不支持该卡种。",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "Checkout无法再次域名执行。",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "Could not create the transaction token.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "Could not create the transaction token with CVV data.",
    [ERRORS_LABELS.ERRORS_ALERTS_THREE_DS_FAILED]: "3-D 安全验证无法完成。",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "该服务不可使用",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "该服务暂时不可用。 请稍后再试。",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "借记卡和预付卡仅支持实际支付，不支持授权。",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "付款处理失败。",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "自动付款处理失败。",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "参数有误。",
    [ERRORS_LABELS.PROCESSING_ERROR]: "Processing error",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "Charge could not be registered by Paidy.",
    [ERRORS_LABELS.EMAIL_EXISTS_FOR_CARD]: "您输入的电子邮件与此卡关联的邮件不匹配",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "由于经常出现付款失败，该服务在一段时间内无法使用。",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "付款处理失败。",
    [ERRORS_LABELS.NO_GATEWAY_AVAILABLE_TO_PROCESS_REQUEST]: "您连接的系统不支持此请求。",

    /* request */
    [RequestErrorCode.RequestError]: "请求无效",

    /* generic */
    [ResponseErrorCode.BadRequest]: "请求有误",
    [ResponseErrorCode.Forbidden]: "操作禁止",
    [ResponseErrorCode.NotFound]: "找不到资源",
    [ResponseErrorCode.NotAllowed]: "操作不允许",
    [ResponseErrorCode.Conflicted]: "资源与其他资源冲突",
    [ResponseErrorCode.TooManyRequests]: "API请求过多",
    [ResponseErrorCode.InternalServerError]: "内部服务器错误",
    [ResponseErrorCode.ServiceUnavailable]: "服务不可用",
    [ResponseErrorCode.NotAuthorized]: "未授权",

    /* global */
    [ResponseErrorCode.UnknownError]: "发生错误。",
    [ResponseErrorCode.Timeout]: "超时错误",
    [ResponseErrorCode.DBError]: "数据库错误",
    [ResponseErrorCode.InvalidRequest]: "请求无效",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "无法获得幂等结果",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "服务不可用请重试",

    /* auth */
    [ResponseErrorCode.InvalidDomain]: "域名无效",
    [ResponseErrorCode.InvalidCredentials]: "账号或密码错误",
    [ResponseErrorCode.AuthHeaderMissing]: "缺少授权Header",
    [ResponseErrorCode.InvalidPermissions]: "您没有访问此资源所需的权限。",
    [ResponseErrorCode.ImproperAuth]: "授权不当",
    [ResponseErrorCode.CouldNotRefreshAuth]: "无法刷新授权令牌",
    [ResponseErrorCode.UserBanned]: "此用户已被禁止。",
    [ResponseErrorCode.InvalidLoginToken]: "登录令牌无效",
    [ResponseErrorCode.InvalidAppToken]: "应用令牌无效",
    [ResponseErrorCode.ExpiredLoginToken]: "登录令牌已过期",
    [ResponseErrorCode.OutdatedAppToken]: "应用令牌已过时",
    [ResponseErrorCode.ChargeTooQuick]: "不允许另外的支付请求",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "File upload error",
    [ResponseErrorCode.FileMaxSizeExceeded]: "File max size exceeded",
    [ResponseErrorCode.FileInvalidType]: "Invalid file type",
    [ResponseErrorCode.FileNotFound]: "File not found",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "Token generation exception",
    [ResponseErrorCode.TokenForWrongStore]: "Invalid token",
    [ResponseErrorCode.NonSubscriptionPayment]: "Non subscription payment",
    [ResponseErrorCode.BankAccountExists]: "Bank account exists",
    [ResponseErrorCode.EmailExists]: "Email exists",
    [ResponseErrorCode.StoreExists]: "Store exists",
    [ResponseErrorCode.GatewayCredentialsExists]: "Gateway credentials exists",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "Rule for merchant is not unique",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "Rule set for merchant is not unique",
    [ResponseErrorCode.NonUniqueRule]: "Rule is not unique",
    [ResponseErrorCode.RulePriorityMustBePositive]: "Rule priority must be positive",
    [ResponseErrorCode.NonExistingRule]: "Non existing rule",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "Must contain unique elements only",
    [ResponseErrorCode.WebhookURLExists]: "Webhook URL exists",
    [ResponseErrorCode.GroupExists]: "Group exists",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "Primary bank account not found",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "Must have a primary bank account",
    [ResponseErrorCode.VerificationDataExists]: "Verification data exists",
    [ResponseErrorCode.TemplateExists]: "Template exists",
    [ResponseErrorCode.StratusCredentialsExists]: "Stratus credentials exists",
    [ResponseErrorCode.StratusRuleStillInUse]: "Stratus rule still in use",
    [ResponseErrorCode.ResourceLimitReached]: "Resource limit reached",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "令牌类型无效",
    [ResponseErrorCode.InvalidToken]: "令牌无效",
    [ResponseErrorCode.InvalidCard]: "信用卡无效",
    [ResponseErrorCode.ForbiddenIP]: "IP地址禁止",
    [ResponseErrorCode.InvalidUserData]: "用户数据无效",
    [ResponseErrorCode.NonUniqueActiveToken]: "活令牌不唯一",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "交易已处理",
    [ResponseErrorCode.TransactionTokenExpired]: "交易令牌已过期",
    [ResponseErrorCode.NoTestCardInLiveMode]: "测试卡不能用于生产环境",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "不允许循环令牌",
    [ResponseErrorCode.RecurringTokenDisabled]: "循环令牌已禁用",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "循环使用限制为必填项",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "循环用法需要CVV",
    [ResponseErrorCode.UsageLimitNotApplicable]: "使用限制不适用",
    [ResponseErrorCode.CardProcessingDisabled]: "卡处理已禁用",
    [ResponseErrorCode.QRProcessingDisabled]: "二维码处理已禁用",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "便利店处理已禁用",
    [ResponseErrorCode.NotOneTimeToken]: "不是一次性令牌",
    [ResponseErrorCode.NotSubscriptionToken]: "不是订阅令牌",
    [ResponseErrorCode.NotRecurringToken]: "不是循环令牌",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "货币必须和支付一致",
    [ResponseErrorCode.RefundNotWithinBounds]: "退款不在范围内",
    [ResponseErrorCode.InvalidTransfer]: "无效转账",
    [ResponseErrorCode.TransferAlreadyExists]: "转账已存在",
    [ResponseErrorCode.NoLedgers]: "没有分类账",
    [ResponseErrorCode.FailedToAssociateLedgers]: "关联分类账失败",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "未验证时不能启用生产模式",
    [ResponseErrorCode.SelfTransferNotPermitted]: "不允许自行转账",
    [ResponseErrorCode.CardLocked]: "信用卡被锁",
    [ResponseErrorCode.SubscriptionProcessing]: "订阅正在处理",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "无法更新交易令牌",
    [ResponseErrorCode.AlreadyCaptured]: "已经Capture",
    [ResponseErrorCode.CaptureAmountTooLarge]: "Capture金额太大",
    [ResponseErrorCode.PartialCaptureNotSupported]: "不支持部分Capture",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "借记授权已停用",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "预付授权已停用",

    [ResponseErrorCode.NoGatewaysAvailable]: "没有可用的网关",
    [ResponseErrorCode.CardBrandNotSupported]: "不支持该信用卡类型",
    [ResponseErrorCode.CardCountryNotSupported]: "不支持该信用卡国家",
    [ResponseErrorCode.CVVRequired]: "CVV为必填项目",
    [ResponseErrorCode.LastNameRequired]: "姓为必填项目",
    [ResponseErrorCode.AuthNotSupported]: "不支持Auth",

    [ResponseErrorCode.InvalidBinRange]: "Bin范围无效",
    [ResponseErrorCode.VerificationRequired]: "需要验证ID",

    /* validation responses */
    [ResponseErrorCode.ValidationError]: "验证错误",
    [ResponseErrorCode.RequiredValue]: "值不能为空",
    [ResponseErrorCode.MustBeFutureTime]: "必须为将来时间",
    [ResponseErrorCode.InvalidFormat]: "格式无效",
    [ResponseErrorCode.InvalidPercentFee]: "百分比费用无效",
    [ResponseErrorCode.InvalidCardNumber]: "卡号无效",
    [ResponseErrorCode.InvalidCardExpiration]: "信用卡有效期限无效",
    [ResponseErrorCode.InvalidCVV]: "输入 3 或 4 位数的安全密码",
    [ResponseErrorCode.InvalidFormatLength]: "长度无效",
    [ResponseErrorCode.InvalidFormatUUID]: "UUID格式无效",
    [ResponseErrorCode.InvalidFormatBase64]: "base64格式无效",
    [ResponseErrorCode.InvalidFormatEmail]: "邮件地址无效",
    [ResponseErrorCode.InvalidFormatCurrency]: "货币无效",
    [ResponseErrorCode.InvalidCurrency]: "货币无效",
    [ResponseErrorCode.InvalidAmount]: "金额无效",
    [ResponseErrorCode.InvalidEventForStore]: "店铺无效事件",
    [ResponseErrorCode.InvalidEventForPlatform]: "平台无效事件",
    [ResponseErrorCode.InvalidEventForMerchant]: "商户无效事件",
    [ResponseErrorCode.InvalidFormatDomain]: "域名格式无效",
    [ResponseErrorCode.InvalidFormatUrl]: "URL格式无效",
    [ResponseErrorCode.InvalidFormatObject]: "对象格式无效",
    [ResponseErrorCode.InvalidFormatCountry]: "国家无效",
    [ResponseErrorCode.InvalidPhoneNumber]: "电话号码无效",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "BIC/SWIFT代码无效",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "路由号码无效",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "路由码无效",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "IFSC码无效",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "银行账号无效",
    [ResponseErrorCode.InvalidPasswords]: "密码无效",
    [ResponseErrorCode.InvalidTimePeriod]: "时间段无效",
    [ResponseErrorCode.InvalidDayOfWeek]: "无效的星期几",
    [ResponseErrorCode.InvalidWeekOfMonth]: "一个月中的无效星期",
    [ResponseErrorCode.InvalidDayOfMonth]: "一个月中的无效日期",
    [ResponseErrorCode.InvalidColorsSize]: "颜色无效",
    [ResponseErrorCode.NestedJsonNotAllowed]: "不允许嵌套JSON",
    [ResponseErrorCode.InvalidFormatDate]: "日期格式无效",
    [ResponseErrorCode.InvalidChargeStatus]: "支付状态无效",
    [ResponseErrorCode.InvalidQRScanGateway]: "二维码扫描网关无效",
    [ResponseErrorCode.CardLimitExceededForStore]: "超过店铺信用卡限制",
    [ResponseErrorCode.InvalidLanguage]: "语言无效",
    [ResponseErrorCode.SubscriptionNotAllowed]: "不允许订阅",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "只允许使用一次令牌",
    [ResponseErrorCode.AuthExpired]: "授权已过期",
    [ResponseErrorCode.InvalidTemplateKey]: "模板键值无效",
    [ResponseErrorCode.NonPublicTemplate]: "非公开模板",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "只允许使用日本电话号码",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "有效期限超出范围",
    [ResponseErrorCode.UnsupportedLanguage]: "不支持的语言",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "不支持默认语言",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "Capture 仅允许用于信用卡付款",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "Capture的卡类型无效",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "预定capture日期无效",
    [ResponseErrorCode.IncoherentDateRange]: "日期范围不一致",
    [ResponseErrorCode.InvalidMerchantStatus]: "商户状态无效",
    [ResponseErrorCode.MustHaveOneElement]: "必须至少有一个元素",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "必须至少有一个元素",
    [ResponseErrorCode.ThresholdMustBeNull]: "阈值必须为空",
    [ResponseErrorCode.IllegalNumberOfItems]: "项目个数不合法",
    [ResponseErrorCode.UnableToReadCredentials]: "无法读取credentials",
    [ResponseErrorCode.GatewayError]: "网关错误",
    [ResponseErrorCode.GatewayNoLongerSupported]: "不再支持的网关",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "无效的支付额度限制",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "CVV 金额必须包含平台货币",
    [ResponseErrorCode.TransferScheduleWaitPeriodAndPeriodRequired]: "需要转账日程等待时间和所需时间",
    [ResponseErrorCode.ChargeAmountTooLow]: "支付金额过低",
    [ResponseErrorCode.ChargeAmountTooHigh]: "支付金额过高",
    [ResponseErrorCode.MustContainPlatformCurrency]: "平台货币为必填项",

    /* Used when creating a new Merchant */
    [ResponseErrorCode.OnlyASCII]: "Only ASCII characters are supported",
    [ResponseErrorCode.UniqueCharacters]: "Unique characters",
    [ResponseErrorCode.AtLeastOneDigit]: "Must contain at least one digit",
    [ResponseErrorCode.AtLeastOneLetter]: "Must contain at least one letter",
    [ResponseErrorCode.EmptyRoles]: "Cannot have empty roles",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "Editing own roles not allowed",
    [ResponseErrorCode.InvalidCardBrand]: "The supplied card brand is not supported",
    [ResponseErrorCode.UnsupportedCountry]: "The supplied country is not supported",
    [ResponseErrorCode.UnsupportedCurrency]: "The supplied currency is not supported",
    [ResponseErrorCode.CannotBanSelf]: "Cannot ban self",
    [ResponseErrorCode.CannotSelfTerminate]: "Cannot self terminate",
    [ResponseErrorCode.NoDuplicateCurrencies]: "No currency duplicates allowed",

    [ResponseErrorCode.PlatformNotFound]: "Platform not found",
    [ResponseErrorCode.InvalidPlatform]: "Invalid platform",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "Invalid invoice fee configuration",
    [ResponseErrorCode.InvalidPlatformRole]: "Invalid platform role",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "Date not submitted",
    [ResponseErrorCode.NoBankAccount]: "No bank account",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "Percent fee not submitted",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "Insufficient system manager info",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "Credentials exist",
    [ResponseErrorCode.InvalidMerchantCategoryCode]: "Invalid merchant category code",
    [ResponseErrorCode.GatewayConfigurationRequired]: "Gateway configuration required",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "Paidy credentials already created",

    /* Gateway simulation */
    [ResponseErrorCode.PlatformCredentialsDisabled]: "Platform credential disabled",
    [ResponseErrorCode.ForbiddenGateway]: "Forbidden gateway",
    [ResponseErrorCode.ForbiddenQrGateway]: "Forbidden QR gateway",
    [ResponseErrorCode.BlacklistedTagOnMerchant]: "Blacklisted tag on merchant",
    [ResponseErrorCode.BlacklistedTagOnStore]: "Blacklisted tag on store",
    [ResponseErrorCode.NotOnWhitelist]: "Not on whitelist",
    [ResponseErrorCode.CannotShareMerchantCredentials]: "Cannot share merchant credentials",
    [ResponseErrorCode.MissingGatewayConfiguration]: "Missing gateway configuration",
    [ResponseErrorCode.GatewayConfigNotFound]: "Gateway configuration not found",
    [ResponseErrorCode.NotActive]: "Not active",
    [ResponseErrorCode.ModeNotSupported]: "Mode not supported",
    [ResponseErrorCode.TaggedPlatformCredentialsDisabled]: "Tagged platform credentials disabled",
    [ResponseErrorCode.NoStratusConfiguration]: "No stratus configuration",

    /* Refund */
    [ResponseErrorCode.RefundExceedsChargeAmount]: "Refund exceeds charge amount",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "Cannot refund unsuccessful charge",
    [ResponseErrorCode.RefundNotAllowed]: "Refund not allowed",
    [ResponseErrorCode.PartialRefundNotSupported]: "Partial refund not supported",
    [ResponseErrorCode.CancelNotAllowed]: "Cancel not allowed",
    [ResponseErrorCode.RefundPercentageExceeded]: "Refund percentage exceeded",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay not enabled",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay already enabled",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Apple Pay certificate already updated",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "Apple Pay unsupported algorithm",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Apple Pay certificate not found",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay unable to generate certificate request",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay invalid configuration",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay invalid certificate",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay invalid algorithm",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay invalid certificate format",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay invalid signature",
    [ResponseErrorCode.ApplePayError]: "Apple Pay error",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "卡号错误",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "卡上的有效月份无效",
    [ResponseErrorCode.CardExpirationYearInvalid]: "卡上的有效年份无效",
    [ResponseErrorCode.CardExpired]: "卡的有效期已过",
    [ResponseErrorCode.CardCVVInvalid]: "安全码错误",
    [ResponseErrorCode.CardRejected]: "验证筛选错误（请联系发卡行）",
    [ResponseErrorCode.CardInvalid]: "卡无效。",
    [ResponseErrorCode.ChargeInvalidData]: "请求数据无效。",
    [ResponseErrorCode.TooManyChargeRequests]: "同一卡在短时间内有过多账单请求。 请稍候再试。",
    [ResponseErrorCode.CancelUnavailable]: "此账单无法取消",
    [ResponseErrorCode.ChargeExpired]: "账单已过期。",
    [ResponseErrorCode.SeizeCard]: "验证筛选错误（如卡丢失、被盗、无效或受限）",
    [ResponseErrorCode.ContactBank]: "验证筛选错误（请联系发卡行）",
    [ResponseErrorCode.FraudSuspected]: "授权筛选错误（涉嫌欺诈使用、安全限制）",
    [ResponseErrorCode.BankSystemFailure]: "卡公司方面的交易错误",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "不支持动态描述符",
    [ResponseErrorCode.PaymentCodeInvalid]: "条形码/QR 码无效",
    [ResponseErrorCode.PaymentCodeExpired]: "条形码/QR 码已过期",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "条形码/QR 码已被使用",
    [ResponseErrorCode.PaymentCodeStillInUse]: "条形码/QR 码正在使用中",
    [ResponseErrorCode.RejectedHighRisk]: "因超过高风险阈值而被拒绝",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "验证期已过",
    [ResponseErrorCode.RevertFailed]: "退货失败。 需要手动操作",
    [ResponseErrorCode.RefundFailed]: "报销失败",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "客户资金不足",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "元数据字段中的值无效或缺失",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "跨境交易未授权，无身份证",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "跨境交易未授权。 电话号码丢失",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "不允许跨境交易。 付款方式不可接受",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "不允许跨境交易。 姓名不详。",
    [ResponseErrorCode.LimitExceededForPaymentType]: "付款方式交易已超出限额",
    [ResponseErrorCode.LimitExceededForMerchant]: "超过账户交易限额",
    [ResponseErrorCode.TransactionNotFound]: "未找到交易",
    [ResponseErrorCode.DuplicateTransaction]: "重复交易",
    [ResponseErrorCode.PaymentWalletRejected]: "支付钱包被连接系统拒绝",
    [ResponseErrorCode.InsufficientMerchantInformation]: "账户缺少连接系统要求的信息",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "不允许跨境交易 不接受货币",
    [ResponseErrorCode.GatewayServerError]: "0570-035-连接系统错误（请致电 0570-035-672 联系我们的支持中心）",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "由于连接系统原因，所选付款暂时不可用",
    [ResponseErrorCode.PaymentCanceled]: "付款已取消",
    [ResponseErrorCode.ExceededPendingThreshold]: "启动支付程序需要时间",
    [ResponseErrorCode.InternalError]: "系统内部错误",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "Unable to generate certificate request",
    [ResponseErrorCode.InvalidMerchantSettings]: "Invalid merchant settings",
    [ResponseErrorCode.UnsupportedAlgorithm]: "Unsupported algorithm",
    [ResponseErrorCode.InvalidPaymentToken]: "Invalid payment token",
    [ResponseErrorCode.ExpiredPaymentToken]: "Expired payment token",
    [ResponseErrorCode.InvalidCertificate]: "Invalid certificate",
    [ResponseErrorCode.InvalidLeafCertificate]: "Invalid leaf certificate",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "Invalid intermediate cerificate",
    [ResponseErrorCode.InvalidChainOfTrust]: "Invalid chain of trust",
    [ResponseErrorCode.InvalidSignature]: "Invalid signature",

    /** Platform */
    [ResponseErrorCode.PlatformKeyExists]: "Domain already in use",
    [ResponseErrorCode.MerchantConsoleURIExists]: "Merchant console URI exists",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "Idempotency key conflict",

    /* BannedCards */
    [ResponseErrorCode.CardBanned]: "Card banned",
    [ResponseErrorCode.CardAlreadyBanned]: "Card already banned",
    [ResponseErrorCode.CardNotBanned]: "Card not banned",
    [ResponseErrorCode.TestCardCannotBeBanned]: "TestCard cannot be banned",

    /* Stats */
    [ResponseErrorCode.InvalidMetric]: "Invalid metric",
    [ResponseErrorCode.InvalidResource]: "Invalid resource",

    /* Installments */
    [ResponseErrorCode.UseStartOn]: "Use Start on",
    [ResponseErrorCode.PaymentInTimePeriod]: "Payment in time period",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "Second charge already made",
    [ResponseErrorCode.NotSupportedByProcessor]: "Not supported by processor",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "Subscription already canceled",
    [ResponseErrorCode.SubscriptionNotFound]: "Subscription not found",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "Must be lower than full amount",
    [ResponseErrorCode.InstallmentPlanNotFound]: "Installment plan not found",
    [ResponseErrorCode.InstallmentAlreadySet]: "Installment already set",
    [ResponseErrorCode.InstallmentInvalidPlan]: "Invalid installment plan",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "Invalid installment plan type",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "InstallmentPaymentType is not allowed for plan",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "Invalid installment initial amount",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "Installment max payout period exceeded",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "Insufficient installment amount per charge",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "Installment revolving plan cannot set initial amount",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "Installment revolving plan cannot set subsequent cycles start",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "Installment processor initial amounts is not supported",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "Installment processor period is not supported",
    [ResponseErrorCode.CannotChangeToken]: "Cannot change token",
    [ResponseErrorCode.InstallmentsNotEnabled]: "Installments not enabled",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "Cannot change installment amount",
    [ResponseErrorCode.CannotChangeToInstallment]: "Cannot change to installment",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "Must be month based to set",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "Must be within two cycles",
    [ResponseErrorCode.Deprecated]: "Deprecated",
    [ResponseErrorCode.CannotBeZero]: "Cannot be zero",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "Need at least two cycles",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "Invalid installment cycles count",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "Cannot set initial amount to zero",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "Cannot set until subscription started",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "Cannot set after subscription started",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "Cannot change canceled subscription",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "Stratus merchant already exists",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "Stratus store already exists",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "Stratus store allocation limit reached",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "Invalid elastic index",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "Invalid date histogram interval",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "Invalid SQS endpoint",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "未找到 BIN",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhn 检查失败",
    [ResponseErrorCode.InvalidCardNumberLength]: "卡号长度不对",
    [ResponseErrorCode.CardPaymentDisabled]: "信用卡支付被禁用",
    [ResponseErrorCode.DebitDisabled]: "借记卡已停用",
    [ResponseErrorCode.PrepaidDisabled]: "预付卡已禁用",
    [ResponseErrorCode.CountryNotSupported]: "不支持卡的国家",
    [ResponseErrorCode.Unspecified]: "无效卡",

    [ResponseErrorCode.InvalidJapanesePostalCode]: "日本邮编不正确",

    [NotSelectedReason.DescriptorNotSupported]: "不支持描述符",
    [NotSelectedReason.GatewayAlreadyUsed]: "网关已被使用",
    [NotSelectedReason.ChargeWithoutCVVNotSupported]: "不支持无CVV的支付",
    [NotSelectedReason.CardBrandNotSupported]: "不支持该卡类别",
    [NotSelectedReason.CountryNotSupported]: "不支持该国家",
    [NotSelectedReason.ModeNotSupported]: "不支持该模式",
    [NotSelectedReason.AuthCaptureNotSupported]: "不支持Auth capture",
    [NotSelectedReason.CvvAuthNotSupported]: "不支持CVV验证",
    [NotSelectedReason.CustomerIpAddressMissing]: "缺少用户IP地址",
    [NotSelectedReason.PaymentTypeNotSupported]: "不支持该支付类型",
    [NotSelectedReason.NotActive]: "未激活",
    [NotSelectedReason.GatewayNotAvailable]: "网关不可用",
    [NotSelectedReason.GatewayNotSupported]: "不支持该网关",
    [NotSelectedReason.PlatformNotSupported]: "不支持该平台",
    [NotSelectedReason.MerchantNotSupported]: "不支持该商户",
    [NotSelectedReason.StoreNotSupported]: "不支持该店铺",
    [NotSelectedReason.DoesNotMatchRequestedCurrency]: "请求货币类型不一致",
    [NotSelectedReason.RateLimited]: "利率限制",
    [NotSelectedReason.GatewayConfigNotFound]: "没有找到网关配置",
    [NotSelectedReason.LastNameRequired]: "姓为必填项",
    [NotSelectedReason.NotChosenGatewayForQRCode]: "未选择二维码网关",
    [NotSelectedReason.NotChosenGateway]: "未选择网关",
    [NotSelectedReason.CallMethodNotSupported]: "不支持调用方式",
    [NotSelectedReason.OSTypeNotSpecified]: "未指定OS类型",
    [NotSelectedReason.SubscriptionPlanValidationFailed]: "订阅计划验证失败",
    [NotSelectedReason.InstallmentCapableNotMatching]: "分期付款能力不匹配",
    [NotSelectedReason.CardBankIssuerNotSupported]: "不支持该发卡行",
    [NotSelectedReason.CardBinNotSupported]: "不支持该卡BIN",
    [NotSelectedReason.CurrencyNotSupported]: "不支持该国家",
    [NotSelectedReason.AuthCaptureSupportNotMatching]: "Auth capture 支持不一致",
    [NotSelectedReason.NotOnWhitelist]: "不在白名单内",
    [NotSelectedReason.BlacklistedTagOnMerchant]: "商家黑名单标签",
    [NotSelectedReason.BlacklistedTagOnStore]: "店铺黑名单标签",
    [NotSelectedReason.ForbiddenGateway]: "禁用网关",
    [NotSelectedReason.ForbiddenCredential]: "禁用的验证信息",
    [NotSelectedReason.CannotShareMerchantCredentials]: "不能共享商家验证信息",
    [NotSelectedReason.CannotShareStoreCredentials]: "不能共享店铺验证信息",
    [NotSelectedReason.PlatformCredentialsDisabled]: "平台验证信息已禁用",
    [NotSelectedReason.TaggedPlatformCredentialsDisabled]: "标记的平台验证信息已禁用",
};

export default LABELS;
