import * as ERRORS_LABELS from "../labels/errors";

export const LABELS = {
    [ERRORS_LABELS.ERRORS_TITLE]: "checkout設定錯誤",
    [ERRORS_LABELS.ERRORS_CHECKOUT]: "無法連接checkout服務。",
    [ERRORS_LABELS.ERRORS_NO_SCRIPT_ID]: "Checkout腳本有誤。",
    [ERRORS_LABELS.ERRORS_NO_APP_ID]: "必須設定應用Token。",
    [ERRORS_LABELS.ERRORS_INVALID_APP_ID]: "令牌无效。",
    [ERRORS_LABELS.ERRORS_NO_CHECKOUT]: "必須設定Token或者是Payment的Checkout。",
    [ERRORS_LABELS.ERRORS_NOT_PAYMENT_CHECKOUT]: "'payment'のチェックアウトタイプが必要です。",
    [ERRORS_LABELS.ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE]: "'one_time'のトークンタイプが必要です。",
    [ERRORS_LABELS.ERRORS_INVALID_TOKEN_TYPE]: "Token類型應指定為'one_time'、'subscription'或'recurring'。",
    [ERRORS_LABELS.ERRORS_INVALID_AMOUNT]: "金額應為正整數。",
    [ERRORS_LABELS.ERRORS_INVALID_DATE]: "日期格式無效。",
    [ERRORS_LABELS.ERRORS_INVALID_CURRENCY]: "該貨幣不可用。",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PERIOD]: "定期付款期間請指定為'daily'、'weekly'、'biweekly'、'monthly'、'bimonthly'、'quarterly'、'semiannually'或'annually'。",
    [ERRORS_LABELS.ERRORS_INVALID_LOCALE]: "Locale請設定為'auto'、'en-US'、'ja-JP'、'zh-CN'、'zh-TW'、'ko-KR'、'th-TH'或'ru-RU'。",
    [ERRORS_LABELS.ERRORS_INVALID_USAGE_LIMIT]: "使用限制請指定為'daily'、'weekly'、'monthly'或'yearly'。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_AMOUNT]: "如果设定了商品，金额参数必须省略。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_CURRENCY]: "如果設定了商品，金額參數必須省略。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_SUBSCRIPTION_ID]: "如果指定了定期付款ID，Token類型請設定為'subscription'。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN]: "如果指定了分期付款類型，Token類型請設定為'subscription'。",
    [ERRORS_LABELS.ERRORS_UNNECESSARY_INSTALLMENT_PLAN_QTY]: "如果指定了分期付款參數，Token類型請設定為'subscription'。",
    [ERRORS_LABELS.ERRORS_NO_SUBSCRIPTION_PERIOD]: "請指定定期付款期間。",
    [ERRORS_LABELS.ERRORS_TITLE_TOO_LONG]: "標題最多64個字。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_PLAN]: "分期付款類型請指定為 'revolving' 或 'fixed_cycles'。",
    [ERRORS_LABELS.ERRORS_INVALID_SUBSCRIPTION_PLAN]: "分期付款類型請指定為 'fixed_cycles' 或 'fixed_cycle_amount'。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_AMOUNT]: "金額應為正整數。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENT_INITIAL_AMOUNT]: "金額應為正整數。",
    [ERRORS_LABELS.ERRORS_INVALID_CUSTOMER_ID]: "顧客ID必須為UUID格式。",
    [ERRORS_LABELS.ERRORS_INVALID_TIMEZONE]: "時區無效。",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPE]: "不支持當前支付方式。",
    [ERRORS_LABELS.ERRORS_INVALID_PAYMENT_TYPES]: "不支持當前支付方式。",
    [ERRORS_LABELS.ERRORS_INVALID_INSTALLMENTS]: "不支持某些分期付款周期。 可用：1、3、5、6、10、12、15、18、20、24 和循环信贷",
    [ERRORS_LABELS.ERRORS_INVALID_PRODUCTS]: "商品应该是用逗号“,”分隔的 uuid 列表",
    [ERRORS_LABELS.ERRORS_INVALID_AUTH_CODE]: "認證代碼有誤。",
    [ERRORS_LABELS.ERRORS_INVALID_COLOR_CODE]: "顏色代碼格式應是以'#'開頭的3位或6位的16進制數。",
    [ERRORS_LABELS.ERRORS_INVALID_LOGO_TYPE]: "Logo類型必須為'Background', 'Centered', 或 'None'。",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS]: "郵寄地址有誤",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE]: "國家代碼有誤",
    [ERRORS_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_ZIP]: "郵政編碼有誤",
    [ERRORS_LABELS.ERRORS_EXPIRATION_TIMESHIFT]: "汇款日期无效",
    [ERRORS_LABELS.ERRORS_EXPIRATION_PERIOD]: "有效期限无效。",
    [ERRORS_LABELS.ERRORS_PAIDY_ZIP_CODE_REQUIRED]: "Paidy支付必須設定郵政編碼",
    [ERRORS_LABELS.ERRORS_INVALID_METADATA]: "Metadata should be a record of string, number or boolean.",
    [ERRORS_LABELS.ERRORS_GENERIC]: "參數設定有誤。",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT]: "When providing the 'allowCardInstallments' property, the checkout must of 'token' or 'payment'.",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_TOKEN_TYPE]: "When providing the 'allowCardInstallments' property, the token type must of 'one_time' or 'recurring'.",
    [ERRORS_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_USAGE_LIMIT]: "When providing the 'allowCardInstallments' property, the recurring usage limit should be ignored.",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_KEY]: "自定义字段需要一个Key。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_LABEL]: "自定义字段需要一个Label。",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_TYPE]: "自定义字段需要定义类型。 它可以是'select' 或者 'string'",
    [ERRORS_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS]: "'select' 自定义字段需要一个选项列表。",
    [ERRORS_LABELS.ERRORS_INVALID_THREE_DS_MODE]: "3DSモードには、'normal'、'skip'、'force'のいずれかを指定して下さい。",
    [ERRORS_LABELS.PAYMENT_TYPE_NOT_SUPPORTED]: "该卡不支持指定的付款类别（如分期付款次数）。",
    [ERRORS_LABELS.UNFINISHED_CHARGE_EXIST_FOR_TOKEN]: "代币收费尚未完成",
    [ERRORS_LABELS.PAYMENT_EXPIRATION_EXCEEDS_PERIOD]: "Expiration exceeds period",
};

export default LABELS;
