import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "Processing payment failed.",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "Processing payment... Please do not close this checkout.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "Payment has been processed successfully.",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "Payment details have been registered successfully.",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "Payment has been registered successfully. Please visit a local store and pay the fee.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "Subscription has been created successfully.",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "Pay {money}",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "Pay",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "Pay",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "Card details",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "Including local cards",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "Convenience store payment details",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "Convenience store or Pay-easy",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "Processing payment timed out. Please contact support.",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "Successfully updated subscription information.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "Updating subscription information... Please do not close this checkout.",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "The transaction couldn't be confirmed due to a system connection error. We apologize for the inconvenience but please contact the Payment Support Center to confirm if your transaction was successful or not.",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "Univa Paycast Support Center",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "For calls within Japan: 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "For calls outside of Japan, IP calls and PHS calls: 06-6538-2060",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "Email: {email}",

    // Review
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} ending in {lastFour}",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "expires {month}/{year}",
};

export default LABELS;
