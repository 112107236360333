import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "Вы будете перенаправлены на процесс выпуска токенов Paidy. Пожалуйста, подождите немного.",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "Оплата одобрена Paydy. Чтобы завершить, нажмите кнопку ниже.",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "Paidy",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "Дата рождения: 1970-01-30",
};

export default LABELS;
