import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "Платёж не прошёл.",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "Обрабатываем платёж. Не закрывайте это окно",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "Платёж  совершён",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "Платежная информация получена и зарегистрированна",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "Запрос на оплату в киоске зарегистрирован. Посетите ближайший круглосуточный магазин и оплатите покупку.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "Платёж  совершён.",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "Оплата {money}",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "Оплатить",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "Оплатить",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "Информация о карте",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "local card обмен",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "Информация об оплате в киоске в круглосуточном магазине",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "Оплата в киоске в круглосуточном магазине или Pay-easy",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "Время обработки платежа истекло. Пожалуйста, обратитесь в службу поддержки.",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "Регулярный платёж совершён.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "Регулярный платёж обрабарывается Не закрывайте это окно.",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "Произошла ошибка при оплате Пожалуйста, позвоните в службу поддержки по указанному ниже номеру, чтобы подтвердить оплату.",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "Запросы относительно обработки платежей",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "Univa Peacast Support Center Co., Ltd.",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "Тел: 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "* Пожалуйста, используйте номер 06-6538-2060 для звонков из-за границы, по IP Phone и по PHS",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "E-mail: {email}",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} последние четыре цифры {lastFour}",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "Срок действия {month}/{year}",
};

export default LABELS;
