import { StateShape, store } from "checkout/ts/redux/store";
import { PatchedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { OSType } from "checkout/ts/resources/CheckoutInfoBrand";
import { OnlineBrand, OnlineCallMethod } from "univapay-node";

const iosDeviceRegex = /ipad|iphone|ipod/;

const isIOS = () => {
    const userAgent = navigator.userAgent?.toLowerCase();
    const platform = navigator.platform?.toLowerCase();
    const maxTouchPoints = navigator.maxTouchPoints;

    return (
        iosDeviceRegex.test(platform) ||
        iosDeviceRegex.test(userAgent) ||
        (platform === "macintel" && maxTouchPoints > 1)
    );
};

export const isMobile = (): boolean => {
    const ua: string = navigator.userAgent?.toLowerCase();
    const mobileRegex = /mobile|ip(hone|od|ad)|android|blackberry|kindle|netfront|silk|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/i;

    return mobileRegex.test(ua);
};

export const isInline = () => (store.getState() as StateShape).application.params?.params?.inline === true;

export const isWeChatBrowser = () => /micromessenger/i.test(navigator.userAgent);

export const getOnlineCallMethodParams = (brand: OnlineBrand | PatchedOnlineBrand) => {
    // Brands that have a different handling based on the OS type (for mobile support)
    const OS_TYPE_SPECIFIC_BRANDS: (OnlineBrand | PatchedOnlineBrand)[] = [
        OnlineBrand.ALIPAY_PLUS_ONLINE,
        OnlineBrand.ALIPAY_ONLINE,
    ];

    const useOsType = OS_TYPE_SPECIFIC_BRANDS.includes(brand) && isMobile();
    const osType = useOsType ? (isIOS() ? OSType.IOS : OSType.ANDROID) : undefined;

    const baseHttpMethod = [OnlineBrand.PAY_PAY_ONLINE, PatchedOnlineBrand.D_BARAI_ONLINE].includes(brand)
        ? OnlineCallMethod.HTTP_POST
        : OnlineCallMethod.HTTP_GET;

    // TODO: Use enum after SDK update
    return { callMethod: osType ? ("http_get_mobile" as OnlineCallMethod) : baseHttpMethod, osType };
};

export const detectExternalScripts = () => window.performance.getEntriesByType("resource");

export const detectUnknownExternalScripts = (whiteListedHosts = []) =>
    detectExternalScripts()
        .filter(({ name }) => !whiteListedHosts.includes(new URL(name).host))
        .reduce((acc, request) => {
            const host = new URL(request.name).host;
            acc[host] = (acc[host] || []).concat(request);

            return acc;
        }, {});
