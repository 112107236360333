export const COMMON_BUTTONS_BACK = "COMMON_BUTTONS_BACK";
export const COMMON_BUTTONS_CLOSE = "COMMON_BUTTONS_CLOSE";
export const COMMON_BUTTONS_NEXT = "COMMON_BUTTONS_NEXT";
export const COMMON_BUTTONS_PREVIOUS = "COMMON_BUTTONS_PREVIOUS";
export const COMMON_BUTTONS_FINISH = "COMMON_BUTTONS_FINISH";
export const COMMON_BUTTONS_SUBMIT = "COMMON_BUTTONS_SUBMIT";
export const COMMON_TOTAL = "COMMON_TOTAL";
export const COMMON_PAY_WITH = "COMMON_PAY_WITH";

export const COMMON_OPTIONAL = "COMMON_OPTIONAL";
export const COMMON_REQUIRED = "COMMON_REQUIRED";

export const PRIVACY_LINK = "PRIVACY_LINK";

export const COMMON_COMMA = "COMMON_COMMA";

export const COMMON_HEADER_EXTERNAL_FEES = "COMMON_HEADER_EXTERNAL_FEES";

export const COMMON_INPUT_SELECT_PLACEHOLDER = "COMMON_INPUT_SELECT_PLACEHOLDER";
