export const ERROR_CHECKOUT_SUBSCRIPTION = "ERROR_CHECKOUT_SUBSCRIPTION";

export class SubscriptionError extends Error {
    code: string = ERROR_CHECKOUT_SUBSCRIPTION;
    failedSubscriptionId: string = null;

    constructor(failedSubscriptionId?: string, message?: string) {
        super(message);
        this.failedSubscriptionId = failedSubscriptionId;
    }
}
