export const BANK_TRANSFER_INSTRUCTION = "BANK_TRANSFER_INSTRUCTION";
export const BANK_TRANSFER_TOKEN_INSTRUCTION = "BANK_TRANSFER_TOKEN_INSTRUCTION";
export const BANK_TRANSFER_BANK_NAME = "BANK_TRANSFER_BANK_NAME";
export const BANK_TRANSFER_BANK_CODE = "BANK_TRANSFER_BANK_CODE";
export const BANK_TRANSFER_BRANCH_NAME = "BANK_TRANSFER_BRANCH_NAME";
export const BANK_TRANSFER_BRANCH_CODE = "BANK_TRANSFER_BRANCH_CODE";
export const BANK_TRANSFER_ACCOUNT_NUMBER = "BANK_TRANSFER_ACCOUNT_NUMBER";
export const BANK_TRANSFER_ACCOUNT_NAME = "BANK_TRANSFER_ACCOUNT_NAME";
export const BANK_TRANSFER_AMOUNT = "BANK_TRANSFER_AMOUNT";
export const BANK_TRANSFER_DEADLINE = "BANK_TRANSFER_DEADLINE";

// Subscriptions
export const BANK_TRANSFER_FIRST_PAYMENT = "BANK_TRANSFER_FIRST_PAYMENT";
export const BANK_TRANSFER_FIRST_DEADLINE = "BANK_TRANSFER_FIRST_DEADLINE";
export const BANK_TRANSFER_NEXT_PAYMENT = "BANK_TRANSFER_NEXT_PAYMENT";
export const BANK_TRANSFER_NEXT_DEADLINE = "BANK_TRANSFER_NEXT_DEADLINE";
