import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "下記の銀行口座にお振込みください",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION]: "お支払先口座情報の確認はお手続き完了メールからご確認いただくか、ご利用のショップ様へお問い合わせください",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "金融機関名",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "金融機関コード",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "支店名",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "支店コード",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "口座番号",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "口座名義",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "振込金額",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "振込期限",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "初回振込金額",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "初回振込期限",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "次回振込金額",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "次回振込期限",
};

export default LABELS;
