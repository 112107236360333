export const ERROR_CHECKOUT_TOKEN = "ERROR_CHECKOUT_TOKEN";

export class TokenError extends Error {
    code: string = ERROR_CHECKOUT_TOKEN;
    failedTokenId: string = null;
    failedChargeId?: string = null;
    storeId: string = null;

    constructor(failedTokenId: string, failedChargeId: string, storeId: string, message: string) {
        super(message);
        this.failedTokenId = failedTokenId;
        this.failedChargeId = failedChargeId;
        this.storeId = storeId;
    }
}
