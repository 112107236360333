import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "選擇支付方式",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "支付方式",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "支付方式: {method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "顧客詳細信息",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "輸入確認碼",

    [FORM_LABELS.FORM_FIELDS_NAME]: "山田 太郎",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "yamada@univapay.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "電話號碼",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "電子郵件",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "CVV",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "有效期限(MM/YY)",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "有效期限",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "持卡人英文姓名",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "卡付款",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "便利店付款",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "Paidy付款",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "Online",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "銀行轉賬",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_D_BARAI]: "DBarai",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "PayPay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+ (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE]: "DBarai (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "PayPay (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat (test only)",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "城市",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "國家",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "地址",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "小區名・門牌號",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "姓名",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "姓名 (假名)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "地區",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "郵政編碼",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "卡號",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "持卡人英文姓名",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "123",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "安全碼",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "月",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "年",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "張 三",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "便利店",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "選擇便利店",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "同意保存個人信息",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "選擇卡",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "新卡",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "刪除卡信息",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "選擇便利店",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "其他便利店",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "刪除便利店信息",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "選擇之前使用的數據:",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "新建",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "刪除數據",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "卡號",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "請查看您的電子郵件或短信中的驗證碼並在此輸入。",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "支付",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "全額",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20次",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24次",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "循環支付",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "{brand}不支援分期付款。",
};

export default LABELS;
