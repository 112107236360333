export const PAYMENT_BUTTONS_PAY = "PAYMENT_BUTTONS_PAY";
export const PAYMENT_BUTTONS_PAY_NO_AMOUNT = "PAYMENT_BUTTONS_PAY_NO_AMOUNT";
export const PAYMENT_BUTTONS_ONLINE_WITH_LOGO = "PAYMENT_BUTTONS_ONLINE_WITH_LOGO";

export const PAYMENT_DATA_CARD_TITLE = "PAYMENT_DATA_CARD_TITLE";
export const PAYMENT_DATA_CARD_LOCAL_CARDS = "PAYMENT_DATA_CARD_LOCAL_CARDS";

export const PAYMENT_DATA_KONBINI_TITLE = "PAYMENT_DATA_KONBINI_TITLE";
export const PAYMENT_DATA_KONBINI_PAYMENT_SELECT = "PAYMENT_DATA_KONBINI_PAYMENT_SELECT";

export const PAYMENT_ALERT_PROCESSING = "PAYMENT_ALERT_PROCESSING";
export const PAYMENT_ALERT_SUCCESS = "PAYMENT_ALERT_SUCCESS";
export const PAYMENT_ALERT_SUBSCRIPTION_SUCCESS = "PAYMENT_ALERT_SUBSCRIPTION_SUCCESS";
export const PAYMENT_ALERT_TOKEN_SUCCESS = "PAYMENT_ALERT_TOKEN_SUCCESS";
export const PAYMENT_ALERT_KONBINI_SUCCESS = "PAYMENT_ALERT_KONBINI_SUCCESS";
export const PAYMENT_ALERT_ERROR = "PAYMENT_ALERT_ERROR";
export const PAYMENT_TIMEOUT = "PAYMENT_TIMEOUT";

export const PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS = "PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS";
export const PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING = "PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING";

// Pending form (Processing timeout)
export const TRANSACTION_TIMEOUT_MESSAGE = "TRANSACTION_TIMEOUT_MESSAGE";
export const SUPPORT_CENTER_FIRST_LINE = "SUPPORT_CENTER_FIRST_LINE";
export const SUPPORT_CENTER_SECOND_LINE = "SUPPORT_CENTER_SECOND_LINE";
export const SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN = "SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN";
export const SUPPORT_PHONE_NUMBER_WITHIN_JAPAN = "SUPPORT_PHONE_NUMBER_WITHIN_JAPAN";
export const SUPPORT_EMAIL = "SUPPORT_EMAIL";

// Review
export const PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR = "PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR";
export const PAYMENT_REVIEW_EXPIRY = "PAYMENT_REVIEW_EXPIRY";
