import { SCRIPT_NAME } from "../../common/constants";
import { extractHostname } from "../../common/utils/url";

let checkoutBaseUrl = CHECKOUT_URL;
let checkoutUrl = CHECKOUT_URL;

const checkoutSrc =
    (document.currentScript as HTMLScriptElement)?.src ||
    document.querySelector(`script[src$="${SCRIPT_NAME}"]`)?.getAttribute("src") ||
    undefined;

if (checkoutSrc) {
    const a = document.createElement("a");
    a.href = checkoutSrc;

    const domain = extractHostname(checkoutSrc);

    // Removing the SRI from the URL to ensure we always load the latest checkout to prevent merchant
    // from using a older version that may contain bugs
    checkoutBaseUrl = `${a.protocol}//${domain?.replace("-sri", "")}:${a.port}`;
    checkoutUrl = `${checkoutBaseUrl}/checkout/index.html`;
}

export const BASE_CHECKOUT_URL = checkoutBaseUrl;
export const FULL_CHECKOUT_URL = checkoutUrl;
