import * as SUBSCRIPTIONS_LABELS from "../labels/subscriptions";

export const LABELS: Record<string, string> = {
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_BUTTONS_PAY]: "Оплата {period} {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD]: "{money} Платёж за {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INITIAL]: "Первоначальный платёж {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY]: "Одноразовый платёж {period} {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD]: "Регулярный платёж {money} периодичность {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_INSTALLMENT]: "Последующий платёж {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_START]: "Следующая дата выставления счетов: {date}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_CVV_AUTH]: "Добавление карты (в первый раз)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_DAILY]: "Ежедневно",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_WEEKLY]: "Еженедельно",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIWEEKLY]: "Ежедвухнедельно",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_MONTHLY]: "Ежемесячно",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIMONTHLY]: "Каждые 2 месяца",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_QUARTERLY]: "Ежеквартально",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_SEMIANNUALLY]: "Ежеполугодно",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_ANNUALLY]: "Ежегодно",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY]: "Регулярный платеж (ежедневный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY]: "Регулярный платеж (еженедельный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY]: "Регулярный платеж (ежедвухнедельный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY]: "Регулярный платеж (ежемесячный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY]: "Регулярный платеж (ежеквартальный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY]: "Регулярный платеж (ежеполугодный)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY]: "Регулярный платеж (ежегодный)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT]: "Первоначальная сумма: {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE]: "Оплата в рассрочку",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT]: "Первоначальная сумма {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES]: "Оплатить {money} за {cycles} раз",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING]: "{money} (rovolving)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL]: "Платёж {period} {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL]: "Платёж {period} {money}, далее",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL]: "Оплатить {money} за {cycles} раз",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES]: "Затем оплатить {money} за {cycles} раз",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL]: "Затем оплатить {money} за {cycles} раз",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS]: "Автоматическое время выставления счетов: {cycles} раз",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_CYCLES]: "периоды",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_START]: "Первый взнос",
};

export default LABELS;
