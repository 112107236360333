/**
 *  @module Resources/CheckoutInfoBrand
 */

import { PatchedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { PatchedCardBrand } from "common/types";
import {
    AuthParams,
    BankTransferBrand,
    ConvenienceStore,
    CRUDResource,
    HTTPMethod,
    OnlineBrand,
    OnlineCallMethod,
    SendData,
} from "univapay-node";

import { redirectInIFrame } from "../../../common/utils/redirect";
import { store } from "../redux/store";

/**
 * TODO: Remove this file when updating SDKs major versions
 */
export enum OSType {
    IOS = "ios",
    ANDROID = "android",
}

export type CheckoutInfoBrandPayload = {
    amount: number;
    currency: string;
    callMethod: OnlineCallMethod | "http_get_mobile";

    /**
     * Required when callMethod is "app", "sdk" or "http_get_mobile". Leave empty for the rest
     */
    osType?: OSType;
};

export type CheckoutInfoBrandItemBrand = {
    brandName: string;
    brandDisplayName: string;
    extras: {
        logos?: {
            logoName: string;
            logoUrl: string;
            logoPattern: string;
            logoWidth: string;
            logoHeight: string;
        }[];
        promoNames?: string[];
    };
};

export type CheckoutInfoBrandItem = {
    service: string;
    serviceName: string;
    brands: CheckoutInfoBrandItemBrand[];
};

// TODO (fees): Replace return type when API is ready
export type PaymentMethodBrand =
    | "paidy"
    | OnlineBrand
    | PatchedOnlineBrand
    | PatchedCardBrand
    | ConvenienceStore
    | BankTransferBrand;
export type ExternalFees = Record<PaymentMethodBrand, number>;

export class CheckoutInfoBrand extends CRUDResource {
    get(
        brand: OnlineBrand | PatchedOnlineBrand,
        data?: SendData<CheckoutInfoBrandPayload>,
        auth?: AuthParams
    ): Promise<CheckoutInfoBrandItem> {
        return this.defineRoute(HTTPMethod.POST, "/checkout_info/gateways/:brand")(data, undefined, auth, { brand });
    }

    async getWeChatAuthCode({ appId }: { appId: string }): Promise<{ code: string }> {
        return redirectInIFrame<{ code: string }>({
            search: { weChatAppId: appId },
            promise: new Promise<{ code: string }>((resolve) =>
                window.addEventListener("message", (event) => {
                    if (event.data.type === "WECHAT_REDIRECT") {
                        console.info(event);
                        resolve(event.data);
                    }
                })
            ),
            locale: store.getState().intl.locale,
        });
    }

    getWeChatOpenId(
        data?: SendData<{ authorizationCode: string }>,
        auth?: AuthParams
    ): Promise<{ appId: string; openId: string }> {
        return this.defineRoute(HTTPMethod.POST, `/checkout_info/gateways/${OnlineBrand.WE_CHAT_ONLINE}`)(
            data,
            undefined,
            auth
        );
    }

    getExternalFees(data?: SendData<void>, auth?: AuthParams): Promise<ExternalFees> {
        // TODO (fees): Replace route when API is ready
        return this.defineRoute(HTTPMethod.GET, "/checkout_info/external_fees")(data, undefined, auth);
    }
}
