import { onlineTestBrands, PatchedOnlineBrand, SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { NewCardBrand } from "common/types";
import { CardBrand, ConvenienceStore, OnlineBrand, PaymentType } from "univapay-node";

import { PatchedSupportedBrand } from "../redux/models/configuration";

export const isOnlineBrand = (type): type is SupportedOnlineBrand =>
    Object.values(OnlineBrand).includes(type) ||
    onlineTestBrands.includes(type) ||
    Object.values(OnlineBrand).includes(type) ||
    Object.values(PatchedOnlineBrand).includes(type);

export const isPaymentType = (type): type is PaymentType => Object.values(PaymentType).includes(type);

export const isKonbiniBrand = (type): type is ConvenienceStore => Object.values(ConvenienceStore).includes(type);

export const isCardBrand = (type): type is CardBrand => Object.values({ ...CardBrand, ...NewCardBrand }).includes(type);

export const getSupportedKonbiniBrands = (supportedBrands: PatchedSupportedBrand[]) => {
    const supportedKonbiniBrands: ConvenienceStore[] = supportedBrands.reduce((acc, { brand }) => {
        if (isKonbiniBrand(brand) && !acc.includes(brand)) {
            acc.push(brand);
        }

        return acc;
    }, []);

    return supportedKonbiniBrands;
};

export const getSupportedCardBrands = (
    supportedBrands: PatchedSupportedBrand[],
    onlyDirectCurrency?: boolean,
    currency?: string
) => {
    const supportedCardBrands: CardBrand[] = supportedBrands.reduce((acc, { brand, supportedCurrencies }) => {
        const isCurrencySupportedByBrand =
            !currency ||
            !onlyDirectCurrency ||
            !supportedCurrencies.length ||
            supportedCurrencies.includes(currency.toUpperCase());

        if (isCardBrand(brand) && !acc.includes(brand) && isCurrencySupportedByBrand) {
            acc.push(brand);
        }

        return acc;
    }, []);

    return supportedCardBrands;
};
