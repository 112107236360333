import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "The :attribute must be a list.",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "The :attribute field must be between :min and :max.",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "The :attribute must be boolean value.",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "The :attribute is not a valid date format.",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "The :attribute and :different must be different.",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "The :attribute format is invalid.",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "The :attribute may not be greater than :max.",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "The :attribute may not be greater than :max characters.",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "The :attribute must be at least :min.",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "The :attribute must be at least :min characters.",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "The selected :attribute is invalid.",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "The :attribute must be a number.",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: "The :attribute format is invalid.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "The :attribute field is required.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: "The :attribute field is required when :other is :value.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: "The :attribute field is required when :field is not empty.",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "The :attribute and :same fields must match.",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "The :attribute must be a string.",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "The :attribute format is invalid.",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name} is a required field",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name} must be a valid email",
    [VALIDATION_LABELS.VALIDATION_BANNED_EMAIL]: "Email banned",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name} must be a valid phone number",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name} must be a valid card number",
    [VALIDATION_LABELS.VALIDATION_ZIP]: "{name} must be a valid zip code",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name} must be a valid date",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "Card has expired",
    [VALIDATION_LABELS.VALIDATION_CVV_LENGTH]: "CVV must be a 3 digits number",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "This is a required field",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name} contains invalid characters",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name} does not have a valid length",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name} must contain at least one blank space",
};

export default LABELS;
