import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

enum NotSelectedReason {
    DescriptorNotSupported = "descriptor_not_supported",
    GatewayAlreadyUsed = "gateway_already_used",
    ChargeWithoutCVVNotSupported = "charge_without_cvv_not_supported",
    CardBrandNotSupported = "card_brand_not_supported",
    CountryNotSupported = "country_not_supported",
    ModeNotSupported = "mode_not_supported",
    AuthCaptureNotSupported = "auth_capture_not_supported",
    CvvAuthNotSupported = "cvv_auth_not_supported",
    CustomerIpAddressMissing = "customer_ip_address_missing",
    PaymentTypeNotSupported = "payment_type_not_supported",
    NotActive = "not_active",
    GatewayNotAvailable = "gateway_not_available",
    GatewayNotSupported = "gateway_not_supported",
    PlatformNotSupported = "platform_not_supported",
    MerchantNotSupported = "merchant_not_supported",
    StoreNotSupported = "store_not_supported",
    DoesNotMatchRequestedCurrency = "does_not_match_requested_currency",
    RateLimited = "rate_limited",
    GatewayConfigNotFound = "gateway_config_not_found",
    LastNameRequired = "last_name_required",
    NotChosenGatewayForQRCode = "not_chosen_gateway_for_qr_code",
    NotChosenGateway = "not_chosen_gateway",
    CallMethodNotSupported = "call_method_not_supported",
    OSTypeNotSpecified = "os_type_not_specified",
    SubscriptionPlanValidationFailed = "subscription_plan_validation_failed",
    InstallmentCapableNotMatching = "installment_capable_not_matching",
    CardBankIssuerNotSupported = "card_bank_issuer_not_supported",
    CardBinNotSupported = "card_bin_not_supported",
    CurrencyNotSupported = "currency_not_supported",
    AuthCaptureSupportNotMatching = "auth_capture_support_not_matching",
    NotOnWhitelist = "not_on_whitelist",
    BlacklistedTagOnMerchant = "blacklisted_tag_on_merchant",
    BlacklistedTagOnStore = "blacklisted_tag_on_store",
    ForbiddenGateway = "forbidden_gateway",
    ForbiddenCredential = "forbidden_credential",
    CannotShareMerchantCredentials = "cannot_share_merchant_credentials",
    CannotShareStoreCredentials = "cannot_share_store_credentials",
    PlatformCredentialsDisabled = "platform_credentials_disabled",
    TaggedPlatformCredentialsDisabled = "tagged_platform_credentials_disabled",
}

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "エラー",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "原始應用程序無法連接到Checkout。",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "參數有誤，Checkout無法初始化。",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "通信失敗，請查看通信狀況，重新再試。",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "Timeout error",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "發生超時",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "發生錯誤。請稍後再試。",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "該賬號沒有使用自動付款Token的權限。",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "需要自動付款Token的使用限制。",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "不支持該卡種。",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "Checkout無法在此域名執行。",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "Could not create the transaction token.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "Could not create the transaction token with CVV data.",
    [ERRORS_LABELS.ERRORS_ALERTS_THREE_DS_FAILED]: "3-D Secure 身份驗證無法完成。",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "該服務不可使用",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "該服務暫時不可用。 請稍後再試。",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "借記卡和預付卡僅支持實際支付，不支持授權。",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "付款處理失敗。",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "自動付款處理失敗。",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "參數有誤。",
    [ERRORS_LABELS.PROCESSING_ERROR]: "Processing error",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "Charge could not be registered by Paidy.",
    [ERRORS_LABELS.EMAIL_EXISTS_FOR_CARD]: "您輸入的電子郵件與此卡關聯的郵件不匹配",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "由於經常出現付款失敗，該服務在一段時間內無法使用。",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "付款處理失敗。",
    [ERRORS_LABELS.NO_GATEWAY_AVAILABLE_TO_PROCESS_REQUEST]: "您連接的系統不支援此請求。",

    /* request */
    [RequestErrorCode.RequestError]: "請求無效",

    /* generic */
    [ResponseErrorCode.BadRequest]: "請求有誤",
    [ResponseErrorCode.Forbidden]: "操作禁止",
    [ResponseErrorCode.NotFound]: "找不到資源",
    [ResponseErrorCode.NotAllowed]: "操作不允許",
    [ResponseErrorCode.Conflicted]: "資源與其他資源衝突",
    [ResponseErrorCode.TooManyRequests]: "API請求過多",
    [ResponseErrorCode.InternalServerError]: "內部服務器錯誤",
    [ResponseErrorCode.ServiceUnavailable]: "服務不可用",
    [ResponseErrorCode.NotAuthorized]: "未授權",

    /* global */
    [ResponseErrorCode.UnknownError]: "發生錯誤。",
    [ResponseErrorCode.Timeout]: "超時錯誤",
    [ResponseErrorCode.DBError]: "數據庫錯誤",
    [ResponseErrorCode.InvalidRequest]: "請求無效",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "無法獲得冪等結果",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "服務不可用請重試",

    /* auth */
    [ResponseErrorCode.InvalidDomain]: "域名無效",
    [ResponseErrorCode.InvalidCredentials]: "賬號或密碼錯誤",
    [ResponseErrorCode.AuthHeaderMissing]: "缺少授權Header",
    [ResponseErrorCode.InvalidPermissions]: "您沒有訪問此資源所需的權限。",
    [ResponseErrorCode.ImproperAuth]: "授權不當",
    [ResponseErrorCode.CouldNotRefreshAuth]: "無法刷新授權令牌",
    [ResponseErrorCode.UserBanned]: "此用戶已被禁止。",
    [ResponseErrorCode.InvalidLoginToken]: "登錄令牌無效",
    [ResponseErrorCode.InvalidAppToken]: "應用令牌無效",
    [ResponseErrorCode.ExpiredLoginToken]: "登錄令牌已過期",
    [ResponseErrorCode.OutdatedAppToken]: "應用令牌已過時",
    [ResponseErrorCode.ChargeTooQuick]: "不允許另外的支付請求",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "File upload error",
    [ResponseErrorCode.FileMaxSizeExceeded]: "File max size exceeded",
    [ResponseErrorCode.FileInvalidType]: "Invalid file type",
    [ResponseErrorCode.FileNotFound]: "File not found",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "Token generation exception",
    [ResponseErrorCode.TokenForWrongStore]: "Invalid token",
    [ResponseErrorCode.NonSubscriptionPayment]: "Non subscription payment",
    [ResponseErrorCode.BankAccountExists]: "Bank account exists",
    [ResponseErrorCode.EmailExists]: "Email exists",
    [ResponseErrorCode.StoreExists]: "Store exists",
    [ResponseErrorCode.GatewayCredentialsExists]: "Gateway credentials exists",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "Rule for merchant is not unique",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "Rule set for merchant is not unique",
    [ResponseErrorCode.NonUniqueRule]: "Rule is not unique",
    [ResponseErrorCode.RulePriorityMustBePositive]: "Rule priority must be positive",
    [ResponseErrorCode.NonExistingRule]: "Non existing rule",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "Must contain unique elements only",
    [ResponseErrorCode.WebhookURLExists]: "Webhook URL exists",
    [ResponseErrorCode.GroupExists]: "Group exists",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "Primary bank account not found",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "Must have a primary bank account",
    [ResponseErrorCode.VerificationDataExists]: "Verification data exists",
    [ResponseErrorCode.TemplateExists]: "Template exists",
    [ResponseErrorCode.StratusCredentialsExists]: "Stratus credentials exists",
    [ResponseErrorCode.StratusRuleStillInUse]: "Stratus rule still in use",
    [ResponseErrorCode.ResourceLimitReached]: "Resource limit reached",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "令牌類型無效",
    [ResponseErrorCode.InvalidToken]: "令牌無效",
    [ResponseErrorCode.InvalidCard]: "信用卡無效",
    [ResponseErrorCode.ForbiddenIP]: "IP地址禁止",
    [ResponseErrorCode.InvalidUserData]: "用戶數據無效",
    [ResponseErrorCode.NonUniqueActiveToken]: "活令牌不唯一",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "交易已處理",
    [ResponseErrorCode.TransactionTokenExpired]: "交易令牌已過期",
    [ResponseErrorCode.NoTestCardInLiveMode]: "測試卡不能用於生產環境",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "不允許循環令牌",
    [ResponseErrorCode.RecurringTokenDisabled]: "循環令牌已禁用",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "循環使用限制為必填項",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "循環用法需要CVV",
    [ResponseErrorCode.UsageLimitNotApplicable]: "使用限制不適用",
    [ResponseErrorCode.CardProcessingDisabled]: "卡處理已禁用",
    [ResponseErrorCode.QRProcessingDisabled]: "二維碼處理已禁用",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "便利店處理已禁用",
    [ResponseErrorCode.NotOneTimeToken]: "不是一次性令牌",
    [ResponseErrorCode.NotSubscriptionToken]: "不是訂閱令牌",
    [ResponseErrorCode.NotRecurringToken]: "不是循環令牌",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "貨幣必須和支付一致",
    [ResponseErrorCode.RefundNotWithinBounds]: "退款不在範圍內",
    [ResponseErrorCode.InvalidTransfer]: "無效轉賬",
    [ResponseErrorCode.TransferAlreadyExists]: "轉賬已存在",
    [ResponseErrorCode.NoLedgers]: "沒有分類賬",
    [ResponseErrorCode.FailedToAssociateLedgers]: "關聯分類賬失敗",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "未驗證時不能啟用生產模式",
    [ResponseErrorCode.SelfTransferNotPermitted]: "不允許自行轉賬",
    [ResponseErrorCode.CardLocked]: "信用卡被鎖",
    [ResponseErrorCode.SubscriptionProcessing]: "訂閱正在處理",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "無法更新交易令牌",
    [ResponseErrorCode.AlreadyCaptured]: "已經Capture",
    [ResponseErrorCode.CaptureAmountTooLarge]: "Capture金額太大",
    [ResponseErrorCode.PartialCaptureNotSupported]: "不支持部分Capture",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "借記授權已停用",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "預付授權已停用",

    [ResponseErrorCode.NoGatewaysAvailable]: "沒有可用的網關",
    [ResponseErrorCode.CardBrandNotSupported]: "不支持該信用卡類型",
    [ResponseErrorCode.CardCountryNotSupported]: "不支持該信用卡國家",
    [ResponseErrorCode.CVVRequired]: "CVV為必填項目",
    [ResponseErrorCode.LastNameRequired]: "姓為必填項目",
    [ResponseErrorCode.AuthNotSupported]: "不支持Auth",

    [ResponseErrorCode.InvalidBinRange]: "Bin範圍無效",
    [ResponseErrorCode.VerificationRequired]: "需要验证ID",

    /* validation responses */
    [ResponseErrorCode.ValidationError]: "驗證錯誤",
    [ResponseErrorCode.RequiredValue]: "值不能為空",
    [ResponseErrorCode.MustBeFutureTime]: "必須為將來時間",
    [ResponseErrorCode.InvalidFormat]: "格式無效",
    [ResponseErrorCode.InvalidPercentFee]: "百分比費用無效",
    [ResponseErrorCode.InvalidCardNumber]: "卡號無效",
    [ResponseErrorCode.InvalidCardExpiration]: "信用卡有效期限無效",
    [ResponseErrorCode.InvalidCVV]: "輸入 3 或 4 位數的安全代碼",
    [ResponseErrorCode.InvalidFormatLength]: "長度無效",
    [ResponseErrorCode.InvalidFormatUUID]: "UUID格式無效",
    [ResponseErrorCode.InvalidFormatBase64]: "base64格式無效",
    [ResponseErrorCode.InvalidFormatEmail]: "郵件地址無效",
    [ResponseErrorCode.InvalidFormatCurrency]: "貨幣無效",
    [ResponseErrorCode.InvalidCurrency]: "貨幣無效",
    [ResponseErrorCode.InvalidAmount]: "金額無效",
    [ResponseErrorCode.InvalidEventForStore]: "店鋪無效事件",
    [ResponseErrorCode.InvalidEventForPlatform]: "平台無效事件",
    [ResponseErrorCode.InvalidEventForMerchant]: "商戶無效事件",
    [ResponseErrorCode.InvalidFormatDomain]: "域名格式無效",
    [ResponseErrorCode.InvalidFormatUrl]: "URL格式無效",
    [ResponseErrorCode.InvalidFormatObject]: "對象格式無效",
    [ResponseErrorCode.InvalidFormatCountry]: "國家無效",
    [ResponseErrorCode.InvalidPhoneNumber]: "電話號碼無效",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "BIC/SWIFT代碼無效",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "路由號碼無效",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "路由碼無效",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "IFSC碼無效",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "銀行賬號無效",
    [ResponseErrorCode.InvalidPasswords]: "密碼無效",
    [ResponseErrorCode.InvalidTimePeriod]: "時間段無效",
    [ResponseErrorCode.InvalidDayOfWeek]: "無效的星期幾",
    [ResponseErrorCode.InvalidWeekOfMonth]: "一個月中的無效星期",
    [ResponseErrorCode.InvalidDayOfMonth]: "一個月中的無效日期",
    [ResponseErrorCode.InvalidColorsSize]: "顏色無效",
    [ResponseErrorCode.NestedJsonNotAllowed]: "不允許嵌套JSON",
    [ResponseErrorCode.InvalidFormatDate]: "日期格式無效",
    [ResponseErrorCode.InvalidChargeStatus]: "支付狀態無效",
    [ResponseErrorCode.InvalidQRScanGateway]: "二維碼掃描網關無效",
    [ResponseErrorCode.CardLimitExceededForStore]: "超過店鋪信用卡限制",
    [ResponseErrorCode.InvalidLanguage]: "語言無效",
    [ResponseErrorCode.SubscriptionNotAllowed]: "不允許訂閱",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "只允許使用一次令牌",
    [ResponseErrorCode.AuthExpired]: "授權已過期",
    [ResponseErrorCode.InvalidTemplateKey]: "模板鍵值無效",
    [ResponseErrorCode.NonPublicTemplate]: "非公開模板",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "只允許使用日本電話號碼",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "有效期限超出範圍",
    [ResponseErrorCode.UnsupportedLanguage]: "不支持的語言",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "不支持默認語言",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "Capture 僅允許用於信用卡付款",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "Capture的卡類型無效",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "預定capture日期無效",
    [ResponseErrorCode.IncoherentDateRange]: "日期範圍不一致",
    [ResponseErrorCode.InvalidMerchantStatus]: "商戶狀態無效",
    [ResponseErrorCode.MustHaveOneElement]: "必須至少有一個元素",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "必須至少有一個元素",
    [ResponseErrorCode.ThresholdMustBeNull]: "閾值必須為空",
    [ResponseErrorCode.IllegalNumberOfItems]: "項目個數不合法",
    [ResponseErrorCode.UnableToReadCredentials]: "無法讀取credentials",
    [ResponseErrorCode.GatewayError]: "網關錯誤",
    [ResponseErrorCode.GatewayNoLongerSupported]: "不再支持的網關",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "無效的支付額度限制",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "CVV 金額必須包含平台貨幣",
    [ResponseErrorCode.TransferScheduleWaitPeriodAndPeriodRequired]: "需要轉賬日程等待時間和所需時間",
    [ResponseErrorCode.ChargeAmountTooLow]: "支付金額過低",
    [ResponseErrorCode.ChargeAmountTooHigh]: "支付金額過高",
    [ResponseErrorCode.MustContainPlatformCurrency]: "平台貨幣為必填項",

    /* Used when creating a new Merchant */
    [ResponseErrorCode.OnlyASCII]: "Only ASCII characters are supported",
    [ResponseErrorCode.UniqueCharacters]: "Unique characters",
    [ResponseErrorCode.AtLeastOneDigit]: "Must contain at least one digit",
    [ResponseErrorCode.AtLeastOneLetter]: "Must contain at least one letter",
    [ResponseErrorCode.EmptyRoles]: "Cannot have empty roles",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "Editing own roles not allowed",
    [ResponseErrorCode.InvalidCardBrand]: "The supplied card brand is not supported",
    [ResponseErrorCode.UnsupportedCountry]: "The supplied country is not supported",
    [ResponseErrorCode.UnsupportedCurrency]: "The supplied currency is not supported",
    [ResponseErrorCode.CannotBanSelf]: "Cannot ban self",
    [ResponseErrorCode.CannotSelfTerminate]: "Cannot self terminate",
    [ResponseErrorCode.NoDuplicateCurrencies]: "No currency duplicates allowed",

    [ResponseErrorCode.PlatformNotFound]: "Platform not found",
    [ResponseErrorCode.InvalidPlatform]: "Invalid platform",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "Invalid invoice fee configuration",
    [ResponseErrorCode.InvalidPlatformRole]: "Invalid platform role",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "Date not submitted",
    [ResponseErrorCode.NoBankAccount]: "No bank account",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "Percent fee not submitted",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "Insufficient system manager info",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "Credentials exist",
    [ResponseErrorCode.InvalidMerchantCategoryCode]: "Invalid merchant category code",
    [ResponseErrorCode.GatewayConfigurationRequired]: "Gateway configuration required",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "Paidy credentials already created",

    /* Gateway simulation */
    [ResponseErrorCode.PlatformCredentialsDisabled]: "Platform credential disabled",
    [ResponseErrorCode.ForbiddenGateway]: "Forbidden gateway",
    [ResponseErrorCode.ForbiddenQrGateway]: "Forbidden QR gateway",
    [ResponseErrorCode.BlacklistedTagOnMerchant]: "Blacklisted tag on merchant",
    [ResponseErrorCode.BlacklistedTagOnStore]: "Blacklisted tag on store",
    [ResponseErrorCode.NotOnWhitelist]: "Not on whitelist",
    [ResponseErrorCode.CannotShareMerchantCredentials]: "Cannot share merchant credentials",
    [ResponseErrorCode.MissingGatewayConfiguration]: "Missing gateway configuration",
    [ResponseErrorCode.GatewayConfigNotFound]: "Gateway configuration not found",
    [ResponseErrorCode.NotActive]: "Not active",
    [ResponseErrorCode.ModeNotSupported]: "Mode not supported",
    [ResponseErrorCode.TaggedPlatformCredentialsDisabled]: "Tagged platform credentials disabled",
    [ResponseErrorCode.NoStratusConfiguration]: "No stratus configuration",

    /* Refund */
    [ResponseErrorCode.RefundExceedsChargeAmount]: "Refund exceeds charge amount",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "Cannot refund unsuccessful charge",
    [ResponseErrorCode.RefundNotAllowed]: "Refund not allowed",
    [ResponseErrorCode.PartialRefundNotSupported]: "Partial refund not supported",
    [ResponseErrorCode.CancelNotAllowed]: "Cancel not allowed",
    [ResponseErrorCode.RefundPercentageExceeded]: "Refund percentage exceeded",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay not enabled",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay already enabled",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Apple Pay certificate already updated",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "Apple Pay unsupported algorithm",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Apple Pay certificate not found",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay unable to generate certificate request",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay invalid configuration",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay invalid certificate",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay invalid algorithm",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay invalid certificate format",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay invalid signature",
    [ResponseErrorCode.ApplePayError]: "Apple Pay error",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "卡號錯誤",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "卡片有效期限月份無效",
    [ResponseErrorCode.CardExpirationYearInvalid]: "卡片有效期限年份無效",
    [ResponseErrorCode.CardExpired]: "卡片已過期",
    [ResponseErrorCode.CardCVVInvalid]: "安全碼錯誤",
    [ResponseErrorCode.CardRejected]: "認證審核錯誤（請聯絡您的發卡機構）",
    [ResponseErrorCode.CardInvalid]: "卡無效",
    [ResponseErrorCode.ChargeInvalidData]: "請求資料無效。",
    [ResponseErrorCode.TooManyChargeRequests]: "短時間內對同一張卡的收費請求過多。請稍後再試。",
    [ResponseErrorCode.CancelUnavailable]: "此費用無法取消",
    [ResponseErrorCode.ChargeExpired]: "帳單過期",
    [ResponseErrorCode.SeizeCard]: "身份驗證篩選錯誤（卡片遺失/被盜/無效/受限等）",
    [ResponseErrorCode.ContactBank]: "認證審核錯誤（請聯絡您的發卡機構）",
    [ResponseErrorCode.FraudSuspected]: "認證篩選錯誤（疑似未經授權使用/安全限制）",
    [ResponseErrorCode.BankSystemFailure]: "卡公司側交易錯誤",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "不支援動態描述符",
    [ResponseErrorCode.PaymentCodeInvalid]: "條碼/二維碼無效",
    [ResponseErrorCode.PaymentCodeExpired]: "條碼/二維碼已過期",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "條碼/QR 碼已在使用",
    [ResponseErrorCode.PaymentCodeStillInUse]: "目前正在使用條碼/QR 碼",
    [ResponseErrorCode.RejectedHighRisk]: "因超出高風險閾值而被拒絕",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "驗證期已結束",
    [ResponseErrorCode.RevertFailed]: "返回失敗。需要手動操作",
    [ResponseErrorCode.RefundFailed]: "退款失敗",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "客戶資金不足",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "元資料欄位值無效或缺失",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "不允許跨境交易。我沒有身分證",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "不允許跨境交易。沒有電話號碼",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "不允許跨境交易。不接受付款方式",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "不允許跨境交易。無名",
    [ResponseErrorCode.LimitExceededForPaymentType]: "已超出您的付款方式的交易限額",
    [ResponseErrorCode.LimitExceededForMerchant]: "帳戶交易超出限額",
    [ResponseErrorCode.TransactionNotFound]: "沒有找到優惠",
    [ResponseErrorCode.DuplicateTransaction]: "重複交易",
    [ResponseErrorCode.PaymentWalletRejected]: "您的支付錢包被連接的系統拒絕",
    [ResponseErrorCode.InsufficientMerchantInformation]: "您的帳戶缺少所連接系統所需的信息",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "不允許跨境交易。不可接受的貨幣",
    [ResponseErrorCode.GatewayServerError]: "連線目標系統錯誤（請聯絡支援中心。0570-035-672）",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "由於系統連接原因，所選付款暫時無法使用",
    [ResponseErrorCode.PaymentCanceled]: "付款已取消",
    [ResponseErrorCode.ExceededPendingThreshold]: "花了很長時間才開始付款程序。",
    [ResponseErrorCode.InternalError]: "內部系統錯誤",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "Unable to generate certificate request",
    [ResponseErrorCode.InvalidMerchantSettings]: "Invalid merchant settings",
    [ResponseErrorCode.UnsupportedAlgorithm]: "Unsupported algorithm",
    [ResponseErrorCode.InvalidPaymentToken]: "Invalid payment token",
    [ResponseErrorCode.ExpiredPaymentToken]: "Expired payment token",
    [ResponseErrorCode.InvalidCertificate]: "Invalid certificate",
    [ResponseErrorCode.InvalidLeafCertificate]: "Invalid leaf certificate",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "Invalid intermediate cerificate",
    [ResponseErrorCode.InvalidChainOfTrust]: "Invalid chain of trust",
    [ResponseErrorCode.InvalidSignature]: "Invalid signature",

    /** Platform */
    [ResponseErrorCode.PlatformKeyExists]: "Domain already in use",
    [ResponseErrorCode.MerchantConsoleURIExists]: "Merchant console URI exists",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "Idempotency key conflict",

    /* BannedCards */
    [ResponseErrorCode.CardBanned]: "Card banned",
    [ResponseErrorCode.CardAlreadyBanned]: "Card already banned",
    [ResponseErrorCode.CardNotBanned]: "Card not banned",
    [ResponseErrorCode.TestCardCannotBeBanned]: "TestCard cannot be banned",

    /* Stats */
    [ResponseErrorCode.InvalidMetric]: "Invalid metric",
    [ResponseErrorCode.InvalidResource]: "Invalid resource",

    /* Installments */
    [ResponseErrorCode.UseStartOn]: "Use Start on",
    [ResponseErrorCode.PaymentInTimePeriod]: "Payment in time period",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "Second charge already made",
    [ResponseErrorCode.NotSupportedByProcessor]: "Not supported by processor",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "Subscription already canceled",
    [ResponseErrorCode.SubscriptionNotFound]: "Subscription not found",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "Must be lower than full amount",
    [ResponseErrorCode.InstallmentPlanNotFound]: "Installment plan not found",
    [ResponseErrorCode.InstallmentAlreadySet]: "Installment already set",
    [ResponseErrorCode.InstallmentInvalidPlan]: "Invalid installment plan",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "Invalid installment plan type",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "InstallmentPaymentType is not allowed for plan",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "Invalid installment initial amount",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "Installment max payout period exceeded",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "Insufficient installment amount per charge",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "Installment revolving plan cannot set initial amount",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "Installment revolving plan cannot set subsequent cycles start",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "Installment processor initial amounts is not supported",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "Installment processor period is not supported",
    [ResponseErrorCode.CannotChangeToken]: "Cannot change token",
    [ResponseErrorCode.InstallmentsNotEnabled]: "Installments not enabled",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "Cannot change installment amount",
    [ResponseErrorCode.CannotChangeToInstallment]: "Cannot change to installment",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "Must be month based to set",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "Must be within two cycles",
    [ResponseErrorCode.Deprecated]: "Deprecated",
    [ResponseErrorCode.CannotBeZero]: "Cannot be zero",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "Need at least two cycles",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "Invalid installment cycles count",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "Cannot set initial amount to zero",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "Cannot set until subscription started",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "Cannot set after subscription started",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "Cannot change canceled subscription",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "Stratus merchant already exists",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "Stratus store already exists",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "Stratus store allocation limit reached",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "Invalid elastic index",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "Invalid date histogram interval",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "Invalid SQS endpoint",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "未找到 BIN",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhn 檢查失敗",
    [ResponseErrorCode.InvalidCardNumberLength]: "卡號長度不對",
    [ResponseErrorCode.CardPaymentDisabled]: "信用卡支付被禁用",
    [ResponseErrorCode.DebitDisabled]: "借記卡已停用",
    [ResponseErrorCode.PrepaidDisabled]: "預付卡已禁用",
    [ResponseErrorCode.CountryNotSupported]: "不支持卡的國家",
    [ResponseErrorCode.Unspecified]: "無效卡",

    [ResponseErrorCode.InvalidJapanesePostalCode]: "日本郵編不正確",

    [NotSelectedReason.DescriptorNotSupported]: "不支持描述符",
    [NotSelectedReason.GatewayAlreadyUsed]: "網關已被使用",
    [NotSelectedReason.ChargeWithoutCVVNotSupported]: "不支持無CVV的支付",
    [NotSelectedReason.CardBrandNotSupported]: "不支持該卡類別",
    [NotSelectedReason.CountryNotSupported]: "不支持該國家",
    [NotSelectedReason.ModeNotSupported]: "不支持該模式",
    [NotSelectedReason.AuthCaptureNotSupported]: "不支持Auth capture",
    [NotSelectedReason.CvvAuthNotSupported]: "不支持CVV驗證",
    [NotSelectedReason.CustomerIpAddressMissing]: "缺少用戶IP地址",
    [NotSelectedReason.PaymentTypeNotSupported]: "不支持該支付類型",
    [NotSelectedReason.NotActive]: "未激活",
    [NotSelectedReason.GatewayNotAvailable]: "網關不可用",
    [NotSelectedReason.GatewayNotSupported]: "不支持該網關",
    [NotSelectedReason.PlatformNotSupported]: "不支持該平台",
    [NotSelectedReason.MerchantNotSupported]: "不支持該商戶",
    [NotSelectedReason.StoreNotSupported]: "不支持該店鋪",
    [NotSelectedReason.DoesNotMatchRequestedCurrency]: "請求貨幣類型不一致",
    [NotSelectedReason.RateLimited]: "利率限制",
    [NotSelectedReason.GatewayConfigNotFound]: "沒有找到網關配置",
    [NotSelectedReason.LastNameRequired]: "姓為必填項",
    [NotSelectedReason.NotChosenGatewayForQRCode]: "未選擇二維碼網關",
    [NotSelectedReason.NotChosenGateway]: "未選擇網關",
    [NotSelectedReason.CallMethodNotSupported]: "不支持調用方式",
    [NotSelectedReason.OSTypeNotSpecified]: "未指定OS類型",
    [NotSelectedReason.SubscriptionPlanValidationFailed]: "訂閱計劃驗證失敗",
    [NotSelectedReason.InstallmentCapableNotMatching]: "分期付款能力不匹配",
    [NotSelectedReason.CardBankIssuerNotSupported]: "不支持該發卡行",
    [NotSelectedReason.CardBinNotSupported]: "不支持該卡BIN",
    [NotSelectedReason.CurrencyNotSupported]: "不支持該國家",
    [NotSelectedReason.AuthCaptureSupportNotMatching]: "Auth capture 支持不一致",
    [NotSelectedReason.NotOnWhitelist]: "不在白名單內",
    [NotSelectedReason.BlacklistedTagOnMerchant]: "商家黑名單標籤",
    [NotSelectedReason.BlacklistedTagOnStore]: "店鋪黑名單標籤",
    [NotSelectedReason.ForbiddenGateway]: "禁用網關",
    [NotSelectedReason.ForbiddenCredential]: "禁用的驗證信息",
    [NotSelectedReason.CannotShareMerchantCredentials]: "不能共享商家驗證信息",
    [NotSelectedReason.CannotShareStoreCredentials]: "不能共享店鋪驗證信息",
    [NotSelectedReason.PlatformCredentialsDisabled]: "平台驗證信息已禁用",
    [NotSelectedReason.TaggedPlatformCredentialsDisabled]: "標記的平台驗證信息已禁用",
};

export default LABELS;
