export const SUBSCRIPTIONS_BUTTONS_PAY = "SUBSCRIPTIONS_BUTTONS_PAY";

export const SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD = "SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD";

export const SUBSCRIPTIONS_INFO_PAY = "SUBSCRIPTIONS_INFO_PAY";
export const SUBSCRIPTIONS_INFO_INITIAL = "SUBSCRIPTIONS_INFO_INITIAL";
export const SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD = "SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD";
export const SUBSCRIPTIONS_INFO_PAY_INSTALLMENT = "SUBSCRIPTIONS_INFO_PAY_INSTALLMENT";
export const SUBSCRIPTIONS_INFO_START = "SUBSCRIPTIONS_INFO_START";
export const SUBSCRIPTIONS_CVV_AUTH = "SUBSCRIPTIONS_CVV_AUTH";

export const SUBSCRIPTIONS_PERIOD_DAILY = "SUBSCRIPTIONS_PERIOD_DAILY";
export const SUBSCRIPTIONS_PERIOD_WEEKLY = "SUBSCRIPTIONS_PERIOD_WEEKLY";
export const SUBSCRIPTIONS_PERIOD_BIWEEKLY = "SUBSCRIPTIONS_PERIOD_BIWEEKLY";
export const SUBSCRIPTIONS_PERIOD_MONTHLY = "SUBSCRIPTIONS_PERIOD_MONTHLY";
export const SUBSCRIPTIONS_PERIOD_BIMONTHLY = "SUBSCRIPTIONS_PERIOD_BIMONTHLY";
export const SUBSCRIPTIONS_PERIOD_QUARTERLY = "SUBSCRIPTIONS_PERIOD_QUARTERLY";
export const SUBSCRIPTIONS_PERIOD_SEMIANNUALLY = "SUBSCRIPTIONS_PERIOD_SEMIANNUALLY";
export const SUBSCRIPTIONS_PERIOD_ANNUALLY = "SUBSCRIPTIONS_PERIOD_ANNUALLY";

export const SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY = "SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY";
export const SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY = "SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY";

export const SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT = "SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT";

export const SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE = "SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE";
export const SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT = "SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT";

export const SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES = "SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES";
export const SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING = "SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING";

export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL =
    "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL";
export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL = "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL";

export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL = "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL";
export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES =
    "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES";
export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL = "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL";
export const SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS = "SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS";

export const SUBSCRIPTIONS_INSTALLMENT_CYCLES = "SUBSCRIPTIONS_INSTALLMENT_CYCLES";
export const SUBSCRIPTIONS_INSTALLMENT_START = "SUBSCRIPTIONS_INSTALLMENT_START";
