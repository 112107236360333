import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "เลือกวิธีการชำระเงิน",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "วิธีการชำระเงิน",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "วิธีการชำระเงิน: {method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "ที่อยู่เรียกเก็บเงิน",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "ป้อนรหัสยืนยัน",

    [FORM_LABELS.FORM_FIELDS_NAME]: "ยามาดะทาโร",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "example@email.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "หมายเลขโทรศัพท์",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "ที่อยู่อีเมล",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "รหัสรักษาความปลอดภัย",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "วันหมดอายุ (เดือน/ปี)",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "วันหมดอายุ",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "ชื่อบัตร",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "หมายเลขบัตร",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "การชำระเงินด้วยบัตรเครดิต",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "การตั้งถิ่นฐานร้านสะดวกซื้อ",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "จ่ายเงิน",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "ออนไลน์",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "โอนเงินผ่านธนาคาร",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_D_BARAI]: "DBarai",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "Paypay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "Paypay (ทดสอบเท่านั้น)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay (ทดสอบเท่านั้น)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+(ทดสอบเท่านั้น)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat (ทดสอบเท่านั้น)",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "เทศบาล",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "ประเทศ",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "ที่อยู่ถนน",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "ชื่อคอนโดมิเนียม / หมายเลขห้อง",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "ชื่อ",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "ชื่อ (kana)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "จังหวัด",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "รหัสไปรษณีย์",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "4444 3333 2222 1111",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "Taro Yamada",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "หนึ่งสองสาม",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "หมายเลข 3 (หรือ 4) หลักที่อธิบายไว้ในส่วนบนของคอลัมน์ลายเซ็น ฯลฯ ",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "เดือน",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "ปี",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "Taro Suzuki",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "ร้านสะดวกซื้อ",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "เลือกร้านสะดวกซื้อ",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "ตกลงที่จะรับข้อมูลส่วนบุคคล",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "เลือกการ์ด",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "การ์ดใหม่",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "ลบข้อมูลการ์ด",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "เลือกร้านสะดวกซื้อ",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "ร้านสะดวกซื้ออื่น",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "ลบข้อมูลร้านสะดวกซื้อ",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "เลือกชุดข้อมูลที่ใช้ก่อนหน้านี้:",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "ใหม่",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "ลบชุดข้อมูล",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "โปรดตรวจสอบอีเมลหรือ SMS และป้อนรหัสยืนยัน",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "จำนวนการชำระเงิน",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "กลุ่ม",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24 ครั้ง",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "หมุน",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "{brand} ไม่รองรับการผ่อนชำระ",
};

export default LABELS;
