import * as SUBSCRIPTIONS_LABELS from "../labels/subscriptions";

export const LABELS: Record<string, string> = {
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_BUTTONS_PAY]: "การชำระเงิน {period} {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD]: "{money} การชำระเงินสำหรับแต่ละช่วงเวลา {period}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INITIAL]: "การชำระเงินของ {money} ครั้งแรก",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY]: "การชำระเงิน {period} {money} แล้ว",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD]: "จากนั้นจ่าย {money} สำหรับแต่ละช่วงเวลา {period}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_PAY_INSTALLMENT]: "การชำระเงิน {money} หลังจากนั้น",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_START]: "วันที่เรียกเก็บเงินถัดไป: {date}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_CVV_AUTH]: "การลงทะเบียนบัตรเป็นครั้งแรก",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_DAILY]: "ทุกวัน",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_WEEKLY]: "ทุกสัปดาห์",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIWEEKLY]: "ใหญ่มากทุกสัปดาห์",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_MONTHLY]: "รายเดือน",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_BIMONTHLY]: "ทุก 2 เดือน",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_QUARTERLY]: "หนึ่งในสี่",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_SEMIANNUALLY]: "ครึ่งช่วงเวลา",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_PERIOD_ANNUALLY]: "ทุกปี",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_DAILY]: "การชำระเงินปกติ (ดวงอาทิตย์)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_WEEKLY]: "การชำระเงินปกติ (สัปดาห์)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_BIWEEKLY]: "การชำระเงินปกติ (ทุก ๆ สัปดาห์)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_MONTHLY]: "การชำระเงินปกติ (จันทร์)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_QUARTERLY]: "การชำระเงินปกติ (ไตรมาส)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_SEMIANNUALLY]: "การชำระเงินปกติ (ครึ่งเทอม)",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INFO_INSTALLMENT_ANNUALLY]: "การชำระเงินปกติ (ปี)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT]: "จำนวนเริ่มต้น: {money}",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_TITLE]: "แผนการชำระเงิน",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_INITIAL_AMOUNT]: "การชำระเงินของ {money} ครั้งแรก",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES]: "จ่ายทั้งหมด {money} ใน {cycles} ครั้ง",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING]: "{money} (หมุนเวียน)",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL]: "การชำระเงิน {period} {money}",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL]: "การชำระเงิน {period} {money} แล้ว",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL]: "จ่ายทั้งหมด {money} ใน {cycles} ครั้ง",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES]: "จากนั้นจ่าย {money} ใน {cycles} ครั้ง",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL]: "จากนั้นจ่าย {money} ใน {cycles} ครั้ง",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS]: "เวลาการเรียกเก็บเงินอัตโนมัติ: {cycles} ครั้ง",

    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_CYCLES]: "ชำรุด",
    [SUBSCRIPTIONS_LABELS.SUBSCRIPTIONS_INSTALLMENT_START]: "การชำระเงินครั้งแรก",
};

export default LABELS;
