/**
 *  @module Resources/Charges
 */

import { isInline } from "common/utils/browser";
import {
    AuthParams,
    ChargeItem,
    Charges as NodeCharges,
    ChargeStatus,
    DefinedRoute,
    HTTPMethod,
    PaymentType,
    SendData,
} from "univapay-node";

import { redirectInIFrame } from "../../../common/utils/redirect";
import { store } from "../redux/store";
import { sdk } from "../SDK";

export type ChargeExpiry = {
    id: string;
    chargeId: string;
    expirationDate: string;
    extensionCount: number;
    createdOn: string;
    updatedOn: string;
};

type IssuerToken = {
    issuerToken: string;
    callMethod: "http_post" | "http_get";
    payload: Record<string, string>;
    paymentType: PaymentType;
    contentType: string;
};

export class Charges extends NodeCharges {
    private _expiry?: DefinedRoute;
    expiry(id: string, storeId: string, data?: SendData<void>, auth?: AuthParams): Promise<ChargeExpiry> {
        this._expiry = this._expiry ?? this.defineRoute(HTTPMethod.GET, "/stores/:storeId/charges/:id/expiry/latest");
        return this._expiry(data, undefined, auth, { storeId, id });
    }

    private _issuerToken?: DefinedRoute;
    issuerToken(storeId: string, id: string, auth?: AuthParams): Promise<IssuerToken> {
        this._issuerToken =
            this._issuerToken ??
            this.defineRoute(
                HTTPMethod.GET,
                "/stores/:storeId/charges/:chargeId/three_ds/issuer_token",
                undefined,
                undefined,
                undefined,
                undefined,
                ["payload"]
            );
        return this._issuerToken(null, null, auth, { storeId, chargeId: id });
    }

    async pollThreeDs(storeId: string, id: string, issuerToken: IssuerToken) {
        const locale = store.getState().intl.locale;
        const promise = sdk.charges.poll(
            storeId,
            id,
            null,
            null,
            null,
            null,
            (charge: ChargeItem) => ![ChargeStatus.PENDING, ChargeStatus.AWAITING].includes(charge?.status)
        );

        if (isInline()) {
            store
                .getState()
                .application.connector.emitter.emit("checkout:three-ds-authorization-modal", { issuerToken, locale });

            await promise;
        }

        return redirectInIFrame<ChargeItem>({
            url: issuerToken.issuerToken,
            callMethod: issuerToken.callMethod,
            search: issuerToken.payload,
            iFrameSize: "100%",
            promise,
            parent: document.querySelector(".dialog-checkout"),
            locale,
        });
    }
}
