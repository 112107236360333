import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "Choose a payment method",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "Payment method",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "Payment method: {method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "Billing address",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "Check Confirmation Code",

    [FORM_LABELS.FORM_FIELDS_NAME]: "John Doe",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "example@email.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "Phone number",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "Email",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "Security code",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "Expiration date (MM/YY)",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "Expiration date",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "Name on card",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "Card number",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "Card",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "Convenience Store",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "Paidy",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "Online",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "Bank transfer",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_D_BARAI]: "DBarai",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "PayPay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+ (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE]: "DBarai (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "PayPay (test only)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat (test only)",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "City",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "Country",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "Address line 1",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "Address line 2",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "Name",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "Name (kana)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "State",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "Postcode",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "4444 3333 2222 1111",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "TARO YAMADA",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "123",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "The 3 or 4 digit security code on your card.",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "MM",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "YY",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "Your Name",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "Convenience Store",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "Select Store",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "I acknowledge that my payment data will be saved and could be charged again in the future.",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "Select card",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "Add card",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "Delete card information",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "Select Convenience Store",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "Other Store",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "Delete store information",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "Select previously used data set:",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "New",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "Delete data set",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "Check your email or you should receive an SMS with a code to put in this box.",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "Pay in",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "full amount",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20 times",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24 times",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "Revolving",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "Installments are not supported for {brand}",
};

export default LABELS;
