import { set } from "lodash";
import { ErrorResponse, ResponseErrorCode } from "univapay-node";

import { TokenError } from "../errors/TokenError";

export interface RulesObject {
    [key: string]: boolean;
}

export interface RulesWithMessageObject {
    [key: string]: { [message: string]: boolean };
}

export const validationToErrors = (rules: RulesWithMessageObject) =>
    Object.keys(rules).reduce((errorMessages: Record<string, string>, field: string) => {
        const fieldErrors = rules[field];

        const firstErrorKey = Object.keys(fieldErrors).find((key) => fieldErrors[key]);
        if (firstErrorKey) {
            // use set to unflatten the error: `{ "a.b.c": 1 }` should become `{ a: { b: { c: 1 } } }`
            set(errorMessages, field, firstErrorKey);
        }

        return errorMessages;
    }, {});

type InlineCallbackError = {
    message: string;
    code: number; // charge http code or 400 for validation errors
    details?: string;

    // resource ids (when available)
    token?: string; // transaction token id
    transactionToken?: string; // transaction token id
    charge?: string; // charge id
    subscription?: string; // subscription id

    // Error information
    errorResponse?: ErrorResponse;
    data?: Record<string, ResponseErrorCode>;
};

type FormErrors = { data: Record<string, ResponseErrorCode> };

export const formatInlineSubmitFrontendValidationErrors = (formErrors: FormErrors): ErrorResponse => ({
    status: "error",
    code: ResponseErrorCode.ValidationError,
    errors: Object.entries(formErrors.data).map(([key, value]) => ({ field: key, reason: value })),
});

const extractValidationCodeFromMessage = (message: string): ResponseErrorCode => {
    if (!message) {
        return ResponseErrorCode.UnknownError;
    }

    return (message.toUpperCase().startsWith("CODE: ")
        ? message.split(",")[0].toUpperCase().replace("CODE: ", "")
        : message) as ResponseErrorCode;
};

export const formatInlineSubmitErrors = (
    error: InlineCallbackError | FormErrors | TokenError,
    token?: string,
    charge?: string,
    subscription?: string
): InlineCallbackError => {
    if (!error) {
        return {
            message: ResponseErrorCode.UnknownError,
            code: 499,
            token,
            transactionToken: token,
            subscription,
            charge,
        };
    }

    const errorCodeFromMessage =
        "message" in error && error.message
            ? extractValidationCodeFromMessage(error.message)
            : ResponseErrorCode.ValidationError;

    const generatedToken = token ?? ("failedTokenId" in error ? error.failedTokenId : undefined);
    const generatedCharge = charge ?? ("failedChargeId" in error ? error.failedChargeId : undefined);

    return {
        // Ensure validation errors are handled properly
        message: errorCodeFromMessage,
        code: "code" in error && !!Number(error.code) ? (error.code as number) : 400,
        details: "details" in error && error.details ? (error.details as string) : errorCodeFromMessage,

        token: generatedToken, // legacy support
        transactionToken: generatedToken,
        charge: generatedCharge,
        subscription,

        errorResponse:
            ("errorResponse" in error && error.errorResponse) ||
            ("data" in error && error.data
                ? formatInlineSubmitFrontendValidationErrors(error as FormErrors)
                : undefined),
        data: "data" in error ? error.data : undefined,
    };
};
