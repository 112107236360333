import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "请将您的付款汇到以下银行账号",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "银行名称",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "银行代码",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "分行名称",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "分行代码",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "账号",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "账户名",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "金额",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "截止日期",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "首次付款",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "首次付款截止日期",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "下次付款",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "下次付款截止日期",
};

export default LABELS;
