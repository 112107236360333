import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "{name}は、リストでなければいけません。",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "{name}は:min〜:max文字で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "{name}は、ブール値でなければいけません。",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "{name}は正しい日付形式を入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "{name}と:differentは同じであってはなりません。",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "{name}は正しいメールアドレスを入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "{name}は:max以下で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "{name}は:max文字以下で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "{name}は:min以上で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "{name}は:min文字以上で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "選択された{name}は無効です。",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "{name}は数値で入力してください。",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: '{name}の値 ":value" はパターンにマッチする必要があります。',
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "{name}は必須です。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: ":otherは:valueになったら{name}は必須です。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: ":fieldに値がセットされている場合には、{name}項目は必須となります。",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "{name}と:sameは同じでなければなりません。",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "{name}は、文字列でなければいけません。",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "{name}は正しいURLを入力してください。",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name}は必須です",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name}は正しいメールアドレスを入力してください",
    [VALIDATION_LABELS.VALIDATION_BANNED_EMAIL]: "禁止されたメール",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name}は正しい電話番号を入力してください。",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name}は正しいカード番号を入力してください。",
    [VALIDATION_LABELS.VALIDATION_ZIP]: "{name}は正しい郵便番号を入力してください。",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name}は正しくありません",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "期限切れのカード",
    [VALIDATION_LABELS.VALIDATION_CVV_LENGTH]: "セキュリティコードは3桁で入力してください",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "この項目は必須です",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name}に、利用できない文字が含まれています",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name}のフォーマットは不正です",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name}には少なくとも1つの空白が必要です",
};

export default LABELS;
