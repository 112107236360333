import { Period } from "common/types";
import {
    add,
    addMonths,
    differenceInDays,
    getDaysInMonth,
    isBefore,
    isSameDay,
    setDate,
    startOfDay,
    startOfMonth,
} from "date-fns";
import { parse as parsePeriod } from "iso8601-duration";

export const getStartDate = (startAt?: string, startIn?: Period, startDayOfMonth?: number): Date => {
    if (!startAt && !startIn && !startDayOfMonth) {
        // When no parameters are provided, this is a subscription with an immediate charge
        return null;
    }

    if (startAt) {
        // Ignore other parameters when start at is provided
        return new Date(startAt);
    }

    const today = startOfDay(new Date());
    const nextTick = add(today, parsePeriod(startIn || "P0M"));
    if (!startDayOfMonth) {
        return nextTick;
    }

    /*
     * In the case the start date is before or equal to today, shift it by one month
     *
     * For example:
     * 1. Today is the 23rd / The start date is the 20th / The startIn is set to P0M
     * As the day would be before today, set it to the next month instead of this month
     *
     * 2. Today is the 18th / The start date is the 20th / The startIn is set to P0M
     * As the day would be after today, do not offset it
     *
     * 3. Today is the 23th / The start date is the 20th / The startIn is set to P1M
     * As the day would be after today, do not offset it
     */
    const nextTickWithDate = setDate(nextTick, Math.min(getDaysInMonth(nextTick), startDayOfMonth));
    const shouldAddOneMonth = isBefore(nextTickWithDate, today) || isSameDay(nextTickWithDate, today);
    if (!shouldAddOneMonth) {
        return nextTickWithDate;
    }

    const shiftedNextTick = addMonths(startOfMonth(nextTickWithDate), 1);
    const shiftedStartDay = Math.min(getDaysInMonth(shiftedNextTick), startDayOfMonth);
    const shiftedNextTickWithDay = setDate(shiftedNextTick, shiftedStartDay);

    return shiftedNextTickWithDay;
};

export const getStartDatePeriod = (startAt?: string, startIn?: Period, startDayOfMonth?: number): string => {
    const nextDate = getStartDate(startAt, startIn, startDayOfMonth);
    return nextDate ? `P${differenceInDays(startOfDay(nextDate), startOfDay(new Date()))}D` : null;
};
