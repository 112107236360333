import * as DURATION_LABELS from "../labels/duration";

export const LABELS: Record<string, string> = {
    [DURATION_LABELS.DURATION_SPACER]: " ",
    [DURATION_LABELS.DURATION_YEARS]: "{value} 년",
    [DURATION_LABELS.DURATION_MONTHS]: "{value} 개월",
    [DURATION_LABELS.DURATION_WEEKS]: "{value} 주",
    [DURATION_LABELS.DURATION_DAYS]: "{value} 일",
};

export default LABELS;
