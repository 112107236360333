import { createModel } from "@rematch/core";
import { ChargeExpiry } from "checkout/ts/resources/Charges";

import { sdk } from "../../SDK";
import { Dispatch } from "../store";

type ModelStateShape = {
    chargeExpiry?: ChargeExpiry;
    error?: Error;
};

const initialState: ModelStateShape = {
    chargeExpiry: null,
    error: null,
};

const model = {
    state: initialState,

    reducers: {
        setExpiry: (state: ModelStateShape, { expiry }: { expiry: ChargeExpiry }) => ({ ...state, expiry }),
        setError: (state: ModelStateShape, { error }: { error: Error }) => ({ ...state, error }),
    },

    effects: (dispatch: Dispatch) => ({
        get: async ({ id, storeId }: { id: string; storeId: string }): Promise<ChargeExpiry> => {
            const { expiry: self } = dispatch;

            try {
                const response = await sdk.patchedCharges.expiry(id, storeId);
                self.setExpiry({ expiry: response });
                return response;
            } catch (error) {
                self.setError({ error });
            }
        },
    }),
};

export const expiry = createModel()(model);
