export class DisplayDetect {
    static mobileRegex = /mobile|ip(hone|od|ad)|android|blackberry|kindle|netfront|silk|(hpw|web)os|fennec|minimo|opera m(obi|ini)|blazer|dolfin|dolphin|skyfire|zune/i;
    static tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i;
    static macRegex = /mac/i;
    static iosRegex = /iphone|ipod|ipad/i;
    static macLikeRegex = new RegExp(`${DisplayDetect.macRegex.source}|${DisplayDetect.iosRegex}`, "i");
    static windowsRegex = /win/i;
    static googleAppRegex = /gsa/i;

    static isMobile(): boolean {
        return DisplayDetect.mobileRegex.test(window.navigator.userAgent);
    }

    static isTablet(): boolean {
        return DisplayDetect.tabletRegex.test(window.navigator.userAgent);
    }

    static isMac(): boolean {
        return DisplayDetect.macRegex.test(window.navigator.platform);
    }

    static isIOS(): boolean {
        return DisplayDetect.iosRegex.test(window.navigator.platform);
    }

    static isMacLike(): boolean {
        return DisplayDetect.macLikeRegex.test(window.navigator.platform);
    }

    static isWindows(): boolean {
        return DisplayDetect.windowsRegex.test(window.navigator.platform);
    }

    static isGoogleApp(): boolean {
        return DisplayDetect.googleAppRegex.test(window.navigator.userAgent);
    }

    static isFramed(): boolean {
        // Includes Google Mobile App special case
        return window.location !== window.top.location || DisplayDetect.googleAppRegex.test(window.navigator.userAgent);
    }
}
