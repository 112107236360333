import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "Paily의 토큰 발행 프로세스에 대한 리디렉션. 잠시 기다려주세요.",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "지불은 Paty의 승인을 받았습니다. 완료하려면 아래 버튼을 클릭하십시오.",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "지불",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "생년월일) 1970-01-30",
};

export default LABELS;
