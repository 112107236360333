import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "返回",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "關閉",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "確認",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "下一步",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "返回",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "提交",
    [COMMON_LABELS.COMMON_TOTAL]: "總: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "用{method}付款",

    [COMMON_LABELS.COMMON_OPTIONAL]: "任意",
    [COMMON_LABELS.COMMON_REQUIRED]: "必須",

    [COMMON_LABELS.PRIVACY_LINK]: "隱私政策",

    [COMMON_LABELS.COMMON_COMMA]: "、",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+ processing fees",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "選擇...",
};

export default LABELS;
