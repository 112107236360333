import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "Пожалуйста, переходите на следующий банковский счет",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION]: "Чтобы подтвердить информацию о платежном счете, следуйте инструкциям в электронном письме высланном на ваш адрес или свяжитесь с магазином, который вы используете.",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "Название финансового учреждения",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "Код банка",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "Название ветви",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "Код филиала",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "номер счета",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "Владелец счета",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "Передача суммы денег",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "Срок передачи",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "Первоначальная сумма передачи",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "Первый срок передачи",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "Следующая сумма передачи",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "В следующий раз срок передачи",
};

export default LABELS;
