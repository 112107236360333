export const FORM_PAYMENT_TYPE_TITLE = "FORM_PAYMENT_TYPE_TITLE";
export const FORM_PAYMENT_METHOD = "FORM_PAYMENT_METHOD";
export const FORM_USED_PAYMENT_METHOD = "FORM_USED_PAYMENT_METHOD";

export const FORM_ADDRESS_TITLE = "FORM_ADDRESS_TITLE";

export const FORM_CONFIRMATION_TITLE = "FORM_CONFIRMATION_TITLE";

export const FORM_FIELDS_NAME = "FORM_FIELDS_NAME";
export const FORM_FIELDS_EMAIL = "FORM_FIELDS_EMAIL";
export const FORM_FIELDS_PHONE_NUMBER = "FORM_FIELDS_PHONE_NUMBER";

export const FORM_FIELDS_PHONE_NUMBER_LABEL = "FORM_FIELDS_PHONE_NUMBER_LABEL";
export const FORM_FIELDS_EMAIL_LABEL = "FORM_FIELDS_EMAIL_LABEL";
export const FORM_CARD_FIELDS_CSC_LABEL = "FORM_CARD_FIELDS_CSC_LABEL";
export const FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL = "FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL";
export const FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION = "FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION";
export const FORM_CARD_FIELDS_CARDHOLDER_LABEL = "FORM_CARD_FIELDS_CARDHOLDER_LABEL";
export const FORM_CARD_FIELDS_CARD_NUMBER_LABEL = "FORM_CARD_FIELDS_CARD_NUMBER_LABEL";

export const FORM_PAYMENT_TYPE_CARD = "FORM_PAYMENT_TYPE_CARD";
export const FORM_PAYMENT_TYPE_KONBINI = "FORM_PAYMENT_TYPE_KONBINI";
export const FORM_PAYMENT_TYPE_PAIDY = "FORM_PAYMENT_TYPE_PAIDY";
export const FORM_PAYMENT_TYPE_ONLINE = "FORM_PAYMENT_TYPE_ONLINE";
export const FORM_PAYMENT_TYPE_BANK_TRANSFER = "FORM_PAYMENT_TYPE_BANK_TRANSFER";

export const FORM_ONLINE_PAYMENT_ALIPAY = "FORM_ONLINE_PAYMENT_ALIPAY";
export const FORM_ONLINE_PAYMENT_D_BARAI = "FORM_ONLINE_PAYMENT_DBARAI";
export const FORM_ONLINE_PAYMENT_PAY_PAY = "FORM_ONLINE_PAYMENT_PAY_PAY";
export const FORM_ONLINE_PAYMENT_WE_CHAT = "FORM_ONLINE_PAYMENT_WE_CHAT";
export const FORM_ONLINE_PAYMENT_ALIPAY_PLUS = "FORM_ONLINE_PAYMENT_ALIPAY_PLUS";
export const FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE = "FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE";
export const FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE = "FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE";
export const FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE = "FORM_ONLINE_PAYMENT_TEST_D_BARAI_ONLINE";
export const FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE = "FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE";
export const FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE = "FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE";

export const FORM_ADDRESS_FIELDS_NAME = "FORM_ADDRESS_FIELDS_NAME";
export const FORM_ADDRESS_FIELDS_NAME_KANA = "FORM_ADDRESS_FIELDS_NAME_KANA";
export const FORM_ADDRESS_FIELDS_LINE1 = "FORM_ADDRESS_FIELDS_LINE1";
export const FORM_ADDRESS_FIELDS_LINE2 = "FORM_ADDRESS_FIELDS_LINE2";
export const FORM_ADDRESS_FIELDS_CITY = "FORM_ADDRESS_FIELDS_CITY";
export const FORM_ADDRESS_FIELDS_STATE = "FORM_ADDRESS_FIELDS_STATE";
export const FORM_ADDRESS_FIELDS_ZIP = "FORM_ADDRESS_FIELDS_ZIP";
export const FORM_ADDRESS_FIELDS_COUNTRY = "FORM_ADDRESS_FIELDS_COUNTRY";

export const FORM_CARD_FIELDS_CARDHOLDER = "FORM_CARD_FIELDS_CARDHOLDER";
export const FORM_CARD_FIELDS_CARD_NUMBER = "FORM_CARD_FIELDS_CARD_NUMBER";
export const FORM_CARD_FIELDS_EXP_MONTH = "FORM_CARD_FIELDS_EXP_MONTH";
export const FORM_CARD_FIELDS_EXP_YEAR = "FORM_CARD_FIELDS_EXP_YEAR";
export const FORM_CARD_FIELDS_CSC = "FORM_CARD_FIELDS_CSC";
export const FORM_CARD_FIELDS_CSC_INFO = "FORM_CARD_FIELDS_CSC_INFO";

export const FORM_KONBINI_FIELDS_NAME = "FORM_KONBINI_FIELDS_NAME";
export const FORM_KONBINI_FIELDS_KONBINI_BRAND = "FORM_KONBINI_FIELDS_KONBINI_BRAND";
export const FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT = "FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT";

export const FORM_CARD_SELECTOR_TITLE = "FORM_CARD_SELECTOR_TITLE";
export const FORM_CARD_FIELDS_NEW_CARD = "FORM_CARD_FIELDS_NEW_CARD";
export const FORM_CARD_SELECTOR_DELETE = "FORM_CARD_SELECTOR_DELETE";

export const FORM_FIELDS_ACCEPT_RECURRING = "FORM_FIELDS_ACCEPT_RECURRING";

export const FORM_KONBINI_SELECTOR_TITLE = "FORM_KONBINI_SELECTOR_TITLE";
export const FORM_KONBINI_FIELDS_OTHER = "FORM_KONBINI_FIELDS_OTHER";
export const FORM_KONBINI_SELECTOR_DELETE = "FORM_KONBINI_SELECTOR_DELETE";

export const FORM_PAIDY_SELECTOR_TITLE = "FORM_PAIDY_SELECTOR_TITLE";
export const FORM_PAIDY_FIELDS_NEW = "FORM_PAIDY_FIELDS_NEW";
export const FORM_PAIDY_SELECTOR_DELETE = "FORM_PAIDY_SELECTOR_DELETE";

export const FORM_CONFIRMATION_CODE = "FORM_CONFIRMATION_CODE";

export const FORM_INSTALLMENT_SELECT_LABEL = "FORM_INSTALLMENT_SELECT_LABEL";
export const FORM_INSTALLMENT_SELECT_ONE_TIME = "FORM_INSTALLMENT_SELECT_ONE_TIME";
export const FORM_INSTALLMENT_SELECT_THREE_TIMES = "FORM_INSTALLMENT_SELECT_THREE_TIMES";
export const FORM_INSTALLMENT_SELECT_FIVE_TIMES = "FORM_INSTALLMENT_SELECT_FIVE_TIMES";
export const FORM_INSTALLMENT_SELECT_SIX_TIMES = "FORM_INSTALLMENT_SELECT_SIX_TIMES";
export const FORM_INSTALLMENT_SELECT_TEN_TIMES = "FORM_INSTALLMENT_SELECT_TEN_TIMES";
export const FORM_INSTALLMENT_SELECT_TWELVE_TIMES = "FORM_INSTALLMENT_SELECT_TWELVE_TIMES";
export const FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES = "FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES";
export const FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES = "FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES";
export const FORM_INSTALLMENT_SELECT_TWENTY_TIMES = "FORM_INSTALLMENT_SELECT_TWENTY_TIMES";
export const FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES = "FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES";
export const FORM_INSTALLMENT_REVOLVING = "FORM_INSTALLMENT_REVOLVING";
export const FORM_INSTALLMENT_SELECT_HELPER = "FORM_INSTALLMENT_SELECT_HELPER";
