import * as ENUM_LABELS from "../labels/enum";

export const LABELS: Record<string, string> = {
    [ENUM_LABELS.CONVENIENCE_STORE_SEVEN_ELEVEN]: "セブンイレブン",
    [ENUM_LABELS.CONVENIENCE_STORE_FAMILY_MART]: "ファミリーマート",
    [ENUM_LABELS.CONVENIENCE_STORE_LAWSON]: "ローソン",
    [ENUM_LABELS.CONVENIENCE_STORE_MINI_STOP]: "ミニストップ",
    [ENUM_LABELS.CONVENIENCE_STORE_SEICO_MART]: "セイコーマート",
    [ENUM_LABELS.CONVENIENCE_STORE_DAILY_YAMAZAKI]: "デイリーヤマザキ",
    [ENUM_LABELS.CONVENIENCE_STORE_PAY_EASY]: "ペイジー",
    [ENUM_LABELS.CONVENIENCE_STORE_SUNKUS]: "サンクス",
    [ENUM_LABELS.CONVENIENCE_STORE_CIRCLE_K]: "サークルK",
    [ENUM_LABELS.CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE]: "ヤマザキデイリー",

    [ENUM_LABELS.CARD_VISA]: "Visa",
    [ENUM_LABELS.CARD_AMEX]: "American Express",
    [ENUM_LABELS.CARD_MASTERCARD]: "Mastercard",
    [ENUM_LABELS.CARD_MAESTRO]: "Maestro",
    [ENUM_LABELS.CARD_DISCOVER]: "Discover",
    [ENUM_LABELS.CARD_JCB]: "JCB",
    [ENUM_LABELS.CARD_DINERS]: "Diners Club",
    [ENUM_LABELS.CARD_UNIONPAY]: "UnionPay",
};

export default LABELS;
