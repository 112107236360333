import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "돌아가기",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "닫기",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "완료",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "다음",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "돌아가기",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "보내기",
    [COMMON_LABELS.COMMON_TOTAL]: "합계: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "{method}로 결제",

    [COMMON_LABELS.COMMON_OPTIONAL]: "선택",
    [COMMON_LABELS.COMMON_REQUIRED]: "필수",

    [COMMON_LABELS.PRIVACY_LINK]: "개인정보처리방침에 대해서",

    [COMMON_LABELS.COMMON_COMMA]: ",",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+취급 수수료",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "선택...",
};

export default LABELS;
