import { PaymentType } from "univapay-node";

export enum StepName {
    INFO = "customer-information",
    DATA = "data",
    ADDRESS = "address",
    CONFIRM = "confirm",
    PENDING = "pending",
    CODE = "code",
    GATEWAY = "gateway",
    PROCESSING = "processing",
    PREVIEW = "preview",
    TOKEN_REVIEW = "token-review",
    TOKEN_SELECT = "token-select",
}

export const getErrorUrl = () => "/error";

export const getStepUrl = (paymentType: PaymentType, key: string, step: StepName) =>
    `/paymentData/${paymentType}/${key}/${step}`;

export const getDefaultFlowStepUrl = (paymentType: PaymentType, key: string) => `/paymentData/${paymentType}/${key}`;
