import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "เปลี่ยนเส้นทางสำหรับกระบวนการออกโทเค็นของ Paidy โปรดรอสักครู่",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "การชำระเงินได้รับการอนุมัติจาก Paidey หากต้องการเสร็จสิ้นให้คลิกปุ่มด้านล่าง",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "จ่ายเงิน",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "วันเดือนปีเกิด) 1970-01-30",
};

export default LABELS;
