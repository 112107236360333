import { createModel } from "@rematch/core";
import { getOnlineRoutes } from "checkout/ts/components/flows/online/constants";
import { ChargeError } from "checkout/ts/errors/ChargeError";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import {
    OnlineTestBrand,
    onlineTestBrands,
    PatchedOnlineBrand,
    SupportedOnlineBrand,
} from "checkout/ts/redux/utils/online-constants";
import { UnivaMetadata } from "checkout/ts/utils/metadata";
import { raiseAPIClientError } from "checkout/ts/utils/monitoring";
import { StepName } from "checkout/ts/utils/StepName";
import { ResourceType } from "common/Messages";
import { getOnlineCallMethodParams } from "common/utils/browser";
import { push as redirect } from "connected-react-router";
import {
    ChargeStatus,
    ErrorResponse,
    IssuerTokenItem,
    OnlineBrand,
    PaymentType,
    ProcessingMode,
    ResponseCharge,
    ResponseError,
    TransactionTokenItem,
    TransactionTokenOnlineData,
    TransactionTokenType,
} from "univapay-node";
import { v4 as uuid } from "uuid";

import { sdk } from "../../SDK";
import { Dispatch, StateShape } from "../store";
import { concatPhoneNumber, getIntlMessage, parsePhone } from "../utils/intl";

type ModelStateShape = {
    brand: SupportedOnlineBrand;
    storeId?: string;
    chargeId?: string;
    issuerToken?: string;
    issuerTokenPayload?: Record<string, string>;
    email?: string;
    error?: ResponseError | ErrorResponse | Error;
    token?: TransactionTokenItem;
    weChatAppId?: string;
    weChatOpenId?: string;
    weChatError?: ResponseError | ErrorResponse | Error;
};

type IssuerTokenData = {
    storeId: string;
    chargeId: string;
    issuerToken: string;
    issuerTokenPayload?: Record<string, string>;
};

const initialState: ModelStateShape = {
    brand: null,
    storeId: null,
    chargeId: null,
    issuerToken: null,
    issuerTokenPayload: {},
    email: null,
    error: null,
    token: null,
    weChatAppId: null,
    weChatOpenId: null,
    weChatError: null,
};

const model = {
    state: initialState,

    reducers: {
        setToken: (state: ModelStateShape, { token }: { token: TransactionTokenItem }): ModelStateShape => ({
            ...state,
            token,
        }),
        setBrand: (state: ModelStateShape, { brand }: { brand: SupportedOnlineBrand }): ModelStateShape => ({
            ...state,
            brand,
        }),
        setIssuerTokenData: (
            state: ModelStateShape,
            { storeId, chargeId, issuerToken, issuerTokenPayload }: IssuerTokenData
        ): ModelStateShape => ({
            ...state,
            storeId,
            chargeId,
            issuerToken,
            issuerTokenPayload,
        }),
        setIssuerTokenError: (state: ModelStateShape, { error }: { error: Error }): ModelStateShape => ({
            ...state,
            error,
        }),

        setWeChatAppId: (state: ModelStateShape, { appId }: { appId: string }) => ({
            ...state,
            weChatAppId: appId,
        }),
        setWeChatOpenId: (state: ModelStateShape, { openId }: { openId: string }) => ({
            ...state,
            weChatOpenId: openId,
        }),
        setWeChatError: (state: ModelStateShape, { error }: { error: Error }): ModelStateShape => ({
            ...state,
            weChatError: error,
        }),
    },

    effects: (dispatch: Dispatch) => ({
        create: async (
            payload: {
                tokenType: TransactionTokenType;
                amount: number;
                currency: string;
                brand: SupportedOnlineBrand;
                email?: string;
            },
            state: StateShape
        ): Promise<ResponseCharge> => {
            const { online: self, checkout } = dispatch;

            const { email, amount, currency, brand } = payload;
            const {
                userData,
                application,
                checkout: { paymentMethodKey },
                configuration: {
                    data: { mode },
                },
                application: { connector },
                intl,
            } = state;
            const {
                metadata,
                metadataCharge,
                metadataToken,
                phoneNumber: paramsPhoneNumber,
                univapayCustomerId,
                univapayReferenceId,
            } = application.params.params;

            let token: TransactionTokenItem;

            const getOnlineTestBrand = (brand: SupportedOnlineBrand): OnlineBrand | PatchedOnlineBrand => {
                switch (brand) {
                    case OnlineTestBrand.TEST_ALIPAY_ONLINE:
                        return OnlineBrand.ALIPAY_ONLINE;

                    case OnlineTestBrand.TEST_ALIPAY_PLUS_ONLINE:
                        return OnlineBrand.ALIPAY_PLUS_ONLINE;

                    case OnlineTestBrand.TEST_D_BARAI_ONLINE:
                        return PatchedOnlineBrand.D_BARAI_ONLINE;

                    case OnlineTestBrand.TEST_PAY_PAY_ONLINE:
                        return OnlineBrand.PAY_PAY_ONLINE;

                    case OnlineTestBrand.TEST_WE_CHAT_ONLINE:
                        return OnlineBrand.WE_CHAT_ONLINE;

                    default:
                        return null;
                }
            };

            const tokenBrand = onlineTestBrands.includes(brand)
                ? getOnlineTestBrand(brand)
                : (brand as OnlineBrand | PatchedOnlineBrand);

            const callMethodParams = getOnlineCallMethodParams(tokenBrand);

            const metadataPhoneNumber =
                concatPhoneNumber(userData.phoneNumber || parsePhone(paramsPhoneNumber)) || undefined;

            try {
                token = await sdk.transactionTokens.create({
                    paymentType: PaymentType.ONLINE,
                    data: {
                        brand: tokenBrand as OnlineBrand,
                        userIdentifier: brand === OnlineBrand.WE_CHAT_ONLINE ? state.online.weChatOpenId : undefined,
                        userIdentifierSource: "internal",
                        ...callMethodParams,
                    } as TransactionTokenOnlineData,
                    type: TransactionTokenType.ONE_TIME,
                    metadata: {
                        // Common information metadata
                        [UnivaMetadata.PHONE_NUMBER]: metadataPhoneNumber,
                        [UnivaMetadata.ADDRESS_CITY]: userData.city || undefined,
                        [UnivaMetadata.ADDRESS_COUNTRY]: userData.country || undefined,
                        [UnivaMetadata.ADDRESS_ZIP]: userData.zip || undefined,
                        [UnivaMetadata.ADDRESS_STATE]: userData.state || undefined,
                        [UnivaMetadata.ADDRESS_LINE1]: userData.line1 || undefined,
                        [UnivaMetadata.ADDRESS_LINE2]: userData.line2 || undefined,
                        [UnivaMetadata.CUSTOMER_ID]: univapayCustomerId || undefined,
                        [UnivaMetadata.LEGACY_CUSTOMER_ID]: univapayCustomerId || undefined,
                        [UnivaMetadata.REFERENCE_ID]: univapayReferenceId,
                        [UnivaMetadata.PRODUCT_NAMES]: state.product.products
                            ?.map((product) => product.name)
                            ?.join(getIntlMessage(LOCALE_LABELS.COMMON_COMMA, intl)),

                        ...metadata,
                        ...metadataToken,
                        ...(state.product.products || []).reduce(
                            (acc, product) => ({ ...acc, ...product.metadata }),
                            {}
                        ),
                    },
                    email,
                });

                const { id: chargeId, storeId } = await sdk.charges.create(
                    {
                        amount,
                        currency,
                        transactionTokenId: token.id,
                        metadata: {
                            // Common information metadata
                            [UnivaMetadata.PHONE_NUMBER]: metadataPhoneNumber,
                            [UnivaMetadata.ADDRESS_CITY]: userData.city || undefined,
                            [UnivaMetadata.ADDRESS_COUNTRY]: userData.country || undefined,
                            [UnivaMetadata.ADDRESS_ZIP]: userData.zip || undefined,
                            [UnivaMetadata.ADDRESS_STATE]: userData.state || undefined,
                            [UnivaMetadata.ADDRESS_LINE1]: userData.line1 || undefined,
                            [UnivaMetadata.ADDRESS_LINE2]: userData.line2 || undefined,
                            [UnivaMetadata.CUSTOMER_ID]: univapayCustomerId || undefined,
                            [UnivaMetadata.LEGACY_CUSTOMER_ID]: univapayCustomerId || undefined,
                            [UnivaMetadata.REFERENCE_ID]: univapayReferenceId,
                            [UnivaMetadata.PRODUCT_NAMES]: state.product.products
                                ?.map((product) => product.name)
                                ?.join(getIntlMessage(LOCALE_LABELS.COMMON_COMMA, intl)),

                            ...metadata,
                            ...metadataCharge,
                            ...userData.customFields,
                            ...(state.product.products || []).reduce(
                                (acc, product) => ({ ...acc, ...product.metadata }),
                                {}
                            ),
                        },
                    },
                    { idempotentKey: uuid() }
                );

                const charge = await sdk.charges.poll(storeId, chargeId);
                if (charge.status === ChargeStatus.ERROR || charge.status === ChargeStatus.FAILED) {
                    throw new ChargeError();
                }

                // Allow skipping the issuer in test mode with +charge-pending email virtually making the charge
                // to be in a pending state until API timeout for testing purpose
                if (mode === ProcessingMode.TEST && email && email.includes("+charge-pending")) {
                    self.setIssuerTokenData({ storeId, chargeId, issuerToken: "", issuerTokenPayload: {} });
                } else {
                    const { issuerToken, payload } = (await sdk.charges.getIssuerToken(
                        storeId,
                        chargeId
                    )) as IssuerTokenItem & { payload: string };
                    self.setIssuerTokenData({
                        storeId,
                        chargeId,
                        issuerToken,
                        issuerTokenPayload: payload && typeof payload === "string" ? JSON.parse(payload) : payload,
                    });
                }

                return charge;
            } catch (error) {
                raiseAPIClientError(error, "Online charge creation failure");
                await self.setIssuerTokenError({ error });
                checkout.setError({ error });
                checkout.resetProcessed();
                connector.emitter.emit("checkout:error", {
                    error,
                    resourceType: ResourceType.CHARGE,
                    tokenId: token?.id,
                });

                if (token) {
                    await sdk.transactionTokens.delete(token.storeId, token.id);
                }

                await dispatch(redirect(getOnlineRoutes(StepName.CONFIRM, paymentMethodKey)));
            }
        },

        poll: async (payload: { storeId: string; chargeId: string }, state: StateShape): Promise<ResponseCharge> => {
            const { checkout, application } = dispatch;

            const { storeId, chargeId } = payload;
            let timer;

            const {
                application: { connector },
                online: { token },
                checkout: { paymentMethodKey },
            } = state;

            try {
                await dispatch(redirect(getOnlineRoutes(StepName.CONFIRM, paymentMethodKey)));

                const successCondition = ({ status }) =>
                    ![ChargeStatus.AWAITING, ChargeStatus.PENDING].includes(status);
                const charge = await sdk.charges.poll(storeId, chargeId, null, null, null, null, successCondition);

                if (charge.status === ChargeStatus.ERROR || charge.status === ChargeStatus.FAILED) {
                    throw new ChargeError();
                }

                checkout.setData({ data: charge, resourceType: ResourceType.CHARGE });
                checkout.setProcessed();
                checkout.clearError();
                application.autoCloseApplication();
                connector.emitter.emit("checkout:success", {
                    resourceType: ResourceType.CHARGE,
                    response: charge,
                    tokenId: token?.id,
                    chargeId: charge?.id,
                });

                return charge;
            } catch (error) {
                raiseAPIClientError(error, "Online polling failure");
                checkout.setError({ error });
                checkout.resetProcessed();
                connector.emitter.emit("checkout:error", {
                    error,
                    resourceType: ResourceType.CHARGE,
                    tokenId: token?.id,
                    chargeId: chargeId,
                });
            } finally {
                if (timer) {
                    clearTimeout(timer);
                }

                await dispatch(redirect(getOnlineRoutes(StepName.CONFIRM, paymentMethodKey)));
            }
        },

        getWeChatOpenId: async ({ appId }: { appId: string }): Promise<{ appId: string; openId: string }> => {
            const { online: self } = dispatch;

            try {
                const { code } = await sdk.checkoutInfoBrand.getWeChatAuthCode({ appId });
                const response = await sdk.checkoutInfoBrand.getWeChatOpenId({ authorizationCode: code });

                self.setWeChatOpenId({ openId: response.openId });

                return response;
            } catch (error) {
                self.setWeChatError({ error });
                console.error(error);
            }
        },

        openWeChatWidget: async (_?, state?: StateShape) => {
            // TODO: Add global type
            (window as any).WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                JSON.parse(state.online.issuerToken),
                (res) => {
                    if (res.err_msg !== "get_brand_wcpay_request:ok") {
                        console.warn(`WeChat failed with error ${res.err_msg}`);
                    }
                }
            );
        },
    }),
};

export const online = createModel()(model);
