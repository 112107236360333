import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "지불 처리가 실패했습니다.",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "지불 처리.이 위젯을 닫지 마십시오.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "성공적인 지불 처리.",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "지불 정보를 등록하는 데 성공했습니다.",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "편의점 지불을 등록하는 데 성공했습니다. 가까운 상점을 방문하여 비용을 지불하십시오.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "성공적인 지불은 성공했습니다.",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "{money}의 지불",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "지불하다",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "지불하다",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "카드 정보",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "로컬 카드 호환",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "편의점 지불 정보",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "편의점 지불 또는 급여-지침",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "처리 시간이 정해져 있습니다. 지원에 연락하십시오.",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "정기적 인 지불이 성공적이었습니다.",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "정기적 인 지불이 업데이트되고 있습니다. 이 위젯을 닫지 마십시오.",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "커뮤니케이션 오류가되었습니다. 지불 확인을 위해 다음 지원 다이얼에 문의하십시오.",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "지불 처리에 관한 문의",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "UNIVA Paycast 고객센터",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "전화 : 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "* 국제 전화, IP 전화 및 PHS 고객은 06-6538-2060을 사용하십시오.",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "이메일 : {email}",

    // Review
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} 마지막 번호 {lastfour}",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "만료 날짜 {month}/{year}",
};

export default LABELS;
