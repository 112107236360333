/**
 *  @module Resources/Charges
 */

import {
    AuthParams,
    DefinedRoute,
    HTTPMethod,
    InstallmentPlan,
    ResponseSubscription,
    ScheduleSettings,
    SubscriptionCreateParams,
    SubscriptionPeriod,
    Subscriptions as NodeSubscriptions,
} from "univapay-node";

export type PatchedSubscriptionCreateParams = Omit<SubscriptionCreateParams, "period"> & {
    subscriptionPlan?: { planType: InstallmentPlan; fixedCycles?: number; fixedCyclesAmount?: number };
    period?: SubscriptionPeriod;
    cyclicalPeriod?: string;
    scheduleSettings: ScheduleSettings;
};

export class Subscriptions extends NodeSubscriptions {
    private _patchedCreate?: DefinedRoute;
    patchedCreate(data: PatchedSubscriptionCreateParams, auth?: AuthParams): Promise<ResponseSubscription> {
        this._patchedCreate = this._patchedCreate ?? this.defineRoute(HTTPMethod.POST, "/subscriptions");
        return this._patchedCreate(data, auth);
    }
}
