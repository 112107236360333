import * as FORM_LABELS from "../labels/paidy";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.PAIDY_PROCESSING_INFO]: "You are being redirected to a Paidy token generation process. Please wait...",
    [FORM_LABELS.PAIDY_CONFIRM_CHARGE]: "You have been authenticated with Paidy please complete the payment by clicking the button below",

    [FORM_LABELS.PAIDY_DATA_TITLE]: "Paidy",

    [FORM_LABELS.PAIDY_FORM_DATA_DOB]: "Date of birth (e.g. 1970-01-30)",
};

export default LABELS;
