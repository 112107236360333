export const ERROR_CHECKOUT_PARAMS = "ERROR_CHECKOUT_PARAMS";

export class CheckoutParametersError extends Error {
    code: string = ERROR_CHECKOUT_PARAMS;
    errors: string[] = null;

    constructor(errors: string | string[], message?: string) {
        super(message);
        this.errors = Array.isArray(errors) ? errors : [errors];
    }
}
