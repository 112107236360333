import * as DURATION_LABELS from "../labels/duration";

export const LABELS: Record<string, string> = {
    [DURATION_LABELS.DURATION_SPACER]: "",
    [DURATION_LABELS.DURATION_YEARS]: "{value} год/лет",
    [DURATION_LABELS.DURATION_MONTHS]: "{value} месяц(-а/-ев)",
    [DURATION_LABELS.DURATION_WEEKS]: "{value} неделя(-и/-ь)",
    [DURATION_LABELS.DURATION_DAYS]: "{value} день(-я/-ей)",
};

export default LABELS;
