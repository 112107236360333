import * as BANK_TRANSFER_LABELS from "../labels/bank-transfer";

export const LABELS: Record<string, string> = {
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_INSTRUCTION]: "다음 은행 계좌로 이체하십시오",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION]: "절차 완료 이메일에서 지불 대상 정보를 확인하거나 상점에 문의하십시오",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_NAME]: "금융 기관 이름",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BANK_CODE]: "은행 코드",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_NAME]: "지점명",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_BRANCH_CODE]: "지점 코드",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER]: "계좌 번호",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_ACCOUNT_NAME]: "계정 소유자",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_AMOUNT]: "돈의 양도 금액",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_DEADLINE]: "이전 마감일",

    // Subscriptions
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_PAYMENT]: "초기 전송 금액",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_FIRST_DEADLINE]: "첫 이체 마감일",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_PAYMENT]: "다음 전송 금액",
    [BANK_TRANSFER_LABELS.BANK_TRANSFER_NEXT_DEADLINE]: "다음에 이적 마감일",
};

export default LABELS;
