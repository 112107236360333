import { StateShape } from "checkout/ts/redux/store";

export const needsCvv = (state: StateShape, selectedTokenId?: string) => {
    const { application, configuration, tokens, product } = state;
    const { showCvv, amount, currency } = application.params?.params || {};
    const { allowEmptyCvv } = configuration.data?.cardConfiguration || {};
    const { enabled: thresholdEnabled, threshold } = configuration.data?.recurringCardChargeCvvConfirmation || {};
    const { cvvRequired } = tokens;

    if (!selectedTokenId) {
        return allowEmptyCvv && !cvvRequired ? showCvv : true;
    }

    const widgetCurrency = currency || product.products?.[0]?.currency;

    const thresholds = thresholdEnabled && threshold ? threshold : [];
    const cvvThreshold = widgetCurrency
        ? thresholds.find((threshold) => threshold.currency?.toUpperCase() === widgetCurrency.toUpperCase())
        : undefined;
    const isBelowThreshold = allowEmptyCvv || !amount || (cvvThreshold?.amount || 0) >= amount;

    return cvvRequired || !isBelowThreshold;
};

export const trimConsecutiveSpaces = (text: string) => text?.replace(/\s{2,}/g, " ");

export const hasValidCardHolderNameCharacter = (text: string) => {
    if (!text) {
        return false;
    }

    return Boolean(/^[A-Z0-9'"._\- ]*$/.exec(text.toUpperCase()));
};

export const hasCardHolderValidLength = (text: string) => {
    if (!text) {
        return false;
    }

    return text.length >= 2 && text.length <= 45;
};
export const hasBlankSpace = (text: string) => !!text?.trim().includes(" ");

export const isEmail = (email: string): boolean => {
    const emailRegExp = new RegExp("[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*");

    return !!email.match(emailRegExp)?.length;
};
