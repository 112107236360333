import { OnlineBrand } from "univapay-node";

export enum OnlineTestBrand {
    TEST_ALIPAY_ONLINE = "test-alipay-online",
    TEST_ALIPAY_PLUS_ONLINE = "test-alipay-plus-online",
    TEST_D_BARAI_ONLINE = "test-d-barai-online",
    TEST_PAY_PAY_ONLINE = "test-pay-pay-online",
    TEST_WE_CHAT_ONLINE = "test-we-chat-online",
}

export enum PatchedOnlineBrand {
    D_BARAI_ONLINE = "d_barai_online",
}

export type SupportedOnlineBrand = OnlineBrand | OnlineTestBrand | PatchedOnlineBrand;
export const onlineTestBrands: SupportedOnlineBrand[] = [...Object.values(OnlineTestBrand)];

export const SUPPORTED_ONLINE_GATEWAYS = [
    OnlineBrand.ALIPAY_ONLINE,
    PatchedOnlineBrand.D_BARAI_ONLINE,
    OnlineBrand.PAY_PAY_ONLINE,
    OnlineBrand.WE_CHAT_ONLINE,
    OnlineBrand.ALIPAY_PLUS_ONLINE,
];
