export enum UnivaMetadata {
    NAME = "univapay-name",
    NAME_KANA = "univapay-name-kana",
    PHONE_NUMBER = "univapay-phone-number",
    ADDRESS_COUNTRY = "univapay-address-country",
    ADDRESS_CITY = "univapay-address-city",
    ADDRESS_STATE = "univapay-address-state",
    ADDRESS_ZIP = "univapay-address-zip",
    ADDRESS_LINE1 = "univapay-address-line1",
    ADDRESS_LINE2 = "univapay-address-line2",
    CUSTOMER_ID = "univapay-customer-id",
    LEGACY_CUSTOMER_ID = "gopay-customer-id",
    REFERENCE_ID = "univapay-reference-id",
    PRODUCT_NAMES = "univapay-product-names",
}
