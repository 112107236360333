/**
 *  @module Resources/TransactionToken
 */

import { POLLING_INTERVAL } from "common/constants";
import { TimeoutError } from "common/errors/TimeoutError";
import { isInline } from "common/utils/browser";
import {
    AuthParams,
    CvvAuthorizedStatus,
    DefinedRoute,
    HTTPMethod,
    PaymentType,
    TransactionTokenCardDataItem,
    TransactionTokenItem,
    TransactionTokens as NodeTransactionToken,
} from "univapay-node";

import { redirectInIFrame } from "../../../common/utils/redirect";
import { TokenError } from "../errors/TokenError";
import { LOCALE_LABELS } from "../locale/labels";
import { store } from "../redux/store";
import { getMessage } from "../utils/intl";

type PollOptions = {
    successCondition: (token: TransactionTokenItem) => boolean;
    failureCondition: (token: TransactionTokenItem) => boolean;
    error: string;

    maxRetry?: number;
    interval?: number;
};

export type IssuerToken = {
    issuerToken: string;
    callMethod: "http_post" | "http_get";
    payload: Record<string, string>;
    paymentType: PaymentType;
    contentType: string;
};

export enum ThreeDsStatus {
    SUCCESSFUL = "successful",
    FAILED = "failed",
    ERROR = "error",
    AWAITING = "awaiting",
    PENDING = "pending",
}

export type PatchedTokenCardData = TransactionTokenCardDataItem & {
    threeDs: {
        enabled: boolean;
        status: ThreeDsStatus;
    };
};

export class TransactionTokens extends NodeTransactionToken {
    private _issuerToken?: DefinedRoute;
    issuerToken(storeId: string, id: string, auth?: AuthParams): Promise<IssuerToken> {
        this._issuerToken =
            this._issuerToken ??
            this.defineRoute(
                HTTPMethod.GET,
                "/stores/:storeId/tokens/:tokenId/three_ds/issuer_token",
                undefined,
                undefined,
                undefined,
                undefined,
                ["payload"]
            );
        return this._issuerToken(null, null, auth, { storeId, tokenId: id });
    }

    async pollCvvAuth(storeId: string, id: string) {
        return this.poll(storeId, id, {
            successCondition: (token) => token.data.cvvAuthorize.status === CvvAuthorizedStatus.CURRENT,
            failureCondition: (token) => token.data.cvvAuthorize?.status === CvvAuthorizedStatus.FAILED,
            error: getMessage(LOCALE_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED),
        });
    }

    async pollPendingThreeDsToken(storeId: string, id: string) {
        return this.poll(storeId, id, {
            successCondition: (token) =>
                (token.data as PatchedTokenCardData)?.threeDs?.status !== ThreeDsStatus.PENDING,
            failureCondition: (token) =>
                [ThreeDsStatus.FAILED, ThreeDsStatus.ERROR].includes(
                    (token.data as PatchedTokenCardData)?.threeDs?.status
                ),
            error: getMessage(LOCALE_LABELS.ERRORS_ALERTS_THREE_DS_FAILED),
        });
    }

    async pollThreeDs(storeId: string, id: string, issuerToken: IssuerToken) {
        const locale = store.getState().intl.locale;
        const promise = this.poll(storeId, id, {
            successCondition: (token) =>
                (token.data as PatchedTokenCardData)?.threeDs?.status === ThreeDsStatus.SUCCESSFUL,
            failureCondition: (token) =>
                [ThreeDsStatus.FAILED, ThreeDsStatus.ERROR].includes(
                    (token.data as PatchedTokenCardData)?.threeDs?.status
                ),
            error: getMessage(LOCALE_LABELS.ERRORS_ALERTS_THREE_DS_FAILED),
        });

        if (isInline()) {
            store
                .getState()
                .application.connector.emitter.emit("checkout:three-ds-authorization-modal", { issuerToken, locale });

            await promise;
        }

        return redirectInIFrame<TransactionTokenItem>({
            url: issuerToken.issuerToken,
            callMethod: issuerToken.callMethod,

            search: issuerToken.payload,
            promise,
            parent: document.querySelector(".dialog-checkout") || window.document.body,
            locale,
        });
    }

    async poll(
        storeId: string,
        id: string,
        { maxRetry = 40, interval = POLLING_INTERVAL, successCondition, failureCondition, error }: PollOptions
    ): Promise<TransactionTokenItem> {
        let retryCount = 0;
        let token: TransactionTokenItem;

        do {
            try {
                token = await this.get(storeId, id);
            } catch (error) {
                console.warn("Could not get transaction token. Waiting for next poll", error);
            }

            // Wait for interval. Prevent flooding the API
            await new Promise((resolve) => setTimeout(resolve, interval));

            retryCount++;
        } while (retryCount < maxRetry && !successCondition(token) && !failureCondition(token));

        if (!successCondition(token) && !failureCondition(token)) {
            throw new TimeoutError(maxRetry * interval);
        }

        if (failureCondition(token)) {
            throw new TokenError(
                token.id,
                "chargeId" in token.data.cvvAuthorize ? token.data.cvvAuthorize.chargeId : null,
                token.storeId,
                error
            );
        }

        return token;
    }
}
