import * as COMMON_LABELS from "../labels/common";

export const LABELS: Record<string, string> = {
    [COMMON_LABELS.COMMON_BUTTONS_BACK]: "กลับ",
    [COMMON_LABELS.COMMON_BUTTONS_CLOSE]: "ปิด",
    [COMMON_LABELS.COMMON_BUTTONS_FINISH]: "เสร็จสมบูรณ์",
    [COMMON_LABELS.COMMON_BUTTONS_NEXT]: "ไปที่ถัดไป",
    [COMMON_LABELS.COMMON_BUTTONS_PREVIOUS]: "กลับ",
    [COMMON_LABELS.COMMON_BUTTONS_SUBMIT]: "ส่ง",
    [COMMON_LABELS.COMMON_TOTAL]: "ทั้งหมด: {totalAmount}",
    [COMMON_LABELS.COMMON_PAY_WITH]: "จ่ายด้วย {method}",

    [COMMON_LABELS.COMMON_OPTIONAL]: "ใดๆ",
    [COMMON_LABELS.COMMON_REQUIRED]: "ที่จำเป็น",

    [COMMON_LABELS.PRIVACY_LINK]: "เกี่ยวกับการจัดการข้อมูลส่วนบุคคล",

    [COMMON_LABELS.COMMON_COMMA]: ",",

    [COMMON_LABELS.COMMON_HEADER_EXTERNAL_FEES]: "+ค่าธรรมเนียมการจัดการ",

    [COMMON_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER]: "ทางเลือก...",
};

export default LABELS;
