import { IntlState } from "react-intl-redux";
import { createModel } from "@rematch/core";
import { CheckoutPaymentData, FormCheckoutData } from "checkout/ts/components/checkout/FormCheckout";
import { FullCardData } from "checkout/ts/components/flows/card/FormData";
import { ChargeError } from "checkout/ts/errors/ChargeError";
import { SubscriptionError } from "checkout/ts/errors/SubscriptionError";
import { TokenError } from "checkout/ts/errors/TokenError";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { PatchedSubscriptionCreateParams } from "checkout/ts/resources/Subscription";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { UnivaMetadata } from "checkout/ts/utils/metadata";
import { raiseAPIClientError } from "checkout/ts/utils/monitoring";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { FORM_CHECKOUT_NAME, FORM_CODE_CONFIRM_NAME, FORM_CUSTOMER_INFO } from "common/constants";
import { TimeoutError } from "common/errors/TimeoutError";
import { ResourceType } from "common/Messages";
import { push as redirect } from "connected-react-router";
import { camelCase, set } from "lodash";
import { clearSubmit, setSubmitFailed, setSubmitSucceeded, startSubmit, stopSubmit } from "redux-form";
import {
    ChargeCreateParams,
    ChargeItem,
    ChargeStatus,
    InstallmentCyclesParams,
    InstallmentPlan,
    PaymentType,
    ResponseError,
    ResponseErrorCode,
    SubscriptionItem,
    SubscriptionPeriod,
    SubscriptionStatus,
    TemporaryTokenAliasItem,
    TemporaryTokenAliasMedia,
    TemporaryTokenAliasQrLogoType,
    TemporaryTokenAliasQrOptions,
    TransactionTokenCardDataItem,
    TransactionTokenItem,
    TransactionTokenType,
    ValidationError,
} from "univapay-node";
import { v4 as uuid } from "uuid";

import { CheckoutParams, CheckoutType, ThreeDsMode } from "../../../../common/types";
import { sdk } from "../../SDK";
import { getStartDate, getStartDatePeriod } from "../../utils/period";
import { rateLimit } from "../rate-limiter";
import { Dispatch, StateShape, store } from "../store";
import { concatPhoneNumber, getIntlMessage, parsePhone } from "../utils/intl";
import { createTransactionToken, isChargeInstallments } from "../utils/token";

import { ApplicationParams } from "./application";
import { PatchedCheckoutInfo } from "./configuration";
import { PatchProductItem } from "./product";
import { PatchedTransactionTokenUpdateParams } from "./tokens";
import { UserDataStateShape } from "./user-data";

export enum NewSubscriptionStatus {
    AUTHORIZED = "authorized",
}

const ChargeSuccessfulStatuses = [ChargeStatus.AUTHORIZED, ChargeStatus.SUCCESSFUL, ChargeStatus.AWAITING];
const SubscriptionFailedStatuses = [
    SubscriptionStatus.UNPAID,
    SubscriptionStatus.UNCONFIRMED,
    SubscriptionStatus.SUSPENDED,
    SubscriptionStatus.CANCELED,
];
const ChargeFailedStatuses = [ChargeStatus.FAILED, ChargeStatus.ERROR];
const SubscriptionSuccessfulStatuses = [SubscriptionStatus.CURRENT, NewSubscriptionStatus.AUTHORIZED];

export const FailedStatuses = [...ChargeFailedStatuses, ...SubscriptionFailedStatuses];
export const SuccessfulStatuses = [...ChargeSuccessfulStatuses, ...SubscriptionSuccessfulStatuses];

type QrData = {
    id: string;
    token: string;
    storeId: string;
};
export type CheckoutResponse = TransactionTokenItem | ChargeItem | SubscriptionItem | QrData;

type LongPollingParams = {
    token: TransactionTokenItem;
    transaction: CheckoutResponse;
    resourceType: ResourceType;
};

const throwWhenChargeError = (charge: ChargeItem, subscription?: SubscriptionItem) => {
    if ((charge && charge.error) || FailedStatuses.includes((subscription ?? charge).status)) {
        throw new ChargeError(charge.error ?? new Error("Failed status"));
    }
};

const generateQrCode = async (
    item: TemporaryTokenAliasItem & { key?: string },
    token: string,
    storeId: string,
    qrColor: string,
    qrLogoType: TemporaryTokenAliasQrLogoType
) => {
    const data: TemporaryTokenAliasQrOptions = {
        media: TemporaryTokenAliasMedia.QR,
        size: 196,
        color: qrColor,
        logo: qrLogoType,
    };

    const blob = await sdk.tokenAliases.get(storeId, item.key, data);
    const encoded = URL.createObjectURL(blob);

    return { id: encoded, token, storeId };
};

const getChargeParams = (
    paymentType: PaymentType,
    applicationParams: ApplicationParams,
    products: PatchProductItem[],
    checkoutInfo: PatchedCheckoutInfo,
    userData: UserDataStateShape,
    tokenId: string,
    intl: IntlState
): ChargeCreateParams & { threeDs? } => {
    const {
        metadata = {},
        metadataCharge = {},
        capture,
        captureAt,
        captureIn,
        captureDayOfMonth,
        descriptor,
        ignoreDescriptorOnError,
        onlyDirectCurrency,
        threeDsMode,
        threeDsRedirect,
    } = applicationParams.params;

    const {
        amount,
        currency,
        univapayCustomerId,
        params: { phoneNumber: paramsPhoneNumber, univapayReferenceId },
    } = getCheckoutParams(applicationParams, products, checkoutInfo);

    const chargeMetadata = {
        // Common information metadata
        [UnivaMetadata.PHONE_NUMBER]:
            concatPhoneNumber(userData.phoneNumber || parsePhone(paramsPhoneNumber)) || undefined,
        [UnivaMetadata.NAME]: userData.name || undefined,
        [UnivaMetadata.NAME_KANA]: userData.nameKana || undefined,
        [UnivaMetadata.ADDRESS_CITY]: userData.city || undefined,
        [UnivaMetadata.ADDRESS_COUNTRY]: userData.country || undefined,
        [UnivaMetadata.ADDRESS_ZIP]: userData.zip || undefined,
        [UnivaMetadata.ADDRESS_STATE]: userData.state || undefined,
        [UnivaMetadata.ADDRESS_LINE1]: userData.line1 || undefined,
        [UnivaMetadata.ADDRESS_LINE2]: userData.line2 || undefined,
        [UnivaMetadata.CUSTOMER_ID]: univapayCustomerId,
        [UnivaMetadata.LEGACY_CUSTOMER_ID]: univapayCustomerId,
        [UnivaMetadata.REFERENCE_ID]: univapayReferenceId,
        [UnivaMetadata.PRODUCT_NAMES]: products
            ?.map((product) => product.name)
            ?.join(getIntlMessage(LOCALE_LABELS.COMMON_COMMA, intl)),

        ...metadata,
        ...metadataCharge,
        ...products?.reduce((acc, { metadata }) => ({ ...acc, ...metadata }), {}),
        ...userData.customFields,
    };

    const chargeData = {
        amount,
        currency,
        metadata: chargeMetadata,
        transactionTokenId: tokenId,
        descriptor,

        /**
         * @deprecated Use threeDs.mode instead. Keeping it for smoother update
         */
        threeDsMode,

        ...(ignoreDescriptorOnError && { ignoreDescriptorOnError }),
        onlyDirectCurrency,
    };

    const captureDate = getStartDate(captureAt, captureIn, captureDayOfMonth)?.toISOString();

    switch (paymentType) {
        case PaymentType.CARD:
        case PaymentType.APPLE_PAY:
        case PaymentType.PAIDY:
            return {
                ...chargeData,
                ...(capture !== undefined ? { capture, captureAt: captureDate } : null),
                threeDs: {
                    mode: threeDsMode,
                    redirectEndpoint:
                        window.location.hostname === "localhost"
                            ? "http://localhost:8888/redirect?status=three_ds_success"
                            : threeDsRedirect || undefined,
                },
            };

        case PaymentType.BANK_TRANSFER:
        case PaymentType.KONBINI:
            return {
                ...chargeData,
                // API does not support capture parameter for those payment method. Only the date is required
                ...(capture !== undefined ? { captureAt: captureDate } : null),
            };

        default:
            return chargeData;
    }
};

const getUserInstallmentsCaptureParams = (
    params: Partial<CheckoutParams>,
    userInstallmentCycles?: number | "revolving"
) => {
    if (params.capture !== false || !userInstallmentCycles) {
        return {};
    }

    const captureIn = getStartDatePeriod(params.captureAt, params.captureIn, params.captureDayOfMonth);
    return { firstChargeAuthorizationOnly: true, firstChargeCaptureAfter: captureIn };
};

const getSubscriptionParams = (
    paymentType: PaymentType,
    applicationParams: ApplicationParams,
    products: PatchProductItem[],
    checkoutInfo: PatchedCheckoutInfo,
    userData: UserDataStateShape,
    tokenId: string,
    userInstallmentCycles: number | "revolving",
    intl: IntlState
): PatchedSubscriptionCreateParams => {
    const { subscriptionTimezone, metadata = {}, metadataSubscription = {} } = applicationParams.params;

    const {
        period,
        initialAmount,
        scheduleSettings: paramsScheduleSettings,
        installmentPlan,
        subscriptionPlan,
        preserveEndOfMonth,
        amount,
    } = getCheckoutParams(applicationParams, products, checkoutInfo);

    const scheduleSettings = {
        ...(subscriptionTimezone ? { zoneId: subscriptionTimezone } : null),
        ...(preserveEndOfMonth ? { preserveEndOfMonth } : null),
        ...paramsScheduleSettings,
    };

    const installmentSelectParams = (() => {
        if (userInstallmentCycles === "revolving") {
            return { planType: InstallmentPlan.REVOLVING };
        } else if (userInstallmentCycles > 1) {
            return {
                planType: InstallmentPlan.FIXED_CYCLES,
                fixedCycles: userInstallmentCycles,
            } as InstallmentCyclesParams;
        }

        return undefined;
    })();

    const plan = subscriptionPlan
        ? { subscriptionPlan }
        : installmentPlan
        ? { installmentPlan }
        : installmentSelectParams
        ? { installmentPlan: installmentSelectParams }
        : {};

    const periodParams = (() => {
        if (userInstallmentCycles === "revolving" || userInstallmentCycles > 1) {
            // Only monthly installment are supported by card processors
            return { period: SubscriptionPeriod.MONTHLY };
        }

        return Object.values(SubscriptionPeriod).includes(period as SubscriptionPeriod)
            ? { period: period as SubscriptionPeriod }
            : { cyclicalPeriod: period };
    })();

    const chargeParams = getChargeParams(
        paymentType,
        applicationParams,
        products,
        checkoutInfo,
        userData,
        tokenId,
        intl
    );

    return {
        ...chargeParams,
        ...getUserInstallmentsCaptureParams(applicationParams.params, userInstallmentCycles),

        ...periodParams,
        scheduleSettings,
        amount,
        initialAmount,
        ...plan,
        metadata: {
            ...metadata,
            ...metadataSubscription,
            ...chargeParams?.metadata,
            ...(products || []).reduce((acc, product) => ({ ...acc, ...product.metadata }), {}),
        },
    };
};

type ModelStateShape = {
    processed: boolean;
    error: any;
    paymentType: PaymentType;
    paymentMethodKey: string;
    confirmed: boolean;
    failedSubscriptionId?: string;
    idempotentKey: string;

    /**
     * Temporary store data for confirmation in case of using a confirmation code (triggered on another page after process)
     */
    confirmationData?: FormCheckoutData;

    /**
     * Final resource of the transaction
     */
    data: CheckoutResponse;

    /**
     * Type of the final resource of the transaction
     */
    resourceType: ResourceType;

    /**
     * Token generated during the transaction
     * Transitionary step when the checkout type is PAYMENT
     */
    token: TransactionTokenItem;

    /**
     * Charge generated during the transaction
     * Transitionary step when creating subscription
     * Not present when the checkout type is TOKEN
     */
    charge?: ChargeItem;

    /**
     * User installment count for later processing of subscription in case of just creating a token.
     * Will be returned in the inline callback.
     */
    userInstallmentCount?: number | "revolving";
};

const initialState: ModelStateShape = {
    processed: false,
    error: null,
    token: null,
    paymentType: null,
    paymentMethodKey: null,
    data: null,
    confirmed: false,
    failedSubscriptionId: null,
    idempotentKey: uuid(),
    confirmationData: null,
    resourceType: null,
    charge: null,
    userInstallmentCount: null,
};

const model = {
    state: initialState,

    reducers: {
        setPaymentMethod: (state: ModelStateShape, { type, key }: { type: PaymentType; key: string }) => ({
            ...state,
            paymentType: type,
            paymentMethodKey: key,
        }),
        setData: (
            state: ModelStateShape,
            { data, resourceType }: { data: CheckoutResponse; resourceType: ResourceType }
        ) => ({ ...state, data, resourceType }),
        setToken: (state: ModelStateShape, { token }: { token: TransactionTokenItem }) => ({ ...state, token }),
        setCharge: (state: ModelStateShape, { charge }: { charge: ChargeItem }) => ({ ...state, charge }),
        setError: (state: ModelStateShape, { error }: { error: Error }) => ({ ...state, error }),
        setProcessed: (state: ModelStateShape) => ({ ...state, processed: true }),
        setConfirmed: (state: ModelStateShape) => ({ ...state, confirmed: true }),
        setFailedSubscriptionId: (state: ModelStateShape, payload: { failedSubscriptionId: string }) => ({
            ...state,
            failedSubscriptionId: payload.failedSubscriptionId,
        }),
        setConfirmationData: (state: ModelStateShape, payload: { data: FormCheckoutData }) => ({
            ...state,
            confirmationData: payload.data,
        }),

        resetIdempotentKey: (state: ModelStateShape) => ({ ...state, idempotentKey: uuid() }),

        clearItem: (state: ModelStateShape) => ({
            ...state,
            resourceType: null,
            data: null,
            token: null,
            charge: null,
        }),
        clearError: (state: ModelStateShape) => ({ ...state, error: null }),
        resetProcessed: (state: ModelStateShape) => ({ ...state, processed: false }),
        resetConfirmed: (state: ModelStateShape) => ({ ...state, confirmed: false }),

        setUserInstallmentCount: (state: ModelStateShape, payload: { userInstallmentCount: number | "revolving" }) => ({
            ...state,
            userInstallmentCount: payload.userInstallmentCount,
        }),
    },

    effects: (dispatch: Dispatch) => ({
        process: async (
            payload: { values: FormCheckoutData; failOnError?: boolean },
            state?: StateShape
        ): Promise<CheckoutResponse | null> => {
            const { values: checkoutData, failOnError = false } = payload;
            const { checkout: self, tokens: tokensDispatch } = dispatch;

            dispatch(clearSubmit(FORM_CHECKOUT_NAME));
            self.clearError();
            self.clearItem();
            self.resetProcessed();

            const {
                application: {
                    connector,
                    params: {
                        params: { merchantCardRegistration },
                    },
                },
                tokens: { tokens },
                checkout: { paymentType, paymentMethodKey },
            } = state;

            let token: TransactionTokenItem = tokens[checkoutData.data.token];
            const isNewToken = !tokens[checkoutData.data.token];

            try {
                // STAGE 1: get or create a new token
                // Don't need to create a token if a saved token is used
                if (merchantCardRegistration) {
                    // in the card registration flow, one merchant can have 1 card registered at a time
                    // therefore if token already registered, delete the old one and create a new one.
                    if (token) {
                        await rateLimit(() => sdk.transactionTokens.delete(token.storeId, token.id));
                    }
                    token = await createTransactionToken({ ...checkoutData, paymentType }, state);
                } else {
                    token = token || (await createTransactionToken({ ...checkoutData, paymentType }, state));
                }

                self.setUserInstallmentCount({ userInstallmentCount: checkoutData.installmentCycles });
                self.setToken({ token });
                self.resetIdempotentKey();

                if (isNewToken) {
                    connector.emitter.emit("checkout:token-created", token);
                }

                // STAGE 2: if confirmation is required (token.confirmed equals exclusively "false"), redirect to confirm flow
                if (token.confirmed === false) {
                    self.setConfirmationData({ data: checkoutData });
                    await dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CODE)));
                    return null;
                } else {
                    return self.complete({
                        ...checkoutData,
                        deleteTokenOnFailure: token.type !== TransactionTokenType.RECURRING && isNewToken,
                    });
                }
            } catch (error) {
                console.error(error);

                // Set cvv auth charge for error display
                if (error instanceof TokenError && error.failedChargeId) {
                    const cvvAuthCharge = await sdk.charges.get(error.storeId, error.failedChargeId);
                    self.setCharge({ charge: cvvAuthCharge });
                }

                const cvvAuthCharge = store.getState().checkout.charge;

                if (error instanceof TimeoutError) {
                    connector.emitter.emit("checkout:pending", {});
                    await dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.PENDING)));
                    return null;
                }

                // General error: Show error on confirm page
                if (!(error instanceof ResponseError) || checkoutData.data.failsOnValidationError) {
                    connector.emitter.emit("checkout:error", {
                        error: cvvAuthCharge?.error || error,
                        resourceType: ResourceType.TRANSACTION_TOKEN,
                        response: token,
                        tokenId: token?.id,
                        chargeId: cvvAuthCharge?.id,
                    });

                    self.setError({ error });
                    self.setProcessed();
                    await dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CONFIRM)));
                    return null;
                }

                if (failOnError) {
                    raiseAPIClientError(error, "Token creation failure");
                    connector.emitter.emit("checkout:error", {
                        error: cvvAuthCharge?.error || error,
                        resourceType: ResourceType.TRANSACTION_TOKEN,
                        response: token,
                        tokenId: token?.id,
                        chargeId: cvvAuthCharge?.id,
                    });
                    self.setError({ error });
                    self.setProcessed();
                    return null;
                }

                const errorCode = error.errorResponse.code;
                const errorMessage = error.errorResponse.errors[0]?.reason || errorCode || "error";
                const invalidFields = {};

                // Set validation error or redirect to confirm page for general errors
                switch (errorCode) {
                    case ResponseErrorCode.ValidationError: {
                        if (error.errorResponse?.errors.some((error) => error.reason === "INVALID_FORMAT_CURRENCY")) {
                            raiseAPIClientError(error, "Token creation failure");
                            connector.emitter.emit("checkout:error", {
                                error,
                                resourceType: ResourceType.TRANSACTION_TOKEN,
                                response: token,
                                tokenId: token?.id,
                            });
                            self.setError({ error });
                            self.setProcessed();
                            await dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CONFIRM)));
                            return null;
                        }

                        (error.errorResponse?.errors as ValidationError[]).forEach((validationError) => {
                            const formattedFieldPath = validationError.field?.split(".").map(camelCase).join(".");
                            set(invalidFields, formattedFieldPath, validationError.reason);
                        });
                        break;
                    }

                    case ResponseErrorCode.CardExpirationMonthInvalid:
                    case ResponseErrorCode.CardExpirationYearInvalid:
                    case ResponseErrorCode.CardExpired:
                        set(invalidFields, "data.exp", errorMessage);
                        break;

                    case ResponseErrorCode.CardCVVInvalid:
                    case ResponseErrorCode.CVVRequired:
                    case ResponseErrorCode.RecurringUsageRequiresCVV:
                        tokensDispatch.setCvvRequired({ cvvRequired: true });
                        set(invalidFields, "data.cvv", errorMessage);
                        break;

                    case ResponseErrorCode.InvalidCard:
                    case ResponseErrorCode.LiveModeNotEnabledWhenUnverified:
                    case ResponseErrorCode.CardBanned:
                    case ResponseErrorCode.CardLocked:
                    case ResponseErrorCode.NoTestCardInLiveMode:
                        set(invalidFields, "data.cardNumber", errorMessage);
                        break;

                    case "EMAIL_EXISTS_FOR_CARD" as ResponseErrorCode:
                        set(invalidFields, "email", errorMessage);
                        dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.INFO)));
                        dispatch(setSubmitFailed(FORM_CUSTOMER_INFO, "email"));
                        dispatch(stopSubmit(FORM_CUSTOMER_INFO, { email: errorCode }));
                        break;

                    default:
                        raiseAPIClientError(error, "Token creation failure");
                        connector.emitter.emit("checkout:error", {
                            error,
                            resourceType: ResourceType.TRANSACTION_TOKEN,
                            response: token,
                            tokenId: token?.id,
                        });
                        self.setError({ error });
                        self.setProcessed();
                        await dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CONFIRM)));
                        return null;
                }

                self.clearError();
                self.resetProcessed();
                dispatch(setSubmitFailed(FORM_CHECKOUT_NAME, ...Object.keys(invalidFields)));
                dispatch(stopSubmit(FORM_CHECKOUT_NAME, invalidFields));
            }
        },

        complete: async (payload: CheckoutPaymentData, state: StateShape): Promise<CheckoutResponse> => {
            if (state.checkout.processed) {
                return;
            }

            const { checkout: self, application } = dispatch;
            const {
                application: {
                    connector,
                    params: {
                        params: { qrColor, qrLogoType, merchantCardRegistration },
                    },
                    params: applicationParams,
                },
                product: { products },
                checkout: { token, idempotentKey, paymentType, paymentMethodKey },
                configuration: { data: configurationData },
                userData,
                intl,
            } = state;

            const { checkoutType, isSubscription } = getCheckoutParams(applicationParams, products, configurationData);

            // Billing data passed as query params
            const {
                shippingAddressLine1,
                shippingAddressLine2,
                shippingAddressState,
                shippingAddressCity,
                shippingAddressCountryCode,
                shippingAddressZip,
                phoneNumber: prefilledPhoneNumber,

                threeDsMode,
            } = applicationParams.params;

            const hasPrefilledAddress =
                !!shippingAddressCity &&
                !!shippingAddressCountryCode &&
                !!shippingAddressLine1 &&
                !!shippingAddressState &&
                !!shippingAddressZip;

            // Identify billing data edited by the user while browsing through the screens
            const addressKeys = new Set(["line1", "line2", "state", "city", "country", "zip"]);

            // Note: Currently only patching token for card payments
            const billingData = (token.data as TransactionTokenCardDataItem)?.billing || {};

            const hasEditedAddress = Object.keys(userData).some(
                (key) =>
                    addressKeys.has(key) && (userData[key] || key === "line2") && userData[key] !== billingData[key]
            );

            const hasEditedPhoneNumber = userData.phoneNumber && userData.phoneNumber !== billingData.phoneNumber;

            const threeDsRequired =
                (configurationData?.cardConfiguration?.threeDsRequired || threeDsMode === ThreeDsMode.FORCE) &&
                threeDsMode !== ThreeDsMode.SKIP;
            const threeDsAddressRequired =
                ((configurationData?.cardConfiguration?.threeDsRequired &&
                    configurationData?.cardConfiguration?.threeDsAddressRequired) ||
                    threeDsMode === ThreeDsMode.FORCE) &&
                threeDsMode !== ThreeDsMode.SKIP;

            let response: CheckoutResponse;
            let resourceType: ResourceType;
            let chargeResource: ChargeItem;
            let subscriptionResource: SubscriptionItem;

            // For only card registration flow we don't need to show any confirmation screen, just close the widget.
            if (merchantCardRegistration) {
                await application.close();
            } else {
                dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CONFIRM)));
            }

            try {
                switch (checkoutType) {
                    case CheckoutType.TOKEN:
                        response = token;
                        resourceType = ResourceType.TRANSACTION_TOKEN;
                        break;

                    case CheckoutType.PAYMENT:
                        // Create subscription for subscription checkout or when the user selected an installment cycle count (>1)
                        if (isSubscription || isChargeInstallments(payload.installmentCycles)) {
                            resourceType = ResourceType.SUBSCRIPTION;

                            if (
                                payload.data.token &&
                                ((threeDsAddressRequired && (hasPrefilledAddress || hasEditedAddress)) ||
                                    (threeDsRequired && (prefilledPhoneNumber || hasEditedPhoneNumber)))
                            ) {
                                await rateLimit(() =>
                                    sdk.transactionTokens.update(token.storeId, token.id, {
                                        data: {
                                            ...(hasPrefilledAddress && {
                                                line1: shippingAddressLine1,
                                                line2: shippingAddressLine2,
                                                state: shippingAddressState,
                                                city: shippingAddressCity,
                                                country: shippingAddressCountryCode,
                                                zip: shippingAddressZip,
                                            }),
                                            ...(prefilledPhoneNumber && {
                                                phoneNumber: parsePhone(prefilledPhoneNumber),
                                            }),
                                            // Always overwrite with the user-edited values
                                            ...(hasEditedAddress && {
                                                line1: userData.line1,
                                                line2: userData.line2,
                                                state: userData.state,
                                                city: userData.city,
                                                country: userData.country,
                                                zip: userData.zip,
                                            }),
                                            ...(hasEditedPhoneNumber && {
                                                phoneNumber: userData.phoneNumber,
                                            }),
                                        },
                                        // TODO: Patching metadata is not currently allowed without using secret - address once changes for https://github.com/univapaycast/gyron-payments-api/issues/7543 are released
                                    } as PatchedTransactionTokenUpdateParams)
                                );
                            }

                            const params = getSubscriptionParams(
                                paymentType,
                                applicationParams,
                                products,
                                configurationData,
                                userData,
                                token.id,
                                payload.installmentCycles,
                                intl
                            );

                            response = await rateLimit(() =>
                                sdk.patchedSubscriptions.patchedCreate(params, { idempotentKey: uuid() })
                            );
                            subscriptionResource = response;

                            if (response) {
                                connector.emitter.emit("checkout:subscription-created", response);
                            }
                            if (SubscriptionFailedStatuses.includes((response as SubscriptionItem)?.status)) {
                                throw new SubscriptionError(
                                    (response as SubscriptionItem)?.status === SubscriptionStatus.UNCONFIRMED
                                        ? response.id
                                        : null
                                );
                            }
                        } else {
                            resourceType = ResourceType.CHARGE;

                            // if required, have to patch the CVV back onto the token before making the charge
                            if (
                                payload.data.token &&
                                ("cvv" in payload.data ||
                                    (threeDsAddressRequired && (hasPrefilledAddress || hasEditedAddress)) ||
                                    (threeDsRequired && (prefilledPhoneNumber || hasEditedPhoneNumber)))
                            ) {
                                await rateLimit(() =>
                                    sdk.transactionTokens.update(token.storeId, token.id, {
                                        data: {
                                            ...("cvv" in payload.data && { cvv: (payload.data as FullCardData).cvv }),
                                            ...(hasPrefilledAddress && {
                                                line1: shippingAddressLine1,
                                                line2: shippingAddressLine2,
                                                state: shippingAddressState,
                                                city: shippingAddressCity,
                                                country: shippingAddressCountryCode,
                                                zip: shippingAddressZip,
                                            }),
                                            ...(prefilledPhoneNumber && {
                                                phoneNumber: parsePhone(prefilledPhoneNumber),
                                            }),
                                            // Always overwrite with the user-edited values
                                            ...(hasEditedAddress && {
                                                line1: userData.line1,
                                                line2: userData.line2,
                                                state: userData.state,
                                                city: userData.city,
                                                country: userData.country,
                                                zip: userData.zip,
                                            }),
                                            ...(hasEditedPhoneNumber && {
                                                phoneNumber: userData.phoneNumber,
                                            }),
                                        },
                                        // TODO: Patching metadata is not currently allowed without using secret - address once changes for https://github.com/univapaycast/gyron-payments-api/issues/7543 are released
                                    } as PatchedTransactionTokenUpdateParams)
                                );
                            }

                            const params = getChargeParams(
                                paymentType,
                                applicationParams,
                                products,
                                configurationData,
                                userData,
                                token.id,
                                intl
                            );

                            response = await sdk.charges.create(params, { idempotentKey });

                            chargeResource = response;
                            self.setCharge({ charge: response });

                            if (response) {
                                connector.emitter.emit("checkout:charge-created", response);
                            }

                            throwWhenChargeError(response);
                        }
                        break;

                    case CheckoutType.QR: {
                        const aliasResponse = await sdk.tokenAliases.create({ transactionTokenId: token.id });
                        response = await generateQrCode(aliasResponse, token.id, token.storeId, qrColor, qrLogoType);
                        resourceType = ResourceType.TRANSACTION_TOKEN;
                        break;
                    }
                }

                if (checkoutType === CheckoutType.PAYMENT) {
                    // Get the state of the payment and emit callback
                    const { charge, subscription } = await self.longPolling({
                        token,
                        transaction: response,
                        resourceType,
                    });
                    response = subscription ?? charge;
                    chargeResource = charge;
                    subscriptionResource = subscription;

                    throwWhenChargeError(charge, subscription);

                    if (
                        charge
                            ? charge.status === ChargeStatus.PENDING
                            : subscription?.status === SubscriptionStatus.UNVERIFIED
                    ) {
                        throw new TimeoutError();
                    }
                }

                // Since the state is known emit callback
                self.setData({ data: response, resourceType });
                self.setProcessed();
                dispatch(setSubmitSucceeded(FORM_CHECKOUT_NAME));
                connector.emitter.emit("checkout:success", {
                    resourceType,
                    response: response ?? subscriptionResource ?? chargeResource,
                    tokenId: token?.id,
                    chargeId: chargeResource?.id,
                    subscriptionId: subscriptionResource?.id,
                });
                await application.autoCloseApplication();

                return response;
            } catch (error) {
                const {
                    checkout: { charge, data, token },
                } = store.getState() as StateShape;

                if (error instanceof TimeoutError) {
                    console.error("Timeout error");
                    await dispatch(redirect(getStepUrl(token?.paymentType, paymentMethodKey, StepName.PENDING)));
                    self.setProcessed();
                    connector.emitter.emit("checkout:pending", { resourceType, response: data, tokenId: token?.id });
                    return response;
                }

                // Set validation error or redirect to confirm page for general errors
                switch (error?.errorResponse?.code) {
                    case "EMAIL_EXISTS_FOR_CARD" as ResponseErrorCode: {
                        const errorCode = error.errorResponse.errors[0]?.reason || error.errorResponse.code;

                        self.clearError();
                        self.resetProcessed();
                        dispatch(redirect(getStepUrl(paymentType, paymentMethodKey, StepName.INFO)));
                        dispatch(setSubmitFailed(FORM_CUSTOMER_INFO, "email"));
                        dispatch(stopSubmit(FORM_CUSTOMER_INFO, { email: errorCode }));
                        break;
                    }

                    default:
                        self.setProcessed();
                        raiseAPIClientError(error, "Transaction creation failure");
                        console.error(error);
                        self.setError({ error });
                        connector.emitter.emit("checkout:error", {
                            error,
                            resourceType,
                            response: data,
                            tokenId: token?.id,
                            chargeId: charge?.id,
                            subscriptionId: resourceType === ResourceType.SUBSCRIPTION ? data?.id : null,
                        });

                        if (payload.deleteTokenOnFailure) {
                            await sdk.transactionTokens.delete(token.storeId, token.id);
                        }

                        if (state.application.params.params.autoCloseOnError) {
                            await application.autoCloseApplication();
                        }
                }

                return response;
            }
        },

        longPolling: async (
            payload: LongPollingParams,
            state: StateShape
        ): Promise<{ charge: ChargeItem; subscription: SubscriptionItem }> => {
            const { checkout: self } = dispatch;
            const { transaction, resourceType, token } = payload;

            let subscriptionResource: SubscriptionItem;
            let chargeResource: ChargeItem;
            self.clearError();

            if ("period" in transaction || "cyclicalPeriod" in transaction) {
                const [subscription, charge] = await Promise.all([
                    sdk.subscriptions.poll(
                        transaction.storeId,
                        transaction.id,
                        null,
                        null,
                        null,
                        null,
                        // For authorized charges, bank transfer and konbini, the subscription will stay unverified as long as the charge is authorized
                        ({ status }) =>
                            status !== SubscriptionStatus.UNVERIFIED ||
                            [ChargeStatus.AUTHORIZED, ChargeStatus.AWAITING].includes(
                                (store.getState() as StateShape).checkout.charge?.status
                            ),
                        (subscription) => {
                            subscriptionResource = subscription;
                        }
                    ),
                    (() =>
                        (transaction as SubscriptionItem).initialAmount === 0
                            ? Promise.resolve(null)
                            : sdk.api
                                  .longPolling(
                                      () => sdk.subscriptions.charges(transaction.storeId, transaction.id),
                                      (charges) => !!charges.items.length
                                  )
                                  .then(({ items: [{ storeId, id }] }) =>
                                      sdk.charges.poll(
                                          storeId,
                                          id,
                                          null,
                                          null,
                                          null,
                                          undefined,
                                          undefined,
                                          (charge) => {
                                              chargeResource = charge;
                                              self.setCharge({ charge: chargeResource });
                                          }
                                      )
                                  ))(),
                ]);
                subscriptionResource = subscription;
                chargeResource = charge;
            } else {
                subscriptionResource = null;
                chargeResource = await sdk.charges.poll(transaction.storeId, transaction.id);
            }

            if (
                token.paymentType === PaymentType.CARD && // 3DS is only for cards
                chargeResource?.status === ChargeStatus.AWAITING && // Only charge waiting for 3DS are awaiting
                token.type !== TransactionTokenType.RECURRING // Recurring token already are 3DS authorized
            ) {
                state.application.connector.emitter.emit("checkout:three-ds-authorization", {
                    resourceType: ResourceType.TRANSACTION_TOKEN,
                    response: chargeResource,
                    tokenId: token?.id,
                    chargeId: chargeResource?.id,
                });
                try {
                    const issuerToken = await sdk.patchedCharges.issuerToken(chargeResource.storeId, chargeResource.id);
                    chargeResource = await sdk.patchedCharges.pollThreeDs(
                        chargeResource.storeId,
                        chargeResource.id,
                        issuerToken
                    );
                    state.application.connector.emitter.emit("checkout:three-ds-authorization-success", {
                        resourceType: ResourceType.TRANSACTION_TOKEN,
                        response: chargeResource,
                        tokenId: token?.id,
                        chargeId: chargeResource?.id,
                    });
                } catch (error) {
                    state.application.connector.emitter.emit("checkout:three-ds-authorization-failure", {
                        error: chargeResource.error || error,
                        resourceType: subscriptionResource ? ResourceType.SUBSCRIPTION : ResourceType.CHARGE,
                        response: chargeResource,
                        tokenId: chargeResource?.id,
                    });
                }
            }

            self.setCharge({ charge: chargeResource });
            self.setData({ data: subscriptionResource ?? chargeResource, resourceType });

            if (chargeResource === null && subscriptionResource === null) {
                // Cancel or timeout
                throw new TimeoutError();
            }

            return { charge: chargeResource, subscription: subscriptionResource };
        },

        confirmCode: async (payload: { code: string }, state: StateShape) => {
            const { checkout: self } = dispatch;
            const { id, storeId } = state.checkout.token;

            try {
                self.clearError();
                self.resetConfirmed();

                dispatch(startSubmit(FORM_CODE_CONFIRM_NAME));
                await sdk.transactionTokens.confirm(storeId, id, { confirmationCode: payload.code });

                self.setConfirmed();
                await self.complete(state.checkout.confirmationData);

                dispatch(stopSubmit(FORM_CODE_CONFIRM_NAME));
            } catch (error) {
                self.setError();
                dispatch(stopSubmit(FORM_CODE_CONFIRM_NAME, error));
            }
        },
    }),
};

export const checkout = createModel()(model);
