import { createModel } from "@rematch/core";
import { FormCheckoutData } from "checkout/ts/components/checkout/FormCheckout";
import { SubscriptionError } from "checkout/ts/errors/SubscriptionError";
import { TokenError } from "checkout/ts/errors/TokenError";
import { raiseAPIClientError } from "checkout/ts/utils/monitoring";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { ResourceType } from "common/Messages";
import { push as redirect } from "connected-react-router";
import {
    ErrorResponse,
    PaymentType,
    ResponseError,
    ResponseTransactionToken,
    SubscriptionItem,
    TransactionTokenItem,
} from "univapay-node";

import { sdk } from "../../SDK";
import { Dispatch, StateShape } from "../store";
import { createTransactionToken } from "../utils/token";

export type SubscriptionStateShape = {
    record: SubscriptionItem;
    error?: ResponseError | ErrorResponse | Error;
    isPatching: boolean;
};

const initialState: SubscriptionStateShape = {
    record: null,
    error: null,
    isPatching: false,
};

const model = {
    state: initialState,

    reducers: {
        setRecord: (state: SubscriptionStateShape, { record }: { record: SubscriptionItem }) => ({
            ...state,
            record,
        }),

        setError: (state: SubscriptionStateShape, { error }: { error: ResponseError | ErrorResponse | Error }) => ({
            ...state,
            error,
        }),

        setPatching: (state: SubscriptionStateShape, { isPatching }: { isPatching: boolean }) => ({
            ...state,
            isPatching,
        }),
    },

    effects: (dispatch: Dispatch) => ({
        update: async (
            payload: { subscriptionId: string; data: FormCheckoutData; selectedToken?: TransactionTokenItem },
            state: StateShape
        ) => {
            const { subscription: self, checkout, application } = dispatch;
            const { subscriptionId, data, selectedToken } = payload;

            const {
                application: { connector },
                checkout: { paymentMethodKey },
            } = state;

            self.setPatching({ isPatching: true });

            let newToken: ResponseTransactionToken = null;
            try {
                await dispatch(redirect(getStepUrl(PaymentType.CARD, paymentMethodKey, StepName.CONFIRM)));

                if (!selectedToken) {
                    newToken = await createTransactionToken(data, state);
                }

                const token = selectedToken ?? newToken;

                const patchedSubscription = await sdk.subscriptions.update(token.storeId, subscriptionId, {
                    transactionTokenId: token.id,
                });

                if (!patchedSubscription) {
                    throw new SubscriptionError();
                }

                self.setRecord({ record: patchedSubscription });
                checkout.setData({ data: patchedSubscription, resourceType: ResourceType.SUBSCRIPTION });

                checkout.setProcessed();
                checkout.clearError();
                application.autoCloseApplication();
                connector.emitter.emit("checkout:success", {
                    resourceType: ResourceType.SUBSCRIPTION,
                    response: patchedSubscription,
                    tokenId: token?.id,
                });
            } catch (error) {
                // Set cvv auth charge for error display
                if (error instanceof TokenError && error.failedChargeId) {
                    const cvvAuthCharge = await sdk.charges.get(error.storeId, error.failedChargeId);
                    checkout.setCharge({ charge: cvvAuthCharge });
                }

                if (newToken) {
                    await sdk.transactionTokens.delete(newToken.storeId, newToken.id);
                }

                raiseAPIClientError(error, "Token update failure");
                self.setError({ error });
                checkout.setError({ error });
                checkout.resetProcessed();
            }
        },
    }),
};

export const subscription = createModel()(model);
