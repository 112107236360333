import { updateIntl } from "react-intl-redux";
import { createModel } from "@rematch/core";
import { Connector } from "checkout/ts/Connector";
import { CheckoutParametersError } from "checkout/ts/errors/CheckoutParametersError";
import { getLocaleData } from "checkout/ts/locale";
import { AUTO_CLOSE_TIMEOUT_DEFAULT } from "common/constants";
import { getLocale } from "common/locale";
import { CheckoutParams, CheckoutType } from "common/types";
import { formatParameters, schema } from "common/validation/schema";
import { omit, pick } from "lodash";

import { Dispatch, StateShape } from "../store";

import { SuccessfulStatuses } from "./checkout";

export type ApplicationParams = {
    appId?: string;
    checkout?: CheckoutType;
    params?: CheckoutParams;
};

type ModelStateShape = {
    open?: boolean;

    params?: ApplicationParams;
    globalError?: Error;
    connector?: Connector;
    origin?: string;
};

const initialState: ModelStateShape = {
    open: false,
    connector: null,
    globalError: null,
    params: {
        params: {
            locale: "auto",
        } as CheckoutParams,
    },
};

const model = {
    state: initialState,

    reducers: {
        setConnector: (state: ModelStateShape, { connector }: { connector: Connector }) => ({ ...state, connector }),
        setError: (state: ModelStateShape, { error }: { error: Error }) => ({ ...state, globalError: error }),
        setParams: (state: ModelStateShape, { params }: { params: ApplicationParams }) => ({ ...state, params }),

        open: (state: ModelStateShape) => ({ ...state, open: true }),
        setClose: (state: ModelStateShape) => ({ ...state, open: false }),

        clearItem: (state: ModelStateShape) => ({ ...state, data: null }),
        clearError: (state: ModelStateShape) => ({ ...state, error: null }),
    },

    effects: (dispatch: Dispatch) => ({
        connectOrigin: async (): Promise<Connector> => {
            const { application: self } = dispatch;

            const connector = new Connector();
            self.setConnector({ connector });

            try {
                const connectorParams = (await connector.connect()) as ApplicationParams;

                const locale = getLocale(connectorParams.params.locale);
                await dispatch(updateIntl(getLocaleData(locale)));

                const { params: buttonParams, ...appParams } = connectorParams;
                const params = formatParameters({ ...buttonParams, ...appParams }, { hasLegacyPlanParameter: true });

                try {
                    schema.validateSync(params);
                } catch (errors) {
                    throw new CheckoutParametersError(errors);
                }

                const appParamsKeys = Object.keys(appParams);
                const formattedParams = omit(params, appParamsKeys);
                self.setParams({
                    params: { ...pick(params, appParamsKeys), params: formattedParams as CheckoutParams },
                });

                return connector;
            } catch (error) {
                self.setError({ error });
            } finally {
                connector.emitter.emit("checkout:opened");
                self.open();
            }
        },

        autoCloseApplication: async (_?, state?: StateShape) => {
            const { application: self } = dispatch;
            const { autoClose = false, autoCloseOnError = false } = state.application.params.params;
            const { data, processed } = state.checkout;

            if (!autoClose && !autoCloseOnError) {
                return; // The application should not auto close from the parameter. Do not close.
            }
            if (!processed || (data?.["status"] && !SuccessfulStatuses.includes(data["status"]) && !autoCloseOnError)) {
                return;
            }

            await new Promise((resolve) => setTimeout(resolve, AUTO_CLOSE_TIMEOUT_DEFAULT)); // Delay auto close
            await self.close();
        },

        close: async (_?, state?: StateShape) => {
            const { application: self } = dispatch;
            const {
                connector,
                params: {
                    params: { hasBeforeClosingCallback },
                },
            } = state.application;
            const { failedSubscriptionId } = state.checkout;

            // Connector connection may have been lost when the parent page is closed. Do not assume the connector
            if (connector) {
                const shouldClose = await (async () => {
                    if (!hasBeforeClosingCallback) {
                        return true;
                    }

                    try {
                        return connector.beforeClosing();
                    } catch (error) {
                        console.error(error);
                        return true; // Return true on error for the user not to be stuck and to be consistent with the no connector
                    }
                })();

                if (!shouldClose) {
                    return;
                }

                connector.emitter.emit("checkout:closed", { failedSubscriptionId });
            }

            self.setClose();
        },
    }),
};

export const application = createModel()(model);
