import {
    MessageChargeCreated,
    MessageError,
    MessageSubscriptionCreated,
    MessageSuccess,
    MessageThreeDsAuthorization,
    MessageThreeDsAuthorizationFailure,
    MessageThreeDsAuthorizationSuccess,
    MessageTokenCreated,
} from "common/Messages";
import { lcfirst } from "common/utils/string";
import {
    ECFormCustomField,
    ECFormCustomFieldType,
    InstallmentPlan,
    OnlineBrand,
    PaymentType,
    TemporaryTokenAliasQrLogoType,
    TransactionTokenType,
    UsageLimit,
} from "univapay-node";
import * as Yup from "yup";

import "./yup-extend";

import { Countries, Timezones } from "../locale/constants";
import { LOCALE_LABELS } from "../locale/labels";
import { CheckoutParams, CheckoutStyles, CheckoutType, InlineStyleKeys, MetaParams, ThreeDsMode } from "../types";

import { toArray, toBoolean, toNumber, toObject } from "./utils";

enum MetaKey {
    Charge = "metaCharge",
    Subscription = "metaSubscription",
    Token = "metaToken",
}

export const updateSchema = Yup.object({
    metadata: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataCharge: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataSubscription: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataToken: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),

    name: Yup.string(),
    nameKana: Yup.string(),
    email: Yup.string().email(),
    phoneNumber: Yup.object({
        countryCode: Yup.string(),
        localNumber: Yup.string(),
    }),
    address: Yup.object({
        zip: Yup.string(),
        line1: Yup.string(),
        line2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        countryCode: Yup.string()
            .transform((value) => value?.toLowerCase())
            .oneOf(
                Object.keys(Countries).map((country) => country?.toLowerCase()),
                LOCALE_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE
            ),
    }),
});

export const schema = Yup.object({
    // Checkout information
    appId: Yup.string().validJwt(LOCALE_LABELS.ERRORS_INVALID_APP_ID).required(LOCALE_LABELS.ERRORS_NO_APP_ID),
    checkout: Yup.string().oneOf(
        [CheckoutType.PAYMENT, CheckoutType.TOKEN, CheckoutType.QR],
        LOCALE_LABELS.ERRORS_INVALID_AMOUNT
    ),
    tokenType: Yup.string().oneOf(
        [TransactionTokenType.ONE_TIME, TransactionTokenType.SUBSCRIPTION, TransactionTokenType.RECURRING],
        LOCALE_LABELS.ERRORS_INVALID_TOKEN_TYPE
    ),

    // Processing options
    instantChargeTokenId: Yup.string(),
    cvvAuthorize: Yup.boolean(),
    onlyDirectCurrency: Yup.boolean(),
    univapayCustomerId: Yup.string().uuid(LOCALE_LABELS.ERRORS_INVALID_CUSTOMER_ID),
    paymentTypes: Yup.array()
        .when("checkout", ([checkout]) => {
            if (checkout === CheckoutType.TOKEN) {
                return Yup.array().test(
                    "has-valid-payment-types",
                    LOCALE_LABELS.ERRORS_NOT_PAYMENT_CHECKOUT,
                    (types) =>
                        !types?.length ||
                        types.some(
                            (type) =>
                                ![PaymentType.ONLINE, "d_barai_online", ...Object.values(OnlineBrand)].includes(type)
                        )
                );
            }
        })
        .when("tokenType", ([tokenType]) => {
            if (
                tokenType &&
                tokenType !== TransactionTokenType.ONE_TIME &&
                tokenType !== TransactionTokenType.RECURRING
            ) {
                return Yup.array().of(
                    Yup.string().notOneOf(
                        [PaymentType.ONLINE, "d_barai_online", ...Object.values(OnlineBrand)],
                        LOCALE_LABELS.ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE
                    )
                );
            }
        })
        .of(Yup.string().paymentMethod(undefined, LOCALE_LABELS.ERRORS_INVALID_PAYMENT_TYPE)),
    showCvv: Yup.boolean().required(),
    cardInstallmentOptions: Yup.array().of(Yup.string().cardInstallment(LOCALE_LABELS.ERRORS_INVALID_INSTALLMENTS)),
    usageLimit: Yup.string().usageLimit(LOCALE_LABELS.ERRORS_INVALID_USAGE_LIMIT),
    subscriptionId: Yup.string().uuid(),
    allowCardInstallments: Yup.boolean().when(["tokenType", "usageLimit"], ([tokenType, usageLimit]) =>
        Yup.mixed().falsyWhen(
            tokenType === TransactionTokenType.SUBSCRIPTION || !!usageLimit,
            LOCALE_LABELS.ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT
        )
    ),
    customFields: Yup.array().of(
        Yup.object({
            key: Yup.string()
                .required(LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_KEY)
                .min(1, LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_KEY),
            label: Yup.string()
                .required(LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_LABEL)
                .min(1, LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_LABEL),
            type: Yup.string()
                .required(LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_TYPE)
                .oneOf(
                    [ECFormCustomFieldType.STRING, ECFormCustomFieldType.SELECT],
                    LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_TYPE
                ),
            required: Yup.boolean(),
            options: Yup.array().when("type", ([type]) =>
                type === ECFormCustomFieldType.SELECT
                    ? Yup.array()
                          .min(1, LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS)
                          .of(Yup.string().min(1, LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS))
                    : Yup.mixed().test("prohibited", LOCALE_LABELS.ERRORS_NO_CUSTOM_FIELD_OPTIONS, (value) => !value)
            ),
        })
    ),
    ignoreDescriptorOnError: Yup.boolean(),
    confirmationRequired: Yup.boolean(),
    threeDsMode: Yup.string().oneOf(Object.values(ThreeDsMode), LOCALE_LABELS.ERRORS_INVALID_THREE_DS_MODE),
    threeDsRedirect: Yup.string(),

    // Metadata
    metadata: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataCharge: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataSubscription: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    metadataToken: Yup.object().metadata(LOCALE_LABELS.ERRORS_INVALID_METADATA),
    univapayReferenceId: Yup.string(),

    // Address
    address: Yup.boolean(),
    requireEmail: Yup.boolean(),
    requireBillingAddress: Yup.boolean(),
    requireName: Yup.boolean(),
    requireNameKana: Yup.boolean(),
    requirePhoneNumber: Yup.boolean(),
    autoClose: Yup.boolean(),
    autoCloseOnError: Yup.boolean(),
    autoSubmit: Yup.boolean(),
    autoSubmitOnError: Yup.boolean(),
    email: Yup.string().email(),
    shippingAddressZip: Yup.string(),
    shippingAddressLine1: Yup.string(),
    shippingAddressLine2: Yup.string(),
    shippingAddressCity: Yup.string(),
    shippingAddressState: Yup.string(),
    shippingAddressCountryCode: Yup.string()
        .transform((value) => value?.toLowerCase())
        .oneOf(
            Object.keys(Countries).map((country) => country?.toLowerCase()),
            LOCALE_LABELS.ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE
        ),
    buyerName: Yup.string(),
    buyerDateOfBirth: Yup.string(),
    buyerNameTransliteration: Yup.string(),
    customerName: Yup.string(),
    customerKana: Yup.string(),
    phoneNumber: Yup.string(),
    phoneNumberCountryCode: Yup.string(),

    // Payment information
    amount: Yup.number()
        .integer(LOCALE_LABELS.ERRORS_INVALID_AMOUNT)
        .min(0, LOCALE_LABELS.ERRORS_INVALID_AMOUNT)
        .when(["products", "productCodes"], ([products, productCodes]) =>
            Yup.mixed().falsyWhen(
                !!products?.length || !!productCodes?.length,
                LOCALE_LABELS.ERRORS_UNNECESSARY_CURRENCY
            )
        )
        .when(["checkout", "products", "productCodes"], ([checkout, products, productCodes]) =>
            checkout === CheckoutType.PAYMENT && !products?.length && !productCodes?.length
                ? Yup.string().required(LOCALE_LABELS.ERRORS_INVALID_AMOUNT)
                : undefined
        ),
    currency: Yup.string()
        .matches(/[a-z]{3}/i, LOCALE_LABELS.ERRORS_INVALID_CURRENCY)
        .when(["products", "productCodes"], ([products, productCodes]) =>
            Yup.mixed().falsyWhen(
                !!products?.length || !!productCodes?.length,
                LOCALE_LABELS.ERRORS_UNNECESSARY_CURRENCY
            )
        )
        .when(["checkout", "products", "productCodes"], ([checkout, products, productCodes]) =>
            checkout === CheckoutType.PAYMENT && !products?.length && !productCodes?.length
                ? Yup.string().required()
                : undefined
        ),

    // Captures
    capture: Yup.boolean().when("checkout", ([checkout]) => Yup.mixed().falsyWhen(checkout !== CheckoutType.PAYMENT)),
    captureAt: Yup.date()
        .min(new Date())
        .when("capture", ([capture]) => Yup.mixed().falsyWhen(capture !== false)),
    captureIn: Yup.string()
        .duration(LOCALE_LABELS.ERRORS_INVALID_CAPTURE_IN)
        .when(["capture", "captureAt"], ([capture, captureAt]) =>
            Yup.mixed().falsyWhen(capture !== false || !!captureAt)
        ),
    captureDayOfMonth: Yup.number()
        .integer()
        .when(["capture", "captureAt"], ([capture, captureAt]) =>
            Yup.mixed().falsyWhen(capture !== false || !!captureAt)
        ),

    // Products
    products: Yup.array().of(Yup.string().uuid(LOCALE_LABELS.ERRORS_INVALID_PRODUCTS)),
    productCodes: Yup.array().of(Yup.string()),
    productQuantities: Yup.number()
        .integer()
        .when("products", ([products]) => Yup.mixed().falsyWhen(!products?.length)),
    productCodeQuantities: Yup.number()
        .integer()
        .when("productCodes", ([productCodes]) => Yup.mixed().falsyWhen(!productCodes?.length)),

    // UX options
    removeCheckoutButtonAfterCharge: Yup.boolean().required(),
    description: Yup.string(),
    qrColor: Yup.string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/, LOCALE_LABELS.ERRORS_INVALID_COLOR_CODE),
    qrLogoType: Yup.string().oneOf(
        [
            TemporaryTokenAliasQrLogoType.Background,
            TemporaryTokenAliasQrLogoType.Centered,
            TemporaryTokenAliasQrLogoType.None,
        ],
        LOCALE_LABELS.ERRORS_INVALID_LOGO_TYPE
    ),
    title: Yup.string().max(64, LOCALE_LABELS.ERRORS_TITLE_TOO_LONG),
    header: Yup.string(),
    dark: Yup.boolean(),
    inline: Yup.boolean().required(),
    horizontalInlineLayout: Yup.boolean(),
    locale: Yup.string().locale(LOCALE_LABELS.ERRORS_INVALID_LOCALE).required(),
    showLogo: Yup.boolean(),
    timeout: Yup.number().min(1),
    hideRecurringCheckbox: Yup.boolean(),
    hidePrivacyLink: Yup.boolean(),

    // Subscriptions
    subscriptionPeriod: Yup.string().subscriptionPeriod(LOCALE_LABELS.ERRORS_INVALID_SUBSCRIPTION_PERIOD),
    subscriptionInitialAmount: Yup.number().integer().min(0, LOCALE_LABELS.ERRORS_INVALID_AMOUNT),
    subscriptionStart: Yup.date().min(new Date()),
    subscriptionStartIn: Yup.string().duration(LOCALE_LABELS.ERRORS_INVALID_SUBSCRIPTION_START_IN),
    subscriptionRetryInterval: Yup.string().duration(LOCALE_LABELS.ERRORS_INVALID_SUBSCRIPTION_RETRY_INTERVAL),
    subscriptionStartDayOfMonth: Yup.number().integer().min(1).max(31),
    subscriptionTimezone: Yup.string().oneOf(Object.keys(Timezones), LOCALE_LABELS.ERRORS_INVALID_TIMEZONE),
    subscriptionPreserveEndOfMonth: Yup.boolean(),
    installmentPlan: Yup.string()
        .oneOf([InstallmentPlan.FIXED_CYCLES, InstallmentPlan.REVOLVING], LOCALE_LABELS.ERRORS_INVALID_INSTALLMENT_PLAN)
        .when("tokenType", ([tokenType]) =>
            Yup.mixed().falsyWhen(!tokenType || tokenType === TransactionTokenType.ONE_TIME)
        ),
    subscriptionPlan: Yup.string()
        .oneOf(
            [InstallmentPlan.FIXED_CYCLE_AMOUNT, InstallmentPlan.FIXED_CYCLES],
            LOCALE_LABELS.ERRORS_INVALID_SUBSCRIPTION_PLAN
        )
        .when(["tokenType", "installmentPlan"], ([tokenType, installmentPlan]) =>
            Yup.mixed().falsyWhen(!tokenType || tokenType === TransactionTokenType.ONE_TIME || !!installmentPlan)
        ),
    subscriptionQty: Yup.number()
        .min(0, LOCALE_LABELS.ERRORS_INVALID_INSTALLMENT_AMOUNT)
        .when("subscriptionPlan", ([subscriptionPlan]) => Yup.mixed().falsyWhen(!subscriptionPlan)),
    installmentQty: Yup.number()
        .min(0, LOCALE_LABELS.ERRORS_INVALID_INSTALLMENT_AMOUNT)
        .when("installmentPlan", ([installmentPlan]) => Yup.mixed().falsyWhen(!installmentPlan)),

    // Expiration
    expirationTimeShift: Yup.string().timeShift(LOCALE_LABELS.ERRORS_EXPIRATION_TIMESHIFT),
    expirationPeriod: Yup.string().duration(LOCALE_LABELS.ERRORS_EXPIRATION_PERIOD),
    bankTransferExpirationPeriod: Yup.string().duration(LOCALE_LABELS.ERRORS_EXPIRATION_PERIOD),
    bankTransferExpirationTimeShift: Yup.string().timeShift(LOCALE_LABELS.ERRORS_EXPIRATION_PERIOD),
    convenienceStoreExpirationPeriod: Yup.string().duration(LOCALE_LABELS.ERRORS_EXPIRATION_PERIOD),
    convenienceStoreExpirationTimeShift: Yup.string().timeShift(LOCALE_LABELS.ERRORS_EXPIRATION_PERIOD),
});

const parseCustomFields = (params: Record<string, string> | CheckoutParams) => {
    if (params.customFields) {
        return params.customFields as ECFormCustomField[];
    }

    const customFields = [];
    const keys = toArray<string>(params.customFieldKeys) ?? [];
    const labels = toArray<string>(params.customFieldLabels) ?? [];
    const types = toArray<string>(params.customFieldTypes) ?? [];
    const required = toArray<string>(params.customFieldRequired) ?? [];
    const options = toArray<string>(params.customFieldOptions) ?? [];
    let optionsIndex = 0;

    const maxLength = Math.max(keys.length, labels.length, types.length);

    for (let i = 0; i < maxLength; i++) {
        const customField = {
            key: keys[i] ?? "",
            label: labels[i] ?? "",
            type:
                types[i] === "string"
                    ? ECFormCustomFieldType.STRING
                    : types[i] === "select"
                    ? ECFormCustomFieldType.SELECT
                    : "",
            required: required[i] === "true",
        };

        if (customField["type"] === ECFormCustomFieldType.SELECT) {
            customField["options"] = options[optionsIndex] ? options[optionsIndex].split(";") : [];
            optionsIndex++;
        } else {
            customField["options"] = undefined;
        }

        customFields.push(customField);
    }

    return customFields;
};

type FormatOptions = {
    hasLegacyPlanParameter?: boolean;
    formatMetadata?: boolean;
};

/**
 * Legacy parameter are including the subscription initial amount in the plan amount.
 * This should only apply to installment plans and subscription plans
 */
const computePlanAmount = (params: Record<string, string> | CheckoutParams) =>
    (toNumber(params.amount) || 0) - (toNumber(params.subscriptionInitialAmount) || 0);

const computeSubscriptionPlanQty = (params: Record<string, string> | CheckoutParams) =>
    params.subscriptionPlan === InstallmentPlan.FIXED_CYCLES && toNumber(params.subscriptionInitialAmount)
        ? toNumber(params.subscriptionQty) - 1
        : toNumber(params.subscriptionQty);

export const formatParameters = (
    params: (Record<string, string> | CheckoutParams) & Partial<CheckoutStyles>,
    options?: FormatOptions
): CheckoutParams => {
    const {
        address,
        allowCardInstallments,
        amount,
        appId,
        autoClose,
        autoCloseOnError,
        autoSubmit,
        autoSubmitOnError,
        bankTransferExpirationPeriod,
        bankTransferExpirationTimeShift,
        beforeClosing,
        buyerDateOfBirth,
        buyerName,
        buyerNameTransliteration,
        callback,
        capture,
        captureAt,
        captureDayOfMonth,
        captureIn,
        cardholder,
        cardInstallmentOptions,
        merchantCardRegistration,
        checkout,
        confirmationRequired,
        convenienceStoreExpirationPeriod,
        convenienceStoreExpirationTimeShift,
        currency,
        customerName,
        customerNameKana,
        customFieldKeys, // eslint-disable-line @typescript-eslint/no-unused-vars
        customFieldLabels, // eslint-disable-line @typescript-eslint/no-unused-vars
        customFieldOptions, // eslint-disable-line @typescript-eslint/no-unused-vars
        customFieldRequired, // eslint-disable-line @typescript-eslint/no-unused-vars
        customFields,
        customFieldTypes, // eslint-disable-line @typescript-eslint/no-unused-vars
        cvvAuthorize,
        dark,
        description,
        descriptor,
        email,
        exp,
        expirationPeriod,
        expirationTimeShift,
        hasBeforeClosingCallback,
        header,
        horizontalInlineLayout,
        ignoreDescriptorOnError,
        inline,
        inlineBaseFontSize,
        inlineFieldFocusStyle,
        inlineFieldInvalidStyle,
        inlineFieldStyle,
        inlineItemErrorStyle,
        inlineItemLabelStyle,
        inlineItemStyle,
        inlineStyles,
        inlineTextFieldFocusStyle,
        inlineTextFieldInvalidStyle,
        inlineTextFieldStyle,
        inlineTextItemErrorStyle,
        inlineTextItemLabelStyle,
        inlineTextItemStyle,
        inlineToggleFieldFocusStyle,
        inlineToggleFieldInvalidStyle,
        inlineToggleFieldStyle,
        inlineToggleItemErrorStyle,
        inlineToggleItemLabelStyle,
        inlineToggleItemStyle,
        inlineSelectFieldStyle,
        inlineSelectFieldInvalidStyle,
        inlineSelectFieldFocusStyle,
        inlineSelectItemStyle,
        inlineSelectItemLabelStyle,
        inlineSelectItemErrorStyle,
        inlineRadioItemStyle,
        inlineRadioItemLabelStyle,
        inlineRadioFieldFocusStyle,
        installmentPlan,
        installmentQty,
        instantChargeTokenId,
        locale,
        metadata,
        metadataCharge,
        metadataSubscription,
        metadataToken,
        onError,
        onlyDirectCurrency,
        onPending,
        onSuccess,
        onTokenCreated,
        onChargeCreated,
        onSubscriptionCreated,
        onThreeDsAuthorization,
        onThreeDsAuthorizationSuccess,
        onThreeDsAuthorizationFailure,
        onThreeDsAuthorizationModalOpened,
        onValidationError,
        paymentMethods,
        paymentType,
        paymentTypes,
        phoneNumber,
        phoneNumberCountryCode,
        productCodes,
        products,
        productQuantities,
        productCodeQuantities,
        qrColor,
        qrLogoType,
        removeCheckoutButtonAfterCharge,
        requireBillingAddress,
        requireEmail,
        requireName,
        requireNameKana,
        requirePhoneNumber,
        shippingAddressCity,
        shippingAddressCountryCode,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingAddressState,
        shippingAddressZip,
        showCvv,
        showLogo,
        submitButtonText,
        subscriptionId,
        subscriptionInitialAmount,
        subscriptionPeriod,
        subscriptionPlan,
        subscriptionPreserveEndOfMonth,
        subscriptionQty,
        subscriptionRetryInterval,
        subscriptionStart,
        subscriptionStartDayOfMonth,
        subscriptionStartIn,
        subscriptionTimezone,
        title,
        timeout,
        hideRecurringCheckbox,
        hidePrivacyLink,
        tokenType,
        univapayCustomerId,
        univapayReferenceId,
        usageLimit,
        threeDsMode,
        threeDsRedirect,
        ...extraParamsAsMetadata
    } = params;
    const { formatMetadata = false, hasLegacyPlanParameter = false } = options || {};

    const formattedInlineStyles = {
        [InlineStyleKeys.ITEM]: toObject(inlineItemStyle, undefined, ";"),
        [InlineStyleKeys.ITEM_LABEL]: toObject(inlineItemLabelStyle, undefined, ";"),
        [InlineStyleKeys.ITEM_ERROR]: toObject(inlineItemErrorStyle, undefined, ";"),
        [InlineStyleKeys.FIELD]: toObject(inlineFieldStyle, undefined, ";"),
        [InlineStyleKeys.FIELD_INVALID]: toObject(inlineFieldInvalidStyle, undefined, ";"),
        [InlineStyleKeys.FIELD_FOCUS]: toObject(inlineFieldFocusStyle, undefined, ";"),

        [InlineStyleKeys.TEXT_FIELD]: toObject(inlineTextFieldStyle, undefined, ";"),
        [InlineStyleKeys.TEXT_FIELD_INVALID]: toObject(inlineTextFieldInvalidStyle, undefined, ";"),
        [InlineStyleKeys.TEXT_FIELD_FOCUS]: toObject(inlineTextFieldFocusStyle, undefined, ";"),
        [InlineStyleKeys.TEXT_ITEM]: toObject(inlineTextItemStyle, undefined, ";"),
        [InlineStyleKeys.TEXT_ITEM_LABEL]: toObject(inlineTextItemLabelStyle, undefined, ";"),
        [InlineStyleKeys.TEXT_ITEM_ERROR]: toObject(inlineTextItemErrorStyle, undefined, ";"),

        [InlineStyleKeys.TOGGLE_FIELD]: toObject(inlineToggleFieldStyle, undefined, ";"),
        [InlineStyleKeys.TOGGLE_FIELD_INVALID]: toObject(inlineToggleFieldInvalidStyle, undefined, ";"),
        [InlineStyleKeys.TOGGLE_FIELD_FOCUS]: toObject(inlineToggleFieldFocusStyle, undefined, ";"),
        [InlineStyleKeys.TOGGLE_ITEM]: toObject(inlineToggleItemStyle, undefined, ";"),
        [InlineStyleKeys.TOGGLE_ITEM_LABEL]: toObject(inlineToggleItemLabelStyle, undefined, ";"),
        [InlineStyleKeys.TOGGLE_ITEM_ERROR]: toObject(inlineToggleItemErrorStyle, undefined, ";"),

        [InlineStyleKeys.SELECT_FIELD]: toObject(inlineSelectFieldStyle, undefined, ";"),
        [InlineStyleKeys.SELECT_FIELD_INVALID]: toObject(inlineSelectFieldInvalidStyle, undefined, ";"),
        [InlineStyleKeys.SELECT_FIELD_FOCUS]: toObject(inlineSelectFieldFocusStyle, undefined, ";"),
        [InlineStyleKeys.SELECT_ITEM]: toObject(inlineSelectItemStyle, undefined, ";"),
        [InlineStyleKeys.SELECT_ITEM_LABEL]: toObject(inlineSelectItemLabelStyle, undefined, ";"),
        [InlineStyleKeys.SELECT_ITEM_ERROR]: toObject(inlineSelectItemErrorStyle, undefined, ";"),

        [InlineStyleKeys.RADIO_ITEM]: toObject(inlineRadioItemStyle, undefined, ";"),
        [InlineStyleKeys.RADIO_ITEM_LABEL]: toObject(inlineRadioItemLabelStyle, undefined, ";"),
        [InlineStyleKeys.RADIO_FIELD_FOCUS]: toObject(inlineRadioFieldFocusStyle, undefined, ";"),
        ...toObject(inlineStyles, {}, ";"),
    };

    const formattedCustomFields = toArray(customFields) ?? parseCustomFields(params);

    const baseMetaParams = {
        metadata: toObject(metadata) ?? {},
        metadataCharge: toObject(metadataCharge) ?? {},
        metadataSubscription: toObject(metadataSubscription) ?? {},
        metadataToken: toObject(metadataToken) ?? {},
    } as MetaParams;

    const metaParams: MetaParams = formatMetadata
        ? Object.keys(extraParamsAsMetadata).reduce((acc: MetaParams, key: string) => {
              let {
                  metadata: metadataCommon = {},
                  metadataCharge = {},
                  metadataSubscription = {},
                  metadataToken = {},
              } = acc;

              if (key.indexOf(MetaKey.Charge) === 0) {
                  metadataCharge = {
                      ...metadataCharge,
                      [lcfirst(key.replace(MetaKey.Charge, ""))]: extraParamsAsMetadata[key],
                  };
              } else if (key.indexOf(MetaKey.Subscription) === 0) {
                  metadataSubscription = {
                      ...metadataSubscription,
                      [lcfirst(key.replace(MetaKey.Subscription, ""))]: extraParamsAsMetadata[key],
                  };
              } else if (key.indexOf(MetaKey.Token) === 0) {
                  metadataToken = {
                      ...metadataToken,
                      [lcfirst(key.replace(MetaKey.Token, ""))]: extraParamsAsMetadata[key],
                  };
              } else {
                  metadataCommon = { ...metadataCommon, [key]: extraParamsAsMetadata[key] };
              }

              return {
                  metadata: metadataCommon,
                  metadataCharge,
                  metadataSubscription,
                  metadataToken,
              };
          }, baseMetaParams)
        : baseMetaParams;

    const formattedAmount =
        hasLegacyPlanParameter && (installmentPlan || subscriptionPlan) ? computePlanAmount(params) : toNumber(amount);

    const formattedSubscriptionQty =
        hasLegacyPlanParameter && subscriptionPlan ? computeSubscriptionPlanQty(params) : toNumber(subscriptionQty);

    return {
        // Checkout information
        appId,
        checkout: checkout as CheckoutType,
        tokenType: (tokenType as TransactionTokenType) || TransactionTokenType.ONE_TIME,
        merchantCardRegistration: toBoolean(merchantCardRegistration),

        // Processing options
        instantChargeTokenId,
        cvvAuthorize: toBoolean(cvvAuthorize),
        onlyDirectCurrency: toBoolean(onlyDirectCurrency),
        univapayCustomerId,
        paymentTypes: toArray(paymentMethods || paymentTypes, paymentType ? [paymentType] : undefined) as PaymentType[],
        showCvv: toBoolean(showCvv, true),
        cardInstallmentOptions: toArray(cardInstallmentOptions),
        usageLimit: usageLimit as UsageLimit,
        subscriptionId: subscriptionId,
        allowCardInstallments: toBoolean(allowCardInstallments, false),
        customFields: formattedCustomFields,
        ignoreDescriptorOnError: toBoolean(ignoreDescriptorOnError, false),
        confirmationRequired: toBoolean(confirmationRequired, false),
        threeDsMode: threeDsMode as ThreeDsMode,
        threeDsRedirect,

        // Metadata
        metadata: metaParams.metadata,
        metadataCharge: metaParams.metadataCharge,
        metadataSubscription: metaParams.metadataSubscription,
        metadataToken: metaParams.metadataToken,
        univapayReferenceId,

        // Address
        address: toBoolean(address),
        requireEmail: toBoolean(requireEmail),
        requireBillingAddress: toBoolean(requireBillingAddress),
        requirePhoneNumber: toBoolean(requirePhoneNumber),
        requireName: toBoolean(requireName),
        requireNameKana: toBoolean(requireNameKana),
        autoClose: toBoolean(autoClose),
        autoCloseOnError: toBoolean(autoCloseOnError),
        autoSubmit: toBoolean(autoSubmit),
        autoSubmitOnError: toBoolean(autoSubmitOnError),
        email,
        cardholder,
        exp,
        shippingAddressZip,
        shippingAddressLine1,
        shippingAddressLine2,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressCountryCode,
        phoneNumber,
        phoneNumberCountryCode,
        customerName,
        customerNameKana,
        buyerName,
        buyerDateOfBirth,
        buyerNameTransliteration,

        // Payment information
        amount: formattedAmount,
        currency,

        // Captures
        capture: toBoolean(capture),
        captureAt,
        captureIn,
        captureDayOfMonth: toNumber(captureDayOfMonth),

        // Products
        products: toArray(products),
        productCodes: toArray(productCodes),
        productQuantities: toArray(productQuantities)?.map(toNumber),
        productCodeQuantities: toArray(productCodeQuantities)?.map(toNumber),

        // UX options
        removeCheckoutButtonAfterCharge: toBoolean(removeCheckoutButtonAfterCharge, false),
        description,
        qrColor,
        qrLogoType: qrLogoType as TemporaryTokenAliasQrLogoType,
        title,
        header,
        dark: toBoolean(dark),
        inline: toBoolean(inline, false),
        horizontalInlineLayout: toBoolean(horizontalInlineLayout, false),
        locale: locale ?? "auto",
        descriptor,
        submitButtonText,
        showLogo: toBoolean(showLogo),
        timeout: toNumber(timeout),
        hideRecurringCheckbox: toBoolean(hideRecurringCheckbox, false),
        hidePrivacyLink: toBoolean(hidePrivacyLink, false),

        // Subscriptions
        subscriptionPeriod,
        subscriptionInitialAmount: toNumber(subscriptionInitialAmount),
        subscriptionStart,
        subscriptionStartIn,
        subscriptionRetryInterval,
        subscriptionStartDayOfMonth: toNumber(subscriptionStartDayOfMonth),
        subscriptionTimezone,
        subscriptionPreserveEndOfMonth: toBoolean(subscriptionPreserveEndOfMonth),
        installmentPlan: installmentPlan as InstallmentPlan,
        subscriptionPlan: subscriptionPlan as InstallmentPlan,
        subscriptionQty: formattedSubscriptionQty,
        installmentQty: toNumber(installmentQty),

        // Expiration date
        expirationTimeShift,
        expirationPeriod,
        bankTransferExpirationPeriod,
        bankTransferExpirationTimeShift,
        convenienceStoreExpirationPeriod,
        convenienceStoreExpirationTimeShift,

        // Callbacks
        onSuccess: onSuccess as (message: MessageSuccess) => void,
        onPending: onPending as (message: MessageSuccess) => void,
        callback: callback as (message: MessageSuccess) => void,
        onError: onError as (message: MessageError) => void,
        onTokenCreated: onTokenCreated as (message: MessageTokenCreated) => boolean,
        onChargeCreated: onChargeCreated as (message: MessageChargeCreated) => boolean,
        onSubscriptionCreated: onSubscriptionCreated as (message: MessageSubscriptionCreated) => boolean,
        onThreeDsAuthorization: onThreeDsAuthorization as (message: MessageThreeDsAuthorization) => boolean,
        onThreeDsAuthorizationSuccess: onThreeDsAuthorizationSuccess as (
            message: MessageThreeDsAuthorizationSuccess
        ) => boolean,
        onThreeDsAuthorizationFailure: onThreeDsAuthorizationFailure as (
            message: MessageThreeDsAuthorizationFailure
        ) => boolean,
        onThreeDsAuthorizationModalOpened: onThreeDsAuthorizationModalOpened as () => boolean,
        onValidationError: onValidationError as (fields: string[]) => boolean,

        beforeClosing: beforeClosing as () => boolean,
        hasBeforeClosingCallback: toBoolean(hasBeforeClosingCallback),

        // Inline
        inlineStyles: formattedInlineStyles,
        inlineBaseFontSize: inlineBaseFontSize || "10px",
    };
};
