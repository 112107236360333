import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "エラー",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "元のアプリケーションからチェックアウトに接続できません。",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "パラメーターが不正のため、チェックアウトを初期化できません。",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "通信に失敗しました。通信状況を確認し、もう一度やり直してください。",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "内部処理でタイムアウトが発生しました。サポートへお問い合わせください。",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "タイムアウトが発生しました。",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE]: "支払い方法が不正のため、チェックアウトを初期化できません。",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE]: "この支払い方法はサポートされていません。",
    [ERRORS_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE]: "この支払い方法はサポートされていません。",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "予期しないエラーです",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "アカウントは、リカーリングトークンを使用する権限を持っていません。",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "リカーリングトークンには、使用制限が必要です。",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "このカードブランドには対応していません。",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "アプリトークンのドメインが不正です",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "トークンの作成に失敗しました。",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "CVV認証が失敗しました",
    [ERRORS_LABELS.ERRORS_ALERTS_THREE_DS_FAILED]: "3-Dセキュア認証が完了できませんでした",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT]: "分割払いはサポートされていません。",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "サービス利用不可",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "サービス利用不可",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "デビットとプリペイドカードは、実売上のみに対応しており、仮売上には対応していません。",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "支払い処理に失敗しました。",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "定期課金処理に失敗しました。",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "パラメーターが正しくありません。",
    [ERRORS_LABELS.PROCESSING_ERROR]: "処理エラー",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "Paidyより承認が下りませんでした。詳細はPaidyへお問い合わせください。",
    [ERRORS_LABELS.EMAIL_EXISTS_FOR_CARD]: "前回と同じメールアドレスを入力してもう一度お試しください。",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "失敗回数が上限に達したため、一定時間サービスを利用できません。時間をおいて再度お試しください。",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "支払い処理に失敗しました",
    [ERRORS_LABELS.NO_GATEWAY_AVAILABLE_TO_PROCESS_REQUEST]: "このリクエストは接続先システムで対応していせん。サポートへお問い合わせください。",

    /* request */
    [RequestErrorCode.RequestError]: "不正なリクエスト",

    /* generic */
    [ResponseErrorCode.BadRequest]: "不正なリクエストエラー",
    [ResponseErrorCode.Forbidden]: "禁止されている操作",
    [ResponseErrorCode.NotFound]: "リソースが見つかりません",
    [ResponseErrorCode.NotAllowed]: "許可されていない操作です",
    [ResponseErrorCode.Conflicted]: "他のリソースと競合しています",
    [ResponseErrorCode.TooManyRequests]: "APIへのリクエストが集中しています",
    [ResponseErrorCode.InternalServerError]: "内部システムエラー",
    [ResponseErrorCode.ServiceUnavailable]: "サービスが利用できません",
    [ResponseErrorCode.NotAuthorized]: "許可されていない操作です",

    /* global */
    [ResponseErrorCode.UnknownError]: "予期しないエラーです。サポートへお問い合わせください。",
    [ResponseErrorCode.Timeout]: "リクエストに対してレスポンスがタイムアウトとなりました。お時間おいて再度お手続きお願い致します。",
    [ResponseErrorCode.DBError]: "システムエラー",
    [ResponseErrorCode.InvalidRequest]: "不正なリクエスト",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "冪等性キーに該当するキャッシュは見つかった為、リクエストされた内容は処理しませんでしたが、以前の処理結果のキャッシュの取得に失敗しました。",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "サービスが一時的に利用できません。時間を置いて再試行してください。",
    [ResponseErrorCode.RateLimitExceeded]: "APIリクエスト制限を超えました。",

    /* auth */
    [ResponseErrorCode.InvalidIpAddress]: "不正なIPアドレス",
    [ResponseErrorCode.InvalidDomain]: "リクエストされた Origin ヘッダのドメインは、指定されたアプリトークンのドメインに登録されていません。",
    [ResponseErrorCode.InvalidCredentials]: "認証情報が正しくありません。",
    [ResponseErrorCode.AuthHeaderMissing]: "Authorization ヘッダが指定されていません。",
    [ResponseErrorCode.InvalidPermissions]: "アプリトークンの種類が正しくないか、アカウントの権限が不足しています。",
    [ResponseErrorCode.ImproperAuth]: "オーソリの状態が正しくありません",
    [ResponseErrorCode.CouldNotRefreshAuth]: "ログイントークンの更新に失敗しました。サポートへお問い合わせください。",
    [ResponseErrorCode.UserBanned]: "利用が禁止されたユーザーです。",
    [ResponseErrorCode.InvalidLoginToken]: "ログイントークンが無効です。",
    [ResponseErrorCode.InvalidAppToken]: "無効なトークン",
    [ResponseErrorCode.ExpiredLoginToken]: "ログイントークンの有効期限が切れました。",
    [ResponseErrorCode.OutdatedAppToken]: "アプリトークンのバージョンが古いです。新しくアプリトークンを作成しなおしてください。",
    [ResponseErrorCode.ChargeTooQuick]: "制限付きのリカーリングトークンで、指定期間内に複数の課金を作成しようとしました。",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "ファイルのアップロードエラーです",
    [ResponseErrorCode.FileMaxSizeExceeded]: "アップロードされたファイルのサイズが大きすぎます。",
    [ResponseErrorCode.FileInvalidType]: "アップロードされたファイルのMIMEタイプが正しくありません。",
    [ResponseErrorCode.FileNotFound]: "ファイルが見つかりません",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "トークンの生成時に例外が発生しました",
    [ResponseErrorCode.TokenForWrongStore]: "トランザクショントークンのストアが定期課金のストアと異なります。",
    [ResponseErrorCode.NonSubscriptionPayment]: "課金の作成にワンタイムトークンもしくはリカーリングトークンを指定してください。",
    [ResponseErrorCode.BankAccountExists]: "既に存在する銀行口座です",
    [ResponseErrorCode.EmailExists]: "既に存在するメールアドレスです",
    [ResponseErrorCode.StoreExists]: "既に存在するストアです",
    [ResponseErrorCode.GatewayCredentialsExists]: "既に存在するゲートウェイクレデンシャルです",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "加盟店向けのルールがユニークではありません",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "加盟店向けのルールセットがユニークではありません",
    [ResponseErrorCode.NonUniqueRule]: "ルールがユニークでへありません",
    [ResponseErrorCode.RulePriorityMustBePositive]: "ルールの優先順位は、正の値ではないといけません",
    [ResponseErrorCode.NonExistingRule]: "存在しないルールです",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "エレメントは、ユニークでなければいけません",
    [ResponseErrorCode.WebhookURLExists]: "指定されたURLは既に登録されています。",
    [ResponseErrorCode.GroupExists]: "既に存在するグループです",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "プライマリ設定された銀行口座が見つかりません",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "プライマリ設定された銀行口座が必要です",
    [ResponseErrorCode.VerificationDataExists]: "審査用のデータが存在します",
    [ResponseErrorCode.TemplateExists]: "テンプレートが存在します",
    [ResponseErrorCode.StratusCredentialsExists]: "CAFISのクレデンシャルが存在します",
    [ResponseErrorCode.StratusRuleStillInUse]: "CAFISルールは使用中です",
    [ResponseErrorCode.ResourceLimitReached]: "リソース制限の上限に達しました。",
    [ResponseErrorCode.GatewayConfigurationExists]: "ゲートウェイの設定が存在します",
    [ResponseErrorCode.RequiresValidMerchantGatewayConfig]: "ゲートウェイの設定が見つかりません",
    [ResponseErrorCode.MergedConfigurationNotFound]: "ゲートウェイの設定が見つかりません",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "トランザクショントークンの種類が正しくありません。",
    [ResponseErrorCode.InvalidToken]: "不正なトークン",
    [ResponseErrorCode.InvalidCard]: "不正なカード",
    [ResponseErrorCode.TransactionTokenIsNotRecurring]: "トークンはリカーリングトークンではありません",
    [ResponseErrorCode.ForbiddenIP]: "リクエスト元のIPアドレスから割り出された国が、設定された許可する国に含まれていません。",
    [ResponseErrorCode.InvalidUserData]: "トークンはリカーリングトークンではありません",
    [ResponseErrorCode.NonUniqueActiveToken]: "アクティブなトランザクショントークンが既に存在します。",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "使用済みのトランザクショントークンは指定できません。",
    [ResponseErrorCode.TransactionTokenExpired]: "トランザクショントークンの有効期限が切れました。",
    [ResponseErrorCode.NoTestCardInLiveMode]: "本番モード(live)でテストカードは使用できません。",
    [ResponseErrorCode.ProcessingModeMismatch]: "アプリトークンのモードと一致していません",
    [ResponseErrorCode.PaymentTypeNotAllowed]: "支払い方法が許可されていません",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "リカーリングトークンが許可されていません",
    [ResponseErrorCode.RecurringTokenDisabled]: "トランザクショントークンが無効になっているか、アカウントにリカーリングトークンを使用する権限がありません。",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "usage_limit パラメータが必要です。",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "CVV の提供が必要です。",
    [ResponseErrorCode.CvvAuthorizationNotCompleted]: "CVV認証に失敗しました",
    [ResponseErrorCode.UsageLimitNotApplicable]: "usage_limit は指定できません。",
    [ResponseErrorCode.CardProcessingDisabled]: "支払い方法でカードが無効になっています。",
    [ResponseErrorCode.QRProcessingDisabled]: "支払い方法でQRコードが無効になっています。",
    [ResponseErrorCode.PaidyProcessingDisabled]: "支払い方法でPaidyが無効になっています",
    [ResponseErrorCode.MerchantQRProcessingDisabled]: "支払い方法でMerchantQRが無効になっています",
    [ResponseErrorCode.OnlineProcessingDisabled]: "支払い方法でオンラインが無効になっています",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "支払い方法でコンビニ決済が無効になっています。",
    [ResponseErrorCode.NotOneTimeToken]: "ワンタムトークン以外はサポートされていません。",
    [ResponseErrorCode.NotSubscriptionToken]: "定期課金トークンもしくはリカーリングトークンを指定してください。",
    [ResponseErrorCode.NotRecurringToken]: "継続課金トークンではありません。",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "返金時の通貨は課金時の通貨と同じである必要があります。",
    [ResponseErrorCode.NoDirectCurrencyGateway]: "通貨の変換をせずに利用可能なゲートウェイが設定されていません。",
    [ResponseErrorCode.TokenMustBeConfirmed]: "確認されてないトークンです",
    [ResponseErrorCode.ConfirmationRequiresEmail]: "確認には、メールが必要です",
    [ResponseErrorCode.WrongConfirmationCode]: "不正な確認コード",
    [ResponseErrorCode.RefundNotWithinBounds]: "返金金額が有効な範囲ではありません。0より大きく、課金金額以下の必要があります。",
    [ResponseErrorCode.PartialRefundNotSupported]: "一部返金はサポートされていません。",
    [ResponseErrorCode.InvalidTransfer]: "不正な振込",
    [ResponseErrorCode.TransferAlreadyExists]: "振込が既にあります。",
    [ResponseErrorCode.NoLedgers]: "元帳がありません。",
    [ResponseErrorCode.FailedToAssociateLedgers]: "元帳を関連づけられませんでした",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "本番モード(live)を使用するにはアカウントの審査の完了が必要です。",
    [ResponseErrorCode.SelfTransferNotPermitted]: "自分への振込は許可されません。",
    [ResponseErrorCode.CardLocked]: "このカードは一定期間内の失敗回数がしきい値を超えた為、一時的にロックされています。",
    [ResponseErrorCode.SubscriptionProcessing]: "定期課金を処理中です",
    [ResponseErrorCode.AlreadyCaptured]: "対象の課金は既にキャプチャ済みか、オーソリが完了していません。",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "トランザクショントークンを更新できません",
    [ResponseErrorCode.CaptureAmountTooLarge]: "キャプチャの金額がオーソリ時の金額より大きいです。",
    [ResponseErrorCode.PartialCaptureNotSupported]: "一部キャプチャはサポートされていません",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "デビットカードのオーソリは無効です",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "プリペイドカードのオーソリは無効です",

    [ResponseErrorCode.NoGatewaysAvailable]: "利用可能なゲートウェイが見つかりません。",
    [ResponseErrorCode.NoGatewayCredentialsAvailable]: "有効なゲートウェイのクレデンシャルがありません",
    [ResponseErrorCode.NoGatewayTransactionIdAvailable]: "有効なトランザクションIDがありません",
    [ResponseErrorCode.PaymentTypeNotSupportedForCheck]: "支払い方法がサポートされていません",
    [ResponseErrorCode.NoGatewayCredentialsForSelectedPaymentType]: "選択された支払い方法のゲートウェイのクレデンシャルがありません",
    [ResponseErrorCode.DisabledPaymentType]: "支払方法が無効化されています",
    [ResponseErrorCode.CardBrandNotSupported]: "指定されたカードブランドに対応したゲートウェイが設定されていません。",
    [ResponseErrorCode.CardCountryNotSupported]: "指定されたカード発行国に対応したゲートウェイが設定されていません。",
    [ResponseErrorCode.CVVRequired]: "CVV の提供が必要です。",
    [ResponseErrorCode.LastNameRequired]: "カード名義にスペースで区切られた苗字が必要です。",
    [ResponseErrorCode.AuthNotSupported]: "オーソリに対応したゲートウェイが設定されていません。",
    [ResponseErrorCode.GatewayConfigurationRequired]: "ゲートウェイの設定が必須です",
    [ResponseErrorCode.GatewayConfigurationNotRequired]: "ゲートウェイの設定が必須ではありません",

    [ResponseErrorCode.InvalidBinRange]: "BIN番号が不正な範囲です",
    [ResponseErrorCode.OverlappingStratusTerminalIdRange]: "ストラタスのターミナルIDが重複しています",
    [ResponseErrorCode.InvalidStratusTerminalIdRange]: "不正なストラタスのターミナルID",
    [ResponseErrorCode.InvalidCardCompany]: "不正なカード会社",
    [ResponseErrorCode.VerificationRequired]: "審査IDが必須です",

    /* validation responses */
    [ResponseErrorCode.ChangeProhibited]: "このフィールドの変更は禁止されています。",
    [ResponseErrorCode.ForbiddenParameter]: "このパラメータを使用する権限が不足しているか、要件を満たしていません。",
    [ResponseErrorCode.ValidationError]: "リクエスト内容のパラメータにバリデーションエラーがあります。詳細は errors を参照してください。",
    [ResponseErrorCode.RequiredValue]: "必須のパラメータです。",
    [ResponseErrorCode.MustBeFutureTime]: "未来の日時の必要があります。",
    [ResponseErrorCode.InvalidFormat]: "不正な形式です。",
    [ResponseErrorCode.InvalidPercentFee]: "不正な料率です",
    [ResponseErrorCode.InvalidCardNumber]: "カード番号の形式が正しくありません。",
    [ResponseErrorCode.InvalidCardExpiration]: "カード有効期限が過ぎています。",
    [ResponseErrorCode.InvalidCVV]: "セキュリティコードは3桁または4桁で入力してください",
    [ResponseErrorCode.InvalidFormatLength]: "長さのフォーマットが不正です",
    [ResponseErrorCode.InvalidFormatUUID]: "uuidのフォーマットが不正です",
    [ResponseErrorCode.InvalidFormatBase64]: "base64のフォーマットが不正です",
    [ResponseErrorCode.InvalidFormatEmail]: "メールアドレスの形式が不正です。",
    [ResponseErrorCode.InvalidCardDescriptor]: "ディスクリプタの形式が正しくありません。",
    [ResponseErrorCode.InvalidFormatCurrency]: "認識できない通貨コードです。",
    [ResponseErrorCode.InvalidCurrency]: "認識できない通貨です。",
    [ResponseErrorCode.InvalidAmount]: "金額は 0 より大きい整数である必要があります。",
    [ResponseErrorCode.InvalidEventForStore]: "そのストアに対して不正なイベントです",
    [ResponseErrorCode.InvalidEventForPlatform]: "Invalid Event For Platform",
    [ResponseErrorCode.InvalidEventForMerchant]: "Invalid Event For Merchant",
    [ResponseErrorCode.InvalidFormatDomain]: "ドメイン名の形式が不正です。",
    [ResponseErrorCode.InvalidFormatUrl]: "URLが不正です",
    [ResponseErrorCode.InvalidFormatObject]: "オブジェクトのフォーマットが不正です",
    [ResponseErrorCode.InvalidFormatCountry]: "認識できない国コードです。",
    [ResponseErrorCode.InvalidPhoneNumber]: "電話番号の形式が不正です。",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "スィフトコードが不正です",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "ルーティング番号が不正です",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "ルーティングコードが不正です",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "IFCSコードが不正です。",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "銀行口座番号のフォーマットが不正です",
    [ResponseErrorCode.InvalidPasswords]: "パスワードが不正です",
    [ResponseErrorCode.InvalidTimePeriod]: "認識できない期間の形式です。",
    [ResponseErrorCode.InvalidDayOfWeek]: "不正な曜日です",
    [ResponseErrorCode.InvalidWeekOfMonth]: "何週目かが不正です",
    [ResponseErrorCode.InvalidDayOfMonth]: "月の何日目かが不正です",
    [ResponseErrorCode.InvalidColorsSize]: "カラーサイズが不正です",
    [ResponseErrorCode.NestedJsonNotAllowed]: "ネストされたJSONの形式は使用できません。",
    [ResponseErrorCode.InvalidFormatDate]: "日付のフォーマットが不正です",
    [ResponseErrorCode.InvalidChargeStatus]: "不正な課金の状態です。",
    [ResponseErrorCode.InvalidQRScanGateway]: "QRコード決済のゲートウェイが設定されていないか有効ではありません。",
    [ResponseErrorCode.NotQRCharge]: "QR課金ではありません",
    [ResponseErrorCode.NotOnlineCharge]: "オンライン課金ではありません",
    [ResponseErrorCode.IssuerTokenEmpty]: "イシュアートークンが空です",
    [ResponseErrorCode.CardLimitExceededForStore]: "一定期間内でのカードの合計決済額が限度額を超えました。",
    [ResponseErrorCode.InvalidLanguage]: "認識できない言語コードです。",
    [ResponseErrorCode.SubscriptionNotAllowed]: "定期課金の使用は許可されていません",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "ワンタイムトークンのみ許可されています",
    [ResponseErrorCode.AuthExpired]: "オーソリの期限が切れています",
    [ResponseErrorCode.InvalidTemplateKey]: "テンプレートキーが不正です",
    [ResponseErrorCode.NonPublicTemplate]: "パブリックテンプレートではありません",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "日本の電話番号のみサポートされています。",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "有効期限が許可された範囲外です。",
    [ResponseErrorCode.UnsupportedLanguage]: "サポートされていない言語です",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "デフォルト言語が設定されていません",
    [ResponseErrorCode.OnlyForCardPayment]: "支払い方法がカードでのみ利用できます。",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "カード決済の場合のみキャプチャが可能。",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "カードタイプ判別不可の為、オーソリできません",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "キャプチャ実行日時は、現在から1時間後かつ7日以内である必要があります。",
    [ResponseErrorCode.IncoherentDateRange]: "日付の範囲が正しくありません",
    [ResponseErrorCode.InvalidMerchantStatus]: "加盟店のステータスが正しくありません",
    [ResponseErrorCode.MustHaveOneElement]: "少なくとも１つのエレメントが必要です",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "少なくとも１つのエレメントが必要です",
    [ResponseErrorCode.ThresholdMustBeNull]: "閾値は、nullである必要があります",
    [ResponseErrorCode.IllegalNumberOfItems]: "アイテム数が不正です",
    [ResponseErrorCode.UnableToReadCredentials]: "クレデンシャルを読み込むことができません",
    [ResponseErrorCode.GatewayError]: "ゲートウェイエラー",
    [ResponseErrorCode.GatewayNoLongerSupported]: "そのゲートウェイは、サポートしていません",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "課金金額の限度額が正しくありません",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "CVVの金額は、プラットフォームの通貨で指定してください",
    [ResponseErrorCode.ChargeAmountTooLow]: "課金金額が課金最少額より不足しています。",
    [ResponseErrorCode.ChargeAmountTooHigh]: "課金金額が課金最大額より超過しています。",
    [ResponseErrorCode.MustContainPlatformCurrency]: "プラットフォームの通貨で指定してください",
    [ResponseErrorCode.CurrencyMustBeInAmountsList]: "通貨で指定してください",
    [ResponseErrorCode.InvalidJapanesePostalCode]: "無効な日本の郵便番号",

    [ResponseErrorCode.BrandNotDefined]: "ブランドが未定義です",

    /* Used when creating a new Merchant */
    [ResponseErrorCode.OnlyASCII]: "Ascii文字のみサポートされています。",
    [ResponseErrorCode.UniqueCharacters]: "ユニークな文字",
    [ResponseErrorCode.AtLeastOneDigit]: "最低１つの番号",
    [ResponseErrorCode.AtLeastOneLetter]: "最低１つの文字",
    [ResponseErrorCode.EmptyRoles]: "空のロールを持つことはできません。",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "自分のロールは編集できません",
    [ResponseErrorCode.EditOwnStatusNotAllowed]: "自分のステータスは編集できません",
    [ResponseErrorCode.InvalidPathValue]: "不正なパス",
    [ResponseErrorCode.InvalidCardBrand]: "認識できないカードブランドです。",
    [ResponseErrorCode.UnsupportedCountry]: "その国は、サポートしていません。",
    [ResponseErrorCode.UnsupportedCurrency]: "その通貨は、サポートしていません。",
    [ResponseErrorCode.CannotBanSelf]: "自分を禁止ユーザーにすることはできません",
    [ResponseErrorCode.CannotSelfTerminate]: "Cannot Self Terminate",
    [ResponseErrorCode.NoDuplicateCurrencies]: "同じ通過を重複して登録することはできません",

    [ResponseErrorCode.PlatformNotFound]: "プラットフォームが見つかりません",
    [ResponseErrorCode.InvalidPlatform]: "プラットフォームの指定が正しくありません。",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "不正なインボイス費用の設定",
    [ResponseErrorCode.InvalidPlatformRole]: "プラットフォームのロールが不正です",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "データが提出されていません。",
    [ResponseErrorCode.NoBankAccount]: "銀行口座がありません。",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "料率が提出されていません。",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "システム管理者の情報が不十分です",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "クレデンシャルが既に存在しています。",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "1つのPaidyクレデンシャルのみ使用可能です",
    [ResponseErrorCode.OnlyOneDBaraiCredentialsAllowed]: "1つのd払いクレデンシャルのみ使用可能です",
    [ResponseErrorCode.OnlyMerchantOrStoreCredentialsAllowed]: "加盟店と店舗のクレデンシャルが必要です",
    [ResponseErrorCode.RefundExceedsChargeAmount]: "返金金額が課金金額を超過しています。",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "成功以外の状態の課金は返金できません。",
    [ResponseErrorCode.RefundNotAllowed]: "返金に対応していない支払い方法、もしくは返金が許可されませんでした。",
    [ResponseErrorCode.RefundPercentageExceeded]: "返金制限を超えています",
    [ResponseErrorCode.CancelNotAllowed]: "この支払い方法はキャンセルに対応していません。もしくは対象の課金のステータスはキャンセルできない状態です。",

    [ResponseErrorCode.PaidyShippingAddressNeedsOneOptionalField]: "Paidyの配送先には1つのオプションフィールドが必要です",

    [ResponseErrorCode.RevertNotAllowed]: "復帰は許可されていません",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Payが有効ではありません",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Payは既に有効です",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Apple Payの証明書は更新済みです",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "Apple Payでサポートされていないアルゴリズムです",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Apple Payの証明書が見つかりません",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay Unable To Generate Certificate Request",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay Invalid Configuration",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay Invalid Certificate",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay Invalid Algorithm",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay Invalid Certificate Format",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay Invalid Signature",
    [ResponseErrorCode.ApplePayError]: "Apple Pay Error",
    [ResponseErrorCode.ApplePayCertificateAlreadySet]: "Apple Payの証明書はすでに設定されています",
    [ResponseErrorCode.ApplePayCertificateStillInUse]: "Apple Payの証明書はまだ使われています",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "カード番号エラー",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "カードの有効月が無効です",
    [ResponseErrorCode.CardExpirationYearInvalid]: "カードの有効年が無効です",
    [ResponseErrorCode.CardExpired]: "有効期限エラー（期限切れ、入力間違い）",
    [ResponseErrorCode.CardCVVInvalid]: "セキュリティーコードエラー",
    [ResponseErrorCode.CardRejected]: "認証審査エラー（カード発行会社へお問い合わせください）",
    [ResponseErrorCode.CardInvalid]: "カードが無効です",
    [ResponseErrorCode.ChargeInvalidData]: "無効なリクエストデータです。",
    [ResponseErrorCode.TooManyChargeRequests]: "短時間に同じカードへの課金リクエストが多すぎます。しばらく時間をおいてから再度お試しください",
    [ResponseErrorCode.CancelUnavailable]: "この課金はキャンセル操作ができません",
    [ResponseErrorCode.ChargeExpired]: "課金の期限切れ",
    [ResponseErrorCode.SeizeCard]: "認証審査エラー（カード紛失・盗難・無効・制限など）",
    [ResponseErrorCode.ContactBank]: "認証審査エラー（カード発行会社へお問い合わせください）",
    [ResponseErrorCode.FraudSuspected]: "認証審査エラー（不正利用の疑い・セキュリティ制限）",
    [ResponseErrorCode.BankSystemFailure]: "カード会社側の取引エラー",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "ダイナミックディスクリプターがサポートされていません",
    [ResponseErrorCode.PaymentCodeInvalid]: "バーコード/QRコードが無効です",
    [ResponseErrorCode.PaymentCodeExpired]: "バーコード/QRコードの有効期限が切れています",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "バーコード/QRコードは既に使用されています",
    [ResponseErrorCode.PaymentCodeStillInUse]: "バーコード/QRコードは現在使用されています",
    [ResponseErrorCode.RejectedHighRisk]: "高リスクのしきい値を超えたため拒否されました",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "確認期間が終了しました",
    [ResponseErrorCode.RevertFailed]: "復帰に失敗しました。手動操作が必要です",
    [ResponseErrorCode.RefundFailed]: "払い戻しに失敗しました",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "お客様の資金が不足しています",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "メタデータフィールドの値が無効または欠落しています",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "国境を越えた取引は許可されていません。IDカードがありません",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "国境を越えた取引は許可されていません。電話番号がありません",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "国境を越えた取引は許可されていません。受け入れられない支払方法",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "国境を越えた取引は許可されていません。名前がありません",
    [ResponseErrorCode.LimitExceededForPaymentType]: "お支払い方法の取引制限を超えています",
    [ResponseErrorCode.LimitExceededForMerchant]: "アカウントの取引制限を超えています",
    [ResponseErrorCode.TransactionNotFound]: "取引が見つかりませんでした",
    [ResponseErrorCode.DuplicateTransaction]: "重複取引",
    [ResponseErrorCode.PaymentWalletRejected]: "お支払いウォレットが接続先システムで拒否されました",
    [ResponseErrorCode.InsufficientMerchantInformation]: "接続先システムが要求する情報がアカウントに不足しています",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "国境を越えた取引は許可されていません。受け入れられない通貨",
    [ResponseErrorCode.GatewayServerError]: "接続先システムエラー。お時間おいて再度お手続きお願い致します。",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "選択された支払いは接続先システムにより一時的に利用できません",
    [ResponseErrorCode.PaymentCanceled]: "お支払いはキャンセルされました",
    [ResponseErrorCode.ExceededPendingThreshold]: "決済処理の開始に時間がかかりました",
    [ResponseErrorCode.InternalError]: "内部システムエラー",

    /* online */
    [ResponseErrorCode.AlipayOnlineInvalidSignatureType]: "不正なAlipayOnlineサインタイプ",
    [ResponseErrorCode.PayPayOnlineInvalidPayMethod]: "不正なPayPayOnlineな支払方法",
    [ResponseErrorCode.PayPayOnlineInvalidMerchantId]: "不正なPayPayOnlineなMerchantID",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "証明書をリクエストできません",
    [ResponseErrorCode.InvalidMerchantSettings]: "加盟店の設定が正しくありません",
    [ResponseErrorCode.UnsupportedAlgorithm]: "サポートされていないアルゴリズムです",
    [ResponseErrorCode.InvalidPaymentToken]: "ペイメントトークンが不正です",
    [ResponseErrorCode.ExpiredPaymentToken]: "ペイメントトークンが期限切れです",
    [ResponseErrorCode.InvalidCertificate]: "不正な証明書です",
    [ResponseErrorCode.InvalidLeafCertificate]: "不正なリーフ証明書です",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "不正な中間証明書です",
    [ResponseErrorCode.InvalidChainOfTrust]: "不正な信頼チェーンです",
    [ResponseErrorCode.InvalidSignature]: "不正な署名です",

    /** Platform */
    [ResponseErrorCode.PlatformKeyExists]: "プラットフォームキーが存在します",
    [ResponseErrorCode.MerchantConsoleURIExists]: "マーチャントコンソールのURIが存在します",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "冪等性が保証されたリクエストの際に、指定された冪等性キーが以前に異なるAPIやメソッドで使用されています。",

    /* BannedCards */
    [ResponseErrorCode.CardBanned]: "禁止されたカード",
    [ResponseErrorCode.CardAlreadyBanned]: "既に禁止されたカード",
    [ResponseErrorCode.CardNotBanned]: "禁止されていないカード",
    [ResponseErrorCode.TestCardCannotBeBanned]: "テストカードは禁止できません。",

    /* Stats */
    [ResponseErrorCode.InvalidMetric]: "不正な単位",
    [ResponseErrorCode.InvalidResource]: "不正なリソース",

    /* Installments */
    [ResponseErrorCode.UseStartOn]: "定期課金開始日を使用",
    [ResponseErrorCode.PaymentInTimePeriod]: "期限内の支払",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "２回目の課金が実施されています",
    [ResponseErrorCode.NotSupportedByProcessor]: "ゲートウェイでサポートされていません。",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "定期課金はキャンセル済みです",
    [ResponseErrorCode.SubscriptionNotFound]: "定期課金が見つかりません",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "合計金額より少ない必要があります。",
    [ResponseErrorCode.InstallmentPlanNotFound]: "分割払いが見つかりません",
    [ResponseErrorCode.InstallmentAlreadySet]: "定期課金が既に開始しているため変更できません。",
    [ResponseErrorCode.InstallmentInvalidPlan]: "サポートされていない分割支払いプランです。",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "認識できない分割払いプランです。",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "分割払いの支払い方法として許可されていない支払い方法です。",
    [ResponseErrorCode.CardProcessorDisabledForInstallmentPlan]: "分割払いに対応していないカード会社です",
    [ResponseErrorCode.CardProcessorInstallmentFutureDated]: "分割払いの開始日を設定できないカード会社です",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "不正な分割払いの初回金額です",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "分割払いの支払期間が、設定された最大の支払期間を超過しています。",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "分割払いの一回あたりの金額が小さすぎます",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "リボ払いでは、初回の支払い金額を設定できません",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "リボ払いでは、定期課金の開始日を設定できません",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "このゲートウェイでは初回金額の指定はサポートされていません。",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "ゲートウェイでは、分割払いの期間がサポートされていません",
    [ResponseErrorCode.CannotChangeToken]: "この状態の定期課金のトランザクショントークンは変更できません。",
    [ResponseErrorCode.SubscriptionNotEnabled]: "定期課金は無効です",
    [ResponseErrorCode.SubscriptionAlreadyEnded]: "定期課金は既に終了しています。",
    [ResponseErrorCode.SubscriptionUnsupportedForInstallment]: "定期課金は、分割払いをサポートしていません",
    [ResponseErrorCode.InstallmentsNotEnabled]: "分割払いは無効です",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "分割金額を変更できません",
    [ResponseErrorCode.CannotChangeToInstallment]: "分割払いに変更することはできません",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "課金を行う頻度が monthly である必要があります。",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "２回以内でなければいけません",
    [ResponseErrorCode.Deprecated]: "非推奨のパラメータです。",
    [ResponseErrorCode.CannotBeZero]: "０を設定できません",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "少なくとも２回必要です",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "利用できない分割回数です。",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "初回金額を０に設定できません",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "定期課金が開始するまで設定できません",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "定期課金が開始後は、設定できません",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "キャンセルされた定期課金は変更することはできません。",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "CAFIS加盟店は、既に使用されています",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "CAFISストアは、既に使用されています",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "CAFISストアが割り当て数の上限に達しました",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "Invalid Elastic Index",
    [ResponseErrorCode.InvalidDynamoTable]: "Invalid dynamo table",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "Invalid Date Histogram Interval",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "Invalid SQS Endpoint",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "BIN番号が見つかりません",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhnチェックに失敗しました。",
    [ResponseErrorCode.InvalidCardNumberLength]: "カード番号の桁数が不適切です。",
    [ResponseErrorCode.CardPaymentDisabled]: "カードが利用できません。",
    [ResponseErrorCode.DebitDisabled]: "デビットカードが利用できません。",
    [ResponseErrorCode.PrepaidDisabled]: "プリペイドカードが利用できません。",
    [ResponseErrorCode.CountryNotSupported]: "サポートされていない国のカードです。",
    [ResponseErrorCode.Unspecified]: "不正なカード",
};

export default LABELS;
