import * as PAYMENT_LABELS from "../labels/payment";

export const LABELS: Record<string, string> = {
    [PAYMENT_LABELS.PAYMENT_ALERT_ERROR]: "支払い処理に失敗しました。",
    [PAYMENT_LABELS.PAYMENT_ALERT_PROCESSING]: "支払い処理中。このウィジットを閉じないでください。",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUCCESS]: "支払い処理に成功しました。",
    [PAYMENT_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS]: "お支払い情報の登録に成功しました。",
    [PAYMENT_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS]: "コンビニ払いの登録に成功しました。お近くの店舗をお訪ねの上、料金をお支払いください。",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS]: "定期支払いに成功しました。",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY]: "{money}のお支払い",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT]: "支払う",
    [PAYMENT_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO]: "支払う",
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS]: "local card対応",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_TITLE]: "コンビニ支払い情報",
    [PAYMENT_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT]: "コンビニ決済またはPay-easy",
    [PAYMENT_LABELS.PAYMENT_TIMEOUT]: "Processing payment timed out. Please contact support.",

    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS]: "お支払い情報の変更に成功しました。",
    [PAYMENT_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING]: "定期支払い更新中。このウィジットを閉じないでください。",

    // Pending form (Processing timeout)
    [PAYMENT_LABELS.TRANSACTION_TIMEOUT_MESSAGE]: "通信エラーとなりました。決済確認のため、下記サポートダイヤルまでお問い合わせください。",
    [PAYMENT_LABELS.SUPPORT_CENTER_FIRST_LINE]: "決済処理に関するお問い合わせ",
    [PAYMENT_LABELS.SUPPORT_CENTER_SECOND_LINE]: "株式会社ユニヴァ・ペイキャスト　サポートセンター",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN]: "TEL: 0570-035-672",
    [PAYMENT_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN]: "※国際電話、IP電話、PHSのお客様は06-6538-2060をご利用ください",
    [PAYMENT_LABELS.SUPPORT_EMAIL]: "e-mail: {email}",

    // Review
    [PAYMENT_LABELS.PAYMENT_DATA_CARD_TITLE]: "カード情報",
    [PAYMENT_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR]: "{brand} 末尾番号 {lastFour}",
    [PAYMENT_LABELS.PAYMENT_REVIEW_EXPIRY]: "有効期限 {month}/{year}",
};

export default LABELS;
