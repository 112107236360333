import { CheckoutParams, CheckoutType } from "common/types";
import {
    add,
    addMonths,
    format as formatDate,
    getDaysInMonth,
    isBefore,
    isSameDay,
    setDate,
    startOfDay,
    startOfMonth,
} from "date-fns";
import { parse as parsePeriod } from "iso8601-duration";
import {
    InstallmentPlan,
    ScheduleSettings,
    SubscriptionCreateParams,
    SubscriptionPeriod,
    TransactionTokenType,
} from "univapay-node";

import { convertSubscriptionPeriodToCyclical } from "../components/checkout/utils";
import { getNextPeriodTick } from "../components/flows/common/utils/period";
import { ApplicationParams } from "../redux/models/application";
import { PatchedCheckoutInfo } from "../redux/models/configuration";
import { PatchProductItem } from "../redux/models/product";

export type SubscriptionParams = Omit<
    SubscriptionCreateParams,
    "installmentPlan" | "transactionTokenId" | "amount" | "currency" | "period"
> & {
    period?: SubscriptionPeriod;
    cyclicalPeriod?: string;
    scheduleSettings: ScheduleSettings;
    installmentPlan?: {
        planType: InstallmentPlan;
        fixedCycles?: number;
        fixedCycleAmount?: number;
    };
    subscriptionPlan?: {
        planType: InstallmentPlan;
        fixedCycles?: number;
        fixedCycleAmount?: number;
    };
};

const computeSubscriptionStartDate = (params: CheckoutParams, product: PatchProductItem) => {
    const startAt = product?.subscriptionStartOn || params.subscriptionStart;
    const startIn =
        product?.subscriptionStartIn ||
        (product?.subscriptionStartInMonths ? `P${product?.subscriptionStartInMonths}M` : params.subscriptionStartIn);
    const startDayOfMonth = product?.subscriptionStartDayOfMonth || params.subscriptionStartDayOfMonth;

    if (!startAt && !startIn && !startDayOfMonth) {
        const ignoreFirstPayment =
            product?.subscriptionInitialAmount === 0 || (!product && params.subscriptionInitialAmount === 0);

        if (ignoreFirstPayment) {
            const nextCycle = convertSubscriptionPeriodToCyclical(
                product ? product.subscriptionPeriod || product.subscriptionCyclicalPeriod : params.subscriptionPeriod
            );

            return add(startOfDay(new Date()), parsePeriod(nextCycle));
        }

        // When no parameters are provided, this is a subscription with an immediate charge
        return null;
    }

    if (startAt) {
        // Ignore other parameters when start at is provided
        return new Date(startAt);
    }

    const today = startOfDay(new Date());
    const nextTick = add(today, parsePeriod(startIn || "P0M"));
    if (!startDayOfMonth) {
        return nextTick;
    }

    /*
     * In the case the start date is before or equal to today, shift it by one month
     *
     * For example:
     * 1. Today is the 23rd / The start date is the 20th / The startIn is set to P0M
     * As the day would be before today, set it to the next month instead of this month
     *
     * 2. Today is the 18th / The start date is the 20th / The startIn is set to P0M
     * As the day would be after today, do not offset it
     *
     * 3. Today is the 23th / The start date is the 20th / The startIn is set to P1M
     * As the day would be after today, do not offset it
     */
    const nextTickWithDate = setDate(nextTick, Math.min(getDaysInMonth(nextTick), startDayOfMonth));
    const shouldAddOneMonth = isBefore(nextTickWithDate, today) || isSameDay(nextTickWithDate, today);
    if (!shouldAddOneMonth) {
        return nextTickWithDate;
    }

    const shiftedNextTick = addMonths(startOfMonth(nextTickWithDate), 1);
    const shiftedStartDay = Math.min(getDaysInMonth(shiftedNextTick), startDayOfMonth);
    const shiftedNextTickWithDay = setDate(shiftedNextTick, shiftedStartDay);

    return shiftedNextTickWithDay;
};

const computeSubscriptionPlan = (
    params: CheckoutParams,
    product: PatchProductItem,
    hasImmediateCharge: boolean,
    subscriptionInitialAmount: number
) => {
    const hasSubscriptionPlan = !!params.subscriptionPlan;

    const planType = product?.subscriptionCycles
        ? InstallmentPlan.FIXED_CYCLES
        : params.subscriptionPlan || params.installmentPlan;

    const isFirstChargeNotInstallment =
        subscriptionInitialAmount === 0 || (hasImmediateCharge && !!subscriptionInitialAmount);

    const subscriptionCycles =
        product?.subscriptionCycles ||
        (planType === InstallmentPlan.FIXED_CYCLES
            ? hasSubscriptionPlan
                ? params.subscriptionQty
                : params.installmentQty
            : undefined);

    const fixedCycles = isFirstChargeNotInstallment ? subscriptionCycles + 1 : subscriptionCycles;
    const fixedCycleAmount = planType === InstallmentPlan.FIXED_CYCLE_AMOUNT ? params.subscriptionQty : undefined;

    const plan = {
        planType,
        fixedCycles,
        fixedCycleAmount,
    };

    return planType
        ? hasSubscriptionPlan || product?.subscriptionCycles
            ? {
                  subscriptionPlan: plan,
              }
            : {
                  installmentPlan: plan,
              }
        : null;
};

// TODO: Add shippingFees to link in public SDK
const computeShippingFees = (product: PatchProductItem[]) =>
    (product || []).reduce((acc, { shippingFees }) => acc + (Number(shippingFees) || 0), 0);

export const getCheckoutParams = (
    { checkout, params }: ApplicationParams,
    products: PatchProductItem[],

    // TODO: Update SDK to fix those types
    checkoutInfo: PatchedCheckoutInfo
) => {
    // Only one subscription product can be held at a time
    const isSubscription = isSubscriptionForm(params) || isSubscriptionProduct(products?.[0]);
    const subscriptionProduct = isSubscriptionProduct(products?.[0]) ? products[0] : undefined;

    const shippingFees = computeShippingFees(products);

    const subscriptionStartOn = computeSubscriptionStartDate(params, subscriptionProduct);

    const retryInterval = params.subscriptionRetryInterval || subscriptionProduct?.subscriptionRetryInterval;

    const scheduleSettings = isSubscription
        ? {
              zoneId: null,
              startOn: subscriptionStartOn ? formatDate(subscriptionStartOn, "yyyy-MM-dd") : null,
              ...(retryInterval ? { retryInterval } : null),
          }
        : null;

    const subscriptionInitialAmount = (() => {
        const paramAmount = subscriptionProduct?.subscriptionInitialAmount ?? params.subscriptionInitialAmount;
        const amount = (paramAmount || 0) + (shippingFees || 0);

        // Subscription with deferred start date should have 0 to prevent double charge.
        // Otherwise, 0 amount are not accepted so no initial amount should be undefined.
        const noAmountValue = paramAmount === 0 ? 0 : undefined;
        return amount || noAmountValue;
    })();

    const subscriptionPlanParams = isSubscription
        ? computeSubscriptionPlan(
              params,
              subscriptionProduct,
              isInitialChargeImmediate(subscriptionInitialAmount, scheduleSettings),
              subscriptionInitialAmount
          )
        : null;

    const amount = products?.length
        ? getAmountFromProduct(products)
        : subscriptionPlanParams?.subscriptionPlan
        ? params.amount + Number(subscriptionInitialAmount || 0)
        : params.amount;

    const bankTransferConfigExpirationPeriod = checkoutInfo?.bankTransferConfiguration?.expiration;
    const bankTransferConfigTimeShift = checkoutInfo?.bankTransferConfiguration?.expirationTimeShift?.enabled
        ? checkoutInfo.bankTransferConfiguration.expirationTimeShift.value
        : undefined;

    const convenienceStoreConfigExpirationPeriod = checkoutInfo?.convenienceConfiguration?.expiration;
    const convenienceStoreConfigTimeShift = checkoutInfo?.convenienceConfiguration?.expirationTimeShift?.enabled
        ? checkoutInfo.convenienceConfiguration.expirationTimeShift.value
        : undefined;

    return {
        mode: checkoutInfo?.mode,
        checkoutType: products ? CheckoutType.PAYMENT : checkout,

        amount: Number(amount || 0) + Number(shippingFees || 0),
        currency: products?.[0]?.currency || params.currency,

        // Common
        tokenType:
            params.tokenType === TransactionTokenType.RECURRING
                ? TransactionTokenType.RECURRING
                : subscriptionProduct || params.tokenType === TransactionTokenType.SUBSCRIPTION
                ? TransactionTokenType.SUBSCRIPTION
                : TransactionTokenType.ONE_TIME,
        tokenOnly: !products?.length && (!!params.subscriptionId || checkout === CheckoutType.TOKEN),
        univapayCustomerId: params.univapayCustomerId,
        shippingFees,

        // Expiration
        bankTransferExpirationPeriod:
            params.bankTransferExpirationPeriod || params.expirationPeriod || bankTransferConfigExpirationPeriod,
        bankTransferExpirationTimeShift:
            params.bankTransferExpirationTimeShift || params.expirationTimeShift || bankTransferConfigTimeShift,
        convenienceStoreExpirationPeriod:
            params.convenienceStoreExpirationPeriod ||
            params.expirationPeriod ||
            convenienceStoreConfigExpirationPeriod,
        convenienceStoreExpirationTimeShift:
            params.convenienceStoreExpirationTimeShift || params.expirationTimeShift || convenienceStoreConfigTimeShift,

        // Charge
        auth: !params.capture,
        captureOn:
            params.captureAt ||
            (params.captureIn ? formatDate(getNextPeriodTick(params.captureIn), "yyyy-MM-dd HH:mm") : null),

        // Subscription
        isSubscription,
        period: isSubscription
            ? subscriptionProduct?.subscriptionPeriod ||
              subscriptionProduct?.subscriptionCyclicalPeriod ||
              params.subscriptionPeriod
            : null,
        preserveEndOfMonth: isSubscription
            ? params.subscriptionPreserveEndOfMonth || subscriptionProduct?.subscriptionPreserveEndOfMonth
            : null,
        scheduleSettings,
        initialAmount: isSubscription ? subscriptionInitialAmount : undefined,

        ...subscriptionPlanParams,
        subscriptionId: subscriptionProduct ? undefined : params.subscriptionId,

        cvvAuthorize: params.cvvAuthorize,

        params,
    };
};

export const isSubscriptionForm = (params: CheckoutParams) =>
    params &&
    (params.tokenType === TransactionTokenType.SUBSCRIPTION ||
        (params.tokenType === TransactionTokenType.RECURRING && !!params.subscriptionPeriod));

export const isSubscriptionProduct = (product: PatchProductItem) =>
    product && product.tokenType === TransactionTokenType.SUBSCRIPTION;

const isInitialChargeImmediate = (initialAmount: number, scheduleSettings: ScheduleSettings) => {
    const { startOn } = scheduleSettings || {};

    const startOnDate = new Date(startOn);
    return !!initialAmount || !startOn || isBefore(startOnDate, new Date()) || isSameDay(startOnDate, new Date());
};

export const getAmountFromProduct = (products: PatchProductItem[]) =>
    products.reduce(
        (acc, { amount, subscriptionCycles, subscriptionInitialAmount }) =>
            acc + amount * (subscriptionCycles || 1) + (subscriptionCycles ? subscriptionInitialAmount || 0 : 0),
        0
    );
